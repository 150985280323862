$(document).ready(function(){
  $("body").on('click', '.ga-trackable', function(){
    var trackableItem = $(this)
    var category      = trackableItem.data('ga-category');
    var action        = trackableItem.data('ga-action');
    var label         = trackableItem.data('ga-label');
    ga('send','event', makeshiftGoogleAnalytics.categories[category], makeshiftGoogleAnalytics.actions[action], makeshiftGoogleAnalytics.label[label])
  });
});

/* Google Analytics Meta-data constants */

export var makeshiftGoogleAnalytics = {
  actions: {
    assign: "Assigned",
    click: "Clicked",
    destroy: "Destroy",
    drag: "Dragged",
    displayed: "Displayed",
    hidden: "Hidden"
  },

  categories: {
    accountActivation: "Account Activation",
    availableShift: "Available shift",
    breadCrumbNavigation: "Bread crumbs navigation",
    departmentCalendar: "Department calendar",
    passwordReset: "Password Reset",
    sideNavigation: "Side bar navigation",
    topNavigation: "Top bar navigation",
    userCalendar: "User calendar",
  },

  label:{
    analytics: "Analytics",
    analyticsAvailability: "Analytics availability",
    analyticsAvailableShifts: "Analytics available shifts",
    analyticsLabourBudget: "Analytics labour budget",
    analyticsLedger: "Analytics ledger breakdown",
    analyticsShiftExchange: "Analytics shift exchange",
    analyticsTurnover: "Analytics turnover",
    analyticsWorkforce: "Analytics workforce",
    byAvailability: "By availability",
    byRequest: "By request",
    dashboard: "Dashboard",
    departmentAvailable: "Department available shifts",
    departmentCalendar: "Department calendar",
    departmentEmployee: "Department employee",
    departmentExchange: "Department shift exchanges",
    departmentProfile: "Department profile",
    departmentRotation: "Department rotations",
    departments: "Departments",
    locations: "Locations",
    messages: "Messages",
    buttons: {
      publish_and_notify: "Publish & Notify"
    },
    published_shift: "Published Shift",
    rotation: "Rotation",
    showHidePassword: "Show / Hide Password",
    shift:"Shift",
    shiftOnAvailability: "Shift on Availability",
    timeOff: "Time off",
    uniqueDepartment: "Unique department",
    uniqueLocation: "Unique location",
    uniqueUser: "Unique user",
    unpublished_shift: "UnPublished Shift",
    userCalendar: "User calendar",
    userProfile: "User profile",
    users: "Users",
    weeklyBudgetBar: "Budget Bar"
  }
};


