<template>
  <div class="user-mass-assignment">
    <slot v-if="modal">
      <div class="modal">
        <div class="modal-header">
          <h3>{{modalHeaderText}}</h3>
        </div>
        <div class="modal-body">
          <slot v-if="attribute === 'departments'">
            <department-association-selection
              :key="0"
              :selected-users="selectedUsers"
              :new-row-trigger="newRowTrigger"
              :time-zone="timeZone"
              :trigger-disabled="rows > 1"
            />
            <department-association-selection
              v-for="n in rows - 1"
              :key="n"
              :selected-users="selectedUsers"
              :new-row-trigger="newRowTrigger"
              :time-zone="timeZone"
              :trigger-disabled="n !== rows - 1"
            />
          </slot>

          <slot v-else-if="attribute === 'employment_type_id'">
            <select autocomplete="off" @change="handleAttributeChange" class="overflow-hidden">
              <option  v-for="(type) in employmentTypes"
                :key="type.id" :value="type.id">
                {{type.name}}
              </option>
            </select>
          </slot>

          <slot v-else-if="attribute === 'role'">
            <div>
              <input
                type="radio"
                name="role"
                value="company_admin"
                @change="handleAttributeChange"
                :checked="value === 'company_admin'"
              />
              {{I18n.t('roles.company_admin')}}
              <input
                type="radio"
                name="role"
                class="margin-left"
                value="location_admin"
                @change="handleAttributeChange"
                :checked="value === 'location_admin'"
              />
              {{I18n.t('roles.location_admin')}}
              <input
                type="radio"
                name="role"
                class="margin-left"
                value="department_admin"
                @change="handleAttributeChange"
                :checked="value === 'department_admin'"
              />
              {{I18n.t('roles.department_admin')}}
              <input
                type="radio"
                name="role"
                class="margin-left"
                value="employee"
                @change="handleAttributeChange"
                :checked="value === 'employee'"
              />
              {{I18n.t("roles.employee")}}
            </div>
          </slot>

          <slot v-else-if="attribute === 'view_wages'">
            <div>
              {{I18n.t('user_mass_assignments.view_wages.body')}}
              <input
                type="hidden"
                name="view_wages"
                :value="true"
                :read-only="true"
              />
            </div>
          </slot>
        </div>
        <div class="modal-footer">
          <button
            class="btn"
            @click="handleCancel"
          >
            {{I18n.t('buttons.cancel')}}
          </button>
          <button
            class="btn btn-blue"
            @click="handleAssign"
            :disabled="processing"
          >
            {{assignmentButtonText}}
          </button>
        </div>
      </div>
      <div class="modal-backdrop in" />
    </slot>

    <select
      class="select uniform"
      @change="handleChange"
      :value="attribute"
      :disabled="selectedUsers.length === 0"
    >
      <option disabled value="default">{{I18n.t('user_mass_assignments.selected', {count: selectedUsers.length})}}</option>
      <option value="departments">{{I18n.t('user_mass_assignments.departments.title')}}</option>
      <option value="employment_type_id">{{I18n.t('user_mass_assignments.employment_type.title')}}</option>
      <option value="role">{{I18n.t('user_mass_assignments.role.title')}}</option>
      <option value="view_wages">{{I18n.t('user_mass_assignments.view_wages.title')}}</option>
    </select>
  </div>
</template>

<script>
import DepartmentAssociationSelection from './department_association_selection';

export default {
  name: 'UserMassAssignmentSelection',
  props: {
    currentUserId: Number,
    allAccessibleUserIds: Array,
    employmentTypes: Array,
  },
  data() {
    return {
      modal: false,
      value: false,
      processing: false,
      attribute: 'default',
      selectedUsers: [],
      rows: 1,
      timeZone: null,
      usersAndRoles: this.allAccessibleUserIds
    }
  },
  computed: {
    modalHeaderText() {
      switch (this.attribute) {
        case 'departments':
          return I18n.t('user_mass_assignments.departments.header', {count: this.selectedUsers.length});
          break;
        case 'employment_type_id':
          return I18n.t('user_mass_assignments.employment_type.header');
          break;
        case 'role':
          return I18n.t('user_mass_assignments.role.header');
          break;
        case 'view_wages':
          return I18n.t('user_mass_assignments.view_wages.header', {count: this.selectedUsers.length});
          break;
      }
    },

    assignmentButtonText() {
      if (this.processing) {
        return I18n.t('user_mass_assignments.processing');
      } else if (!this.processing && this.attribute === 'view_wages') {
        return I18n.t('user_mass_assignments.view_wages.title');
      }
      return I18n.t('user_mass_assignments.assign_button');
    }
  },
  mounted() {
    const self = this;

    $(document).on('datatable:drawn', function() {
      const userCheckBoxes = $('input[type=checkbox]').not('#all_users');
      const visibleUserIds = self.getVisibleUserIds(userCheckBoxes);

      self.selectedUsers = _.filter(self.selectedUsers, (userId) => _.contains(visibleUserIds, userId));

      $('#all_users').prop('checked', self.selectedUsers.length === visibleUserIds.length);

      userCheckBoxes.filter((index, input) => _.contains(self.selectedUsers, $(input).val()))
                    .prop('checked', true);
      userCheckBoxes.on('change', function() {
        let selectedUsersCopy = self.selectedUsers;

        // Add or remove userId to selectedUsers if the box is checked/unchecked
        if (this.checked) {
          selectedUsersCopy.push(this.value);
        } else {
          selectedUsersCopy = _.reject(selectedUsersCopy, (userId) => userId === this.value);
        }

        self.selectedUsers = selectedUsersCopy;
        $('#all_users').prop('checked', self.selectedUsers.length === self.getVisibleUserIds().length);
        $('select.select.uniform').uniform();
      });
    });

    $('#all_users').on('click', function() {
      const userCheckBoxes = $('input[type=checkbox]').not('#all_users');
      userCheckBoxes.prop('checked', this.checked);
      self.selectedUsers = this.checked ? self.getVisibleUserIds(userCheckBoxes) : [];
    });
  },

  updated() {
    $('select').uniform({ selectAutoWidth: false });
  },

  methods: {
    handleChange(event) {
      let defaultValue;

      switch(event.target.value) {
        case 'departments':
          defaultValue = {};
          break;
        case 'employment_type_id':
          defaultValue = '1';
          break;
        case 'role':
          const roles = this.selectedUsers.map((userId) => {
            const index = this.usersAndRoles.findIndex((i) => i[0] == userId);
            return this.usersAndRoles[index][1];
          });
          const unique = roles.filter((v, i, a) => a.indexOf(v) === i);

          if (unique.length == 1) {
            defaultValue = unique[0];
          } else {
            defaultValue = 'employee';
          }
          break;
        default:
          defaultValue = true;
          break;
      }

      this.modal      = true;
      this.processing = false;
      this.value      = defaultValue;
      this.attribute  = event.target.value;
    },

    handleCancel() {
      this.modal      = false;
      this.processing = false;
      this.value      = false;
      this.rows       = 1;
      this.timeZone   = null;
      this.attribute  = 'default';
    },

    handleAssign() {
      const userIdsArray = this.selectedUsers.map((userId) => userId);
      const data = { 'user_ids': userIdsArray };

      this.processing = true;

      if (this.attribute === 'departments') {
        const associated_ids = $('.user-mass-assignment.row-fluid').map(function() {
          const department_id = $(this).find('select[name="department_id"]').val();
          const position_id = $(this).find('select[name="position_id"]').val();
          const job_site_id = $(this).find('select[name="job_site_id"]').val();
          return { department_id, position_id, job_site_id };
        });

        data['associated_ids'] = associated_ids.toArray();

        // Wait for channel subscription to be completed before making API call to avoid race condition.
        const pusherChannel = this.subscribeToMassAssignmentChannel();
        pusherChannel.bind('pusher:subscription_succeeded', () => this.postMassAssignments(data, null));
      } else {
        data['attribute'] = {};
        data['attribute'][this.attribute] = this.value;
        this.postMassAssignments(data, this.handleComplete);
      }
    },

    handleAttributeChange(event) {
      this.value = event.target.value;
    },

    handleComplete(data) {
      this.displayFlashMessage(data.status, data.message);

      if (data.user_ids_and_roles) {
        this.usersAndRoles = data.user_ids_and_roles;
      }

      const dataTable = $('table#users_list').dataTable();
      dataTable.fnReloadAjax('users.json', this.handleCancel, true);
    },

    subscribeToMassAssignmentChannel() {
      const pusher = window.getPusherInstance(window.config.pusherKey);
      const pusherChannel = pusher.subscribe(`user-mass-assignment-${this.currentUserId}`);
      pusherChannel.bind('user-mass-assignment-complete', this.handleComplete);
      return pusherChannel;
    },

    postMassAssignments(data, successCallback) {
      $.ajax({
        method: 'POST',
        url: '/user_mass_assignments',
        data: data,
        success: successCallback,
        error: (response) => {
          this.handleCancel();
          let status, errorMessage;
          const responseData = response.responseJSON;
          if (responseData && responseData.message) {
            status = responseData.status;
            errorMessage = responseData.message;
          } else {
            status = 'error';
            errorMessage = I18n.t('user_mass_assignments.error');
          }
          this.displayFlashMessage(status, errorMessage);
        },
      });
    },

    newRowTrigger(timeZone) {
      this.rows++;
      this.timeZone = timeZone;
    },

    // Allow passing in the array of user checkboxes
    // to avoid parsing through the DOM more than necessary.
    getVisibleUserIds(userCheckBoxes = null) {
      if (!userCheckBoxes) {
        userCheckBoxes = $('input[type=checkbox]').not('#all_users');
      }
      return $.map(userCheckBoxes, (checkBox) => checkBox.value);
    },

    displayFlashMessage(status, messageText) {
      const messageHtml = `
        <div class="alert alert-${status}">
          <a class="close" data-dismiss="alert">×</a>
        <div id="flash_error">${messageText}</div></div>
      `;
      $('body #flash-messages').html(messageHtml);
    },
  },
  components: {
    DepartmentAssociationSelection,
  }
}
</script>

<style scoped>
  .modal-body {
    overflow-x: hidden;
  }
</style>
