<template>
  <label :class="{ 'ui-checkbox-container': true, disabled }">
    <input
      v-if="defaultChecked"
      type="checkbox"
      checked="checked"
      :id="id"
      :name="name"
      :class="inputClass"
      :disabled="disabled"
      @change="$emit('change', $event)"
    />
    <input
      v-else
      type="checkbox"
      :id="id"
      :name="name"
      :class="inputClass"
      :disabled="disabled"
      @change="$emit('change', $event)"
    />
    <span :class="checkboxClass"></span>
  </label>
</template>

<script>
export default {
  name: "UiCheckbox",
  props: {
    disabled: Boolean,
    defaultChecked: Boolean,
    small: Boolean,
    id: String,
    name: String,
    inputClass: String,
    icon: {
      type: String,
      default: "check",
      validator: (val) => ["check", "dash"].includes(val),
    },
  },
  computed: {
    checkboxClass() {
      return {
        "ui-checkbox": true,
        check: this.icon === "check",
        dash: this.icon === "dash",
        small: this.small,
      };
    },
  },
  example: `
<template>
  <div>
    <h4 class="my-6 border-b">UiCheckbox</h4>
    <div>
      <ui-checkbox></ui-checkbox>
      <ui-checkbox small></ui-checkbox>
      <ui-checkbox default-checked></ui-checkbox>
      <ui-checkbox small default-checked></ui-checkbox>
      <ui-checkbox default-checked icon="dash"></ui-checkbox>
      <ui-checkbox small default-checked icon="dash"></ui-checkbox>
    </div>
  </div>
</template>

<script>
  export default {}
<\/script>
`,
};
</script>

<style lang="scss">
.ui-checkbox-container {
  @apply #{ relative inline-block cursor-pointer text-base select-none pl-8 };

  &.disabled {
    @apply #{ cursor-not-allowed };
  }

  input:checked ~ .ui-checkbox {
    @apply #{ bg-blue border-blue };
  }

  input:checked ~ .ui-checkbox:after {
    @apply #{ block };
  }

  input:disabled ~ .ui-checkbox {
    @apply #{ bg-gray-300 border-gray-400 pointer-events-none };
  }

  input {
    @apply #{ absolute opacity-0 top-0 left-0 z-10 mt-0 };
  }

  .ui-checkbox {
    @apply #{ absolute top-0 left-0 h-5 w-5 bg-white rounded border border-line transition-all duration-100 };

    &.check:after {
      @apply #{ absolute hidden border-white border-r-2 border-b-2 };
      content: "";
      top: 1px;
      left: 7px;
      height: 13px;
      width: 4px;
      transform: rotate(45deg);
    }

    &.dash:after {
      @apply #{ absolute hidden border-white border-b-2 };
      content: "";
      top: 7px;
      left: 5px;
      height: 2px;
      width: 10px;
    }
  }

  .ui-checkbox.small {
    @apply #{ h-4 w-4 };

    &.check:after {
      content: "";
      top: 1px;
      left: 5px;
      height: 9px;
      width: 4px;
      transform: rotate(45deg);
    }

    &.dash:after {
      content: "";
      top: 5px;
      left: 4px;
      height: 2px;
      width: 8px;
    }
  }
}
</style>
