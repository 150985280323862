import { formatPartialUnavailableTimes } from 'core/helpers/format_helpers.js';
export var rotations = (function($) {

  var init = function(settings) {
    $.extend(config, {
      departmentId:                       settings.departmentId,
      routes: {
        departmentPositionsPath:          '/departments/'+settings.departmentId+'/rotations/edit_position',
        departmentUnavailableTypesPath:   '/departments/'+settings.departmentId+'/rotations/edit_unavailable_type'
      },
      selectors: {
        rotationName:           $('#rotation_name'),
        rotationDescription:    $('#rotation_description'),
        rotationDraggables:     $('#rotation-draggables'),
        rotationDroppable:      $('#drag-rotation'),
        rotationLength:         $('#rotation_length'),
        rotationPattern:        $('#rotation_blueprint'),
        rotationDays:           $('#rotation_days'),
      }
    });

    buildDraggableBlocks();
    $("#initialize_rotation").on("click", initializeRotation);
    $("#create_rotation").on("click", createRotation);
    $("#update_rotation").on("click", updateRotation);
    $("#cancel_changes").on("click", cancelChanges);
    loadRotation();
    setupDraggableItems();
    setDraggablesWidth();
    $(window).resize(function() { setDraggablesWidth(); });
    $(window).on('scroll', function() { fixDraggables(); });
  };

  var config = {};

  /* ---- Build and initialize the rotation pattern */
  // MKS.shift_types = { id1: [title, title_text], id2: [title, title_text] }
  var buildDraggableBlocks = function() {
    if (MKS.shift_types === undefined) return;
    var shift = $("#shift_type");
    var unavailable = $("#unavailable");

    $.each(MKS.shift_types, function(key, value) {
      shift.append('<li class="span6 long-names" id="' + key + '" data-keyword="' + value[1] + '" style="margin-left: 2px;">' + value[0] + '</li>');
    });

    unavailable.append('<li class="span6 long-names unavailable" id="0" data-keyword="Time Off" style="width: 100%;"><strong>' +
      I18n.t('rotations.js.time_off_short').replace(' ', '<br>') +
      '</strong></li>'
    );
  };

  var setupDraggableItems = function() {
    $("#shift_type li, #unavailable li").draggable({
      revert: 'invalid',
      containment: 'document',
      helper: function() {
        var helper = $(this).clone();
        helper.css('height','40px');
        helper.css('width','80px');
        helper.find('span.shift-type-line2').hide();
        helper.removeClass('span6');
        return helper;
      },
      cursor: 'move',
      opacity: 0.8
    });
  };

  var loadRotation = function(){
    if(config.selectors.rotationLength.length > 0) {
      var num_rotations = get_rotation_in_days($('input[type=radio]:checked').val(), config.selectors.rotationLength.val());
      buildRotationDays(num_rotations);
      if(config.selectors.rotationPattern.val().length > 0){
        var pattern = JSON.parse(config.selectors.rotationPattern.val());
        buildPattern(pattern);
      }
    }
  };

  var buildRotationDays = function(num_rotation_days) {
    var rotation_days = config.selectors.rotationDays;
    rotation_days.empty();
    var num_week = 0;
    for(var i=0; i < num_rotation_days; i++) {
      if (i % 7 === 0) {
        num_week = num_week + 1;
        var rotation_week = $('<div class="row-fluid rotation_week"></div>').appendTo(rotation_days);
        rotation_week.append('<div class="week_day">' +
          I18n.t('rotations.js.week_label', { num_week: num_week }) +
          '</div>'
        );
      }
      rotation_week.append(
        '<div class="rotation_day" id="rotation_day' + i + '"><div class="shift_block"><div class="placeholder"><div class="day-tag"><strong>' +
        I18n.t('rotations.js.day', { day_num: i+1 }) +
        '</strong></div></div></div></div>'
      );
      var $rotation_day = $("#rotation_day" + i);
      $rotation_day.droppable({
        activeClass: "ui-state-default",
        hoverClass: "ui-state-hover",
        accept: ":not(.uii-sortable-helper)",
        drop: handleDrop
      })
      $rotation_day.data({day_index: i});
    }
  };

  var buildPattern = function(rotations_pattern) {
    for(var i=0; i < Object.keys(rotations_pattern).length; i++) {
      var shift_types = rotations_pattern[i];

      // Empty rotation day
      if (shift_types == null) {
        var shift_type_id = null;
        var shift_type = '<div class="day-tag"><strong>' + I18n.t('rotations.js.day', { day_num: i + 1 }) + '</strong></div>';
        writeShiftTypeToDiv($("#rotation_day" + i), shift_type, shift_type_id);
      }
      // An all-day unavailable (there can be only one!)
      else if (shift_types[0].hasOwnProperty('unavailable_type_id') && !shift_types[0].partial) {
        var $tempDiv = $('<div>');
        writeUnavailableTypeToDiv($tempDiv, shift_types[0]);
        var $addedDiv = writeShiftTypeToDiv($("#rotation_day" + i), $tempDiv.html(), '0');
        assignUnavailableData($addedDiv, shift_types[0], i);
      }
      // A "shift" (either a shift template or a partial unavailable)
      else {
        $(shift_types).each(function(index, shift_type) {
          var shift_type_id = shift_type.shift_type_id;

          // A partial day unavailable
          if (shift_type.hasOwnProperty('unavailable_type_id')) {
            var $tempDiv = $('<div>');
            writeUnavailableTypeToDiv($tempDiv, shift_type);
            var $addedDiv = writeShiftTypeToDiv($("#rotation_day" + i), $tempDiv.html(), '0');
            assignUnavailableData($addedDiv, shift_type, i);
          }
          // A rotation shift template -- but there's no guarantee the shift type/template still exists
          else if (MKS.shift_types[shift_type.shift_type_id]) {
            var shift_type_html = MKS.shift_types[shift_type.shift_type_id][0];
            var shift_type_div = writeShiftTypeToDiv($("#rotation_day" + i), shift_type_html, shift_type_id);
            writePositionToDiv(shift_type_div, shift_type.position_id, shift_type.job_site_id);
          }
        });
      }
    }
  };

  var initializeRotation = function() {
    if(validateRotationLength())
    {
      // Reset pattern if generate is clicked again.
      var pattern = [];
      var num_rotations = config.selectors.rotationLength.val().trim();
      if(!isPositiveInteger(num_rotations) || num_rotations < 1) {
        bootbox.alert(I18n.t('rotations.js.rotation_error'));
      } else {
        $("#drag-rotation").show();
        var num_rotation_days = get_rotation_in_days($('input[type=radio]:checked').val(), num_rotations)
        buildRotationDays(num_rotation_days);
      }
      setDraggablesWidth();
    }
  };
  /* Build and initialize the rotation pattern ---- */

  /* ---- Drag n drop shift types */
  var handleDrop = function(e, ui) {
    // Unavailable (full or partial)
    // Can't be validated until the modal is closed, because we don't know what times the user selected
    if (ui.draggable.attr('id') === '0') {
      createUnavailableTypeModal($(this));
    }
    // Shift template
    else {
      if (validateShiftTypeHours($(this), ui.draggable.find('span').text())) {
        var new_div = writeShiftTypeToDiv($(this), ui.draggable.html(), ui.draggable.attr('id'));
        setTimeout(function() {createPositionTip($(this), new_div)}, 150);
      } else {
        bootbox.alert(I18n.t('rotations.js.shift_conflict'));
      }
    }
  };

  var writeShiftTypeToDiv = function(rotation_day_div, shift_html, new_shift_type_id) {
    // Time Off       -> Shift Type: replaces it
    // Time Off       -> "Day n": replaces it
    // Shift Type     -> "Day n": replaces it
    // Shift Type     -> Time Off: replaces it
    // Shift Type     -> Shift Type: adds to the list
    if (rotation_day_div.find(".day-tag").length !== 0) {
      var addedDiv = replaceShiftTypeInDiv(rotation_day_div, shift_html);
    } else {
      addedDiv = addShiftTypeToDiv(rotation_day_div, shift_html);
    };

    if (rotation_day_div.find(".day-tag").length == 0) {
      if (new_shift_type_id === "0") {
        highlight(addedDiv, '##cccccc', '#707070', 'none');
        addedDiv.data('unavailable_type_id', null);
        $(addedDiv).parent().addClass('unavailable');
      } else {
        highlight(addedDiv, document.config.msUtilColor, 'white', '1px solid');
        $(addedDiv).parent().addClass('scheduled');
      };
      addShiftTypeData(addedDiv, new_shift_type_id, rotation_day_div);
      addHover(addedDiv);
    };

    return addedDiv;
  };

  var addShiftTypeToDiv = function(rotation_day_div, shift_html) {
    var goesAfter = false;
    var existing_ids = [];
    $.each(rotation_day_div.find('.rotation_shift'), function(index, shift) {existing_ids[index] = $(shift).attr('id')});
    $.each(existing_ids, function(index, id_string){existing_ids[index] = parseInt(id_string.match(/\d+/g)[1])});
    var index = Math.max.apply(Math, existing_ids) + 1;

    var new_shift_type_id = rotation_day_div.attr('id')+"_shift"+index;
    var $shift = $("<div class='shift_block'><div class='placeholder rotation_shift' id='"+new_shift_type_id+"'>"+shift_html+"</div></div>");
    var new_shift_hours = parseShiftTypeHours($shift.find('span').text(), rotation_day_div.data('day_index'));

    var existing_shifts = rotation_day_div.find('.rotation_shift');
    // Comparision to all the other existing shift types to insert in chronologically appropriate spot
    var previous_shift;
    $(existing_shifts).each(function(index, existing_shift) {
      if (new_shift_hours.start_time > $(existing_shift).data('hours').start_time) {
        goesAfter = true;
        previous_shift = existing_shift;
      }
    });

    if (goesAfter) {
      $shift.insertAfter($(previous_shift).parents('.shift_block'));
    } else {
      $(rotation_day_div).prepend($shift);
    }

    return $('#'+new_shift_type_id); //return the newest shift type div so qtip can put the position picker on it.
  };

  var replaceShiftTypeInDiv = function(rotation_day_div, shift_html) {
    var new_shift_type_id = rotation_day_div.attr('id')+'_shift0';
    $(rotation_day_div).find(".shift_block").remove();
    $(rotation_day_div).append('<div class="shift_block"><div class="placeholder rotation_shift" id="'+new_shift_type_id+'">'+shift_html+'</div></div>');

    return $('#'+new_shift_type_id);
  };
  /* Drag n drop shift types ---- */

  /* ---- Additions to shift type blocks */
  var highlight = function(rotation_day_div, backgroundColor, textColor, border) {
    rotation_day_div.parent('.shift_block').css('background-color', backgroundColor);
    rotation_day_div.parent('.shift_block').css('color', textColor);
    rotation_day_div.parent('.shift_block').css('border', border);
  };

  var addShiftTypeData = function(rotation_shift_div, shift_type_id, rotation_day_div) {
    rotation_shift_div.data('shift_type_id', shift_type_id);
    if (shift_type_id !== '0') {
      rotation_shift_div.data('hours', parseShiftTypeHours(rotation_shift_div.find('span').text(), rotation_day_div.data('day_index')));
    };
  };

  var parseShiftTypeHours = function(hours_str, day_index) {
    var hours = hours_str.split(/ /);
    var start_time = timeToSeconds(hours[0]);
    var end_time = timeToSeconds(hours[2].replace(/[()]/g, ''));

    if (end_time <= start_time) {
      end_time = end_time + 86400;
    }

    start_time = start_time + day_index * 86400;
    end_time   =   end_time + day_index * 86400;

    return {'start_time': start_time, 'end_time': end_time};
  };

  var addHover = function(rotation_day_div) {
    rotation_day_div.parent('.shift_block').hover(handlerIn, handlerOut);
  };

  var handlerIn = function() {
    $(this).prepend('<div class="remove">' + I18n.t('buttons.remove') + '</div>');
    $(this).find('div.placeholder').hide();
    $(this).on('click', removeShiftType);
  };

  var handlerOut = function() {
    $(this).find('div.placeholder').show();
    $(this).find('div.remove').remove();
  };

  var applyPositionSelectChange = function(selection, new_div) {
    var positionValue = selection.find('#department_positions option:selected').val();
    var jobSiteValue = selection.find('#department_job_sites option:selected').val();
    writePositionToDiv(new_div, positionValue, jobSiteValue);
    $('div.qtip.position-select').remove();
  };

  var writeUnavailableTypeToDiv = function($time_off_div, unavailable_data) {
    // Set the name
    var unavailable_type_name = MKS.unavailable_types[unavailable_data.unavailable_type_id] || I18n.t('rotations.js.time_off_short');

    var $name_div = $('<div class="unavailable-type-name">');
    var $name     = $('<strong>');
    $name.text(unavailable_type_name);
    $name_div.append($name);
    $time_off_div.append($name_div);

    // Set the hours text, and also the data for the corresponding parsed times
    if (unavailable_data.partial) {
      var $hours_span = $('<span>');
      $hours_span.text(formatPartialUnavailableTimes(unavailable_data.start_time, unavailable_data.end_time));
      $time_off_div.append($hours_span);
    } else {
      $time_off_div.append(I18n.t('unavailable.full_day'));
    }
  };

  // Assign data attributes to an Unavailable
  var assignUnavailableData = function($time_off_div, unavailable_data, day_index) {
    if (unavailable_data.partial) {
      $time_off_div.data('hours',
        parseShiftTypeHours(
          formatPartialUnavailableTimes(unavailable_data.start_time, unavailable_data.end_time),
          day_index
      ));
    }

    // Set the data for reference
    $time_off_div.data(unavailable_data);
  };

  var writePositionToDiv = function(shift_type_div, position_id, job_site_id) {
    var requirementLabels = [];
    if (position_id != null && Object.keys(MKS.positions).indexOf(position_id) > -1) {
      requirementLabels = requirementLabels.concat(MKS.positions[position_id]);
      shift_type_div.data('position_id', position_id);
    }
    if (job_site_id != null && Object.keys(MKS.job_sites).indexOf(job_site_id) > -1) {
      requirementLabels = requirementLabels.concat(MKS.job_sites[job_site_id]);
      shift_type_div.data('job_site_id', job_site_id);
    }

    if (requirementLabels.length > 0) {
      shift_type_div.find('span').replaceWith('<span>'+requirementLabels.join('<br>')+'</span>');
    }
  };
  /* Additions to shift type blocks ---- */

  /* ---- Validators */
  var validateRotationLength = function() {
    var isValid = true;

    if ($("input[type=radio][id*=rotation_time_unit]:checked").val() == "0") {
      if (config.selectors.rotationLength.val() > 365 || config.selectors.rotationLength.val() == "")
      {
        isValid = false;
      }
    }
    else if ($("input[type=radio][id*=rotation_time_unit]:checked").val() == "1") {
      if (config.selectors.rotationLength.val() > 52 || config.selectors.rotationLength.val() == "")
      {
        isValid = false;
      }
    }

    if (!isValid) {
      bootbox.alert(I18n.t('rotations.js.rotation_error'));
    }

    return isValid;
  };

  // If the rotation extends overnight on the last day,
  // create two separate blocks of existing time to account for the wraparound
  var unwrapRotationShifty = function(shifty, rotationLength) {
    var wrap = shifty.end_time > rotationLength * 86400;
    if (wrap) {
      return [
        {
          start_time: shifty.start_time,
          end_time:   rotationLength * 86400
        }, // up to last day's midnight,
        {
          start_time: 0,
          end_time:   shifty.end_time - rotationLength * 86400
        } // wrapped around to beginning of rotation
      ];
    } else {
      return [shifty, shifty];
    }
  };

  // Compare two shift-like objects, either one of which may wrap around the end of a rotation to the beginning
  var doShiftysConflict = function(shiftyA, shiftyB, rotationLength) {
    var as = unwrapRotationShifty(shiftyA, rotationLength);
    var bs = unwrapRotationShifty(shiftyB, rotationLength);

    // Check all possible pairwise conflicts between the four shift-like objects
    var conflict =
      (as[0].start_time < bs[0].end_time && bs[0].start_time < as[0].end_time) ||
      (as[1].start_time < bs[0].end_time && bs[0].start_time < as[1].end_time) ||
      (as[0].start_time < bs[1].end_time && bs[1].start_time < as[0].end_time) ||
      (as[1].start_time < bs[1].end_time && bs[1].start_time < as[1].end_time);
    return conflict;
  };

  // Examine the current, previous, and next days and return all shift hours
  var shiftHoursInSurroundingDays = function(idx, $rotation_days) {
    var existing_shift_type_hours = [];

    var rotationLength = $rotation_days.length;

    // Examine both current day, previous, and next days for possible conflicts
    // Wrap around so that if we're on the first day of the rotation we check the last, and vice versa
    var indices = [idx];
    indices.push(idx === 0 ? (rotationLength - 1) : idx - 1); // previous day
    indices.push((idx + 1) % rotationLength); // next day

    indices.forEach(function(idx) {
      $rotation_days.eq(idx).find('.rotation_shift').map(function() {
        var hours = $(this).data('hours');
        if (hours !== undefined) {
          existing_shift_type_hours.push(hours);
        }
      });
    });

    return existing_shift_type_hours;
  };

  // Compares the newly dragged rotation object (shift/unavailable) with the existing rotation day content
  var validateShiftTypeHours = function($rotation_day, shift_hours_str) {

    var allDay = !shift_hours_str;

    // If the added shift is all-day, validity is based solely upon whether there is existing "stuff" in the slot
    // All-day unavailables don't "backward" conflict -- they don't conflict with shifts going overnight into the next day
    if (allDay) {
      return $rotation_day.find('.scheduled,.unavailable').length <= 0;
    }

    var isValid = true;

    // If the rotation day already contains an all-day, we have a conflict
    $.each($rotation_day.find('.unavailable .rotation_shift'), function(idx, unavailable) {
      if (!$(unavailable).data('partial')) {
        isValid = false;
      }
    });
    if (!isValid) return isValid;

    var new_shift_type_hours = parseShiftTypeHours(shift_hours_str, $rotation_day.data('day_index'));

    var existing_shift_type_hours = shiftHoursInSurroundingDays($rotation_day.data('day_index'), $('.rotation_day'));

    $.each(existing_shift_type_hours, function(index, existing_hours) {
      var conflict = doShiftysConflict(new_shift_type_hours, existing_hours, $('.rotation_day').length);
      isValid = isValid && !conflict;
    });

    return isValid;
  };

  var validateRotation = function(pattern) {
    if(config.selectors.rotationName.val().trim() == "") {
      bootbox.alert(I18n.t('rotations.js.name_required'));
      return false;
    }

    var pattern_keys = Object.keys(pattern);
    var pattern_values = [];
    $.each(pattern_keys, function(index) {
      pattern_values[index] = pattern[index];
    });
    var unique_pattern_values = unique(pattern_values);

    if(pattern_keys.length === 0 || (unique_pattern_values.length === 1 && unique_pattern_values[0] === null)){
       bootbox.alert(I18n.t('rotations.js.no_shifts'));
      return false;
    }
    return true;
  };
  /* Validators ---- */

  /* ---- JS helper functions */
  var unique = function(array) {
    return $.grep(array,function(el,index){
        return index == $.inArray(el,array);
    });
  };

  var isPositiveInteger = function(s) {
    return(/^\d+$/.test(s));
  };

  var get_rotation_in_days = function(time_unit, num_rotations) {
    var rotation_in_days = (time_unit === "1") ? (num_rotations * 7) : num_rotations;
    return rotation_in_days;
  };

  var timeToSeconds = function(time) {
    time = time.split(/:/);
    var hours = parseInt(time[0].replace('12', '0'));
    var minutes_string = time[1];
    //check for p (as in am/pm)
    if (minutes_string.includes('p')) {
      hours = hours + 12;
    }
    var minutes = parseInt(minutes_string.replace(/\D/g,''));

    return hours * 3600 + minutes * 60;
  };
  /* JS helper functions ---- */

  /* ---- qtip for positions */
  var createPositionTip = function($div, $new_div) {
    // place position tool tip on the parent of the new div.
    // This was the cleanest solution to the tooltip not always placing itself on the new div.
    $new_div.parent('.shift_block').qtip({
      content: {
        text: function(event, api) {
          $.get(config.routes.departmentPositionsPath)
          .then(function(html) {
            api.set('content.text', html);
          }, function(xhr, status, error) {
            api.set('content.text', status + ': ' + error);
          });
          return '<span class="muted" style="font-size:10px;">' + I18n.t('rotations.js.select_position') + '</span>';
        }
      },
      position: {
        my: 'center left',
        at: 'center right',
        viewport: config.selectors.rotationDays,
        adjust: { x: -10, method: 'flip none'}
      },
      show: true,
      hide: 'unfocus',
      style: {
        classes: 'qtip-bootstrap position-select'
      },
      events: {
        // readjust x on flip
        move: function(event, api, position, viewport) {
          if (position.adjusted.left != 0) { position.left += 20 }
        },
        // remove all qtips on hide
        hide: function(event, api) {
          applyPositionSelectChange($(this), $new_div);
          $('div.qtip.position-select').remove();
        }
      }
    });
  };

  var createUnavailableTypeModal = function($rotation_day) {
    $('#unavailable-type-modal').load(config.routes.departmentUnavailableTypesPath, function() {
      $(this).data('rotation_day', $rotation_day);
      $(this).modal({
        backdrop: 'static',
        show: true
      });
    });

    $('#unavailable-type-modal').off('ok').on('ok', function() {
      var $this = $(this);
      var $rotation_day = $this.data('rotation_day');
      var hours_str = null;
      if ($this.data('partial')) {
        hours_str = formatPartialUnavailableTimes($this.data('start_time'), $this.data('end_time'));
      }
      if (validateShiftTypeHours($rotation_day, hours_str)) {
        var $tempDiv = $('<div>');
        writeUnavailableTypeToDiv($tempDiv, $this.data());
        var $addedDiv = writeShiftTypeToDiv($this.data('rotation_day'), $tempDiv.html(), '0');
        assignUnavailableData($addedDiv, $this.data(), $rotation_day.data('day_index'));
      } else {
        bootbox.alert(I18n.t('rotations.js.shift_conflict'));
      }
    });
  };

  var removeShiftType = function() {
    var day_block = (String($(this).find('.rotation_shift')[0].id).match(/[0-9]+/g)[0]);

    $(this).find('div.remove').remove();
    $('div.qtip.position-select').remove();
    day_block = parseInt(day_block) + 1;
    if ($(this).parents('.rotation_day').find('.shift_block').length == 1) {
      $(this).replaceWith("<div class='shift_block'><div class='placeholder'><div class='day-tag'><strong>" + I18n.t('rotations.js.day', { day_num: day_block }) + "</strong></div></div></div>");
    } else {
      $(this).remove();
    }
    highlight($(this).find('.rotation_shift'), '#FFFFFF', '#707070', 'none');
    $(this).unbind("mouseenter mouseleave");
  };

  // Convert the rotation days into a saveable "pattern"
  var updatePattern = function(rotation_days) {
    // updatePattern takes the rotation pattern as is organized on the front end and builds a JSON object that the back end will be able to consume.
    var pattern = {};

    rotation_days.find('.rotation_day').each(function(day_index, rotation_day) {
      rotation_day = $(this);
      pattern[day_index] = [];

      // An empty day
      if (rotation_day.find(".day-tag").length != 0) {
        pattern[day_index] = null;
      }
      // An all-day unavailable (can be the only thing on a day)
      else if (rotation_day.find('.rotation_shift').data().hasOwnProperty('unavailable_type_id') &&
              !rotation_day.find('.rotation_shift').data().partial) {
        pattern[day_index][0] = {};
        pattern[day_index][0]['unavailable_type_id'] = rotation_day.find('.rotation_shift').data('unavailable_type_id');
      }
      // A "shift" (either a partial-day unavailable OR a shift template)
      else {
        rotation_day.find('.rotation_shift').each(function(shift_index, rotation_shift) {
          rotation_shift = $(this);

          pattern[day_index][shift_index] = {};

          // A partial day unavailable (doesn't save the invalid shift type of 0)
          if (rotation_shift.data('shift_type_id') == '0') {
            pattern[day_index][shift_index].unavailable_type_id = rotation_shift.data('unavailable_type_id');
            pattern[day_index][shift_index].partial             = rotation_shift.data('partial');
            pattern[day_index][shift_index].notes               = rotation_shift.data('notes');
            pattern[day_index][shift_index].start_time          = rotation_shift.data('start_time');
            pattern[day_index][shift_index].end_time            = rotation_shift.data('end_time');
          }
          // A rotation shift template
          else {
            pattern[day_index][shift_index].shift_type_id = rotation_shift.data('shift_type_id');
            pattern[day_index][shift_index].position_id   = rotation_shift.data('position_id') ? rotation_shift.data('position_id') : null;
            pattern[day_index][shift_index].job_site_id   = rotation_shift.data('job_site_id') ? rotation_shift.data('job_site_id') : null;
          }
        });
      };
    });

    return pattern;
  };

  var cancelChanges = function(){
    location.href = MKS.rotations_url;
  };

  /* Adjust the width of the fixed div of draggables so it follows the scroll but maintains the correct width */
  var setDraggablesWidth = function() {
    config.selectors.rotationDraggables.css('width', function() {
      return $(this).parent().width();
    });
  };

  var fixDraggables = function() {
    if ($(window).scrollTop() >= config.selectors.rotationDroppable.offset().top) {
      config.selectors.rotationDraggables.addClass('fix-rotation-draggables');
    } else {
      config.selectors.rotationDraggables.removeClass('fix-rotation-draggables');
    }
  }

  /* ---- AJAX submissions */
  var postNewRotation = function(rotation_data) {
	  $.ajax({
          type: 'POST',
          url:  MKS.create_rotation_url,
          data: { rotation: rotation_data },
					dataType: 'script',
          success: function(msg) {
           location.href = MKS.rotations_url;
          }
    });
  };

  var updateCurrentRotation = function(rotation_data) {
    $.ajax({
          type: 'PUT',
          url:  MKS.update_rotation_url,
          data: { rotation: rotation_data },
					dataType: 'script',
          success: function(msg) {
           location.href = MKS.rotations_url;
          }
    });
  };

  var createRotation = function() {
    if(validateRotation(updatePattern(config.selectors.rotationDays)) === true) {
      var pattern = updatePattern(config.selectors.rotationDays)
      postNewRotation({name: config.selectors.rotationName.val().trim(),
                      blueprint: JSON.stringify(pattern),
                      length: config.selectors.rotationLength.val().trim(),
                      time_unit: $('input[name="rotation[time_unit]"]:checked').val(),
                      description: config.selectors.rotationDescription.val().trim()});
    }
  };

  var updateRotation = function(){
    if(validateRotation(updatePattern(config.selectors.rotationDays))) {
      var pattern = updatePattern(config.selectors.rotationDays)
      updateCurrentRotation({name: config.selectors.rotationName.val().trim(),
                            blueprint: JSON.stringify(pattern),
                            description: config.selectors.rotationDescription.val().trim()});
    }
  };
  /* AJAX submissions ---- */

  return {
    init: init,
  };

})(jQuery);
 
window.rotations = rotations;
