window.positionExpressionsTable = (function($) {

  var init = function() {
    $('.dp-edit').click(function(e) {
      // Disable all edits (so you can't edit more than one at once)
      $('.dp-cancel').click();

      var pos_id = $(e.target).data("position-id");
      e.preventDefault();

      e = $("#expr-"   + pos_id);
      var x = $("#e_expr-" + pos_id);

      x.val(e.data("expr"));
      e.hide();
      x.show();
      x.focus();

      $("#eq-"     + pos_id).show();
      $("#edit-"   + pos_id).hide();
      $("#del-"    + pos_id).hide();
      $("#save-"   + pos_id).show();
      $("#cancel-" + pos_id).show();
    });

    $('.dp-cancel').click(function(e) {
      var pos_id = $(e.target).data("position-id");
      e.preventDefault();

      e = $("#expr-" + pos_id);
      $("#eq-"     + pos_id).toggle(!!e.length);
      $("#del-"    + pos_id).toggle(!!e.length);
      e.show();

      $("#e_expr-" + pos_id).hide();

      $("#edit-"   + pos_id).show();
      $("#save-"   + pos_id).hide();
      $("#cancel-" + pos_id).hide();
    });
  }

  return {
    init: init
  };

})(jQuery);
