<template>
  <base-marketing-modal :show="show" :initial-email="initialEmail" product="shift_predict" v-on="$listeners" v-slot="{ handleClose }">
    <div class="mb-8">
      <div class="flex items-center text-blue-light">
        <i class="ui-icon-shift-predict-filled mr-5" style="height: 35px; width: 35px"></i>
        <h3 class="modal-title" v-html="I18n.t('marketing.shift_predict.title')"></h3>
        <button type="button" class="ml-auto bg-transparent" aria-label="Close" @click="handleClose">
          <i class="ui-icon-close text-gray opacity-50 hover:opacity-100" style="height: 18px; width: 18px"></i>
        </button>
      </div>
    </div>
    <div class="mb-8">
      <p class="font-ui-sans font-normal text-base leading-7 text-graphite">
        {{ I18n.t('marketing.shift_predict.description') }}
      </p>
    </div>
    <div class="mb-8">
      <img src="~images/ShiftPredict-illustration.png" alt="SmartInsights" class="illustration" />
    </div>
  </base-marketing-modal>
</template>

<script>
  import BaseMarketingModal from './base_marketing_modal'
  export default {
    name: 'ShiftPredictModal',
    props: {
      show: Boolean,
      initialEmail: String
    },
    components: {
      BaseMarketingModal
    }
  }
</script>

<style lang="scss">
  .modal-title {
    @apply #{ text-blue-light my-0 !important };

    span {
      opacity: 0.7;
    }
  }

  .illustration {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: auto;
    object-fit: cover;

    @media screen and (min-width: 640px) {
      width: auto;
      height: 226px;
    }
  }
</style>
