/*
 * Model that represents a Rotation
 *
 * @param {Object} data JSON attributes
 *
 * @return {Object} Rotation instance
 */
export var Rotation = function(data) {
  this.id                   = data.id;
  this.name                 = data.name;
  this.departmentId         = data.department_id;
  this.description          = data.description;
  this.length               = data.length;
  this.timeUnit             = data.time_unit;
  this.duration             = data.duration;
  this.blueprint            = data.blueprint;
};
