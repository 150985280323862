<template>
  <label class="ui-radio-container">
    <input 
      type="radio"
      :name="name"
      :checked="defaultChecked"
      @change="$emit('change')"
    />
    <span class="ui-radio"></span>
  </label>
</template>

<script>
export default {
  name: 'UiRadio',
  props: {
    name:           String,
    defaultChecked: Boolean,
  },
  example: `
<template>
  <div>
    <h4 class="my-6 border-b">UiRadio</h4>
    <div>
      <ui-radio name="radio"></ui-radio>
      <ui-radio name="radio" default-checked></ui-radio>
    </div>
  </div>
</template>

<script>
  export default {}
<\/script>
  `
}
</script>

<style lang="scss">
  .ui-radio-container {
    @apply #{ relative inline-block cursor-pointer text-base select-none pl-8 };

    input:checked ~ .ui-radio {
      @apply #{ bg-blue border-blue };
    }

    input:checked ~ .ui-radio:after {
      @apply #{ block };
    }

    input {
      @apply #{ absolute h-0 w-0 opacity-0 cursor-pointer }
    }

    .ui-radio {
      @apply #{ absolute top-0 left-0 h-5 w-5 bg-white rounded-full border border-line transition-all duration-100  };

      &:after {
        @apply #{ absolute hidden h-1 w-1 bg-white rounded-full border-white border-2 };
        content: '';
        top: 6px;
        left: 6px;
      }
    }
  }
</style>
