window.adpIntegration = (function($, window, document) {

  var init = function() {
    initBillingWarningPrompt();
  }

  var initBillingWarningPrompt = function() {
    var promptContent = '<h4>' + I18n.t('adp.js.are_you_sure') + '</h4>';
    promptContent += '<div>' + I18n.t('adp.js.bill_customer') + '</div>';

    $("#force-billing").on("submit", function(e) {
      e.preventDefault();
      var form = $(this);

      bootbox.dialog(promptContent, [{
        "label" : I18n.t('buttons.cancel'),
        "class" : "btn"
      }, {
        "label": I18n.t('adp.js.bill_now'),
        "class": "btn-danger",
        "callback": function() {
          form.get(0).submit();
        }
      }
      ]);
    });
  }

  return {
    init: init
  };

})(jQuery, window, document);
