<template>
  <div class="span4" id="big-bar">
    <div class="budget-meter">
      <div class="meter-container">
        <div :class="meterClasses" style="margin: 0;">
          <span
            :class="barClass"
            :style="barStyle"
          >
            <p>{{textValue}}</p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { basic_round } from 'core/helpers/format_helpers';

export default {
  props: {
    isLoading: Boolean,
    format:    Function,
    budgeted:  Number,
    scheduled: Number,
  },
  computed: {
    // Compare rounded values, since that's what the bars show
    barClass() {
      return {
        bar:   this.isLoading,
        empty: this.scheduled === 0 && !this.isLoading,
        over:  (![this.scheduled, this.budgeted].includes(undefined) && !this.isLoading) 
                ? basic_round(this.scheduled) > basic_round(this.budgeted) 
                : false
      };
    },

    barStyle() {
      return {
        width: this.isLoading ? '100%' : this.percentWidth(this.scheduled / this.budgeted || 0)
      };
    },

    meterClasses() {
      return {
        meter: true,
        'progress progress-striped active': this.isLoading,
      };
    },

    textValue() {
      if (!this.isLoading) {
        return this.format(this.scheduled);
      }
    }
  },
  methods: {
    /** 
     * We enforce a minimum percentage to avoid text issues. Once its greater
     * than 0 we go to 20%.  After the percentage is higher than 20% its shown
     * as a real %.
     * 
     * @param {number} percentage
     * @returns {number} in 0 | 20..100
     */
    percentWidth(percentage) {
      if (percentage > 0) {
        // bounded interval between 20 - 100
        return Math.max(
          20, Math.min(
            Math.floor(percentage * 1000) / 10, 100
          )
        ) + '%'; // precision to hundreths
      }
      return 'auto';
    },
  }
}
</script>
