import { user_prefers_12hr } from 'core/helpers/format_helpers.js';
$( document ).on( "focus", "input.time:not(.hasTimePicker)", function() {
  var startTime;
  var timeFormat;
  var $timePicker = $(this);
  timeFormat = user_prefers_12hr() ?  'g:ia' :  'H:i';
  var timePickerOptions = {
    step: 15,
    timeFormat: timeFormat,
    maxTime: '23:59'
  }

  if ($timePicker.hasClass('time-end')) {
    startTime = $('input.time.time-start').val();

    if (startTime) {
      timePickerOptions.scrollDefaultTime = startTime;
    }
  }

  if ($timePicker.hasClass('break-time-end')) {
    var break_punch_id = $timePicker.context.id.slice(0, -12) + "starts_at_time";
    startTime = $('input#' + break_punch_id).val();

    if (startTime) {
      timePickerOptions.scrollDefaultTime = startTime;
    }
  }

  $timePicker.timepicker(timePickerOptions);
  $timePicker.addClass('hasTimePicker')
});

$( document ).on( "changeTime", "input.time.time-start", function() {
  var startTime = $(this).val();
  $('input.time.time-end.hasTimePicker').timepicker('option', 'scrollDefaultTime', startTime);
});
