import FatigueRule from 'core/vue/fatigue_management/fatigue_rule.vue';
import {tConvert, user_prefers_12hr} from 'core/helpers/format_helpers.js';
import {convert_pair} from 'core/models/shift_type.js';
import {flashMessages} from 'core/helpers/flash_messages.js';

export var fatigueManagement = (function ($, window, document) {

  var init = function (settings) {

    $.extend(config, {
      departmentId: settings.departmentId,
      routes: {}
    });

  };

  var config = {};

  /**
   * Init bindings to "tablecrud" events and init Vue component
   * accordingly.
   */
  var initRuleEditor = function () {

    $(document).on('tablecrud:added_row', function () {
      var $fatigueFields = $('.new-record-row .fatigue-fields');
      var vueContainer = document.createElement('div');
      $fatigueFields.append(vueContainer);

      new Vue({
        el: vueContainer,
        data: {
          employmentTypeList: $fatigueFields.find('.props').data('employmentTypeList'),
          positionList: $fatigueFields.find('.props').data('positionList'),
          timeAndAttendance: $fatigueFields.find('.props').data('timeAndAttendance')
        },
        components: {FatigueRule},
        template: '<fatigue-rule v-bind="$data" />'
      });
    });

    $(document).on('tablecrud:edit_row', function () {
      var $fatigueFields = $('.edit-record-row .fatigue-fields');
      var vueContainer = document.createElement('div');
      $fatigueFields.append(vueContainer);

      new Vue({
        el: vueContainer,
        data: {
          initialRuleType: $fatigueFields.find('.props').data('ruleType'),
          initialTimeUnit: $fatigueFields.find('.props').data('timeUnit'),
          initialTimePeriod: $fatigueFields.find('.props').data('timePeriod'),
          initialTimeValue: $fatigueFields.find('.props').data('timeValue'),
          selectedEmploymentTypeIds: $fatigueFields.find('.props').data('selectedEmploymentTypeIds'),
          selectedPositionIds: $fatigueFields.find('.props').data('selectedPositionIds'),
          employmentTypeList: $fatigueFields.find('.props').data('employmentTypeList'),
          positionList: $fatigueFields.find('.props').data('positionList'),
          timeAndAttendance: $fatigueFields.find('.props').data('timeAndAttendance')
        },
        components: {FatigueRule},
        template: '<fatigue-rule v-bind="$data" />'
      });
    });

    $(document).on('ajax:error', function (e, xhr, status, error) {
      if (
        (e.target.classList.contains('new_fatigue_rule') || (e.target.classList.contains('edit_fatigue_rule'))
          && xhr?.responseJSON?.errors?.time_period)) {
        flashMessages.error(xhr.responseJSON.errors.time_period[0]);
        setTimeout(function () {
          flashMessages.clear();
        }, 8000);
      }
    });
  };

  /* Looks for time elements with class "dynamic" and flips the 12/24 hour formatting
   * preference. Currently just for fatigue management but could be made more dynamic
   * and used elsewhere.
   */
  $(document).ready(function () {
    if (user_prefers_12hr()) {
      $('time.dynamic').each(function (index) {
        var formattedTime = tConvert($(this).text());
        formattedTime = convert_pair($(this).text());
        $(this).text(formattedTime);
      });
    }
  });

  /**
   * Open a prompt warning user about breaching fatigue management rules.
   * Accepts callbacks for how to continue/cancel given the result.
   *
   * @param  {String}            Main message for the prompt.
   * @param  {Function}          Callback function if user chooses to continue.
   * @param  {Function}          Callback function if user choose to cancel.
   */
  var fatiguePrompt = function (message, continueCallback, cancelCallback) {

    var promptContent = '<h4>' + I18n.t('fatigue_management.js.warning') + '</h4>';
    promptContent += '<div class="fatigue-message">' + message + '</div>';

    bootbox.dialog(promptContent, [
      {
        'label': I18n.t('buttons.cancel'),
        'class': 'btn',
        'callback': function () {
          cancelCallback();
        }
      },
      {
        'label': I18n.t('fatigue_management.js.continue_assigning'),
        'class': 'btn-danger',
        'callback': function () {
          continueCallback();
        }
      }
    ]);

    if (user_prefers_12hr()) {
      $('.fatigue-message time').each(function (index) {
        var formattedTime = tConvert($(this).text());
        formattedTime = convert_pair($(this).text());
        $(this).text(formattedTime);
      });
    }
  };

  return {
    init: init,
    initRuleEditor: initRuleEditor,
    fatiguePrompt: fatiguePrompt
  };

})(jQuery, window, document);

window.fatigueManagement = fatigueManagement;
