import { analytics } from 'core/analytics/analytics.js';
window.analyticsUnavailables = (function($) {

  /*
  public
  */

  var locationGroup, departmentGroup;

  // Initialize Analytics::Unavailables
  var init = function(settings) {
    var today = new Date();
    $.extend(config, {
      endDate: new Date(today.setFullYear(today.getFullYear(), today.getMonth(), today.getDate() + 7)),
    });
    initUnavailables();

    locationGroup = $('.unavailables').find('#location-department-filter optgroup#location');
    departmentGroup = $('.unavailables').find('#location-department-filter optgroup#department');
  };

  /*
  private
  */

  // Default settings
  var config = {
    chartName: "unavailables",
    filterName: "data-set-filter"
  };

  var clearCustomDateRangeForUnavailables = function() {
    analytics.clearCustomDateRange(".unavailables");
    getUnavailables();
  };

  var getUnavailables = function() {
    var isInactive = $(this).hasClass('inactive');
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();
    var dateRange = analytics.filterDateRange($(this).text());
    config.endDate = analytics.filterEndDate($(this).attr('id'), config.endDate, dateRange);
    var dataHash = { locations: locationIds, departments: departmentIds, range: dateRange, endDate: $.datepicker.formatDate('yy/mm/dd', config.endDate) }

    if (!isInactive) { $('#request-filter').hide(); }

    if (analytics.canMakeChartRequest(config.chartName, isInactive, locationIds, departmentIds)) {
      $('#request-filter').show();
      analytics.makeChartRequest(MKS.unavailables_url, dataHash);
    } else {
      return;
    }
  };

  var initUnavailables = function() {
    analytics.initializePage($('.unavailables'), $('#unavailables-chart-box'));
    $('#request-filter').hide();
    $('.unavailables').find('#btn-group-date-range #date-range-days').removeClass('active');
    $('.unavailables').find('#btn-group-date-range #date-range-months').addClass('active');
    $('.unavailables').find('#data-set-filter').on("change", makeRequestForUnavailables);

    $('.unavailables').find('#location-department-filter').select2().on("change", removeUnavailablesOptionGroup);
    $(".unavailables #submit-chart").on("click", makeRequestForUnavailables);
    $(".unavailables #date-range-days, .unavailables #date-range-weeks, .unavailables #date-range-months, .unavailables #date-range-previous, .unavailables #date-range-next").on("click", getUnavailables);

    $('.unavailables #set-custom-date-range').on("click", selectCustomDateRangeForUnavailables);
    $('.unavailables #clear-custom-date-range').on("click", clearCustomDateRangeForUnavailables);
  };

  var makeRequestForUnavailables = function() {
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();
    if (locationIds.length == 0 && departmentIds.length == 0) { analytics.clearCustomDateRange(".unavailables"); }
    if ($(".unavailables #date-range-days").hasClass('inactive')) {
      selectCustomDateRangeForUnavailables();
    } else {
      getUnavailables();
    }
  };

  var removeUnavailablesOptionGroup = function() {
    analytics.removeOptionGroup($('.unavailables').find('#location-department-filter'), locationGroup, departmentGroup);
  };

  var selectCustomDateRangeForUnavailables = function() {
    var url = MKS.unavailables_url;
    analytics.selectCustomDateRange(url, ".unavailables");

    // if the start & end date are already set, execute the ajax call, otherwise listen for a date change event
    if ($(".analytics #start-date").val() != "" && $(".analytics #end-date").val() != "") {
      analytics.requestCustomDateRange(url, {}, config.chartName, config.filterName);
    }
    else {
      $('.analytics .input-daterange').on("changeDate", function(event) {
        analytics.requestCustomDateRange(url, {}, config.chartName, config.filterName);
      });
    }
  };


  return {
    init: init
  };

})(jQuery);
