<template>
  <div class="row-fluid">
    <div class="span12">
      <div class="box">
        <div
          class="box-header"
          style="border-bottom: none;"
        >
          <div class="row-fluid">
            <div class="span2 budget-bar-title">
              {{I18n.t('budget_bar.js.labour_budget')}}
            </div>

            <budget-bar-toggle
              :mode="mode"
              :onClick="handleClick"
            />

            <budget-bar-meter 
              :isLoading="loading"
              :format="options.format"
              :budgeted="options.budgeted"
              :scheduled="options.scheduled"
            />

            <budget-bar-title
              customId="labour-weekly-budget"
              customClass="budget-total"
              :header="I18n.t('budget_bar.js.weekly_budget')"
              :content="loading ? '' : options.format(options.budgeted)"
            />

            <budget-bar-title
              v-if="showEstimate"
              customId="labour-estimate"
              customClass="estimate"
              :header="options.header" 
              :content="loading ? '' : options.format(options.estimated)" 
            />

            <div class="view-details">
              <ul class="box-toolbar box-toolbar-right">
                <li class="toolbar-link">
                  <a
                    href="#"
                    id="weekly-budget-details"
                    name="weekly-budget-details"
                  >
                    {{I18n.t('budget_bar.js.view_details')}}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { basic_currency_format, basic_format } from 'core/helpers/format_helpers';

import BudgetBarToggle from './budget_bar_toggle';
import BudgetBarMeter from './budget_bar_meter';
import BudgetBarTitle from './budget_bar_title';

export default {
  props: {
    isLoading:      Boolean,
    budgetDollars:  Number,
    budgetHours:    Number,
    estimatedCosts: Number,
    estimatedHours: Number,
    scheduledCosts: Number,
    scheduledHours: Number,
    view: {
      type:     String,
      required: true,
    }
  },
  data() {
    return {
      mode:    departmentSchedule.getBudgetMode(),
      loading: this.isLoading,
    };
  },
  computed: {
    departmentSchedule() { return window.departmentSchedule; },
    showEstimate() { return ['positions', 'job_sites'].includes(this.view); },
    options() {
      switch (this.mode) {
      case 'dollars':
        return {
          isLoading: this.loading,
          scheduled: this.scheduledCosts,
          budgeted:  this.budgetDollars,
          estimated: this.estimatedCosts,
          header:    this.I18n.t('budget_bar.js.estimated'),
          format:    this.formatDollars
        };
      case 'hours':
        return {
          isLoading: this.loading,
          scheduled: this.scheduledHours,
          budgeted:  this.budgetHours,
          estimated: this.estimatedHours,
          header:    this.I18n.t('budget_bar.js.planned'),
          format:    this.formatHours,
        };
      }
    },
  },
  methods: {
    formatDollars(dollars) { return `${basic_currency_format(dollars)}`; },
    
    formatHours(hours) { return `${basic_format(hours)}\u00a0hrs`; },

    handleClick(type) {
      if (type === this.mode) {
        return;
      }

      this.mode = type;
      this.loading = true;

      // Refresh to show new labor budget totals (hours/dollars are different)
      this.departmentSchedule.setBudgetMode(type);
      this.departmentSchedule.loadSchedule();
    }
  },
  components: {
    BudgetBarToggle,
    BudgetBarMeter,
    BudgetBarTitle,
  }
}
</script>
