<template>
  <div class="box">
    <div class="box-header">
      <ul class="nav nav-tabs nav-tabs-left">
        <li class="active">
          <a><slot name="header"></slot></a>
        </li>
      </ul>
      <ul class="box-toolbar">
        <li class="toolbar-link add-btn">
          <a v-if="showAddButton" @click="$emit('add-event')"><i class="fa fa-plus"></i></a>
        </li>
      </ul>
    </div>
    <div class="box-content" :style="contentStyle">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CalendarSidebar',
  props: {
    contentStyle: String,
    readOnly:  {
      type:     Boolean,
      default: false,
    }
  },
  computed:{
    showAddButton() { return this.readOnly !== true },
  }

}
</script>
