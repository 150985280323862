<template>
  <input
    v-if="type !== 'textarea'"
    class="ui-input"
    :type="type"
    :placeholder="placeholder"
    :value="value"
    :disabled="disabled"
    @change="$emit('change')"
  />
  <textarea
    v-else
    class="ui-input" 
    :placeholder="placeholder"
    :value="value"
    :disabled="disabled"
    @change="$emit('change')"
  />
</template>

<script>
export default {
  name: 'UiInput',
  props: {
    placeholder: String,
    value:       String,
    type:        {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
  },
  example: `
<template>
  <div>
    <h4 class="my-6 border-b">UiInput</h4>
    <ui-input></ui-input>

    <h4 class="my-6 border-b">Placeholder</h4>
    <ui-input placeholder="Placeholder"></ui-input>

    <h4 class="my-6 border-b">Types</h4>
    <ui-input type="text" placeholder="Text"></ui-input>
    <ui-input type="number" placeholder="Number"></ui-input>
    <ui-input type="textarea" placeholder="Textarea"></ui-input>

    <h4 class="my-6 border-b">Disabled</h4>
    <ui-input disabled placeholder="Disabled"></ui-input>

    <h4 class="my-6 border-b">Filled</h4>
    <ui-input value="Filled"></ui-input>
  </div>
</template>

<script>
  export default {}
<\/script>
`
}
</script>

<style lang="scss">
  .ui-input {
    @apply #{ h-auto w-auto p-2 shadow border-none rounded bg-white text-sm text-dark resize-none transition-all duration-100 !important };

    &:disabled {
      @apply #{ shadow-none bg-disabled text-gray !important };
    }
    
    &::placeholder {
      @apply #{ text-gray !important };
    }
  }
</style>
