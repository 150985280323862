/*
  Select2 Helpers
 */
window.select2Util = (function($, window, document) {
  /**
   * Creates Search Tag Items with extra attribute for custom formatting results
   * @param  {Object} term             Select2 Term
   * @param  {String} searchChoiceType Type of item being created
   * @return {Object}                  Select2 Object
   */
  var createSearchTag = function(term, searchChoiceType) {
    return { id: term, text: term, newItemText: I18n.t("select2_util.js.create",{ type: searchChoiceType, term: term }) };
  };

  /**
   * Formats the Select2 Result when tagging an item.
   *
   * @note This is to be used in conjunction with createSearchTag
   *
   * @param  {Object} item Select2 Object
   * @return {String}      Formatted String
   */
  var formatSearchTag = function(item) {
    return filterXSS(item.newItemText || item.text);
  };

  /**
   * Returns a message when no result is present.
   * @param  {String} type Type of item being searched for
   * @return {String}      Formatted string
   */
  var formatNoMatches = function(searchType) {
    return I18n.t("select2_util.js.enter_name",{ type: searchType });
  };

  var formatNoMatchesDisabled = function(searchType) {
    return I18n.t("select2_util.js.no_new_tags",{ type: searchType });
  }

  var checkForIllegalChars = function(term) {
    var illegals = [',', '%', '\\', '_'];
    var found_illegal = false;

    _.each(illegals, function(illegal) {
      if (term.indexOf(illegal) > -1) {
        found_illegal = true;
      }
    });

    if (found_illegal) {
      // Return null to disable tag creation if illegal character is present
      return null;
    }

    return term;
  };

  return {
    createSearchTag:      createSearchTag,
    formatSearchTag:      formatSearchTag,
    formatNoMatches:      formatNoMatches,
    formatNoMatchesDisabled: formatNoMatchesDisabled,
    checkForIllegalChars: checkForIllegalChars
  };

})(jQuery, window, document);
