import { basic_unformat, basic_round, basic_format } from 'core/helpers/format_helpers.js';

export var spanBudgetBatches = (function() {

  var config = {}

  var init = function(departmentId) {
    this.departmentId = departmentId;

    $.extend(config, {
      routes: {
        update: '/departments/' + this.departmentId + '/span_budget_batches'
      },
      selectors: {
        budgetRow:     $('#labor-budget-row'),
        editBudgets:   $('#edit-labor-budgets'),
        cancelBudgets: $('#cancel-labor-budgets'),
        saveBudgets:   $('#save-labor-budgets')
      }
    });

    // Edit labor budgets
    config.selectors.editBudgets.click(function() {
      config.selectors.editBudgets.hide();
      config.selectors.cancelBudgets.show();
      config.selectors.saveBudgets.show();

      $.each(config.selectors.budgetRow.find('div.labor-budget-values'), function(i, div) {
        // If we can't edit this budget, then don't create an edit box for it
        if (!$(div).data('editable')) {
          return;
        }

        var $existing = $(div).children('.labor-budget-amount').first();
        $existing.hide();

        var $form = $(document.createElement('form'));
        $form.addClass('labor-budget-amount-form');
        $form.submit(function(e) {
          e.preventDefault();
          saveSpanBudgets();
        });

        var $edit = $(document.createElement('input'));
        $edit.attr('type', 'number');
        $edit.attr('min', 0);
        $edit.attr('max', 10000000); // this should match BudgetsHelper::MAX_BUDGET_VALUE
        $edit.attr('step', 0.01);
        $edit.addClass('labor-budget-amount-edit');
        $edit.val(basic_unformat($existing.text()));
        $edit.data($existing.data());
        $form.append($edit)
        $(div).append($form);
      });
    });

    // Cancel labor budgets
    config.selectors.cancelBudgets.click(function() {
      config.selectors.editBudgets.show();
      config.selectors.cancelBudgets.hide();
      config.selectors.saveBudgets.hide();

      $.each(config.selectors.budgetRow.find('div.labor-budget-values'), function(i, div) {
        $(div).children('.labor-budget-amount-form').remove();
        $(div).children('.labor-budget-amount').show();
      });
    });

    // Save labor budgets
    config.selectors.saveBudgets.click(function() {
      var all_valid = true;
      $('.labor-budget-amount-form').each(function(i,subform) {
        if (!all_valid) return;

        // Hack to trigger showing HTML5 validation
        // We add a button to submit the form and click it
        // We know it's invalid, so it won't actually submit
        // It'll just trigger the HTML5 validation errors to show
        if (!subform.checkValidity()) {
          var tmp = document.createElement('button');
          subform.appendChild(tmp);
          tmp.click();
          subform.removeChild(tmp);
          var all_valid = false;
        }
      });
      if (all_valid) {
        saveSpanBudgets();
      }
    });
  }

  var onBudgetUpdated = function(f) {
    this.onBudgetUpdatedCallback = f;
  }

  var saveSpanBudgets = function() {
    // Collect all budget values and request for them to be saved
    var budgets = [];
    var beginningOfWeek;

    $.each(config.selectors.budgetRow.find('.labor-budget-amount-edit'), function(i, input) {
      beginningOfWeek = beginningOfWeek || $(this).data('beginning_of_week');
      var budget = {
        starts_at: $(this).data('starts_at')
      }

      // Still want to push empty budgets onto the list, because we'll delete those empty budgets
      if ($(this).val()) {
        if (spanBudgetBatches.mode == 'dollars') {
          budget.amount = $(this).val();
        } else {
          budget.hours = $(this).val();
        }
      }

      budgets.push(budget);
    });

    $.ajax(config.routes.update, {
      type: "PATCH",
      contentType: 'application/json',
      data: JSON.stringify({
        budgets: budgets
      })
    }).then(function(data, status, xhr) {
      spanBudgetBatches.onBudgetUpdatedCallback();
    });

    // Go back to normal state...
    config.selectors.editBudgets.show();
    config.selectors.cancelBudgets.hide();
    config.selectors.saveBudgets.hide();

    $.each(config.selectors.budgetRow.find('div.labor-budget-values'), function(i, div) {
      $(div).children('.labor-budget-amount-form').remove();
      $(div).children('.labor-budget-amount').show();
    });
  }

  var setBudgetMode = function(mode) {
    this.mode = mode;
  }

  // Render the budget table row for current week
  // Requires labor budget data and a time
  var updateValues = function(beginningOfWeek, spanBudgetData) {
    // NOTE: This can be called BEFORE we've been init-ed (due to the way department_schedules.js works)
    // We just bail out if we haven't been initialized yet
    // We also require a budget this.mode (dollars/hours) for this to run
    if (!this.departmentId) return;
    if (!this.mode) return;

    var mode = this.mode;

    var $row = config.selectors.budgetRow;
    $row.find('div').remove();

    // Reset the edit/cancel/save buttons
    // This is necessary if somebody switches views (dollars/hours) while editing is active
    config.selectors.editBudgets.show();
    config.selectors.cancelBudgets.hide();
    config.selectors.saveBudgets.hide();

    var found_editable = false;

    // Show the actual and budgeted amounts per day
    $.each(spanBudgetData, function(i, vals) {
      var $div = $(document.createElement('div'));
      $div.addClass('labor-budget-values');
      $div.data('editable', vals.editable);
      found_editable = found_editable || vals.editable;

      var $actual_amount = $(document.createElement('span'));
      $actual_amount.addClass('labor-budget-actual');
      if ('amount' in vals || 'hours' in vals) {
        var over_budget = mode == 'dollars' ?
          basic_round(vals.sched_cost)  > basic_round(vals.amount) :
          basic_round(vals.sched_hours) > basic_round(vals.hours);
        $actual_amount.addClass(over_budget ? 'over-budget' : 'within-budget');
      }
      else if ((mode == 'dollars' && vals.sched_cost) || (mode == 'hours' && vals.sched_hours)) {
        $actual_amount.addClass('over-budget');
      }
      $actual_amount.text(basic_format(mode == 'dollars' ? vals.sched_cost : vals.sched_hours));
      $div.append($actual_amount);

      var $divider = $(document.createElement('span'));
      $divider.html('&nbsp;/&nbsp;');
      $div.append($divider);

      var $budgeted_amount = $(document.createElement('span'));
      $budgeted_amount.addClass('labor-budget-amount');
      if ('amount' in vals || 'hours' in vals) {
        $budgeted_amount.text(basic_format(mode == 'dollars' ? vals.amount : vals.hours));
      }
      $budgeted_amount.data('starts_at', vals.starts_at);
      $budgeted_amount.data('beginning_of_week', beginningOfWeek);
      $div.append($budgeted_amount);

      // Use 1-based indexes, since our first column is just the words "Labour Budget"
      $row.find('th').eq(1+i).append($div)
    });

    found_editable ? $('#edit-labor-budgets').show() : $('#edit-labor-budgets').hide();
  };

  var hide = function() {
    config.selectors.budgetRow.hide();
  }

  var show = function() {
    config.selectors.budgetRow.show();
  }

  var toggle = function() {
    config.selectors.budgetRow.toggle();
  }

  return {
    init:            init,
    setBudgetMode:   setBudgetMode,
    onBudgetUpdated: onBudgetUpdated,
    updateValues:    updateValues,
    hide:            hide,
    show:            show,
    toggle:          toggle
  };

})();
