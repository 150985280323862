$(document).ready(function() {
  $('body').on('keyup', '.dataTables_filter input', function(){
    var $input = $(this);
    if ($input.val().length > 0) {
      $(this).closest('label').append('<i class="fa fa-remove-sign" id="js-clear-search"></i>');
    }
    else {
      $(this).closest('label').find('i#js-clear-search').remove();
    }
  });

  $('body').on('click', 'i#js-clear-search', function() {
    $(this).closest('label').find('input').val('').keyup();
  });
});
