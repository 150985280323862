Array.prototype.toSentence = function() {
  var wordsConnector = ", ",
      twoWordsConnector = ", ",
      lastWordConnector = ", ",
      sentence;

  switch(this.length) {
    case 0:
      sentence = "";
      break;
    case 1:
      sentence = this[0];
      break;
    case 2:
      sentence = this[0] + twoWordsConnector + this[1];
      break;
    default:
      sentence = this.slice(0, -1).join(wordsConnector) + lastWordConnector + this[this.length - 1];
      break;
  }

  return sentence;
}
