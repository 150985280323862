<template>
  <select
    v-if="!opts.data"
    class="ui-select"
    ref="select"
  >
    <slot></slot>
  </select>
  <input
    v-else
    class="ui-select"
    ref="select"
    type="text"
  />
</template>

<script>
export default {
  name: 'UiSelect',
  props: {
    // See https://select2.org/configuration/options-api
    opts: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    $(this.$refs.select).select2({
      allowClear: true,
      dropwdownAutoWidth: true,
      dropdownCssClass: 'ui-select-dropdown',
      ...this.opts,
    })
    .on('select2-open', () => {
      $('.select2-input').prop('placeholder', $('.select2-highlighted').text());
    })
    .on('select2-close', () => {
      $('.select2-input').prop('placeholder', '');
    });
  },
  example: `
<template>
  <div>
    <h4 class="my-6 border-b">UiSelect</h4>
    <ui-select></ui-select>

    <h4 class="my-6 border-b">Options</h4>
    <ui-select>
      <option value="1">Option 1</option>
      <option value="2">Option 2</option>
      <option value="3">Option 3</option>
    </ui-select>
    <div class="my-2"></div>
    <ui-select>
      <optgroup label="Group 1">
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </optgroup>
    </ui-select>
    <div class="my-2"></div>
    <ui-select
      :opts="{
        data: [
          {
            id: 1,
            text: 'JSON 1',
          },
          {
            id: 2,
            text: 'JSON 2',
          },
          {
            id: 3,
            text: 'JSON 3',
          },
        ]
      }"
    >
    </ui-select>
  </div>
</template>

<script>
  export default {}
<\/script>
`
}
</script>

<style lang="scss">
  // Input
  .ui-select {

    &.select2-container-active .select2-choice, 
    &.select2-container-active .select2-choices {
      @apply #{ shadow-md !important };
    }

    .select2-choice,
    .select2-choices {
      @apply #{ h-auto w-auto p-2 shadow border-none rounded text-sm text-dark leading-normal transition-all duration-100 !important };
    }

    .select2-arrow {
      top: 4px !important;
    }
  }

  // Dropdown
  .ui-select-dropdown {
    @apply #{ border-none shadow rounded-md !important };

    .select2-results {
      @apply #{ p-0 m-0 rounded-md };
    }

    .select2-result-label,
    .select2-no-results {
      @apply #{ px-4 py-2 text-sm };
    }

    .select2-highlighted {
      @apply #{ bg-blue };
    }

    .select2-search {
      @apply #{ absolute pt-0 };
      top: -30px;
      width: 95%;

      &::after {
        content: '';
      }

      input {
        @apply #{ bg-white border-none text-sm !important };
      }
    }
  }
</style>
