<template>
  <transition name="modal">
    <div v-if="show" class="ui-modal__container">
      <div class="ui-modal__backdrop" @click="handleClose"></div>
      <div class="ui-modal__body">
        <slot v-bind="{ handleClose }"> </slot>
        <div v-if="success !== ''" class="ui-alert ui-alert--success ui-alert--bordered">
          <i class="ui-icon-check ui-alert__icon"></i>
          <span>
            <p class="ui-alert__title">{{ success }}</p>
          </span>
        </div>
        <div v-else-if="error !== ''" class="ui-alert ui-alert--error ui-alert--bordered">
          <i class="ui-icon-alert-filled ui-alert__icon"></i>
          <span>
            <p class="ui-alert__title">{{ error }}</p>
          </span>
        </div>
        <form v-else class="flex flex-col space-y-6" @submit.prevent="handleSubmit">
          <p class="font-ui-sans font-bold text-base leading-4 text-gray">{{ I18n.t('marketing.form.contact_makeshift') }}</p>
          <input
            v-model="email"
            type="email"
            name="email"
            required
            class="ui-modal__input font-ui-sans"
            :placeholder="I18n.t('activerecord.attributes.user.email')"
          />
          <div for="consent" class="flex items-start space-x-4">
            <input v-model="consent" class="accent-blue-light" type="checkbox" id="consent" name="consent" />
            <label class="font-ui-sans text-sm leading-5 text-gray m-0" for="consent">
              {{ I18n.t('marketing.form.consent') }}
            </label>
          </div>

          <button type="submit" class="ui-modal__btn font-ui-sans" :disabled="loading">
            <i v-if="loading" class="ui-icon-loader animate-spin mr-2"></i>
            <span>{{ I18n.t('marketing.form.inquire') }}</span>
          </button>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'BaseMarketingModal',
    props: {
      show: Boolean,
      initialEmail: String,
      product: String
    },
    data() {
      return {
        email: this.initialEmail,
        consent: false,
        loading: false,
        success: '',
        error: ''
      }
    },
    methods: {
      handleClose() {
        this.$emit('close')
      },
      handleSubmit(e) {
        if (this.loading) return

        this.loading = true
        this.error = ''
        this.success = ''

        $.ajax({
          method: 'POST',
          url: '/demos',
          dataType: 'json',
          data: {
            demo: {
              email: this.email,
              product: this.product,
              marketing_consent: this.consent
            }
          },
          success: (data) => {
            this.success = data.message
          },
          error: (xhr) => {
            console.error(xhr)
            this.error = I18n.t('marketing.form.contact_support')
          },
          complete: () => {
            this.loading = false
          }
        })
      }
    },
    watch: {
      show(newValue, currentValue) {
        if (currentValue && !newValue) {
          setTimeout(() => {
            this.email = this.initialEmail
            this.consent = false
            this.loading = false
            this.success = ''
            this.error = ''
          }, 500)
        }
      }
    }
  }
</script>

<style lang="scss">
  .modal-enter,
  .modal-leave-to {
    opacity: 0;
  }

  .modal-enter-active,
  .modal-leave-active {
    transition: opacity 0.5s;
  }

  .ui-modal__container {
    @apply #{ fixed inset-0 w-full h-full box-border flex flex-col items-center justify-start };

    & > * {
      @apply #{ box-border };
    }

    z-index: 2000;
  }

  .ui-modal__backdrop {
    @apply #{ absolute inset-0 bg-backdrop };
  }

  .ui-modal__body {
    @apply #{ bg-white rounded-xl p-8 overflow-y-auto };

    z-index: 2100;
    margin: 15px;
    margin-top: clamp(15px, 10vh, 150px);

    @media screen and (min-width: 640px) {
      width: 496px;
    }

    .ui-modal__input {
      @apply #{ m-0 px-4 h-5 text-sm leading-5 font-medium border border-gray-400 rounded-lg text-gray !important };

      padding-top: 10px;
      padding-bottom: 10px;
      width: unset;
    }

    .ui-modal__btn {
      @apply #{ px-4 h-10 bg-blue-light text-white rounded-lg };
      @apply #{ font-semibold text-sm leading-5 text-center };

      &:disabled {
        @apply #{ opacity-50 cursor-not-allowed };
      }
    }

    .accent-blue-light {
      accent-color: #43c1e6;
    }
  }
</style>
