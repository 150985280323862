<template>
  <div class="row-fluid">
    <div class="span10">
      <div v-if="errors.length > 0" class="alert alert-danger">
        <p><strong>{{I18n.t('archive_user.js.error')}}&nbsp;</strong>{{errors.toSentence()}}</p>
      </div>

      <div class="users box">
        <div class="box-header">
          
          <slot name="header" />

          <ul class="box-toolbar">
            <li class="toolbar-link">
              <a v-if="!confirmed" href="#" @click="handleConfirmation">{{I18n.t('archive_user.js.restore')}}</a>
              <a v-if="confirmed" href="#" @click="restoreUser">{{I18n.t('archive_user.js.are_you_sure')}}</a>
            </li>
          </ul>
        </div>

        <ui-datatable
          ref="datatable"
          bootstrap
          include-search
          include-page-size
          include-pagination
          include-processing
          include-information
          include-row-selection
          :fields="fields"
          :source="source"
          :opts="opts"
          @edit="handleEdit"
          @save="updateUser"
          @cancel="handleCancel"
          @row-selected="handleSelection"
          @row-deselected="handleSelection"
        >
          <template slot="actions">
            <button data-action="edit" class="btn btn-small btn-gray">{{I18n.t('buttons.edit')}}</button>
          </template>
        </ui-datatable>
      </div>
    </div>

    <div v-if="showBilling" class="span2">
      <div class="alert alert-warning">
        <strong>{{I18n.t('archive_user.js.billing_note')}}</strong>
        <p class="vpadded">{{I18n.t('archive_user.js.account_charged')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import UiDatatable from 'core/vue/common/ui/ui_datatable';

export default {
  name: 'ArchivedUsers',
  props: {
    isSuperAdmin: Boolean,
    showBilling:  Boolean,
    source:       String,
  },
  data() {
    return {
      selectedUserIds: [],

      // UI
      confirmed: false,
      errors: [],

      // DataTable
      fields: {
        id: {
          title: 'id',
          data: 0,
          visible: false,
        },
        name: {
          title: I18n.t('archive_user.js.name'),
          data: 1,
          width: '25%',
          defaultOrder: 'asc',
        },
        employee_id: {
          title: I18n.t('archive_user.js.employee_id'),
          data: 2,
          width: '15%',
        },
        email: {
          title: I18n.t('archive_user.js.email'),
          data: 3,
          width: '25%',
        },
        role: {
          title: I18n.t('archive_user.js.user_role'),
          data: 4,
          width: '15%',
        },
        actions: {
          visible: this.isSuperAdmin,
          isLocal: true,
          title: I18n.t('archive_user.js.actions'),
          data: 5,
          width: '20%',
        }
      },
      opts: {
        displayLength: 50,
        language: {
          ...window.dataTables_i18n,
          sEmptyTable: I18n.t('archive_user.js.no_users'),
        }
      },
    }
  },
  methods: {
    handleEdit(data, row, tr, target) {
      const email       = data[3];
      const $emailCol   = $(tr).find('td:nth-child(4)');
      const $actionsCol = $(tr).find('td:last-child');
      $emailCol.html(`<input type="text" value="${email}"/>`);
      $actionsCol.html(`
        <button data-action="save" class="btn btn-small btn-green">${I18n.t('buttons.save')}</button>
        &nbsp;
        <button data-action="cancel" class="btn btn-small">${I18n.t('buttons.cancel')}</button>
      `);
    },

    handleCancel(data, row, tr, target) {
      const email       = data[3];
      const $emailCol   = $(tr).find('td:nth-child(4)');
      const $actionsCol = $(tr).find('td:last-child');
      $emailCol.removeClass()
               .html(email);
      $actionsCol.html(
        `<button class="btn btn-small btn-gray" data-action="edit">${I18n.t('buttons.edit')}</button>`
      );
    },

    handleSelection(data) {
      this.selectedUserIds = data.map((arr) => parseInt(arr[0]));
    },

    handleConfirmation() {
      this.confirmed = this.selectedUserIds.length > 0;
    },

    restoreUser() {
      $.ajax({
        type: 'PUT',
        url: '/users/batch_restore',
        dataType: 'json',
        data: {
          user_ids: this.selectedUserIds
        },
        error: (xhr) => {
          this.errors = [xhr.responseJSON.error];
        },
        success: () => {
          this.errors = [];
          this.selectedUserIds = [];
          this.$refs.datatable.reload();
        },
        complete: () => {
          this.confirmed = false;
        },
      });
    },

    updateUser(data, row, tr, target) {
      const userId    = parseInt(data[0]);
      const $button   = $(target);
      const $emailCol = $(tr).find('td:nth-child(4)');
      const $input    = $emailCol.find('input');

      $emailCol.removeClass()
               .find('span').remove();
      $button.prop('disabled', true)
             .html(I18n.t('buttons.disable_with.save'));

      $.ajax({
        url: `/users/${userId}`,
        type: 'PUT',
        dataType: 'script',
        data: {
          user: {
            email: $input.val(),
          }
        },
        success: () => {
          this.$refs.datatable.reload();
        },
        error: () => {
          $emailCol.addClass('control-group error')
                   .append(`<span class="error help-inline">${I18n.t('archived_users.js.error_email')}</span>`);
          $button.prop('disabled', false)
                 .html(I18n.t('buttons.save'));
        }
      });
    }
  },
  components: {
    UiDatatable,
  }
}
</script>
