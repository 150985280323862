import { messaging } from 'core/messaging.js';
/*
 * Model that represents a Message
 *
 * @param {Object} data JSON attributes
 *
 * @return {Message}
 */
export var Message = function(data) {
  this.id         = data.id;
  this.body       = data.body;
  this.createdAt  = data.created_at;
  this.userId     = data.user_id;
};

Message.prototype = {

  /*
   * Associated User
   */
  user: function() {
    return messaging.store.findUser(this.userId);
  },

  /*
   * Render template
   */
  render: function() {
    return HandlebarsTemplates['messages/show']({
      message:  this,
      user:     this.user()
    });
  }

};