<template>
  <div class="box">
    <div
      v-if="fullCalendarApi"
      class="box-header"
      style="padding: 10px;"
    >
      {{I18n.t('user_availability.calendar.title', { month: fullCalendarApi.view.title })}}
    </div>
    <full-calendar
      id="availability-calendar"
      ref="fullCalendar"
      default-view="dayGridMonth"
      event-order="start,-title"
      height="auto"
      next-day-threshold="24:00:00"
      :header="header"
      :plugins="plugins"
      :button-text="buttonText"
      :column-header-text="columnHeaderText"
      :events="events"
      :event-render="eventRender"
      :day-render="dayRender"
      :locale="I18n.locale.replace('_', '-')"
      @loading="loading"
    />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import { msUtil } from 'core/ms_util';

export default {
  name: 'AvailabilityCalendar',
  props: {
    userId: {
      type: String,
      required: true,
    },
    readOnly:  {
      type:     Boolean,
      default: false,
    }
  },
  data() {
    return {
      textColor:       '#666666',
      priorDayColor:   '#d6f8ac',
      dayColor:        '#A7F053',
      borderColor:     'transparent',

      fullCalendarApi: null,
      plugins:         [ dayGridPlugin ],
      header:          { left: 'prev', center: 'title', right: 'today, next' },
      buttonText: {
        today: I18n.t('department_schedules.show.toolbar.today')
      },
      columnHeaderText(date) {
        return I18n.t('date.day_names')[date.getDay()];
      },
    };
  },
  mounted() {
    this.fullCalendarApi = this.$refs.fullCalendar.getApi();
  },
  methods: {
    events(fetchInfo, successCallback, failureCallback) {
      const today = Date.today();
      const start = moment(fetchInfo.start).format('YYYY-MM-DD');
      const end   = moment(fetchInfo.end).format('YYYY-MM-DD');
      $.ajax({
        url: `/availabilities?user_id=${this.userId}&starts_at=${start}&ends_at=${end}`,
        success: (res) => {
          const events = res.data.availabilities.map((avail) => ({
            id:              avail.recurring_availability_id || avail.id,
            start:           new Date(`${moment(avail.date).locale('en').format('LL')} ${moment(avail.start_time, 'h:mm A').format('HH:mm')}`),
            title:           this.setTitle(avail),
            textColor:       this.textColor,
            borderColor:     this.borderColor,
            backgroundColor: moment(avail.date).toDate() < today ? this.priorDayColor : this.dayColor,
            extendedProps:   {
              start_time: avail.start_time,
              end_time:   avail.end_time,
              starts_on:  avail.recurrence_start,
              ends_on:    avail.recurrence_end,
              repeats_on: avail.repeats_on,
            }
          }));
          successCallback(events);
        }
      });
    },

    eventRender(info) {
      const el = $(info.el);
      const title = el.find('span.fc-title');
      title.html(title.text());
      el.addClass('availability-event');
      el.find('span.fc-time').remove();
      if (info.event.start >= Date.today() && this.showButtons) {
        el.on('click', () => this.$emit('edit-event', {
          id:          info.event.id,
          date:        moment(info.event.start).format('YYYY-MM-DD'),
          date_string: moment(info.event.start).format(I18n.t('date.formats.moment_availability')).replace('.', ''),
          start_time:  info.event.extendedProps.start_time,
          end_time:    info.event.extendedProps.end_time,
          starts_on:   (
            info.event.extendedProps.starts_on
              ? moment(info.event.extendedProps.starts_on).format('YYYY-MM-DD')
              : moment(info.event.start).format('YYYY-MM-DD')
          ),
          ends_on:     info.event.extendedProps.ends_on && moment(info.event.extendedProps.ends_on).format('YYYY-MM-DD'),
          repeats_on:  info.event.extendedProps.repeats_on || [parseInt(moment(info.event.start).format('d'))],
          repeats:     !!info.event.extendedProps.repeats_on,
        }));
      } else {
        el.addClass('disabled');
      }
    },

    dayRender(info) {
      if (new Date(info.date) >= Date.today()) {
        const el = $(`.fc-day-top[data-date="${moment(info.date).format('YYYY-MM-DD')}"]`);
        el.addClass('availability-cell-top');
        if(this.showButtons){
          el.append(`<a>+ ${I18n.t('user_availability.calendar.add')}</a>`);
          el.on('click', 'a', () => this.$emit('add-event', {
            id:          null,
            date:        moment(info.date).format('YYYY-MM-DD'),
            date_string: moment(info.date).format(I18n.t('date.formats.moment_availability')).replace('.', ''),
            start_time:  null,
            end_time:    null,
            starts_on:   moment(info.date).format('YYYY-MM-DD'),
            ends_on:     null,
            repeats_on:  [parseInt(moment(info.date).format('d'))],
            repeats:     false,
          }));
        }
      }
    },

    loading(isLoading) {
      // Add timeout to wait for render to finish
      setTimeout(() => {
        if (isLoading) {
          msUtil.spinner('availability-calendar');
        } else {
          $('.spinner').remove();
        }
      }, 100);
    },

    setTitle(avail) {
      let title;
      if ((['12:00am', '00:00'].includes(avail.start_time)) && avail.start_time === avail.end_time) {
        title = `<span>${I18n.t('user_availability.calendar.all_day')}</span>`;
      } else {
        title = (avail.end_time)
          ? `<span>${avail.start_time.replace('m', '')} - ${avail.end_time.replace('m', '')}</span>`
          : `<span>${avail.start_time.replace('m', '')}</span>`;
      }
      if (avail.recurring_availability_id) {
        title += `<span>${I18n.t('user_availability.calendar.repeating')}</span>`;
      }
      return title;
    }
  },
  components: {
    FullCalendar,
  },
  computed:{
    showButtons() { return this.readOnly !== true },
  }
}
</script>

<style lang="scss">
.availability {

  &-event {
    text-align: center;
    font-size: 12px !important;
    border-radius: 0 !important;
    margin-left: 3px !important;

    &.disabled {
      cursor: default;
    }
  }

  &-cell-top {
    position: relative;

    &:hover a {
      opacity: 1;
      color: #08c;
    }

    a {
      position: absolute;
      width: 100%;
      padding: 6px 10px 0 0;
      text-align: center;
      opacity: 0;
      box-sizing: border-box;
    }
  }
}
</style>
