// Class for mimicking Rails flash messages when receiving JS responses
// It assumes the existence of a div element with the ID 'flash-messages'
function flashMessagesClass() {

  // Clear all flash messages
  this.clear = function() {
    $('#flash-messages').empty();
  };

  // Append a flash message of a given type ('success' or 'error')
  this.addFlash = function(message, type) {

    // Assume it's a success if nothing is passed for the type
    type = type || 'success';

    // The flash element itself
    var flash = $(document.createElement('div'));
    flash.addClass('alert').addClass('alert-' + type);

    // Dismiss button (the 'x')
    var dismiss = $(document.createElement('a'));
    dismiss.addClass('close');
    dismiss.attr('data-dismiss','alert');
    dismiss.append('&#215;');
    flash.append(dismiss);

    // Add the text of the message itself to the flash
    flash.append(message);

    // Add the flash to the DOM
    $('#flash-messages').html(flash);
  };

  // Set the entire flash area to a single message of a given type ('success' or 'error')
  this.setFlash = function(message, type) {
    this.clear();
    this.addFlash(message, type);
    $('html, body').scrollTop(0);
  };

  // Set the entire flash area to an error with the given message
  this.error = function(message) {
    this.setFlash(message, 'error');
  };

  // Set the entire flash area to a success with the given message
  this.success = function(message) {
    this.setFlash(message, 'success');
  };
}

// Object we can use to interface with the flash messages
// e.g. flashMessages.error('Hello World!');
export var flashMessages = new flashMessagesClass();
