<template>
  <button
    @click="$emit('click')"
    :class="buttonClass"
    :disabled="disabled || loading"
  >
    <span class="flex items-center">
      <template v-if="loading">
        <img
          v-if="primary"
          class="mr-2 spin"
          src="~images/icons/spinner-white.svg"
        />
        <img
          v-if="!primary"
          class="mr-2 spin"
          src="~images/icons/spinner-gray.svg"
        />
      </template>
      <template v-else>
        <img
          v-if="plus && primary && !disabled"
          class="mr-2"
          src="~images/icons/plus-white.svg"
        />
        <img
          v-if="plus && (!primary || disabled)"
          class="mr-2"
          src="~images/icons/plus-gray.svg"
        />
        <img
          v-if="check && primary && !disabled"
          class="mr-2"
          src="~images/icons/check-white.svg"
        />
        <img
          v-if="check && (!primary || disabled)"
          class="mr-2"
          src="~images/icons/check-gray.svg"
        />
      </template>
      <slot></slot>
    </span>
  </button>  
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    noChange: Boolean,
    primary:  Boolean,
    disabled: Boolean,
    small:    Boolean,
    large:    Boolean,
  
    // icons
    loading:  Boolean,
    plus:     Boolean,
    check:    Boolean,
  },
  computed: {
    buttonClass() {
      return {
        'ui-button': true,
        'no-change': this.noChange,
        'primary':   this.primary,
        'loading':   this.loading,
        'p-1':       this.small,
        'p-2':       !(this.small || this.large),
        'p-3':       this.large,
      }
    },
  },
  example: `
<template>
  <div>
    <h4 class="my-6 border-b">UiButton</h4>
    <ui-button>Default</ui-button>
    
    <h4 class="my-6 border-b">Sizes</h4>
    <ui-button small>Small</ui-button>
    <ui-button>Medium</ui-button>
    <ui-button large>Large</ui-button>
    
    <h4 class="my-6 border-b">Styles</h4>
    <ui-button primary>Primary</ui-button>
    <ui-button>Secondary</ui-button>
    <ui-button no-change>No Change</ui-button>

    <h4 class="my-6 border-b">Icons</h4>
    <ui-button primary plus>Primary Plus</ui-button>
    <ui-button plus>Secondary Plus</ui-button>
    <ui-button no-change plus>No Change Plus</ui-button>
    <div class="my-2"></div>
    <ui-button primary check>Primary Check</ui-button>
    <ui-button check>Secondary Check</ui-button>
    <ui-button no-change check>No Change Check</ui-button>

    <h4 class="my-6 border-b">States</h4>
    <ui-button loading primary>Primary Loading</ui-button>
    <ui-button loading>Secondary Loading</ui-button>
    <ui-button loading no-change>No Change Loading</ui-button>
    <div class="my-2"></div>
    <ui-button disabled primary>Primary Disabled</ui-button>
    <ui-button disabled>Secondary Disabled</ui-button>
    <ui-button disabled no-change>No Change Disabled</ui-button>
    <div class="my-2"></div>
    <ui-button disabled primary plus>Primary Disabled</ui-button>
    <ui-button disabled plus>Secondary Disabled</ui-button>
    <ui-button disabled no-change plus>No Change Disabled</ui-button>
    <div class="my-2"></div>
    <ui-button disabled primary check>Primary Disabled</ui-button>
    <ui-button disabled check>Secondary Disabled</ui-button>
    <ui-button disabled no-change check>No Change Disabled</ui-button>
  </div>
</template>

<script>
  export default {}
<\/script>
`
}
</script>

<style lang="scss">
  .ui-button {
    @apply #{ w-auto text-sm bg-white rounded-md text-dark outline-none shadow leading-normal transition duration-100 };

    &:disabled:not(.loading) {
      @apply #{ text-gray font-normal bg-disabled shadow-none border-none cursor-default !important };
    }

    &:hover:not(.loading) {
      @apply #{ shadow-md };
    }

    &:active:not(.loading) {
      @apply #{ shadow-none };
    }

    &.loading {
      @apply #{ cursor-default text-gray };
    }

    &.no-change {
      @apply #{ text-gray bg-transparent border-2 shadow-none font-semibold shadow-none };
      border-color: rgba(67, 77, 87, 0.1); // graphite at 10% opacity

      &:hover {
        @apply #{ shadow-none };
      }

      img {
        opacity: 0.75;
      }
    }

    &.primary {
      @apply #{ text-white bg-green shadow-none };

      &.loading {
        @apply #{ bg-green-loading };
      }

      &:hover:not(.loading) {
        @apply #{ bg-green-hover shadow-none };
      }

      &:active:not(:disabled) {
        @apply #{ bg-green-active !important };
      }
    }
  }
</style>
