/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
*/
// this was a coffee script file converted into a js file
 
import { tConvert } from 'core/helpers/format_helpers.js';

const Cls = (window.Theme = class Theme {
  static initClass() {
    this.colors = { darkGreen: '#779148', red: '#C75D5D', green: '#96c877', blue: '#6e97aa', orange: '#ff9f01', gray: '#6B787F', lightBlue: '#D4E5DE'};
  }
});
Cls.initClass();

jQuery.fn.dataTableExt.oApi.fnSetFilteringDelay = function(oSettings, iDelay) {
  const _that = this;
  if (iDelay === undefined) { iDelay = 250; }
  this.each(function(i) {
    $.fn.dataTableExt.iApiIndex = i;
    const $this = this;
    let oTimerId = null;
    let sPreviousSearch = null;
    const anControl = $('input', _that.fnSettings().aanFeatures.f);
    anControl.unbind('keyup').bind('keyup', () => {
      const $$this = $this;
      if ((sPreviousSearch === null) || (sPreviousSearch !== anControl.val())) {
        window.clearTimeout(oTimerId);
        sPreviousSearch = anControl.val();
        return oTimerId = window.setTimeout(() => {
          $.fn.dataTableExt.iApiIndex = i;
          return _that.fnFilter(anControl.val());
        }
        , iDelay);
      }
    });
    return this;
  });
  return this;
};

const setDefaultHeader = function() {
  const tableHeader = $('th[data-default-sort=true]');
  if ((tableHeader[0] === undefined) || (tableHeader[0] === null)) { return [1, 'asc']; }
  const sortOrder = [tableHeader.index(), tableHeader.data('sort-direction')];
  return sortOrder;
};

$(function() {
  $.extend( $.fn.dataTableExt.oStdClasses,
    {'sWrapper': 'dataTables_wrapper form-inline'});

  // The (custom!) data table for Available Shifts
  if ($('#shifts_list').length > 0 || $('#historical_shifts_list').length > 0) {
  //initialize the data tables
    $('.dTable').dataTable({
      bProcessing: true,
      bServerSide: true,
      bFilter: false,
      sAjaxSource: $('.dTable').data('source'),
      sPaginationType: 'full_numbers',
      iDisplayLength: 25,
      aaSorting: [ setDefaultHeader() ],
      // Disable sorting for History, Time, Requests, and Created By
      aoColumnDefs: [{
        bSortable: false,
        aTargets: [0,2,4,5]
      }],
      sDom: '<"table-responsive"rt><"table-footer"ip>',
      fnRowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        // change to 12 hours format if am/pm is selected
        if ($('#user_data').data('timeformat') === 12) { return $('td:eq(2)', nRow).html(tConvert(aData[2].split(' - ')[0]) + ' - ' +
  tConvert(aData[2].split(' - ')[1])
        ); }
      },
      oLanguage: dataTables_i18n,
      bStateSave: true
    }).fnSetFilteringDelay(600);
    $('.time_column').each(function(index, elem) {
      return $(this).text('help');
    });
  } else {
    //initialize the data tables
    const table = $('.dTable').dataTable({
      bProcessing: true,
      bServerSide: true,
      sAjaxSource: $('.dTable').data('source'),
      sPaginationType: 'full_numbers',
      iDisplayLength: 50,
      aaSorting: [ setDefaultHeader() ],
      aoColumnDefs: [{
        bSortable: false,
        aTargets: $('th.no-sort').map(function() {
          return $(this).index();
        }).get()
      }],
      fnInitComplete() { return $(document).trigger('datatable:loaded'); },
      fnDrawCallback() { return $(document).trigger('datatable:drawn'); },
      sDom: '<"table-header"flr><"table-responsive"t><"table-footer"ip>',
      oLanguage: dataTables_i18n
    });

    if (table.fnSetFilteringDelay) {
      table.fnSetFilteringDelay(600);
    }
  }

  $('.dTable-small').dataTable({
    iDisplayLength: 5,
    bProcessing: true,
    bServerSide: true,
    sAjaxSource: $(this).data('source'),
    sDom: '<"table-header"flr><"table-responsive"t><"table-footer"ip>',
    oLanguage: dataTables_i18n
  });

  //uniform js (only file fields) - note: for dataTables to work, it needs to happen after
  return $('select.uniform, input:file, .dataTables_length select').uniform();
});
