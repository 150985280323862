export var analytics = (function($) {

  /*
  public
  */


  // Initialize Analytics::Workforce
  var init = function(settings) {
    $("#location-department-filter").select2();
    $("#data-set-filter").select2();

    var datePicker = $('.analytics .input-daterange').datepicker({
      format: 'yyyy-mm-dd',
      language: I18n.locale
    });

    datePicker.hide();
    $("#checkbox-container").hide();
    $("#clear-custom-date-range").hide();

    prepareDatePicker();
  };

  // Returns true if a chart request can be made based on certain requirements, otherwise false
  var canMakeChartRequest = function(chartName, isInactive, locationIds, departmentIds) {
    if (isInactive) { return false; }
    $('#' + chartName + '-chart-box').hide();

    if(departmentIds.length === 0 && locationIds.length == 0) {
      var chartClassName = "."+chartName;
      analytics.clearCustomDateRange(chartClassName);
      return false;
    }

    $('#' + chartName + '-chart-box').show();
    return true;
  };

  // When date range is visible
  var clearCustomDateRange = function(chartClassName) {
    $(".custom-date-range").show();
    $("#clear-custom-date-range").hide();
    $(".analytics .input-daterange ").hide();
    $(".date-range-filter").fadeTo(500,1.0);

    $(  chartClassName + " #date-range-days, "
      + chartClassName + " #date-range-weeks, "
      + chartClassName + " #date-range-months, "
      + chartClassName + " #date-range-previous, "
      + chartClassName + " #date-range-next"
    ).removeClass('inactive');
  };

  var filterDateRange = function(dateRange) {
    if ((dateRange === "Days") || (dateRange === "Weeks") || (dateRange === "Months")) {
      return dateRange;
    } else {
      switch ($('#btn-group-date-range .active').attr('id')) {
        case 'date-range-days': return 'Days';
        case 'date-range-weeks': return 'Weeks';
        case 'date-range-months': return 'Months';
        default: return '';
      }
    }
  };

  var filterDepartments = function() {
    var departmentIds = [];

    var selected = $("#location-department-filter").select2("data");
    $(selected).each(function(index, value) {
      var selectedValue = value.id;
      if(selectedValue.search('department') > -1){
        departmentIds.push(selectedValue.replace('department',''));
      }
    });

    return departmentIds;
  };

  var filterEndDate = function(prevNext, endDate, dateRange) {
    if(prevNext === "date-range-previous"){
      endDate = (dateRange === "Days") ? subtractOneDay(endDate) : endDate;
      endDate = (dateRange === "Weeks") ? subtractSevenDays(endDate) : endDate;
      endDate = (dateRange === "Months") ? subtractOneMonth(endDate) : endDate;
    }
    if(prevNext === "date-range-next"){
      endDate = (dateRange === "Days") ? addOneDay(endDate) : endDate;
      endDate = (dateRange === "Weeks") ? addSevenDays(endDate) : endDate;
      endDate = (dateRange === "Months") ? addOneMonth(endDate) : endDate;
    }

    return endDate;
  };

  var filterLocations = function() {
    var locationIds = [];
    var selected = $("#location-department-filter").select2("data");
    $(selected).each(function(index, value) {
      var selectedValue = value.id;
      if(selectedValue.search('location') > -1){
        locationIds.push(selectedValue.replace('location',''));
      }
    });

    return locationIds;
  };

  var filterExchangeType = function() {
    if ($("#exchange_type_exchange").is(":checked")) {
      return "exchange"
    } else if ($("#exchange_type_drop").is(":checked")) {
      return "drop"
    }
    return "all";
  };

  var initializePage = function(selector, chartBox, removeLocations) {
    var locationGroup = selector.find('#location-department-filter optgroup#location');
    chartBox.hide();
    removeLocations = removeLocations || false;
    if(removeLocations) locationGroup.remove();
  };

  // Hits the appropriate controller to create or update the chart
  var makeChartRequest = function(url, dataHash) {
    $.ajax({
      url: url,
      data: dataHash,
      dataType: "script"
    });
  };

  var removeOptionGroup = function(locationDepartmentFilter, locationsOptGroup, departmentsOptGroup) {
    var selected = $("option:selected", locationDepartmentFilter);
    if (selected.length === 0) {
      restoreLocationDepartmentFilter(locationDepartmentFilter, locationsOptGroup, departmentsOptGroup);
      return;
    }

    (selected.val().search('location') > -1) ? locationDepartmentFilter.find('optgroup#department').remove() : locationDepartmentFilter.find('optgroup#location').remove();
  };

  // Gathers custom date range info, then sends a chart request
  // The chart request will retrieve JS from the server and immediately execute it
  var requestCustomDateRange = function(url, filterDataHash, chartName, filterName) {
    var dataHash = buildDataForChartRequest(filterDataHash);

    if (dataHash.locations.length === 0 && dataHash.departments.length === 0) {
      $('#' + chartName + '-chart-box').hide();
      $('#' + filterName).hide();
      return;
    }
    makeChartRequest(url, dataHash);
  };
  // Begin the request for an Analytics Report, and return the promise back to the caller
  var makeAsyncChartDataRequest = function(url, filterDataHash, chartName, filterName) {
    var dataHash = buildDataForChartRequest(filterDataHash);

    if (dataHash.locations.length === 0 && dataHash.departments.length === 0) {
      $('#' + chartName + '-chart-box').hide();
      $('#' + filterName).hide();
      return;
    }
    return $.ajax({
      url: encodeURI(url),
      data: dataHash,
      dataType: "json",
    });
  };

  var buildDataForChartRequest = function(filterDataHash) {
    // Retrieve the date formatting used for this datepicker so we can "undo"
    var startDate = $(".analytics #start-date").datepicker('getDate');
    var endDate = $(".analytics #end-date").datepicker('getDate');

    // Default is today to a week from now
    if(!startDate && !endDate) {
      startDate = new Date();
      endDate = addSevenDays(new Date());
      $(".analytics #start-date").datepicker('setDate', startDate);
      $(".analytics #end-date").datepicker('setDate', endDate);
    }

    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();
    var dateRange = "Days";
    var dataHash = {
      locations: locationIds,
      departments: departmentIds,
      range: dateRange,
      startDate: startDate,
      endDate: endDate
    };
    // merge the two hashes together
    dataHash = $.extend(dataHash, filterDataHash);
    return dataHash;
  };

  // When custom date range is visble
  var selectCustomDateRange = function(url, chartClassName) {
    $(".custom-date-range").hide();
    $("#clear-custom-date-range").show();
    $(".analytics .input-daterange ").show();
    $(".date-range-filter").fadeTo(500,0.2);

    $(  chartClassName + " #date-range-days, "
      + chartClassName + " #date-range-weeks, "
      + chartClassName + " #date-range-months, "
      + chartClassName + " #date-range-previous, "
      + chartClassName + " #date-range-next"
      ).addClass('inactive');
  };

  // Returns a URL for explorting a CSV (ex. used in Ledger analytics page)
  //
  // @return [String]
  //
  var exportURL = function(options) {
    options = options || {};
    var startDate = Date.parse($(".analytics #start-date").val()),
      endDate = Date.parse($(".analytics #end-date").val()),
      params = [];

    _.each(options, function(value, key) {
      params.push(key + "=" + value)
    });

    // If both start and endDates aren't specified.. return a blank string.
    if (!(startDate && endDate)){
      return "";
    } else {
      params.push("startDate=" + startDate.toISOString());
      params.push("endDate=" + endDate.toISOString());
    }

    // Gather locationIds and departmentIds
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();
    var reportType = '';

    if (options.report_type) {
      reportType = options.report_type;
    }

    // Skip the location and department handling for reports that don't need it
    if (reportType != 'shift_audits') {
      // If both locations and departments are empty, return 0.
      if (locationIds.length == 0 && departmentIds.length == 0) {
        return "";
      }

      if (locationIds.length > 0) {
        params.push("locations=" + locationIds.join(','));
      }

      if (departmentIds.length > 0) {
        params.push("departments=" + departmentIds.join(','));
      }
    }

    var url = window.location.pathname + '.xlsx?' + params.join('&');

    return url;
  }

  var showLoadingModal = function() {
    var loadingModal = HandlebarsTemplates['shared/processing_modal']({
      loadingHeader: I18n.t('analytics.loading_modal_header'),
      loadingMessage: I18n.t('analytics.loading_modal_message'),
      loadingWarning: I18n.t('analytics.loading_modal_warning'),
      loadingWarningMessage: I18n.t('analytics.loading_modal_warning_message'),
    });
    var loadingDiv = $('#analytics-loading');

    loadingDiv.html(loadingModal);
    loadingDiv.show();
  };

  var hideLoadingModal = function(spinner, loadingElementId) {
    $('#analytics-loading').hide();
    $('#submit-chart').removeClass('disabled');
  };

  var subscribeToAnalyticsReportChannel = function(privatePusherChannel, reportName, successCallback, errorCallback) {
    privatePusherChannel.bind(reportName + '-report-success', function(pusherData) {
      if (pusherData['report_type'] === 'xlsx') {
        var xlsxUri = pusherData['report_uri'] + '.xlsx';
        window.location.href = xlsxUri;
        hideLoadingModal();
      } else {
        $.ajax({
          url: pusherData.report_uri,
          dataType: "json",
        }).then(function(analyticsReport) {
          successCallback(analyticsReport);
        });
      }
    });

    privatePusherChannel.bind(reportName + '-report-failure', function(pusherData) {
      errorCallback(pusherData);
    });
  };

  var displayDateRangeError = function(message) {
    $('.date-range-error').text(message);
  };

  var hideDateRangeError = function() {
    $('.date-range-error').text('');
  };

  /*
  private
  */

  function addOneDay(date){
    return new Date(date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 1));
  }

  function addSevenDays(date){
    return new Date(date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 7));
  }

  function addOneMonth(date){
    return new Date(date.setFullYear(date.getFullYear(), date.getMonth() + 1));
  }

  // When the user first selects the start OR end date, the other date (which is defaulted as blank) will automatically get set
  // Prevents a controller error
  var prepareDatePicker = function() {
    $(".analytics #start-date").on("change", function(e) {
      $(this).datepicker("hide");
      $(".analytics #end-date").datepicker("show");
      if ($(".analytics #end-date").val() == "") {
        $(".analytics #end-date").val( $(".analytics #start-date").val() );
      }
    });

    $(".analytics #end-date").on("change", function(e) {
      $(this).datepicker("hide");
      if ($(".analytics #end-date").val() == "")  {
        $(".analytics #end-date").val( $(".analytics #start-date").val() );};
      if ($(".analytics #start-date").val() == "") {
        $(".analytics #start-date").val( $(".analytics #end-date").val() );
      }
    });
  };

  function restoreLocationDepartmentFilter(locationDepartmentFilter, locationsOptGroup, departmentsOptGroup){
    if(locationDepartmentFilter.find('optgroup#location').length == 0) {
      locationDepartmentFilter.prepend(locationsOptGroup);
    }
    if(locationDepartmentFilter.find('optgroup#department').length == 0) {
      locationDepartmentFilter.append(departmentsOptGroup);
    }
  }

  function subtractOneDay(date){
    return new Date(date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() - 1));
  }

  function subtractSevenDays(date){
    return new Date(date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() - 7));
  }

  function subtractOneMonth(date){
    return new Date(date.setFullYear(date.getFullYear(), date.getMonth() - 1));
  }

  return {
    init: init,
    canMakeChartRequest: canMakeChartRequest,
    clearCustomDateRange: clearCustomDateRange,
    displayDateRangeError: displayDateRangeError,
    hideDateRangeError: hideDateRangeError,
    filterDateRange: filterDateRange,
    filterDepartments: filterDepartments,
    filterEndDate: filterEndDate,
    filterLocations: filterLocations,
    filterExchangeType: filterExchangeType,
    hideLoadingModal: hideLoadingModal,
    initializePage: initializePage,
    makeAsyncChartDataRequest: makeAsyncChartDataRequest,
    makeChartRequest: makeChartRequest,
    removeOptionGroup: removeOptionGroup,
    requestCustomDateRange: requestCustomDateRange,
    selectCustomDateRange: selectCustomDateRange,
    showLoadingModal: showLoadingModal,
    subscribeToAnalyticsReportChannel: subscribeToAnalyticsReportChannel,
    exportURL: exportURL,
  };

})(jQuery);

window.analytics = analytics;
