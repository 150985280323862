import { msUtil } from 'core/ms_util.js';
import { analytics } from 'core/analytics/analytics.js';
window.analyticsLabourBudget = (function($) {

  /*
  public
  */
  var locationGroup, departmentGroup, config, errorDiv;

  // Initialize Analytics::Labour Budget
  var init = function(settings) {
    var today = new Date();
    config = settings;
    $.extend(config, {
      endDate: new Date(today.setFullYear(today.getFullYear(), today.getMonth(), today.getDate() + 7)),
    });
    initLabourBudget();
    analytics.subscribeToAnalyticsReportChannel(config.privatePusherChannel, 'labour-budget', constructLabourBudgetsReport, displayPusherError);

    locationGroup = $('.labour-budget').find('#location-department-filter optgroup#location');
    departmentGroup = $('.labour-budget').find('#location-department-filter optgroup#department');

    Handlebars.registerHelper('displayInCurrency', function(value) {
      return I18n.l('currency', value);
    });

    errorDiv = $('#analytics-error');
  };

  /*
  private
  */

  var clearCustomDateRangeForLabourBudget = function() {
    analytics.clearCustomDateRange(".labour-budget");
    getLabourBudget();
  };

  var getLabourBudget = function() {
    var isInactive = $(this).hasClass('inactive');
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();
    var dateRange = analytics.filterDateRange($(this).text());
    config.endDate = analytics.filterEndDate($(this).attr('id'), config.endDate, dateRange);
    var dataHash = {
      locations: locationIds,
      departments: departmentIds,
      range: dateRange,
      endDate: $.datepicker.formatDate('yy/mm/dd', config.endDate)
    };

    if (analytics.canMakeChartRequest("labour-budget", isInactive, locationIds, departmentIds)) {
      errorDiv.hide();
      analytics.showLoadingModal();
      $.ajax({
        url: '/analytics/labour_budget',
        data: dataHash,
        dataType: "json",
      });
    } else {
      return;
    }
  };

  var initLabourBudget = function() {
    analytics.initializePage($('.labour-budget'), $('#labour-budget-chart-box'));
    $('.labour-budget #location-department-filter').select2().on("change", removeLabourBudgetOptionGroup);
    $(".labour-budget #submit-chart").on("click", makeRequestForLabourBudget);
    $(".labour-budget #date-range-days, .labour-budget #date-range-weeks, .labour-budget #date-range-months, .labour-budget #date-range-previous, .labour-budget #date-range-next").on("click", getLabourBudget);

    $('.labour-budget #set-custom-date-range').on("click", selectCustomDateRangeForLabourBudget);
    $('.labour-budget #clear-custom-date-range').on("click", clearCustomDateRangeForLabourBudget);
    $("#clear-custom-date-range").hide();
    $('.analytics .input-daterange').datepicker({ language: I18n.locale }).hide();
  };

  var makeRequestForLabourBudget = function() {
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();

    if (locationIds.length == 0 && departmentIds.length == 0) { analytics.clearCustomDateRange(".labour-budget"); }
    if ($(".labour-budget #date-range-days").hasClass('inactive')) {
      selectCustomDateRangeForLabourBudget();
    } else {
      getLabourBudget();
    }
  };

  var removeLabourBudgetOptionGroup = function() {
    analytics.removeOptionGroup($('.labour-budget').find('#location-department-filter'), locationGroup, departmentGroup);
  };

  var selectCustomDateRangeForLabourBudget = function() {
    var url = '/analytics/labour_budget';
    analytics.selectCustomDateRange(url, ".labour-budget");

    // if the start & end date are already set, execute the ajax call, otherwise listen for a date change event
    if ($(".analytics #start-date").val() !== "" && $(".analytics #end-date").val() !== "") {
      analytics.showLoadingModal();
      analytics.makeAsyncChartDataRequest(url, {}, "labour-budget", "");
    }
    else {
      $('.analytics .input-daterange').on("changeDate", function(event) {
        analytics.showLoadingModal();
        analytics.makeAsyncChartDataRequest(url, {}, "labour-budget", "");
      });
    }
  };


  var constructLabourBudgetsReport = function(analyticsReport) {
    var data = analyticsReport.data;
    var labourBudgetTotalHtml = "<div id='labour-budget-total'><h5>";
    var budgetNumber = I18n.l('currency', data.total_budget);
    var budgetCurrency = I18n.t("analytics.labor_budget.show.estimated_budget", { "budget": budgetNumber});
    labourBudgetTotalHtml += budgetCurrency;
    labourBudgetTotalHtml += "</h5></div>";
    $('#labour-budget-total').replaceWith(labourBudgetTotalHtml);

    buildLabourBudgetChart(data.labour_budgets);

    var labourBudgetBreakdown = HandlebarsTemplates['analytics/labour_budget_breakdown']({
      labour_budgets: data.labour_budgets
    });
    $('#labour-budget-breakdown').replaceWith(labourBudgetBreakdown);

    var startDate = analyticsReport.parameters.start_date;
    var endDate = analyticsReport.parameters.end_date;
    var dateRangeHtml = "<p class='title'>" +  msUtil.formattedDateRange(startDate, endDate) + "</p>";
    $('.date-range-filter .title').replaceWith(dateRangeHtml);
    analytics.hideLoadingModal();
  };

  var buildLabourBudgetChart = function(labourBudgetData) {
    // TODO: This is a hack. We need to move this formatting to the backend like we do for time off/unavailables analytics.
    var formattedData = [];
    $.each(labourBudgetData, function(index, series) {
      var formattedLocation = {
        name: series.name,
        sum_total: series.sum_total,
        data: []
      };

      $.each(series.data, function(dataIndex, point) {
        var newData = {x: null, y: null, name: ""};
        newData.x = new Date(point[0]);
        newData.y = point[1];
        newData.name = point[1];
        formattedLocation.data.push(newData);
      });
      formattedData.push(formattedLocation);
    });

    Highcharts.chart({
      chart: {
        type: 'spline',
        renderTo: 'labour-budget-chart'
      },
      credits: { enabled: false },
      series: formattedData,
      title: { text: "" },
      xAxis: {
        type: 'datetime',
        minTickInterval: (24 * 3600 * 1000),
        title: {
          text: I18n.t("analytics.labor_budget.show.x_axis")
        }
      },
      yAxis: {
        title: {
          text: I18n.t("analytics.labor_budget.show.title") + " ($)"
        },
        allowDecimals: false
      }
    });
  };


  var displayPusherError = function(data) {
    analytics.hideLoadingModal();
    console.log('There was an error from pusher: ' + data.message);
    errorDiv.show();
    errorDiv.text(data.message);
  };


  return {
    init: init,
  };

})(jQuery);
