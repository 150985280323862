import { departmentSchedule } from 'core/department_schedules.js';
export var scheduleRotations = (function($, window, document) {

  var rotationId;
  var rotationLength;
  var rotationBlueprint;
  var config;
  var rotationLoadingPrompt;
  var init = false;

  init = function(settings) {
    config = {
      privatePusherChannel: settings.privatePusherChannel,
      routes: {
        createDepartmentRotationPath: settings.createDepartmentRotationPath
      }
    }

    Handlebars.registerHelper('isPublished', function(published) {
      if (published) {
        return new Handlebars.SafeString("<strong>" + I18n.t('rotations.js.published') + "</strong>");
      } else {
        return new Handlebars.SafeString("<strong>" + I18n.t('rotations.js.unpublished') + "</strong>");
      }
    });

    Handlebars.registerHelper('ifEquals', function(arg1, arg2, options) {
      return (arg1 == arg2) ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('remainingErrors', function(totalErrors, displayedErrors) {
      var remainingErrors = totalErrors - displayedErrors.length;

      if (remainingErrors > 0) {
        return new Handlebars.SafeString("<li>And " + remainingErrors + " more...</li>");
      } else {
        return "";
      }
    });

    init = true;

    registerPusherEvents();
  }

  var registerPusherEvents = function() {
    if (config.privatePusherChannel) {

      config.privatePusherChannel.bind('rotation-create.confirm', function(data) {
        openConflictDialogue(data.payload, data);
      });

      config.privatePusherChannel.bind('rotation-create.success', function(data) {
        var rowPosition = departmentSchedule.getCurrentRowPosition();
        departmentSchedule.loadSchedule(location.href, rowPosition);
        hideLoader();
      });

      config.privatePusherChannel.bind('rotation-create.failure', function(data) {
        hideLoader();
      });
    }
  };

  var openModal = function(ui, obj) {
    var $td = obj;
    var $tr = $td.closest('tr');
    var $th = $('.floatThead-table').find('th').eq($td.index());
    var startDate = moment($th.attr('data-date')).toDate();
    var userId = $tr.data('user-id');
    rotationId = ui.draggable.data("rotation-id");
    rotationLength = ui.draggable.data("rotation-length");
    rotationBlueprint = ui.draggable.data("rotation-blueprint");
    openDatePrompt(startDate, userId);
  }

  var openDatePrompt = function(startDate, userId) {
    var rotationDays = Object.keys(rotationBlueprint).length - 1;
    var nextDay = startDate.clone().add(rotationDays).day();
    var dialogMessage = buildDialogMessage(nextDay);

    var buttons = [];
    buttons.push({"label": "Cancel", "callback": function() {}});
    buttons.push({ "label" : "Ok", "class" : "btn-blue", "callback": function() {
        createRotation(startDate, $("input#rotation-end-date").val(), userId);
      }
    });

    var headerText = I18n.t('rotations.js.rotation_end');
    bootbox.dialog(
      $(dialogMessage.join("\n")),
      buttons,
      {"header": headerText});

    $('.datepicker').datepicker({ weekStart: MKS.departmentStartOfWeek, language: I18n.locale }).datepicker('setStartDate', startDate).on('changeDate', function(e) {
      $(this).parent().find('input#rotation-end-date').val($.datepicker.formatDate( "yy-mm-dd", e.date ));
    });
  }

  var createRotation = function(startDate, endDate, userId) {
    var payload = {
      rotationId: rotationId,
      userId: userId,
      startDate: startDate,
      endDate: endDate
    }

    postData(payload);
  }

  var showLoader = function() {
    var promptContent = HandlebarsTemplates['rotations/rotation_loading']({
      adding_rotation: I18n.t('rotations.js.adding_rotation'),
      warning: I18n.t('rotations.js.warning'),
      warning_detail: I18n.t('rotations.js.warning_detail')
    });
    rotationLoadingPrompt = bootbox.dialog(promptContent, [], {
      "header": I18n.t('rotations.js.creating')
    });
  }

  var hideLoader = function() {
    if (rotationLoadingPrompt) {
      rotationLoadingPrompt.modal('hide');
    }
  }

  var postData = function(payload, forceSave) {

    showLoader();

    $.ajax({
      url: config.routes.createDepartmentRotationPath,
      type: 'POST',
      data: JSON.stringify({
        rotation_id: payload.rotationId || payload.rotation_id,
        user_id: payload.userId || payload.user_id,
        start_date: payload.startDate || payload.start_date,
        end_date: payload.endDate || payload.end_date,
        force_save: forceSave
      }),
      contentType: 'application/json',
      dataType: 'json'
    })
  };

  var openConflictDialogue = function(data, responseData) {
    if (responseData.other_department_conflicts) {
      openShowStopperDialogue(data, responseData);
    } else {
      openForceSavePrompt(data, responseData);
    }
  };

  var openShowStopperDialogue = function(postData, responseData) {
    hideLoader();

    var promptContent = HandlebarsTemplates['rotations/rotation_show_stopper']({
      applying: I18n.t('rotations.js.applying'),
      conflicts: I18n.t('rotations.js.conflicts'),
      shift: I18n.t('rotations.js.shift'),
      time_off_block: I18n.t('rotations.js.time_off_block'),
      errors: responseData.errors
    });

    bootbox.dialog(promptContent, [
      {
        "label" : I18n.t('rotations.js.cancel'),
        "class" : "btn-danger",
        "callback": function() {
          hideLoader();
        }
      }
    ]);
  };

  // Open a modal prompting the user to save the rotation and delete conflicting shifts
  var openForceSavePrompt = function(data, responseData) {
    hideLoader();

    var promptContent = HandlebarsTemplates['rotations/rotation_warning']({
      rotation_warning: I18n.t('rotations.js.rotation_warning'),
      applying_rotation: I18n.t('rotations.js.applying_rotation'),
      shift: I18n.t('rotations.js.shift'),
      time_off_block: I18n.t('rotations.js.time_off_block'),
      note: I18n.t('rotations.js.note'),
      note_detail: I18n.t('rotations.js.note_detail'),
      short_errors: responseData.short_errors,
      total_errors: responseData.total_errors
    });

    bootbox.dialog(promptContent, [
      {
        "label" : I18n.t('buttons.cancel'),
        "class" : "btn",
        "callback": function() {
          hideLoader();
        }
      },
      {
        "label" : I18n.t('rotations.js.continue'),
        "class" : "btn-danger",
        "callback": function() {
          postData(data, true);
        }
      }
    ]);
  }

  var buildDialogMessage = function(nextDay) {
    var dialogMessage = ["<div>"];
    dialogMessage.push("<div class='datepicker' id='dater' data-date-format='yyyy-mm-dd'>");
    dialogMessage.push("<input type='hidden' id='rotation-end-date' value='" + $.datepicker.formatDate("yy-mm-dd", nextDay) +"' readonly text-align:center;'></div>");
    dialogMessage.push("</div>");

    return dialogMessage;
  };

  return {
    init: init,
    openModal: openModal
  }

})(jQuery, window, document);
