window.growlChannel = (function($, window, document) {

  var init = function(privatePusherChannel) {
    privatePusherChannel.bind('growl', function(data) {
      $.growl({
        title: data.title,
        message: data.message,
        duration: 5400,
        style: data.style
      });
    });
  };

  return {
    init: init
  }

})(jQuery, window, document);
