import { am_pm_to_hours } from 'core/helpers/format_helpers.js';
window.editShiftDialog = (function($, window, document) {

  /*
  public
  */

  var init = function(settings) {
    $.extend(config, settings, {
      startTime:  settings.startTime,
      endTime:    settings.endTime,
      breaks:     settings.breaks,
      shiftName:  settings.shiftName,
      hideEndsAt: settings.hideEndsAt,
      trc:        settings.trc,
      notes:      settings.notes,
      selectors: {
        startTimeField:   $('input.time.time-start'),
        endTimeField:     $('input.time.time-end'),
        breaksField:      $('#shift_breaks_in_minutes'),
        shiftTypeField:   $('#shift_shift_type_id'),
        shiftName:        $('#shift_name'),
        hideEndsAtField:  $('#shift_hide_ends_at'),
        trcField:         $('#shift_time_reporting_code'),
        notesField:       $('#shift_notes'),
        submitButton:     $('#shift-modal  button:submit'),
        modalBody:        $('#shift-modal .modal-body'),
      }
    });

    initSetValues();
    initOverride();
    initClearBreaks();
    initTabs();
    initValidator();
  };

  /*
  private
  */

  // Fill modal start, end, break times when the modal is initially opened.
  var initSetValues = function() {
    fillFields(
      config.startTime,
      config.endTime,
      config.breaks,
      config.shiftName,
      config.hideEndsAt,
      config.trc, 
      config.notes
    );
  };

  // Attach `change` handler on to the shift type select menu and the start, end, and break fields in the modal.
  // Shift type change triggers an override and fills the start, end, break times, hide ends at, trc, and notes to the selected shift type information.
  // The reverse is also true:
  // A start or end change triggers an override and clears the shift type field.
  var initOverride = function() {
    config.selectors.shiftTypeField.on('change', function() {
      var selectedShiftType = $(this).find('option:selected');

      fillFields(
        selectedShiftType.data('start-time'),
        selectedShiftType.data('end-time'),
        selectedShiftType.data('breaks'),
        selectedShiftType.text(),
        selectedShiftType.data('hide-ends-at'),
        selectedShiftType.data('time-reporting-code'),
        selectedShiftType.data('notes')
      );
      config.breaks = [];
    });

    config.selectors.startTimeField.
      add(config.selectors.endTimeField).
      on('change', function() {
        config.selectors.shiftTypeField.select2('val', '');
        config.selectors.shiftName.val('');
      });
  };

  // Attach `change` handler on the breaks field.
  // If it changes it clears the in-memory breaks array so subsequent closing, and openings of the shift modal have clean break arrays.
  var initClearBreaks = function() {
    config.selectors.breaksField.on('change', function() {
      config.breaks = [];
    });
  };

  var initTabs = function() {

    // The element #shift-modal persists between calls to this JS
    // That means adding the handler would append multiple handlers when really we just want this to fire once
    // We call 'off' so we don't fire this handler multiple times
    $('#shift-modal').off('show').on('show', 'a[data-toggle="tab"]', function(e) {
      var $self = $(e.target);

      // Load contents
      var url = $self.attr('href');
      if (url != undefined) {
        $($self.data('target')).load(url);
      }

      // Toggle action buttons
      if ($self.data('target') == '#edit-details') {
        $('#delete-shift').removeClass('hidden');
        $('#update-shift').removeClass('hidden');
      } else {
        $('#delete-shift').addClass('hidden');
        $('#update-shift').addClass('hidden');
      }
    });
  };

  var initValidator = function() {
    config.selectors.submitButton.on('click', function() {
      if (!config.selectors.startTimeField.val() || !config.selectors.endTimeField.val()) {
        event.preventDefault();
        if (config.selectors.modalBody.find('.alert.alert-error').length < 1) {
          config.selectors.modalBody.prepend("<div class='alert alert-error'>" + I18n.t('edit_shift_dialog.js.blank_start_or_end') + '</div>');
        }
      };
    });
  };

  // Sets values for the start, end, and break fields
  var fillFields = function(startTime, endTime, breaks, name, hideEndsAt, trc, notes) {
    config.selectors.startTimeField.val(startTime);
    config.selectors.endTimeField.val(endTime);
    config.selectors.breaksField.select2('data', formatBreaks(breaks));
    config.selectors.shiftName.val(name);
    config.selectors.hideEndsAtField.prop('checked', hideEndsAt);
    config.selectors.trcField.val(trc);
    config.selectors.notesField.val(notes);
  };

  // Format breaks the same way they are formatted in department_schedule.js
  var formatBreaks = function(breaks) {
   return $.map(breaks, function(value, index) {
      var valueInMinutes = value / 60;

      return {id: valueInMinutes + '___' + _.uniqueId(), text: valueInMinutes};
    });
  };

  return {
    init: init
  }

})(jQuery, window, document);
