import { flashMessages } from 'core/helpers/flash_messages.js';

window.userDepartmentsLocations = (function($) {

  /*
  public
  */

  // Initialize User_Department and UserLocations Datatable
  // Place for config variables if provided
  var init = function(settings) {
    $.extend(config, {
      tableName: settings.tableName,
      defaultSortColumn: settings.defaultSortColumn,
      noSortColumns: settings.noSortColumns,
      userAssociationType: settings.userAssociationType
    });

    initDataTable();
    initButtonToggles();
  };

  /*
  private
  */

  // Default settings
  var config = {
  };

  // Setup department user datatable
  var initDataTable = function() {

    // Add filter delay to limit server requests
    jQuery.fn.dataTableExt.oApi.fnSetFilteringDelay = function(oSettings, iDelay) {
      var _that;
      _that = this;
      if (iDelay === undefined) {
        iDelay = 250;
      }
      this.each(function(i) {
        var $this, anControl, oTimerId, sPreviousSearch;
        $.fn.dataTableExt.iApiIndex = i;
        $this = this;
        oTimerId = null;
        sPreviousSearch = null;
        anControl = $("input", _that.fnSettings().aanFeatures.f);
        anControl.unbind("keyup").bind("keyup", function() {
          var $$this;
          $$this = $this;
          if (sPreviousSearch === null || sPreviousSearch !== anControl.val()) {
            window.clearTimeout(oTimerId);
            sPreviousSearch = anControl.val();
            return oTimerId = window.setTimeout(function() {
              $.fn.dataTableExt.iApiIndex = i;
              return _that.fnFilter(anControl.val());
            }, iDelay);
          }
        });
        return this;
      });
      return this;
    };

    // Extend datatables to use a styled wrapper to conform to style.
    $.extend($.fn.dataTableExt.oStdClasses, {
      "sWrapper": "dataTables_wrapper form-inline"
    });

    $(config.tableName).dataTable({
      // bProcessing: true,
      bServerSide: true,
      sAjaxSource: $(config.tableName).data('source'),
      sPaginationType: "full_numbers",
      iDisplayLength: 50,
      aaSorting: [ [config.defaultSortColumn, "ASC"] ],
      aoColumnDefs: [
      { bSortable: false, aTargets: config.noSortColumns }
      ],
      sDom: "<\"table-header\"flr><\"table-responsive\"t><\"table-footer\"ip>",
      oLanguage: dataTables_i18n
    }).fnSetFilteringDelay(600);

    // Inititialize Uniform for select picker
    $("select.uniform, input:file, .dataTables_length select").uniform()

    // Add a div wrapper so that headers get proper styling
    $.each($("th[class|='header'],th[class|='sorting']"), function() {
      return $(this).wrapInner("<div />");
    });
  };

  // Sets up the Buttons to toggle and the actions that are performed when clicked.
  var initButtonToggles = function() {
    $('body').on('click', 'tbody a.btn.add', function(e) {
      e.preventDefault();
      submitAction($(this));
    });

    $('body').on('click', 'tbody a.btn.remove', function(e) {
      e.preventDefault();
      $('#user-departments-locations-modal').modal('show');
      $('#user-departments-locations-modal #remove-button').data('table-button-id', "#" + $(this).attr('id'))
      $('#remove-button').prop('disabled', false);
    });

    $('body').on('click', '#remove-button', function(e) {
      e.preventDefault();
      var removeButton = $($(this).data('table-button-id'));
      $(this).prop('disabled', true);
      submitAction(removeButton);
    });
  };

  // Submit form for creation/deletion of departments/locations.
  var submitAction = function($button) {
    var $url = $button.attr('href');
    var $url_method = $button.data('url-method');

    // Double-click protection
    if ($button.hasClass('disabled')) {
      return;
    } else {
      $button.addClass('disabled');
    }

    var request = $.ajax({
      url: $url,
      type: $url_method,
    });

    request.done(function(data) {
      $button.removeClass('disabled');
      toggleButtons($button);
      $('#user-departments-locations-modal').modal('hide');
    });

    request.fail(function(data) {
      $button.removeClass('disabled');
      $('#user-departments-locations-modal').modal('hide');
      var $modal = $('.bootbox.modal.fade.in');
      if (!$modal.length) {
        bootbox.alert(
          '<h4>' + I18n.t('department_users.js.error') + '</h4><p>' + data.responseText + '</p>',
          I18n.t("buttons.ok")
        );
      }
    });
  };

  // Used to toggle the states of the buttons.
  var toggleButtons = function($button) {
    var $tr = $button.closest('tr');
    $tr.find('#add_'+ config.userAssociationType +'_' + $button.data(config.userAssociationType+ '-id')).toggle();
    $tr.find('#delete_'+ config.userAssociationType +'_' + $button.data(config.userAssociationType + '-id')).toggle();
  };

  return {
    init: init,
  };

})(jQuery);
