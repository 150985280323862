<template>
  <users-table 
    :custom-id-column-name="I18n.t('activerecord.attributes.user.employee_id')"
    :async-import-func="importBambooUsers"
    :async-archive-func="archiveMakeshiftUser"
    :table-data="tableData"
  >
    <template slot="header">
      <slot name="header" />
    </template>
  </users-table>
</template>

<script>
import UsersTable from '../users_table';

export default {
  name: 'BambooUsersTable',
  props: {
    initialBambooUsers:    Array,
    initialMakeshiftUsers: Array,
    initialErrors:         Array,
    pusherKey:             String,
  },
  data() {
    return {
      bambooUsers: this.initialBambooUsers,
      makeshiftUsers: this.initialMakeshiftUsers,
      errors: this.initialErrors,
      tableData: [],
    }
  },
  created() {
    this.mergeUserInformation();
  },
  methods: {
    mergeUserInformation() {
      const mergedUsers = this.bambooUsers.map((bambooUser) => {
        const makeshiftUser  = _.findWhere(this.makeshiftUsers, { external_id: bambooUser.id }) || {};
        const error          = _.findWhere(this.errors, { status: 'failed', external_id: bambooUser.id }) || {};
        const name           = _.compact([bambooUser.firstName, bambooUser.middleName, bambooUser.lastName]).join(' ');
        const role           = I18n.t('roles.' + (makeshiftUser.role || 'employee'));
        const employmentType = I18n.t('employment_type.' + (makeshiftUser.employment_type_name ? makeshiftUser.employment_type_name.toLowerCase().replace(/\s/, '_') : 'full_time'));

        return [
          error.status_error || '',
          bambooUser.employeeNumber,
          name,
          bambooUser.bestEmail,
          bambooUser.homePhone || bambooUser.workPhone,
          bambooUser.hireDate,
          role,
          employmentType,
          {
            makeshift_id:   makeshiftUser.id,
            integration_id: bambooUser.id,
          },
        ];
      });

      this.tableData = mergedUsers;
    },

    importBambooUsers(bambooIds = []) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: '/integrations/bamboo/users',
          method: 'POST',
          data: {
            bamboo_employee_ids: bambooIds.join(','),
          },
          success: (xhr) => {
            const pusher = window.getPusherInstance(this.pusherKey);
            const pusherChannel = pusher.subscribe(`bamboo-user-import-${xhr.id}`);
            pusherChannel.bind('bamboo-user-import-complete', () => {
              $.ajax({
                url: '/integrations/bamboo/users',
                method: 'GET',
                dataType: 'json',
                success: (xhr) => {
                  this.makeshiftUsers = xhr.makeshift_users;
                  this.errors = xhr.errors;
                  this.mergeUserInformation();
                  resolve();
                },
                error: (xhr) => {
                  console.log(xhr);
                  reject();
                }
              });
            });
          },
          error: (xhr) => {
            console.log(xhr);
            reject();
          }
        });
      });
    },

    archiveMakeshiftUser(makeshiftId) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `/integrations/bamboo/users/${makeshiftId}`,
          method: 'DELETE',
          success: (xhr) => {
            this.makeshiftUsers = xhr;
            this.mergeUserInformation();
            resolve();
          },
          error: (xhr) => {
            console.log(xhr);
            reject();
          }
        });
      });
    }
  },
  components: {
    UsersTable,
  }
}
</script>
