import { remoteFormErrors } from 'core/remote_form_errors.js';
import { flashMessages } from 'core/helpers/flash_messages.js';
window.subscription = (function($, window, document) {

  /* public */

  /*
   * Initializes all the things
   *
   * @param {Object} settings
   */
  var init = function(settings) {
    $.extend(true, config, settings, {
      selectors: {
        cards:                  $(".cards"),
        cardInfo: {
          cardDetails:          $('.card-details'),
          cardNumber:           $("#number"),
          cardCvc:              $("#cvc"),
          cardExpMonth:         $('#exp_month'),
          cardExpYear:          $('#exp_year')
        },
        companyInternationalField:    $('#company_international_address').parents('.control-group'),
        companyNorthAmericanFields:   $('#company_address1, #company_address2, #company_city, #company_state, #company_postal_code').parents('.control-group'),
        companyCountry:               $('#company_country'),
        companyState:                 $('#company_state'),
        companyStateLabel:            $('label[for="company_state"]'),
        companyPostalCode:            $('#company_postal_code'),
        companyPostalCodeLabel:       $('label[for="company_postal_code"]'),
        paymentForm:                  $('#payment-form'),
        paymentFormButtons:           $('#payment-form').find('button, input[type="submit"]'),
        stripeMeta:                   $('meta[name="stripe-key"]')
      }
    });

    initEvents();
    initCountryForm();
    if (config.creditCardBilling) initStripeKey();
    initThrottleFlashMsg();
  };

  var config = {};
  var events = $({});

  var initEvents = function() {
    config.selectors.cardInfo.cardNumber.on("keyup", function() {
      var oldCardType = config.selectors.cards.attr("class").split(/\s+/).splice(2, 1)[0];
      var cardType = Stripe.cardType($(this).val()).toLowerCase().split(/\s+/).join("_");
      if(oldCardType !== undefined) config.selectors.cards.removeClass(oldCardType);
      config.selectors.cards.addClass(cardType);
    }).on("blur", function() {
      if($(this).val().length === 0) {
        config.selectors.cards.attr("class", "cards pull-right");
      }
    });

    config.selectors.cardInfo.cardCvc.on("focus", function() {
      config.selectors.cards.addClass("signature");
    }).on("blur", function() {
      config.selectors.cards.removeClass("signature");
    });

    config.selectors.paymentForm.submit(function(e){
      var $this = $(this);
      var cardInfoSelectors = config.selectors.cardInfo;

      if (cardInfoSelectors.cardDetails.length > 0 && (cardInfoSelectors.cardNumber.hasClass('required') || cardInfoSelectors.cardNumber.val().length > 0 || cardInfoSelectors.cardCvc.val().length > 0 || cardInfoSelectors.cardExpMonth.val() != cardInfoSelectors.cardInfoSelectorscardExpMonth.data('current') || cardInfoSelectors.cardExpYear.val() != cardInfoSelectors.cardExpYear.data('current'))) {
        // Disable the cancel and submit buttons to prevent repeated clicks
        config.selectors.paymentFormButtons.prop('disabled', true);

        Stripe.createToken({
          number: config.selectors.cardInfo.cardNumber.val(),
          cvc: config.selectors.cardInfo.cardCvc.val(),
          exp_month: config.selectors.cardInfo.cardExpMonth.val(),
          exp_year: config.selectors.cardInfo.cardExpYear.val()
        }, stripeResponseHandler);
        // Prevent the form from submitting with the default action
        return false;
      }
    });
  };

  var initCountryForm = function() {
    updateAddressFields(config.selectors.companyCountry.val());

    $('#company_country').on('change', function() {
      var country_code, select_wrapper, url;
      select_wrapper = $('#main_form');
      country_code = $(this).val();
      url = "render_form?parent_region=" + country_code;
      return select_wrapper.load(url);
    });
  };

  var initStripeKey = function(){
    if (config.selectors.stripeMeta.length) {
      Stripe.setPublishableKey(config.selectors.stripeMeta.attr('content'));
    }
  };

  /* private */

  var stripeResponseHandler = function(status, response){
    if (response.error) {
      var errors = {};
      if (response.error.param) {
        errors[response.error.param] = new Array(response.error.message);
      } else {
        // Display generic payment errors above the credit card number field
        errors['number'] = new Array(response.error.message);
      }
      remoteFormErrors.errorFields(config.selectors.paymentForm.selector, null, errors);
      config.selectors.paymentFormButtons.prop('disabled', false);
    } else {
      // token contains id, last4, and card type
      var token = response.id;
      // Insert the token into the form so it gets submitted to the server
      config.selectors.paymentForm.append($('<input type="hidden" name="stripe_token" />').val(token));
      // and submit
      config.selectors.paymentForm.trigger('submit.rails');
    }
  };

  // function to update the corresponding country and postal/zip code when a province vs a state is selected
  var updateAddressFields = function(country) {
    if (country == 'CA') {
      displayCanadianFields();
    } else if (country == 'US') {
      displayAmericanFields();
    } else {
      displayInternationalFields();
    }
  }

  // Show Canadian fields and labels. Hide others.
  var displayCanadianFields = function() {
    config.selectors.companyStateLabel.html('<abbr title="required">*</abbr>' + I18n.t("subscriptions.js.province"));
    config.selectors.companyPostalCodeLabel.html('<abbr title="required">*</abbr>' + I18n.t("subscriptions.js.postal_code"));
    config.selectors.companyNorthAmericanFields.show();
    config.selectors.companyInternationalField.hide();
    var original_val = config.selectors.companyState.val();
    config.selectors.companyState.empty();
    $.each(config.provinces, function(province_name, province_code) {
      var option_part = (province_code == original_val) ? "<option selected></option>" : "<option></option>"
      config.selectors.companyState.append($(option_part).attr("value", province_code).text(province_name));
    });
  }

  // Show American fields and labels. Hide others.
  var displayAmericanFields = function() {
    config.selectors.companyStateLabel.html('<abbr title="required">*</abbr> '+ I18n.t("subscriptions.js.state"));
    config.selectors.companyPostalCodeLabel.html('<abbr title="required">*</abbr> '+ I18n.t("subscriptions.js.zip"));
    config.selectors.companyNorthAmericanFields.show();
    config.selectors.companyInternationalField.hide();
    var original_val = config.selectors.companyState.val();
    config.selectors.companyState.empty();
    $.each(config.states, function(state_name, state_code) {
      var option_part = (state_code == original_val) ? "<option selected></option>" : "<option></option>"
      config.selectors.companyState.append($(option_part).attr("value", state_code).text(state_name));
    });
  }

  // Show International fields and labels. Hide others.
  var displayInternationalFields = function() {
    config.selectors.companyNorthAmericanFields.hide();
    config.selectors.companyInternationalField.show();
  }

  // Flash message to show up when too many requests made to /subscription
  var initThrottleFlashMsg = function() {
    $("#payment-form").on("ajax:error", function(event, data, status, xhr) {
      if (data.status == 429) {
        flashMessages.setFlash('For security reasons this section has been temporarily disabled. Please wait and try again shortly.', 'danger');
      }
    });
  }

  return {
    init: init
  };

})(jQuery, window, document);

