import { ledgerTableItem } from 'core/analytics/ledger_table_item.js';

var timeAndAttendance;
export var ledgerItem = function(department, timeAndAttendance){
  /*

  Role: To manage ledger tables for a single department. Manages the
  toggling between position and employee tables.

  Ledger Item contains:
  - A header with the department title
  - Two buttons to toggle between ledger tables (position and employee)
  - Two ledgerTableItems -- a positions table and employee one.

  @param:
    department: [Object] A formatted object from the backend containing details
      about the department and data to populate the tables (position and employee)

  */

  // CONSTRUCTOR:
  // Elements that are instantiated upon creation of a ledgerItem
  var ledger = $("<div class='ledger'></div>"),
    positionTable = $("<div class='ledger-table-container'><table></table><div>"),
    employeeTable = $("<div class='ledger-table-container'><table></table><div>"),
    toggleButtons = buildToggleTableButtons();

  ledger.append(
    buildHeader(department),
    toggleButtons,
    employeeTable,
    positionTable
  );
  // END OF CONSTRUCTOR

  // Returns a header that includes a line break
  // and a header with the department name
  function buildHeader(dept) {
    var hr = "<hr>",
    header = "<h4>"+dept.name+"</h4>";
    return (hr+header);
  }

  // Returns two buttons to toggle between the position and employee
  // ledger tables
  function buildToggleTableButtons() {
    return $(
      "<div class='ledger-toggle-table-btns'>" +
        "<div type='employee' class='active'>" + I18n.t('ledger_item.js.employees') + "</div>" +
        "<div type='position'>" + I18n.t('ledger_item.js.positions') + "</div>" +
      "</div>"
    );
  }

  // Builds, initiates, and returns a ledgerTableItem
  //
  // @params:
  //    tableEl: [Element] a table element to attach a dataTables.js table to
  //    data: [Array] a formatted set of data (employee or position)
  //    type: [String] a string indicating the type of table: "position" or "employee"
  //    deptTotals: [JSON object] an object containing a department's totals.
  //
  // @return: [Object] ledgerTableItem object. Note: For now, isn't used after it's returned
  //
  function buildLedgerTable(tableEl, data, type, deptTotals, timeAndAttendance) {
    var table = new ledgerTableItem(tableEl, data,type, deptTotals, timeAndAttendance);
    table.init();
    return table;
  }

  function toggleButtonsClick(){
    toggleButtons.children().on('click', function(){
      var clickedBtn = $(this);
      var otherBtn = $(this).siblings();

      // if the button that was clicked was NOT active,
      // then toggle the buttons. else nothing.
      if(!clickedBtn.hasClass('active')){
        $(clickedBtn).addClass('active');
        $(otherBtn).removeClass('active');

        // grab the type attr from the button
        // to determine which table to show
        var type = clickedBtn.attr('type');
        if(type === "position"){
          positionTable.show();
          employeeTable.hide();
        } else if(type === "employee"){
          employeeTable.show();
          positionTable.hide();
        }
      }

    });
  }

  // Formats department totals
  //
  // Note: calculations of totals is already done on the backend. This
  // method simply puts the right values in to a json object.
  //
  function departmentTotals(dept, timeAndAttendance){
    if (timeAndAttendance) {
      return {
        "name": dept.name,
        "actual_hours": dept.actual_hours,
        "actual_cost": dept.actual_cost,
        "hours": dept.hours,
        "cost": dept.cost
      }
    } else {
      return {
        "name": dept.name,
        "hours": dept.hours,
        "cost": dept.cost
      }
    }


  }

  // Initializes a ledgerItem after it's been created.
  function init(){
    // setup click events for Employee/Position buttons
    toggleButtonsClick();

    // Hide the position table by default
    positionTable.hide();

    // Gather Department Totals
    var deptTotals = departmentTotals(department, timeAndAttendance);

    // Instantiate DataTables (Employee and Position)
    buildLedgerTable(employeeTable.find("table"), department.employee_data, "employee", deptTotals, timeAndAttendance);
    buildLedgerTable(positionTable.find("table"), department.position_data, "position", deptTotals, timeAndAttendance);
  }

  return {
    ledger: ledger,
    init: init
  };
}
