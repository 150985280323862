<template>
  <span class="ui-control-wrapper">
    <label
      class="ui-control-container"
      v-for="option in options"
      :key="option.name"
    >
      <input 
        type="radio"
        :name="name"
        :checked="option.value"
        @change="$emit('change')"
      />
      <span class="ui-control">{{ option.name }}</span>
    </label>
  </span>
</template>

<script>
export default {
  name: 'UiControl',
  props: {
    name:    String,
    options: Array,
  },
  example: `
<template>
  <div>
    <h4 class="my-6 border-b">UiControl</h4>
    <ui-control
      name="control"
      :options="[
        {name: 'Option 1', value: false},
        {name: 'Option 2', value: true},
      ]"
    >
    </ui-control>
  </div>
</template>

<script>
  export default {}
<\/script>
`
}
</script>

<style lang="scss">
  .ui-control-wrapper {
    @apply #{ inline-block w-auto bg-white rounded shadow };
    padding: 5px 3px 4px 3px;
  }

  .ui-control-container {
    @apply #{ relative inline-block my-1 cursor-pointer text-gray select-none };

    input:checked ~ .ui-control {
      @apply #{ bg-disabled text-dark };
    }

    input:checked ~ .ui-control:after {
      @apply #{ block };
    }

    input {
      @apply #{ absolute h-0 w-0 opacity-0 cursor-pointer }
    }

    .ui-control {
      @apply #{ h-5 w-5 p-2 text-sm bg-white rounded transition-all duration-100 };
    }
  }
</style>
