<template>
  <div class="csv-file-upload-form">
    <template v-if="processing">
      <div class="modal">
        <div class="modal-header">
          <h3>{{ I18n.t('user_imports.new.please_wait') }}</h3>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
      <div class="modal-backdrop in"></div>
    </template>
    <input
      type="file"
      ref="fileUploader"
      accept="text/csv"
      @change="handleSelectCSV"
    />
    <button
      class="btn btn-blue"
      @click="handleClick"
    >
      {{ I18n.t('user_imports.new.select_csv') }}
    </button>
    <div class="error-status">{{ errorMessage }}</div>
  </div>
</template>

<script>
  export default {
    name: 'CsvFileUploadForm',
    props: {
      errorMessage: String,
      processing: Boolean
    },
    methods: {
      handleClick() {
        this.$refs.fileUploader.value = ''
        this.$refs.fileUploader.click()
      },

      handleSelectCSV(event) {
        const file = event.target.files[0]
        this.handleFileSubmit(file)
      },

      handleFileSubmit(file) {
        this.$emit('update:processing', true)
        this.$emit('update:errorMessage', null)

        const isCSV = file.type === 'text/csv' || file.name.split('.').pop() === 'csv'

        if (!isCSV) {
          this.$emit('update:errorMessage', I18n.t('user_imports.errors.invalid_filetype'))
          this.$emit('update:processing', false)
          return
        }

        this.$emit('submit', file)
      }
    }
  }
</script>

<style lang="scss">
  .csv-file-upload-form {
    .modal {
      top: 30%;
    }

    input[type=file] {
      display: none;
    }

    .error-status {
      color: red;
    }
  }
</style>
