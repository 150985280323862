import { msUtil } from 'core/ms_util.js';

window.dashboard = {};
var scheduleDate;
window.departmentId;

(function(dashboard) {
  dashboard.init = function(default_department_id) {
    scheduleDate = new Date();
    $("#department-filter").select2({
      formatSelection: function(department) {
        var location_name = $(department.element).parent("optgroup").attr("label");
        return location_name + " - " + department.text;
      },
      matcher: function(term, text, opt){
        return text.toUpperCase().indexOf(term.toUpperCase())>=0 || opt.parent("optgroup").attr("label").toUpperCase().indexOf(term.toUpperCase())>=0
      }
    });
    $('#department-filter').on("change", function() {
      setDepartmentId($("#department-filter").select2("data").id);
      pushHistory();
      updateDashboard();
    });
    $(".update-default-department").on("click", function(e){
      e.preventDefault();
      updateDefaultDepartment();
    });
    $('body').on('click', 'a[data-checklist-item-id]', skipChecklistItem);

    // Event called when we hit browser back/forward buttons
    window.addEventListener('popstate', function(event) {
      setDepartmentId(event.state.deptId);
      updateDashboard();
    });

    window.addEventListener('resize', function(event) {
      fixStyles();
    });

    // Initial load
    setDepartmentId(default_department_id);
    updateDashboard({ initial_load: true });
    fixStyles();
  };

  function fixStyles() {
    let maxHeight = $('#dashboard-approvals').find('.box-header').first().height();

    $('#dashboard-approvals').find('.box-header').each((idx, el)=> {
      $(el).removeClass('multi-line');
      if($(el).height() > maxHeight) {
        maxHeight = $(el).height();
      }
    });

    $('#dashboard-approvals').find('.box-header').each((idx, el)=> {
      if ($(el).height() < maxHeight) {
        $(el).addClass('multi-line');
      }
    });
  };

  function setDepartmentId(id) {
    window.departmentId = id;
    $("#department-filter").select2("val", departmentId);
  }

  // Add to history so we can navigate "Back" in browser to this department
  function pushHistory() {
    history.pushState({deptId: departmentId}, document.title, document.location.href);
  }

  // Updates all dynamic views on the screen
  function updateDashboard(opts) {

    // Quick links don't need to be loaded the first time around
    // They're already rendered into the page via show.html.erb
    var initial_load = opts && opts.initial_load;
    if (!initial_load) {
      updateQuickLinks();
    }

    // Show panel if:
    // - Brands::Adaptor.show_time_off? == true
    // - company_setting.allow_time_off_requests == true OR
    // - pending time off requests count is greater than 0
    if (MKS.show_time_off_requests_panel) {
      updateTimeOffRequests();
    }
    updateAvailableShifts();
    updatePendingExchanges();
    updateScheduleContent();
  }

  // Updates the schedule area
  function updateScheduleContent() {
    // Spin baby spin
    var initiateSpinner = msUtil.spinner('schedules');

    $.ajax({
      url: "dashboard/schedule",
      data: { departmentId: departmentId, scheduleDate: $.datepicker.formatDate('yy/mm/dd', scheduleDate) },
      dataType: "script"
    })
      .always(function() {
        initiateSpinner.stop();
      });
  }

  // Triggered externally when a date is changed
  // *Only updates the schedule* (leaves the time off requests etc. alone)
  dashboard.updateSchedule = function updateSchedule(date) {
    if (date instanceof Date) {
      scheduleDate = date
    }
    scheduleDate = getDate($(this).attr('id'), scheduleDate)

    updateScheduleContent()
  }

  // Persists skip of checklist item and hides its row
  function skipChecklistItem(event) {
    event.preventDefault();
    var $this = $(this);
    var checklistItemId = $this.attr('data-checklist-item-id');
    $.ajax({
      type: "POST",
      url: '/checklist_items',
      data: { checklist_item_id: checklistItemId },
      dataType: 'json'
    }).then(function() {
      $this.closest('li').find('div').toggleClass('hidden');
    });
  }

  function updateQuickLinks() {
    // Spin baby spin
    var initiateSpinner = msUtil.spinner('quick-links');

    $.ajax({
      url: "dashboard",
      data: { departmentId: departmentId },
      dataType: "script"
    })
      .always(function() {
        initiateSpinner.stop();
      });
  }

  function updateAvailableShifts() {
    // Spin baby spin
    var initiateSpinner = msUtil.spinner('available-shifts-requests');

    $.getJSON("dashboard/available_shifts", { departmentId: departmentId })
      .done(function(data) {
        $('#available-shifts-count').text(data);
        $('.dashboard #available-shifts-requests a').attr("href", "departments/"+departmentId+"/available_shifts");
      })
      .fail(function() { location.href = '/' })
      .always(function() {
        initiateSpinner.stop();
      });
  }

  function updatePendingExchanges() {
    // Spin baby spin
    var initiateSpinner = msUtil.spinner('pending-shift-exchanges');

    $.getJSON("dashboard/pending_exchanges", { departmentId: departmentId })
      .done(function(data) {
        $('#pending-exchanges-count').text(data);
        $('.dashboard #pending-shift-exchanges a').attr("href", "departments/"+departmentId+"/exchanges");
      })
      .fail(function() { location.href = '/' })
      .always(function() {
        initiateSpinner.stop();
      });
  }

  function updateTimeOffRequests() {
    // Spin baby spin
    var initiateSpinner = msUtil.spinner('time-off-requests');

    $.getJSON("dashboard/time_off_requests", { departmentId: departmentId } )
      .done(function(data) {
        $('#time-off-requests-count').text(data);
        $('.dashboard #time-off-requests a').attr("href", "time_off_requests");
      })
      .fail(function() { location.href = '/' })
      .always(function() {
        initiateSpinner.stop();
      });
  }

  function updateDefaultDepartment() {
    bootbox.confirm(
      I18n.t('dashboard.js.set_default_department'),
      I18n.t('buttons.cancel'),
      I18n.t('buttons.ok'),
      function(bootbox_result) {
        if(bootbox_result) {
          $.ajax({
            type: "PUT",
            url: "dashboard/update_default_department",
            data: { departmentId: departmentId, scheduleDate: $.datepicker.formatDate('yy/mm/dd', scheduleDate) },
            dataType: "script"
          });
        }
      });
  }

  // Mutate date based on whether previous or next button was hit
  function getDate(prevNext, date) {
    if(prevNext === "previous-date") {
      date = subtractOneDay(date);
    }
    if(prevNext === "next-date") {
      date = addOneDay(date);
    }

    return date;
  }

  function subtractOneDay(date){
    return new Date(date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() - 1));
  }

  function addOneDay(date){
    return new Date(date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 1));
  }
})(dashboard);
