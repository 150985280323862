import { msUtil } from 'core/ms_util.js';

window.shiftCustomDialog = (function($, window, document) {

  var init = function(settings) {
    $.extend(true, config, settings, {
      selectors: {
        breaksSelect2:  $(settings["breaksFieldName"]),
        tooltips:       $(config.elements.tooltips)
      }
    });

    initSelect2();
    initToolTips();
  };

  var initSelect2 = function(){
    var breakTimeRegex = /^[0-9]*/;
    config.breaks = [];

    config.selectors.breaksSelect2.select2({
      createSearchChoice: function(term){
        term = breakTimeRegex.exec(term)[0];
        if (term){
          return { id: term + '___' + _.uniqueId(), text: term };
        }
        return null;
      },
      formatSelection: function(object) {
        return select2Formatting(object);
      },
      formatResult: function(object) {
        return select2Formatting(object);
      },
      formatNoMatches: function (term) {
         return I18n.t("shift_custom_dialog.js.enter_time");
      },
      selectOnBlur: true,
      tags: [],
      allowClear: true,
      placeholder: I18n.t("shift_custom_dialog.js.eg_15_min")
    }).select2("val", null);
  };


  var select2Formatting = function(object) {
    return object.text + ' min';
  };

  var initToolTips = function(){
    config.selectors.tooltips.tooltip();
  };

  var config = {
    elements: {
      tooltips: '[data-toggle=tooltip]'
    }
  };

  return {
    init: init
  }

})(jQuery, window, document);

/*
 * Create reusable tab contents
 * Used by all tabs in the available shifts and shift requirements modals
 *
 * @param tabId       ID of the tab content we're hooking up (e.g. 'reply-users')
 * @param url         URL to get content to fill the tab with
 *
 */
window.initTab = function initTab(tabId, url)
{
  // Hook into tab being shown (find a tab with the given data target attribute)
  $('a[data-target="#' + tabId + '"]').on('shown.bs.tab', function(e) {

    var $tab = $('#' + tabId);

    // Set up a spinner which will continue until we have content to show
    $tab.html('<div id="' + tabId + '-spinner" style="width: 150px; height: 150px; margin: 0 auto;"></div>');
    var spinner = msUtil.spinner(tabId + '-spinner');

    // Start to load the content for the tab
    $tab.load(
      url,
      null,
      function(response, status, xhr) {
        // Spinner is done!
        spinner.stop();
      }
    );
  });
}

/*
 * Initialize a DataTable with a common setup
 *
 * @param tabName:       Name for the tab hosting the table.
 *                       We assume a given table ID and sort dropdown ID based on it. (e.g. 'assigned-users')
 * @param initialSort:   Array of columns and directions to initially sort with (e.g. [[0, 'asc']]
 * @param showSort:      Whether or not to show the sort dropdown initially. E.g. if there's only one record (or none!), don't need it.
 */
window.initTabWithTable = function(
  tabName,
  initialSort,
  showSort
) {
  // Set a default value for the initial sort if none was provided
  if (initialSort === undefined) {
    initialSort = [[0, 'asc']];
  }

  var $table = $('#' + tabName + '-table');
  var $sortingOptions = $('#' + tabName + '-sorting');

  $table.dataTable({
    bProcessing: true, // Show "Processing..." when sorting and getting data
    bServerSide: true, // Use server-side processing (must also set AJAX source)
    sAjaxSource: $table.data('source'), // Set source for retrieving data
    sPaginationType: "full_numbers", // Either "two_button" or "full_numbers"
    iDisplayLength: 25, // How many to show on a single page
    sDom: "<\"table-header\"r><\"table-responsive\"t><\"table-footer\"ip>", // Where to place "Processing...", the table itself, information, and pagination
    aaSorting: initialSort, // _Initial_ sorting column (first pass)
    bAutoWidth: false, // Whether to auto-size columns
    bDeferRender: true, // Speed boost for async-loaded tables
    oLanguage: dataTables_i18n, // Add i18n for works like Processing, First, Next etc.
    aoColumnDefs: [{ aTargets: ["_all"], bSortable: false }], // Disallow direct clicking on column headers
    fnDrawCallback: function (oSettings) {
      $('[data-toggle="tooltip"]').tooltip();
    },
  });

  // dataTable styles the table itself, the headers, and table data
  // We want to keep our previous settings
  $table.removeClass('dataTable');
  $table.find('td').removeAttr('style');
  $table.find('th').removeAttr('style');

  $sortingOptions.find('a').on('click', function(e) {
    sortBy(tabName, $(this).data('tabIndex'), $(this).data('tabOrder'));
  });

  // Sort by the initial sorting option, and show that we've done so
  if (showSort) {
    $('#sort-by-' + tabName + ' i').eq(initialSort[0][0]).addClass(
      initialSort[0][1] == 'asc' ? 'fa fa-caret-up' : 'fa fa-caret-down'
    );
  }
  else {
    $('#sort-by-' + tabName).hide();
  }
}

/*
 * Sort the table on a given sorting option by faking a click on a table column
 * We assume that the columnMapping has been initialized in initTabWithTable
 * so we know which data index to sort the table by.
 *
 * @param tabName:       Name for the tab hosting the table.
 *                       We assume a given table ID and sort dropdown ID based on it. (e.g. 'assigned-users')
 * @param sortOptionIdx: Index of Sort By row we're changing
 * @param defaultDir:    Default direction to sort by if this is a "new" sort selection. Otherwise invert it.
 *
 */
export function sortBy(tabName, sortOptionIdx, defaultDir) {
  var $sortBy        = $('#sort-by-' + tabName);
  var $sortOption    = $sortBy.find('i').eq(sortOptionIdx);

  // If we're already sorting by this column, target class is swapped
  var alreadySorting = $sortOption.hasClass('fa-caret-up') || $sortOption.hasClass('fa-caret-down');
  var targetSortDir  = alreadySorting ? ($sortOption.hasClass('fa-caret-up') ? 'desc' : 'asc') : defaultDir;
  var targetClass    = targetSortDir == 'asc' ? 'fa fa-caret-up' : 'fa fa-caret-down';

  // Clear all sorting carets
  $sortBy.find('i').removeClass();

  // Mark the sort column with the target class
  $sortOption.addClass(targetClass);

  // Manually sort the table by a given column index
  $('#' + tabName + '-table').dataTable().fnSort(
    [[
      sortOptionIdx,
      targetSortDir
    ]]
  );
}

/**
 * Update and highight tab counters
 * @param {Object} $label   Span that contains the counter
 * @param {Integer} count   User count
 */
export function adjustCounter(labelId, delta) {

  // Default settings
  var config = {
    highlightColor:     '#ffcc00',
    highlightInterval:  750
  };

  var $label = $('#' + labelId);
  $label.text(parseInt($label.text()) + delta);
  $label.closest('span.label').effect('highlight', {color: config.highlightColor}, config.highlightInterval);
}
