export var jobSiteSort = (function($) {

  var init = function(settings) {
    $.extend(config, {
      selectors: {
        jobSiteSortSelector: $('form#job-site-sort-selector input[type=radio]'),
      },
    });

    initJobSiteSortBySelection();
  };

  /* private */

  var config = {
    cookieName: 'jobSiteSortAttribute',
  }

  var initJobSiteSortBySelection = function() {
    var cookieJobSiteSortAttribute = Cookies.get(config.cookieName) || 'alphabetically';

    config.selectors.jobSiteSortSelector.on('change', function() {
      var selectedAttribute = $(this).val();
      sortJobSitesBySelectedAttribute(selectedAttribute);
    });

    $('input#job-site-sort-' + cookieJobSiteSortAttribute).click();
  }

  var sortJobSitesBySelectedAttribute = function(selectedAttribute) {
    Cookies.set(config.cookieName, selectedAttribute, { secure: true });
    var rows = $('tr.job-site-row').get();
    rows = rows.sort(function(jobSiteI, jobSiteJ) {
      var jobSiteIVal, jobSiteJVal;
      if (selectedAttribute === 'alphabetically') {
        jobSiteIVal = $(jobSiteI).data('keywords').toString();
        jobSiteJVal = $(jobSiteJ).data('keywords').toString();

        return jobSiteIVal.localeCompare(jobSiteJVal);
      } else if (selectedAttribute === 'requirements') {
        if ($(jobSiteI).data('required-employees') < $(jobSiteJ).data('required-employees')) return 1;
        if ($(jobSiteI).data('required-employees') > $(jobSiteJ).data('required-employees')) return -1;
        if ($(jobSiteI).data('required-employees') === $(jobSiteJ).data('required-employees')) {
          jobSiteIVal = $(jobSiteI).data('keywords').toString();
          jobSiteJVal = $(jobSiteJ).data('keywords').toString();
          return jobSiteIVal.localeCompare(jobSiteJVal);
        }
      };
      return 0
    });
    $.each(rows, function(index, row) {
      $('tbody.job-sites').append(row);
    });
  }

  return {
    init: init,
    sortJobSitesBySelectedAttribute: sortJobSitesBySelectedAttribute
  };

})(jQuery);

window.jobSiteSort = jobSiteSort;
