export var onboardingPopovers = (function ($) {
  var init = function (settings) {
    var allSteps = settings.progress.allSteps(settings.checklistItem)
    $.extend(config, {
      progress: settings.progress,
      allSteps: allSteps,
      stepsCount: allSteps.length,
      checklistItem: settings.checklistItem,
      selectors: {
        //TODO: better caching of these selectors
        skipButton: function () {
          return $('.skip-btn')
        },
        qTipClose: function () {
          return $('.qtip-close')
        },
        finishButton: function () {
          return $('#finish-onboarding')
        },
        currentPopup: function () {
          return $('.onboarding-popup')
        }
      }
    })

    initOnboarding()
  }

  /* private */

  var config = {
    progress: [],
    allSteps: null
  }

  var onboardPopup = function (step) {
    var $div = $.isFunction(step.target) ? step.target() : step.target
    var stepNumber = config.allSteps.indexOf(step) + 1

    if ($div.length > 1) {
      $div = $div.filter(':visible').first()
    }

    if ($div.length === 0) {
      config.progress.updateProgress(config.checklistItem)
      // Since the element for the popover may not exist (example: locations nav for a dept admin), move on to the next step (if there is one)
      if (config.progress.nextItem()) {
        onboardingPopovers.init({ progress: config.progress, checklistItem: config.progress.nextItem() })
      }
    }

    $div.qtip({
      id: 'onboarding-qtip-step' + stepNumber, // qtip prepends 'qtip' to the id. so id ends up being: `qtip-onboarding-qtip-step{stepNumber}`
      content: {
        title: step.title,
        text: popoverContent(step.content, step.greyButtonText, stepNumber),
        button: config.stepsCount > 1 ? false : I18n.t('buttons.close')
      },
      position: {
        my: step.position.my,
        at: step.position.at,
        viewport: $('body'),
        adjust: {
          y: step.position.offsetY
        }
      },
      show: {
        ready: true,
        modal: {
          on: true,
          blur: false
        }
      },
      hide: {
        event: step.eventHide
      },
      style: {
        classes: 'qtip-bootstrap onboarding-popup'
      },
      events: {
        // remove previous onboarding qtip step when the next step pops up
        hide: function (event, api) {
          config.selectors.currentPopup().remove()
          $div.children().add($div).css({ position: '', 'z-index': '' })
          $div.unbind(step.eventHide)
        },
        show: function (event, api) {
          step.prerenderEvent()
          config.originalPosition = $div.children().css('position')
          config.originalZIndex = $div.children().css('z-index')
          $div.children().add($div).css({ position: step.zPosition, 'z-index': 14801 })
        },
        visible: function (event, api) {
          $('#onboarding-next-step' + stepNumber).on('click', function () {
            setTimeout(function () {
              config.selectors.currentPopup().qtip('hide')
            }, 90) // 90ms timer because the qtip animation keeps the modal overlay for that long.
            onboardPopup(config.allSteps[stepNumber])
          })

          config.selectors
            .skipButton()
            .add(config.selectors.finishButton())
            .on('click', function () {
              ajaxUpdateProgress(config.checklistItem)
              config.progress.updateProgress(config.checklistItem)
              config.selectors.currentPopup().qtip('hide')

              if (config.progress.nextItem()) {
                onboardingPopovers.init({ progress: config.progress, checklistItem: config.progress.nextItem() })
              }
            })

          step.target.on(step.eventHide, function () {
            // send check to checklist and remove all qtips on completion of onboarding
            config.progress.updateProgress(config.checklistItem)
            config.selectors.currentPopup().qtip('hide')
          })
        }
      }
    })
  }

  var popoverContent = function (content, greyButtonText, stepNumber) {
    var html = ''
    html += "<div class='row-fluid'>"
    html += "<div class='span12'>"
    html += content
    html += '</div>'
    html += '</div>'
    html += "<div class='row-fluid step-btns'>"
    html += "<div class='span4 skip-btn'>"
    if (greyButtonText) {
      html += "<div class='btn'>" + greyButtonText + '</div>'
    }
    html += '</div>'
    if (config.stepsCount > 1) {
      html += "<div class='span4 step-counter'>"
      html += "<span class='step-number'>" + I18n.t('onboarding.js.progress', { step: stepNumber, total_steps: config.stepsCount }) + '</span>'
      html += '</div>'
      html += "<div class='span4 next-step-btn'>"
      if (greyButtonText != I18n.t('buttons.done')) {
        if (config.stepsCount === stepNumber) {
          html += "<div class='btn btn-blue' id='finish-onboarding'>" + I18n.t('buttons.finish') + '</div>'
        } else {
          html += "<div class='btn btn-blue' id='onboarding-next-step" + stepNumber + "'>" + I18n.t('buttons.next') + '</div>'
        }
      }
      html += '</div>'
    }
    html += '</div>'

    return html
  }

  var ajaxUpdateProgress = function (checklistItem) {
    $.ajax({
      type: 'POST',
      url: '/department_schedule_checklist_items',
      data: { department_schedule_checklist_item: checklistItem },
      dataType: 'json'
    })
  }

  var initOnboarding = function () {
    var step = config.allSteps[0]
    onboardPopup(step)
    step.bindAjaxUpdateProgress(ajaxUpdateProgress, config.progress)
  }

  return {
    init: init
  }
})(jQuery)

window.onboardingPopovers = onboardingPopovers
