window.shiftTypeFilter = (function($) {

  /*
  public
  */

  // Initialize Shift Templates
  var init = function() {
    initFilter();
  };

  /*
  private
  */

  /* Set up shift type filtering for department calendar, user calendar, and rotations
     @note: Filtering uses the OR operation on search terms
            Filtering is done against the comma-delimited value of a select2 dropdown
  */
  var initFilter = function() {
    $('#remove-shift-type-filter').on('click', function() {
      $('#shift-type-filter').val('').keyup();
    }).hide();

    $('#shift-type-filter').on('keyup', function() {
      var $shift_types = $('#shift_types div, #shift_type_tab li.ui-draggable, #rotation-shift-types li.ui-draggable');
      var $keywords = $(this).val().toLowerCase();
      $shift_types.show().filter(function() {
        var shiftTypeKeyword = (typeof $(this).data('keyword') !== 'undefined') ? $(this).data('keyword').toString().toLowerCase() : '';
        return (shiftTypeKeyword.indexOf($keywords) < 0);
      }).hide();
      if ($(this).val()) {
        $('#remove-shift-type-filter').show();
      } else {
        $('#remove-shift-type-filter').hide();
      }
    });
  };

  return {
    init: init,
  };

})(jQuery);
