window.statHolidayForm = (function($, window, document) {

  // public

  var init = function(settings) {
    $.extend(true, config, settings, {
      selectors: {
        dateField: settings.dateField || $('input.datepicker'),
        nameField: settings.nameField || $('#stat_holiday_name')
      },
      startDate: settings.startDate || moment().subtract(1, 'years')._d
    })

    initDatepicker()
    initHolidays()
  }

  // private

  var config = {
    holidays: [] // Common holidays available to the Location
  }

  var initDatepicker = function() {
    config.selectors.dateField.datepicker({
      format: 'yyyy-mm-dd',
      startDate: config.startDate,
      endDate: moment().endOf('year').add(1, 'years')._d,
      orientation: 'right auto',
      autoclose: true,
      language: I18n.locale
    })
  }

  

  var initHolidays = function() {
    config.selectors.nameField.select2({ // Allow custom entry into select2 dropdown
      createSearchChoice: function(term, data) {
        if ($(data).filter(function() {
          return this.text.localeCompare(term) === 0
        }).length === 0) {
          return { id: term, text: term }
        }
      },
      data: config.holidays,
      formatSelection: function(val) { // Display common holiday date:name pairs as only name
        return (val.text.indexOf(':') > -1 ? _.escape(val.text.split(':')[1]) : _.escape(val.text))
      },
      initSelection: function(element, callback) {
        // Gets name from field
        var data = { id: '', text: element.val() }
        callback(data)
      },
      multiple: false,
      placeholder: I18n.t('stat_holiday.pick_a_holiday'),
      selectOnBlur: true
    })
      .on('change', function(e) {
        var holidayDateAndName = e.val.split(':')
        // Set date corresponding to selected/custom holiday
        if (holidayDateAndName.length === 2) { // common holiday selected from presets, auto select date
          config.selectors.dateField.datepicker('setDate', moment(holidayDateAndName[0])._d)
          config.selectors.nameField.val(holidayDateAndName[1].trim())
        } else { // custom holiday entered, prompt to select date
          config.selectors.dateField.datepicker('setDate', null).datepicker('show')
        }
      })
  }

  return {
    init: init
  }

})(jQuery, window, document)
