import { remoteFormErrors } from 'core/remote_form_errors.js';
import { makeshiftGoogleAnalytics } from 'core/google_analytics.js';
window.accountActivation = (function($, window, document) {

  /*
  public
  */

  var init = function(settings) {
    $.extend(true, config, settings, {
      selectors: {
        showPasswordCheckbox:   $("#show_password"),
        notificationDropdown:   $("#callout_notification_preference"),
        passwordInput:          $("input#account_activation_form_password"),
        submitButton:           $('input[type=submit]'),
        termsOfServiceCheckbox: $('#accepted_tos'),
      }
    });

    initActions();
  };

  /*
  private
  */

  // Default settings
  var config = {
    resetType: "passwordReset",
    termsRequired: false,
    debounceSpeed: 750,
    notificationChoiceRequired: false,
    selectors: {
      form: null
    }
  };

  /**
   * Initializes actions on the activation page
   */
  var initActions = function(){
    $('body').on('ajax:success', config.selectors.form, function(e, data) {
      window.location = data.link;
    }).on('ajax:error', config.selectors.form, function(e, xhr, status, error) {
      var responseJSON = xhr.responseJSON;
      remoteFormErrors.errorFields($(this), responseJSON.klass, responseJSON.errors);
    });

    config.selectors.termsOfServiceCheckbox.on('change', function(){
      disableSubmitButton( !formValid() );
    });

    config.selectors.notificationDropdown.on('change', function(){
      disableSubmitButton( !formValid() );
    });

    config.selectors.showPasswordCheckbox.on('change', function(e){
      e.preventDefault();
      var $self = $(this);
      var $input = $self.closest('.input-append').find('input').first();

      ga('send','event', makeshiftGoogleAnalytics.categories[config.resetType], makeshiftGoogleAnalytics.actions.click, makeshiftGoogleAnalytics.label.showHidePassword);

      if ($input.prop('type') === 'password') {
        $input.prop('type', 'text');
      } else {
        $input.prop('type', 'password');
      }
    });

    $('body').on('keyup', config.selectors.passwordInput, _.debounce(liveValidatePassword, config.debounceSpeed));
  };

  /**
   * Initializes live validation of the password input
   */
  var liveValidatePassword = function() {

    var controlGroup = config.selectors.passwordInput.closest('.control-group');

    if (passwordValid()) {
      controlGroup.removeClass('error').find('.help-inline').hide();
    } else {
      controlGroup.addClass('error').find('.help-inline').show();
    }

    disableSubmitButton( !formValid() );
  };

  /*
   Helpers
   */

  /**
   * Helper to determine if the form is valid and can be submitted
   * @return {Boolean}
   */
  var formValid = function() {
    var validTerms = (!config.termsRequired || config.selectors.termsOfServiceCheckbox.is(':checked'));
    return validTerms && passwordValid();
  };

  /**
   * Helper to determine if the password is valid
   * @return {Boolean}
   */
  var passwordValid = function(){
    return config.selectors.passwordInput.val().length >= config.minPasswordLength
  };

  /**
   * Sets the disabled state of the submit button based on a passed in boolean
   * @param  {Boolean} disabled
   * @return {jQuery Object} Submit Button
   */
  var disableSubmitButton = function(disabled){
    return config.selectors.submitButton.prop("disabled", disabled);
  };

  return {
    init: init
  };

})(jQuery, window, document);
