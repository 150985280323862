window.adminSupport = (function ($, window, document) {
  const init = function (settings) {
    $.extend(config, {
      privatePusherChannel: settings.privatePusherChannel,
      selectors: {
        modal: $('#company-users-export-loading')
      }
    })

    initPusher()
  }

  const initPusher = () => {
    config.privatePusherChannel.bind('company-users-export.processing', function handleExportProcessing(pusherData) {
      clearError()
      showLoadingModal()
    })

    config.privatePusherChannel.bind('company-users-export.success', function handleExportSuccess(pusherData) {
      hideLoadingModal()
      var export_url = pusherData.export_uri
      window.location.href = export_url
    })

    config.privatePusherChannel.bind('company-users-export.failure', function handleExportFail(pusherData) {
      hideLoadingModal()
      displayFlashError(pusherData.message)
    })
  }

  const showLoadingModal = () => {
    var loadingModal = HandlebarsTemplates['shared/processing_modal']({
      loadingHeader: I18n.t('admin.support.modal_header'),
      loadingMessage: I18n.t('admin.support.modal_message'),
      loadingWarning: I18n.t('admin.support.modal_warning'),
      loadingWarningMessage: I18n.t('admin.support.modal_warning_msg')
    })

    config.selectors.modal.html(loadingModal)
    config.selectors.modal.show()
  }

  const hideLoadingModal = () => {
    config.selectors.modal.hide()
  }

  const clearError = () => {
    $('#flash-messages').html('')
  }

  const displayFlashError = (error) => {
    $('#flash-messages').html(`<div class="alert alert-error">
    <a class="close" data-dismiss="alert">×</a>
    <div id="flash_error">${error}</div>
  </div>`)
  }

  return {
    init: init
  }
})(jQuery, window, document)
