import { clearModalAlerts, renderModalAlert } from 'core/makeshift.js';
import { adjustCounter } from 'core/shift_custom_dialog.js';
// Reduced functionality from departmentSchedules for sharing AvailableShift modal
// Used when clicking a date on an available shift from the left sidebar "Available Shifts"
window.availableShifts = (function($, window, document) {

  var init = function() {
    initModals();
    initModalActions();
  };

// Set up modal window click handler for managing events
  var initModals = function() {
    $('body').on('click', 'a.available-shift-link', function(e) {
      e.preventDefault();
      var url = $(this).attr('href');
      $.get(url)
      .then(function(html) {
        $('#shift-modal').html(html).modal('show');
        // Additional functionality over Department Calendar to navigate to tab based on which column was clicked
        var anchor = url.split('#')[1];
        if (anchor) {
          $('a[data-target=#' + anchor + ']').tab('show');
        }
      });
    });
  };

  // Initializes JSON behaviors performed within modals
  var initModalActions = function() {
    $('#shift-modal').on('click', 'a[data-modal-action], button[data-modal-action]', function(e) {
      e.preventDefault();
      var $self = $(this);

      switch($self.data('modal-action')) {
      case 'assign':
        $.ajax({ url: $self.data('href'), type: 'PUT', dataType: 'json' })
        .then(function(data) {
          clearModalAlerts();

          // We may optionally receive a message back (even on success),
          // so we'll display that message if it exists
          if (data.error_message) {
            renderModalAlert(data);
          }

          adjustCounter('assigned-shifts-count',  +1);
          if ($self.data('source') == 'offer') {
            adjustCounter('request-users-count',  -1);
          } else {
            adjustCounter('eligible-users-count', -1);
          }

          // Could be sent either from requested users or eligible users, so refresh both
          $('#reply-users-table').DataTable().ajax.reload(null, false);
          $('#eligible-users-table').DataTable().ajax.reload(null, false);

        }, function(xhr, status, error) {
          $self.prop('disabled', true);
          renderModalAlert(xhr.responseJSON);
        });
        break;

      // Unassign Available Shift
      case 'unassign':
        $.ajax({ url: $self.data('href'), type: 'DELETE', dataType: 'json' })
        .then(function(data) {
          $('#assigned-users-table').DataTable().ajax.reload(null, false);
          adjustCounter('assigned-shifts-count', -1);
          adjustCounter('eligible-users-count',  +1);
        }, function(xhr, status, error) {
          $self.prop('disabled', true);
          renderModalAlert(xhr.responseJSON);
        });
      break;

      case 'destroy':
        $.ajax({ url: $self.attr('href'), type: 'DELETE', dataType: 'json' })
        .then(function() {
          location.reload();
        });
        break;
      }
    });
  };

  return {
    init: init
  }

})(jQuery, window, document);
