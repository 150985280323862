<template>
  <div class="user-mass-assignment row-fluid">
    <div class="span4">
      <div>{{I18n.t('activerecord.models.department')}}:</div>
      <select
        name="department_id"
        @change="handleSelect"
        :value="department_id"
      >
        <option key="0" value="0" disabled>{{I18n.t('department.index.select_department')}}</option>
        <optgroup
          v-for="group in options"
          :key="group.name"
          :label="formatText(group.name)"
        >
          <option
            v-for="obj in group.value"
            :key="obj.id"
            :location="obj.location_name"
            :value="obj.id"
            :data-time-zone="obj.time_zone"
          >
            {{formatText(obj.name)}}
          </option>
        </optgroup>
      </select>
    </div>

    <div v-if="department_id > 0" class="span4">
      <div>{{I18n.t('positions.positions')}}:</div>
      <select
        name="position_id"
        @change="handleSelect"
        :value="position_id"
      >
        <option key="-1" value="-1" disabled>{{I18n.t('simple_form.placeholders.shift.position')}}</option>
        <option key="all_positions" value="All Positions">{{I18n.t('helpers.users.all_positions')}}</option>
        <option
          v-for="position in positions"
          :key="position.id"
          :value="position.name"
        >
          {{formatText(position.name)}}
        </option>
      </select>
    </div>

    <div v-if="department_id > 0" class="span4">
      <div>{{I18n.t('job_sites.job_site')}}:</div>
      <select
        name="job_site_id"
        @change="handleSelect"
        :value="job_site_id"
      >
        <option key="-1" value="-1" disabled>{{I18n.t('simple_form.placeholders.shift.job_site')}}</option>
        <option key="all_job_sites" value="0">{{I18n.t('helpers.users.all_job_sites')}}</option>
        <option
          v-for="jobSite in jobSites"
          :key="jobSite.id"
          :value="jobSite.id"
        >
          {{formatText(jobSite.name)}}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DepartmentAssociationSelection',
  props: {
    selectedUsers:   Array,
    newRowTrigger:   Function,
    timeZone:        String,
    triggerDisabled: Boolean,
  },
  data() {
    return {
      department_id: 0,
      position_id: -1,
      job_site_id: -1,
      departments: [],
    }
  },
  computed: {
    options() {
      let departmentsCopy = this.departments;
      if (this.timeZone) {
        departmentsCopy = _.where(departmentsCopy, { time_zone: this.timeZone });
      }
      const options = _.map(_.groupBy(departmentsCopy, 'location_name'), (locationDepartments, locationName) => {
        const departmentOptions = _.map(locationDepartments, (department) => {
          return {
            id: department.id,
            name: department.name,
            location_name: locationName,
            time_zone: department.time_zone,
          };
        });
        return { name: locationName, value: departmentOptions };
      });
      return options;
    },

    positions() {
      if (this.department_id) {
        return _.find(this.departments, { id: parseInt(this.department_id) }).positions;
      }
      return [];
    },

    jobSites() {
      if (this.department_id) {
        return _.find(this.departments, {id: parseInt(this.department_id)}).job_sites;
      }
      return [];
    },
  },
  mounted() {
    $.ajax({
      method: 'GET',
      url: '/user_mass_assignments',
      data: { user_ids: this.selectedUsers },
      success: (data) => this.departments = data.departments
    });
  },
  methods: {
    handleSelect(event) {
      const { name, value, options } = event.target;
      if (name == 'department_id' && !this.triggerDisabled) {
        const selectedOption = _.findWhere(options, { value });
        const timeZone = $(selectedOption).data('timeZone');
        this.newRowTrigger(timeZone);
      }
      this[name] = value;
    },

    formatText(text) {
      return text.length > 50 ? `${text.slice(0, 47)}...` : text;
    }
  }
}
</script>
