import { weeklyBudget } from 'core/weekly_budget.js';
import { tConvert, user_prefers_12hr } from 'core/helpers/format_helpers.js';
import { ShiftType, reverse_shifts_time } from 'core/models/shift_type.js';
import { scheduleRotations } from 'core/schedule/rotations.js';
import { scheduleLabels } from 'core/schedule_labels.js';
import { Rotation } from 'core/models/rotation.js';
import { remoteFormErrors } from 'core/remote_form_errors.js';
import { onboardingPopovers } from 'core/onboarding_popovers.js';
import { msUtil } from 'core/ms_util.js';
import { makeshiftGoogleAnalytics } from 'core/google_analytics.js';
import { jobSiteSort } from 'core/job_site_sort.js';
import { fatigueManagement } from 'core/fatigue_management.js';
import { DepartmentScheduleOnboardingProgress } from 'core/models/department_schedule_onboarding_progress.js';
import { clearModalAlerts, renderModalAlert } from 'core/makeshift.js';
import { bootboxMsg } from 'core/helpers/bootbox.js';
import { adjustCounter } from 'core/shift_custom_dialog.js';

export var departmentSchedule = (function($, window, document) {

  /*
  public
  */

  // Initialize Department Calendar and sets current Department ID
  var $self;
  var init = function(settings) {
    $.extend(config, {
      view:                               settings.view,
      showLabourBudget:                   settings.showLabourBudget,
      companyId:                          settings.companyId,
      departmentId:                       settings.departmentId,
      pageTitle:                          settings.pageTitle,
      beginningOfWeek:                    settings.beginningOfWeek,
      startOfWeek:                        settings.startOfWeek,
      pusherUserId:                       settings.pusherUserId,
      pusherKey:                          settings.pusherKey,
      scheduleProviders:                  settings.scheduleProviders,
      overtimeThreshold:                  settings.overtimeThreshold,
      adpUser:                            settings.adpUser,
      scheduleIntegrated:                 settings.scheduleIntegrated,
      privatePusherChannel:               settings.privatePusherChannel,
      jobSitesEnabled:                    settings.jobSitesEnabled,
      isReadOnlySchedule:                 settings.isReadOnlySchedule,
      routes: {
        departmentSchedulePath:           function() {
          if (config.view == 'employees') {
            return config.routes.employeesDepartmentSchedulePath;
          } else if (config.view == 'positions') {
            return config.routes.positionsDepartmentSchedulePath;
          } else if (config.view == 'job_sites') {
            return config.routes.jobSitesDepartmentSchedulePath;
          }
        },
        departmentScheduleUpdatePath:       '/departments/' + settings.departmentId + '/schedule/',
        employeesDepartmentSchedulePath:    '/departments/' + settings.departmentId + '/schedule/employees',
        otherDepartmentSchedulePath:        '/departments/' + settings.departmentId + '/schedule/other_department_shifts',
        positionsDepartmentSchedulePath:    '/departments/' + settings.departmentId + '/schedule/positions',
        jobSitesDepartmentSchedulePath:     '/departments/' + settings.departmentId + '/schedule/job_sites',
        printableDepartmentSchedulePath:    '/departments/' + settings.departmentId + '/schedule/employees.xlsx',
        newDepartmentAvailableShiftPath:    '/departments/' + settings.departmentId + '/available_shifts/new',
        createDepartmentAvailableShiftPath: '/departments/' + settings.departmentId + '/available_shifts',
        editAvailableShiftPath:             function(id) { return '/available_shifts/' + id + '/edit'; },
        availableShiftPath:                 function(id) { return '/available_shifts/' + id; },
        departmentAvailabilitiesPath:       function(userId, date) { return '/departments/' + settings.departmentId + '/availabilities?user_id=' + userId + '&date=' + date; },
        unpublishedDepartmentShiftsPath:    '/departments/' + settings.departmentId + '/shifts/unpublished',
        newDepartmentShiftPath:             '/departments/' + settings.departmentId + '/shifts/new',
        createDepartmentShiftsPath:         '/departments/' + settings.departmentId + '/shifts',
        reassignShiftsPath:                 function(id) { return '/shifts/' + id + '/reassign'; },
        shiftPath:                          function(id) { return '/shifts/' + id; },
        editShiftPath:                      function(id) { return '/shifts/' + id + '/edit'; },
        editShiftPositionPath:              function(id) { return '/shifts/' + id + '/edit_position'; },
        updateShift:                        function(id) { return '/shifts/' + id; },
        newDepartmentShiftRequirementPath:  '/departments/' + settings.departmentId + '/shift_requirements/new',
        shiftRequirementPath:               function(id) { return '/shift_requirements/' + id; },
        unavailablePath:                    function(id) { return '/unavailables/' + id; },
        createUnavailablesPath:             '/unavailables',
        editUnavailablePath:                function(id) { return '/unavailables/' + id + '/edit'; },
        newUnavailablePath:                 '/unavailables/new',
        exportSchedulePath: function(providerName, start_date, archived) {
          var path = '/departments/' +  settings.departmentId  + '/schedule/export?start='+ start_date + '&provider=' + providerName;
          if (providerName == 'raw_excel' || providerName == 'raw_excel_month') path += '&archived=' + archived;
          return path;
        },
        editUnavailableRequestPath:         function(id) { return '/time_off_requests/' + id + '/edit'; },
        newDepartmentCopyPastePath:         '/departments/' + settings.departmentId + '/copy_paste/new',
        createDepartmentCopyPastePath:      '/departments/' + settings.departmentId + '/copy_paste',
        newWeeklyBudgetPath:                '/departments/' + settings.departmentId + '/weekly_budgets/new',
        createWeeklyBudgetPath:             '/departments/' + settings.departmentId + '/weekly_budgets',
        editWeeklyBudgetPath:               function(id) { return '/weekly_budgets/' + id + '/edit'; },
        newUserPath:                        '/departments/' + settings.departmentId + '/schedule/users/new',
        departmentUserPath:                 '/departments/' + settings.departmentId + '/users',
        createDepartmentRotationPath:       '/departments/' + settings.departmentId + '/schedule/rotations',
        unavailablesEditTypePath:           function(id) { return '/unavailables/' + id + '/edit_type'; },
        shiftExportAttemptsPath:            function(start) { return '/departments/' + settings.departmentId + '/shift_export_attempts?start=' + start;}
      },
      selectors: {
        budgetBar: $('#budget-bar'),
        clearShiftTypeFilter: $('#remove-shift-type-filter'),
        clearRotationFilter: $('#remove-rotation-filter'),
        shiftTypeFilter: $('#shift-type-filter'),
        rotationFilter: $('#rotation-filter'),
        shiftTypesBox: $('#shift_types'),
        rotationsBox: $('#rotations'),
        scheduleWrapper: $('.box-wrapper'),
        exportButton: $('#printable-schedule'),
        exportForProviderButton: $('#export-schedule'),
        scheduleProviderFilter: $('#schedule-provider-filter'),
        scheduleCheckbox: $('#checkbox-container'),
        modal: $('#export-modal'),
        availableShiftCells: $('#available-shifts-row th.dropcell'),
        userCells: $('.user-row td.dropcell'),
        lastPublishedAt: $('#last-published-at')
      },
      data: {
        onboarding: {
          progress: DepartmentScheduleOnboardingProgress.init(settings.onboardingProgress)
        }
      }
    });

    buildShiftTypes(settings.shiftTypes);
    buildRotations(settings.rotations);
    initRotationEvents();
    initShiftTypeEvents();
    initShiftTypeFilter();
    initRotationFilter();

    initMoment();
    initViewToggle();
    initDateNavigation();
    initExportScheduleFields();
    initDatepicker('#toolbar-datepicker');
    initDatepicker('#export-schedule-datepicker');
    initDraggables();
    initModals();
    initModalForms();
    initModalActions();
    initFloatThead();
    initPopState();
    initPublish();
    initCopyPaste();
    initExportButton();
    initPremiumRule();
    initPusherDepartmentNotifs();
    initSidebar();
    initWeekData();
    initWebSocketListeners();

    if (config.view == 'employees') {
      initFilter();
    } else if (config.view == 'job_sites') {
      initJobSiteFilter();
    }
    weeklyBudget.init({
      view: config.view,
      departmentId: config.departmentId
    });

    loadSchedule().then(response =>{
      scheduleLabels.init({
        departmentId: config.departmentId,
        jobSitesEnabled: config.jobSitesEnabled,
      }); // after the shifts have been rendered apply the requirement label js

      // Has to come after span budgets to show up consistently on correct element
      if (config.view == 'employees') {
        initOnboardingPopups();
      }
    });
  };

  var events = $({});
  var lastPublishButtonClicked;
  var lastPublishButtonText;

  /*
  private
  */


  // Default settings
  var config = {
    data: {
      onboarding: {
        progress: null,
      }
    },
    view:               'employees',
    showLabourBudget:   true,
    dateFmt:            'YYYY-MM-DD',
    highlightColor:     '#ffcc00',
    highlightInterval:  750,
    transitionInterval: 300
  };

  // Loaded data and associated methods
  var store = {
    // Shift Type objects for department
    // @see assets/javascripts/models/shiftTypes.js
    shiftTypes: [],
    rotations: [],
    lastShiftedShift: {}
  };

  // Shifts etc for currently displayed week
  var weekData = {};

  var initWeekData = function() {
    weekData = {
      shifts: [],
      other_department_shifts: [],
      availabilities: [],
      unavailables: [],
      shift_requirements: [],
      available_shifts: []
    };
  };

  /*
  global variables
  */
  var copyPaste = {
    copiedSourceWeekStartDate: null,  // Set when a week is copied
    repeatSourceWeekStartDate: null,  // Always equal to prior week
    targetWeekStartDate: null         // Set when the current week is initialized
  };

  var initMoment = function() {
    moment.locale(I18n.locale);
    moment.updateLocale(I18n.locale, { week: { dow: config.startOfWeek } });
  };

  var initPremiumRule = function() {
    $('#premium-rule-viewer').qtip({
      prerender: false,
      content: {
        text: function() {
          return $(this).data('content');
        },
        title: function() {
          return $(this).data('original-title');
        },
        button: true
      },
      position: {
        my: 'center right',
        at: 'center left',
      },
      show: {
        event: 'click'
      },
      hide: {
        event: 'click'
      },
      style: {
        classes: 'qtip-bootstrap position-select qtip-premium-rules'
      }
    });
  };

  // Prevent current button click actions
  var initViewToggle = function() {
    $('a.btn-current').on('click', e => {
      e.preventDefault();
    });
  };

  /**
   * Builds Shift Types and adds them to the Department Schedule store
   * @param  {JSON} shiftTypes
   * @return {Array} Shift
   */
  //*
  var buildShiftTypes = function(shiftTypes){
    _.each(shiftTypes, shiftType =>{
      return store.shiftTypes.push(new ShiftType(shiftType));
    });
  };

  /**
   * Builds Rotations and adds them to the Department Schedule store
   * @param  {JSON} rotations
   * @return {Array} Rotation
   */
  //*
  var buildRotations = function(rotations){
    _.each(rotations, rotation =>{
      rotation.blueprint = JSON.stringify(rotation.blueprint);
      return store.rotations.push(new Rotation(rotation));
    });
  };

  var initRotationEvents = function() {

    scheduleRotations.init({
      createDepartmentRotationPath: config.routes.createDepartmentRotationPath,
      privatePusherChannel: config.privatePusherChannel
    });

    var rotationList = HandlebarsTemplates['rotations/index']({
      drag_and_drop: I18n.t('rotations.js.drag_and_drop'),
      if_saved: I18n.t('rotations.js.if_saved'),
      rotations: store.rotations
    });

    config.selectors.rotationsBox.empty().append(rotationList);

    events
      .on('rotation:rendered', () => {
        config.selectors.rotationsBox.find('.draggable-rotation').draggable({
          revert: 'invalid', // when dropped on invalid target, the item will revert back to its initial position
          containment: 'document',
          helper: function() {
            var helper = $(this).clone();
            helper.css('height','35px');
            helper.css('width','100px');
            return helper;
          },
          cursor: 'move',
          opacity: 0.8
        });
      })
      .on('rotation:list_updated', (e, rotations, searching) =>{
        rotationList = HandlebarsTemplates['rotations/index']({
          drag_and_drop: I18n.t('rotations.js.drag_and_drop'),
          if_saved: I18n.t('rotations.js.if_saved'),
          empty_search: I18n.t('rotations.js.empty_search'),
          searching: searching || false,
          rotations: (rotations || store.rotations).sort((a,b) =>{
            return a.secondsSinceMidnight == b.secondsSinceMidnight ? a.duration - b.duration : a.secondsSinceMidnight - b.secondsSinceMidnight;
          })
        });
        config.selectors.rotationsBox.empty().append(rotationList);
        events.trigger('rotation:rendered');
      });

    events.trigger('rotation:list_updated');
  };

  /*
   * Update DOM in response to events triggered by shiftType objects
   */
  var initShiftTypeEvents = function() {
    events
      .on('shiftType:rendered', () => {
        config.selectors.shiftTypesBox.find('.draggable-shift-type').draggable({
          revert: 'invalid', // when dropped on invalid target, the item will revert back to its initial position
          containment: 'document',
          helper: function() {
            var helper = $(this).clone();
            helper.css('height','35px');
            helper.css('width','100px');
            return helper;
          },
          cursor: 'move',
          opacity: 0.8
        });
      })
      .on('shiftType:created', (e, shiftType) =>{
        store.shiftTypes.push(shiftType);
        config.selectors.clearShiftTypeFilter.trigger('click');
      })
      .on('shiftType:list_updated', (e, shiftTypes, searching) =>{
        var shiftTypeList = HandlebarsTemplates['shift_types/index']({
          drag_and_drop: I18n.t('shift_types.js.drag_and_drop'),
          if_saved: I18n.t('shift_types.js.if_saved'),
          empty_search: I18n.t('shift_types.js.empty_search'),
          searching: searching || false,
          shiftTypes: (shiftTypes || store.shiftTypes).sort((a,b) =>{
            return a.secondsSinceMidnight == b.secondsSinceMidnight ? a.duration - b.duration : a.secondsSinceMidnight - b.secondsSinceMidnight;
          }),
        });
        config.selectors.shiftTypesBox.empty().append(shiftTypeList);
        events.trigger('shiftType:rendered');
      });

    /* Used to initialize the Shift Types on first load */
    events.trigger('shiftType:list_updated');
  };

  /* Set up shift type filtering for department calendar, user calendar, and rotations
     @note: Filtering uses the OR operation on search terms
            Filtering is done against the comma-delimited value of a select2 dropdown
  */
  var initShiftTypeFilter = function() {
    config.selectors.clearShiftTypeFilter.on('click', () => {
      config.selectors.shiftTypeFilter.val('').keyup();
    }).hide();

    config.selectors.shiftTypeFilter.on('keyup', _.debounce(filterShiftTypeList, 200));

    function filterShiftTypeList(){
      var matches,
        filterWords = $(this).val().trim().toLowerCase();

      if (!filterWords) {
        config.selectors.clearShiftTypeFilter.hide();
        matches = store.shiftTypes;
      } else {
        config.selectors.clearShiftTypeFilter.show();
        matches = _.filter(store.shiftTypes, shiftType => {
          return shiftType.name.toLowerCase().indexOf(filterWords) !== -1;
        });
      }

      events.trigger('shiftType:list_updated', [matches, filterWords]);
    }
  };

  /* Set up rotation filtering for department calendar, user calendar, and rotations
     @note: Filtering uses the OR operation on search terms
            Filtering is done against the comma-delimited value of a select2 dropdown
  */
  var initRotationFilter = function() {
    config.selectors.clearRotationFilter.on('click', () => {
      config.selectors.rotationFilter.val('').keyup();
    }).hide();

    config.selectors.rotationFilter.on('keyup', _.debounce(filterRotationList, 200));

    function filterRotationList(){
      var matches,
        filterWords = $(this).val().trim().toLowerCase();

      if (!filterWords) {
        config.selectors.clearRotationFilter.hide();
        matches = store.rotations;
      } else {
        config.selectors.clearRotationFilter.show();
        matches = _.filter(store.rotations, rotation => {
          return rotation.name.toLowerCase().indexOf(filterWords) !== -1;
        });
      }

      events.trigger('rotation:list_updated', [matches, filterRotationList]);
    }
  };

  var getCurrentRowPosition = function() {
    var tableWrapper = $('#department-schedule-box .box-wrapper');
    var rowPosition = tableWrapper.scrollTop();
    return rowPosition;
  };

  // Set up date navigation for quick jump to a new week, with push state
  // This is the central function that modifies all dates in the lib.
  var initDateNavigation = function() {
    $('#today, #previous-week, #next-week').on('click', function(e) {
      e.preventDefault();

      // If date navigation has been disabled do nothing
      if ($(this).hasClass('disabled')) {
        return false;
      }

      // Save current scroll position so that we set that on the new table when its ajax loaded
      var tableWrapper = $('#department-schedule-box .box-wrapper');
      var rowPosition = tableWrapper.scrollTop();

      var $url = $(this).attr('href');
      var newPreviousWeekStart;

      if ($(this).attr('id') == 'today') {
        newPreviousWeekStart = moment().startOf('week').subtract(1, 'weeks').format(config.dateFmt);
      } else {
        newPreviousWeekStart = moment($url.split('=')[1], config.dateFmt).subtract(1, 'weeks').format(config.dateFmt);
      }

      // Update previous/next navigation with new start date
      $('#previous-week').attr('href', config.routes.departmentSchedulePath() + '?start=' + newPreviousWeekStart);
      $('#next-week').attr('href', config.routes.departmentSchedulePath() + '?start=' + moment(newPreviousWeekStart, config.dateFmt).add(14, 'days').format(config.dateFmt));
      copyPaste.repeatSourceWeekStartDate = newPreviousWeekStart;

      // Update current date actions
      var currentDate =  moment(newPreviousWeekStart, config.dateFmt).add(1, 'weeks').format(config.dateFmt);

      setBeginningOfWeek(currentDate);

      $('#employees-view').attr('href', config.routes.employeesDepartmentSchedulePath + '?start=' + currentDate);
      $('#positions-view').attr('href', config.routes.positionsDepartmentSchedulePath + '?start=' + currentDate);
      $('#job-sites-view').attr('href', config.routes.jobSitesDepartmentSchedulePath + '?start=' + currentDate);
      $('#printable-schedule').attr('href', config.routes.printableDepartmentSchedulePath + '?start=' + currentDate);
      $('#toolbar-datepicker').attr('data-date', currentDate).datepicker('update', currentDate);
      $('#export-schedule-datepicker').attr('data-date', currentDate).datepicker('update', currentDate);
      copyPaste.targetWeekStartDate = currentDate;
      toggleCopyFunctionality();

      //init export filter
      if (!config.selectors.scheduleProviderFilter.select2('data')) {
        config.selectors.scheduleProviderFilter.select2('data', null);
        config.selectors.exportForProviderButton.attr('href', '#');
        config.selectors.exportForProviderButton.addClass('disabled');
        initExportScheduleFields();
      }
      else {
        var startDate = $('#export-schedule-datepicker').datepicker('getDate');
        var archived = $('#schedule-include-archived').is(':checked');
        var providerName = config.selectors.scheduleProviderFilter.select2('data').name;
        config.selectors.exportForProviderButton.attr('href', config.routes.exportSchedulePath(providerName, startDate, archived));
      }

      // add page to history and load schedulele
      History.pushState({rowPosition: rowPosition}, config.pageTitle, $url);
    });
  };


  // Set up date pickers to quickly select a week in the past or future using the initDateNavigation functions.
  // Delegates actual date setting to $(#previous-week)
  var initDatepicker = function(datepickerId) {
    var $datepicker = $(datepickerId);

    $datepicker.datepicker({
      format: 'yyyy-mm-dd',
      weekStart: config.startOfWeek,
      startDate: $(this).attr('data-date'),
      orientation: 'right auto',
      autoclose: true,
      language: I18n.locale
    }).on('changeDate', e => {
      var newPreviousWeekStart = moment(e.date).startOf('week');
      $('#previous-week').
        attr('href', config.routes.departmentSchedulePath() + '?start=' + newPreviousWeekStart.format(config.dateFmt)).
        trigger('click');
    }).on('changeMonth', e =>{
      setTimeout(() => {
        setActiveWeekSelection();
      }, 100);
    });

    // Highlight current week within the calendar popover.
    $datepicker.on('click', () =>{
      // Hack fix for disabling the datepicker. Just immediately hide it.
      if ($datepicker.hasClass('disabled')) {
        $datepicker.data('datepicker').hide();
      } else {
        setActiveWeekSelection();
      }
    });

    // Highlight week while mousing over
    mouseOverHighlightWeek();
  };

  // Set up event handlers for dragging
  var initDraggables = function() {
    $('.draggable-shift-type, .draggable-unavailable, .draggable-custom-shift, .draggable-rotation').draggable({
      revert: 'invalid', // when dropped on invalid target, the item will revert back to its initial position
      containment: 'document',
      helper: function() {
        var helper = $(this).clone();
        helper.css('height','35px');
        helper.css('width','100px');
        return helper;
      },
      cursor: 'move',
      opacity: 0.8
    });
  };

  /**
   * Initializes the export schedule modal fields
   */
  var initExportScheduleFields = function() {

    // Provider Select2
    config.selectors.scheduleProviderFilter.select2({
      data: buildSelectionsForscheduleProviders(config.scheduleProviders),
      allowClear: true,
      placeholder: I18n.t('department_schedules.show.schedule.placeholder'),
    })
      .on('change', e => {
        if (e.val.length) {
          var startDate = $('#export-schedule-datepicker').attr('data-date');
          var archived = $('#schedule-include-archived').is(':checked');
          if (e.added.name == 'raw_excel' || e.added.name == 'raw_excel_month') {
            config.selectors.scheduleCheckbox.show();
          } else {
            config.selectors.scheduleCheckbox.hide();
          }

          config.selectors.exportForProviderButton.removeClass('disabled');
          config.selectors.exportForProviderButton.attr('href', config.routes.exportSchedulePath(e.added.name, startDate, archived));
        } else {
          config.selectors.scheduleCheckbox.hide();
          config.selectors.exportForProviderButton.attr('href', '#');
          config.selectors.exportForProviderButton.addClass('disabled');
        }
      });

    // Include Archived Users Checkbox
    config.selectors.scheduleCheckbox.hide().on('change', e => {
      var providerName = config.selectors.scheduleProviderFilter.select2('data') ? config.selectors.scheduleProviderFilter.select2('data').name : '';
      var startDate = $('#export-schedule-datepicker').attr('data-date');
      if (providerName) {
        config.selectors.exportForProviderButton.attr('href', config.routes.exportSchedulePath(providerName, startDate, e.target.checked));
      } else {
        config.selectors.exportForProviderButton.attr('href', '#');
      }
    });
  };


  /**
   * Builds selection values for the schedule file Filter
   * @param  {Array} scheduleProviders schedule file names
   * @return {Array}                  Array with provider names and their index as an id
   */
  var buildSelectionsForscheduleProviders = function(scheduleProviders) {
    return _.map(scheduleProviders, provider => {
      return { id: scheduleProviders.indexOf(provider) + 1, text: I18n.t('department_schedules.show.schedule' + '.' + provider), name: provider };
    });
  };


  /**
   * Initializes the Export button to show Export Modal and schedule file Selection
   */
  var initExportButton = function() {
    config.selectors.exportButton.on('click', e => {
      e.preventDefault();
      config.selectors.modal.modal('show');
      $('.alert').remove();
    });
  };

  var getFilters = function(type, $el){

    var raw_vals = $el.val();
    var valid_items = [];
    var req_ids = [];

    valid_items = _.filter(raw_vals, val =>{
      return val.includes(type+':');
    });

    req_ids = _.map(valid_items, item =>{
      return item.split(':')[1];
    });

    return req_ids;
  };

  var userHas = function(userRow, property, rawFilter){
    // Make sure we got an array
    var filterData = _.flatten([rawFilter]);

    // No filter applied, return early
    if(filterData.length == 0){ return true; }

    // Data attr ex) data-skill=["1","2","3"]
    var userData = _.flatten([$(userRow).data(property)]);

    var meetsRequirement = false;

    if (property === 'user-id') {
      // userData contains ANY of the filterData
      meetsRequirement = _.some(filterData, f =>{
        return userData.includes(parseInt(f));
      });
    } else {
      // userData contains ALL of the filterData
      meetsRequirement = _.all(filterData, f =>{
        return userData.includes(parseInt(f));
      });
    }

    return meetsRequirement;
  };

  // Set up employment type, position, and name filtering on user rows
  var initFilter = function() {
    var handleFiltering = function() {
      var $rows = $('tr.user-row');
      var $el = $(this);
      var $keywords = $(this).val();

      if ($keywords !== null && $keywords.length > 0) {
        $rows.addClass('hidden').filter((idx, userRow) => {
          var validForFilters = userHas(userRow, 'emptype',  getFilters('emptype',  $el)) &&
                                userHas(userRow, 'user-id',  getFilters('employee', $el)) &&
                                userHas(userRow, 'jobsite',  getFilters('jobsite',  $el)) &&
                                userHas(userRow, 'position', getFilters('position', $el)) &&
                                userHas(userRow, 'skill',    getFilters('skill',    $el));
          return validForFilters;
        }).removeClass('hidden');
      } else {
        $rows.removeClass('hidden');
      }
    };

    $('#user-filter-wrapper').load('/departments/' + config.departmentId + '/schedule/user_filter', () => {
      $('#user-filter').select2();
    });

    $(document.body).on('change', '#user-filter', handleFiltering);
  };

  //TODO: this can probably be refactored to generalize the initFilter function and just pass in `user` or `job_site` as an argument
  var initJobSiteFilter = function() {
    $('#job-site-filter').on('change', function() {
      var $rows = $('tr.job-site-row');
      var $keywords = $(this).val();
      if ($keywords !== null && $keywords.length > 0) {
        $rows.addClass('hidden').filter(function() {
          // Enforce string in case name is an integer
          var rowKeywords = $(this).data('keywords').toString();

          return $keywords.includes(rowKeywords);
        }).removeClass('hidden');
      } else {
        $rows.removeClass('hidden');
      }
    });

    jobSiteSort.init();
  };

  // Set up modal window click handler for managing events
  var initModals = function() {
    // Current Shifts launch ShiftsController#edit modal
    // Past Department Shifts & Other Department Shifts launch ShiftsController#show modal
    $('body').on('click', 'div.shift, div.other-department-shift', function(e) {
      e.preventDefault();

      var $self = $(this);
      var id = $self.data('shift-id');
      var url = ($(this).hasClass('shift') && !$(this).closest('td').hasClass('nodrop')) ? config.routes.editShiftPath(id) : config.routes.shiftPath(id);

      // Create a callback for opening the shift modal
      var cb = function() {
        $.get(url).then(html => {
          $('#shift-modal').html(html).modal('show');
        }, (xhr, status, error) => {
          if (xhr.status == 403) {
            alert(I18n.t('department_schedules.js.shift_permission'));
          } else {
            destroyEvent('shift', id);
            alert(I18n.t('department_schedules.js.shift_removed'));
          }
        });
      };

      // Find the position qtip, its form, and the form values
      var $qtip      = $('div.qtip.position-select');
      var $qtipForm  = $qtip.find('form');
      var formValues = $qtipForm.serializeArray()
        .filter(obj => ['shift[position_id]', 'shift[job_site_id]'].includes(obj.name))
        .filter(obj => obj.value);

      // If there are any values, bind the callback and submit the form
      // Otherwise remove the qtip and run the callback
      if (formValues.length > 0) {
        $('.edit_shift').on('ajax:success', cb);
        $qtipForm.submit();
      } else {
        $qtip.remove();
        cb();
      }
    });

    // Available Shifts launch AvailableShiftsController#show modal
    $('body').on('click', 'div.available-shift', function(e) {
      e.preventDefault();

      var $self = $(this);
      var id = $self.data('available-shift-id');
      var url = config.routes.availableShiftPath(id);

      $.get(url).then(html => {
        $('#shift-modal').html(html).modal('show');
      }, (xhr, status, error) => {
        if (xhr.status == 403) {
          alert(I18n.t('department_schedules.js.available_shift_permission'));
        } else {
          destroyEvent('available-shift', id);
          alert(I18n.t('department_schedules.js.available_shift_removed'));
        }
      });
    });

    // We add the :not so that we don't add the click event handler onto the Time Off draggable item itself
    // It would cause an error if you tried to open a modal to edit the unavailable, because it's just a template
    $('body').on('click', 'div.unavailable:not(.ui-draggable)', function(e) {
      e.preventDefault();

      var $self = $(this);
      var id = $self.data('unavailable-id');

      // Current Unavailables launch UnavailablesController#edit modal
      // Past Unavailables launch UnavailablesController#show modal
      var url = !$self.closest('td').hasClass('nodrop') ? config.routes.editUnavailablePath(id) : config.routes.unavailablePath(id);

      $.get(url).then(html => {
        $('#shift-modal').html(html).modal('show');
      }, (xhr, status, error) => {
        if (xhr.status == 403) {
          alert(I18n.t('department_schedules.js.time_off_permission'));
        } else {
          destroyEvent('unavailable', id);
          alert(I18n.t('department_schedules.js.time_off_removed'));
        }
      });
    });

    // Current UnavailableRequests launch UnavailableRequestsController#edit modal
    $('body').on('click', 'div.unavailable-request', function(e) {
      e.preventDefault();

      var $self = $(this);
      var id = $self.data('unavailable-request-id');
      var url = config.routes.editUnavailableRequestPath(id);

      $.get(url).then(html => {
        $('#shift-modal').html(html).modal('show');
      }, (xhr, status, error) => {
        if (xhr.status == 403) {
          alert(I18n.t('department_schedules.js.time_off_request_permission'));
        } else {
          destroyEvent('unavailable-request', id);
          alert(I18n.t('department_schedules.js.time_off_request_removed'));
        }
      });
    });

    // Non-persisted ShiftRequirements launch ShiftRequirementsController#new
    // Persisted ShiftRequirements launch ShiftRequirementsController#edit modal
    $('body').on('click', 'div.shift-requirement', function(e) {
      e.preventDefault();

      var $self = $(this);
      var id = $self.data('shift-requirement-id');
      var url;
      var params = {};

      if (id) {
        url = config.routes.shiftRequirementPath(id);
      } else {
        url = config.routes.newDepartmentShiftRequirementPath;
        $.extend(params, {
          shift_requirement: {
            source_shift_id: $self.attr('data-source-shift-id'),
            required_employees: $self.attr('data-shifts-count') || 1
          }
        });
      }

      var modal = $('#shift-modal');
      openModalWithSpinner(modal);

      $.get(url, params).then(html => {
        modal.html(html);
        modal.find('form').attr('data-initial-required-employees', modal.find('input#shift_requirement_required_employees').val());
      }, (xhr, status, error) => {
        displayModalLoadError(modal);
        // allow some time to see the error message before carrying on with
        // what with the clean-up
        setTimeout(() => {
          destroyEvent('shift-requirement', id);
        }, 3000);
      });

    });

    // New User modal
    $('body').on('click', '#add-employee-link', e => {
      e.preventDefault();

      if (config.adpUser) {
        window.location = config.routes.departmentUserPath;
        return;
      }

      $.get(config.routes.newUserPath).then(html => {
        $('#shift-modal').html(html).modal('show');
      });
    });
  };

  // Budgets must react dynamically to addition/removal of shifts
  // weeklyBudget:calculate was being triggered in various places (and span budgets should tie into the same events)
  var updateBudgets = function() {
    weeklyBudget.budgetEvents.trigger('weeklyBudget:calculate');
  };

  // Callback hooks for create/update modal forms and qtip forms
  var initModalForms = function() {
    var newAndEditShiftHandles = '.new_shift_requirement, .new_shift, .new_available_shift, .edit_shift, .edit_weekly_budget, .new_unavailable, .edit_unavailable_request';
    $('body')
      .on('ajax:before', newAndEditShiftHandles, () => {
        reverse_shifts_time($('#shift_start_time'), $('#shift_end_time'), 12);
      })
      .on('ajax:send', newAndEditShiftHandles, () => {
        if (user_prefers_12hr()) {
          reverse_shifts_time($('#shift_start_time'), $('#shift_end_time'), 24);
        }
      })
      .on('ajax:success', '.new_shift_requirement', (e, data) => {
        createShiftRequirement(data.shift_requirement);
      })
      .on('ajax:success', '#new_available_shift', (e, data) => {
        afterAvailableShift(data.available_shift, true);
      })
      .on('ajax:success', '.edit_available_shift', (e, data) => {
        afterAvailableShift(data.available_shift, false);
      })
      .on('ajax:success', '.new_shift', (e, data) => {
        var shiftType;

        createCustomShift(data.shift);

        if (typeof(data.shift_type) != 'undefined') {
          shiftType = new ShiftType(data.shift_type);
          events.trigger('shiftType:created', [shiftType]);
        }
      })
      .on('ajax:success', '.edit_shift', (e, data) => {
        updateShift(data.shift);
        updateHours($('tr[data-user-id=' + data.shift.user_id + ']'));
        updateBudgets();
      })
      .on('ajax:success', '.edit_unavailable', (e, data) => {
        updateUnavailable(data.unavailable);
      })
      .on('ajax:success', '.new_user', (e, data) => {
        location.reload();
      })
      .on('ajax:success', '.edit_unavailable_request', (e, data) => {
        $.each(data, (idx, unavailable) => {
          approveUnavailableRequest(unavailable.id, unavailable.unavailable_type_name);
        });
      })
      .on('ajax:error', newAndEditShiftHandles, (e, xhr, status, error) => {
        if (xhr.responseJSON.error_type != 'FatigueConflictError') {
          renderModalAlert(xhr.responseJSON);
        }
      })
      .on('ajax:error', '.new_shift, .edit_shift, .new_available_shift', (e, xhr, status, error) => {

        // if we get a fatigue rule error back we throw up a warning prompt
        // if the user choose to continue we remove the "check" flag and
        // resubmit the form.
        if (xhr.responseJSON.error_type == 'FatigueConflictError') {
          fatigueManagement.fatiguePrompt(xhr.responseJSON.error_message, () => {
            $(e.target).find('[id*=fatigue]').remove();
            $(e.target).submit();
          }, () => {
            // do nothing will cancel prompt
          });
        }
      })
      .on('ajax:error', '.new_user', function(e, xhr, status, error) {
        var responseJSON = xhr.responseJSON;
        remoteFormErrors.errorFields($(this), responseJSON.klass, responseJSON.errors);
      })
      .on('ajax:success', '.new_unavailable', (e, data, status, xhr) => {
        $.each(data, (idx, unavailable) => {
          var $th = $('.floatThead-table').find('th[data-date='+unavailable.date+']');

          // May have created objects outside this weekly schedule view (in the case of Unavailables)
          if (!$th.length) return;

          var $td = $('tr.user-row[data-user-id=' + unavailable.user_id + ']').find('td').eq($th.index());

          var $div = createGlobalShift(false, unavailable.formattedTitle);
          createUnavailableElement($div, unavailable.id);
          $td.prepend($div);
        });

        // Follow the existing pattern of using shift-modal for all kinds of things, even unavailables
        $('#shift-modal').modal('hide');
      });
  };

  // Initializes JSON behaviors performed within modals
  var initModalActions = function() {
    $('#shift-modal').on('click', 'a[data-modal-action], button[data-modal-action], button[data-dismiss]', function(e) {
      e.preventDefault();
      var $self = $(this);

      if ($self.data('disabled')) {
        return;
      }

      // Closing a modal (custom shift, available shift, shift requirement)
      if ($self.data('dismiss') === 'modal') {
        // Exceptions: we don't need to recalculate for:
        // - cancelling creating a new shift requirement
        // - cancelling creating a custom shift
        // - cancelling creating a new user
        // - cancelling the View Details window (for either new or existing budget)
        if ($self.parents('#new_shift_requirement').length)                  { return; }
        if ($self.parents('#new_shift').length)                              { return; }
        if ($self.parents('#new_user').length)                               { return; }
        if ($self.parents('.new_weekly_budget, .edit_weekly_budget').length) { return; }

        updateBudgets();
        return;
      }

      switch($self.data('modal-action')) {

      // Destroy event
      case 'destroy':
        $.ajax({
          url: $self.attr('href'),
          type: 'DELETE',
          dataType: 'json',
          data: {
            audit_action: 'delete_shift',
            audit_action_source: 'department_calendar',
            unavailable_request_admin_note: $('#unavailable_request_admin_note').val()
          }
        })
          .then(data => {
            destroyEvent($self.data('event-type'), $self.data('id'), $self.data('published'));
          });
        break;

        // Assign AvailableShift either via request ('offer') or eligibility ('available'/'availability')
      case 'assign':

        var url = $self.data('href') + '&check_fatigue=true';
        var assignShift = function(url) {

          $.ajax({
            url: url,
            type: 'PUT',
            dataType: 'json'
          })
            .then(data => {

              // We may optionally receive a message back (even on success),
              // so we'll display that message if it exists
              clearModalAlerts();
              if (data.error_message) {
                renderModalAlert(data);
              }

              // Update counts for assigned shifts and eligible users
              adjustCounter('assigned-shifts-count',  +1);
              if ($self.data('source') == 'offer') {
                adjustCounter('request-users-count',  -1);
              } else {
                adjustCounter('eligible-users-count', -1);
              }

              // Could be sent either from requested users or eligible users, so refresh both
              $('#reply-users-table').DataTable().ajax.reload(null, false);
              $('#eligible-users-table').DataTable().ajax.reload(null, false);

              assignAvailableShift(data.shift);
            }, (xhr, status, error) => {
              $self.prop('disabled', true);
              renderModalAlert(xhr.responseJSON);

              // if we get a fatigue rule error back we throw up a warning prompt
              // if the user choose to continue we remove the "check" flag and
              // resubmit the form.
              if (xhr.responseJSON.error_type == 'FatigueConflictError') {
                // we don't need to standard alert box error
                clearModalAlerts();
                fatigueManagement.fatiguePrompt(xhr.responseJSON.error_message, () => {
                  assignShift($self.data('href'));
                }, () => {
                  // re-enable the "Assign" button
                  $self.prop('disabled', false);
                });
              }
            });
        };

        assignShift(url);

        break;

        // Unassign Available Shift
      case 'unassign':
        $.ajax({ url: $self.data('href'), type: 'DELETE', dataType: 'json' })
          .then(data => {

            // We may optionally receive a message back (even on success),
            // so we'll display that message if it exists
            clearModalAlerts();
            if (data.error_message) {
              renderModalAlert(data);
            }

            $('#assigned-users-table').DataTable().ajax.reload(null, false);

            // Update counts for assigned shifts and eligible users
            adjustCounter('assigned-shifts-count', -1);
            adjustCounter('eligible-users-count',  +1);

            // Remove the shift from the schedule
            $('[data-shift-id=' + $self.data('id') + ']').remove();

          }, (xhr, status, error) => {
            $self.prop('disabled', true);
            renderModalAlert(xhr.responseJSON);
          });
        break;

        // Approve UnavailableRequest
      case 'approve-unavailable-request':
        $.ajax({ url: $self.attr('href'), type: 'PUT', dataType: 'json' })
          .then(data => {
            approveUnavailableRequest($self.data('id'));
          }, (xhr, status, error) => {
            if (xhr.status == 404) {
              destroyEvent('unavailable-request', $self.data('id'));
              alert(I18n.t('department_schedules.js.time_off_request_removed'));
            } else {
              renderModalAlert(xhr.responseJSON);
            }
          });
        break;
      }
    });
  };

  /**
   * Initializes the Publish & Notify Buttons
   * XHR request to DepartmentSchedules#update
   * @note Disables buttons while the event is happening, highlights all changes and displays a HUD when successful.
   */
  var initPublish = function() {
    if (config.isReadOnlySchedule){
      return;
    }
    var publishedAt = $('#last-published-at').html();

    publishedAt= user_prefers_12hr() ?
      publishedAt.replace(publishedAt.substring(publishedAt.lastIndexOf(' ')), ' ' +
        tConvert(publishedAt.substring(publishedAt.lastIndexOf(' ')).trim())) :
      publishedAt ;
    $('#last-published-at').html(publishedAt);

    $('.publish-and-notify .dropdown-menu').click(e => {
      e.stopPropagation();
    });

    $('.input-daterange').datepicker({
      format: 'yyyy-mm-dd',
      autoclose: true,
      language: I18n.locale
    }).on('show', e => {
      $('.datepicker').click(() => { return false; });
    });

    $('#publish-all-shifts, #publish-range-shifts, #publish-current-week-shifts').on('click', function(e){
      e.preventDefault();

      lastPublishButtonClicked  = $(this);
      lastPublishButtonText     = $(this).text();

      var url = $(this).attr('data-href');

      // temporarily disable button and change button name to 'submitting'
      $(this).prop('disabled', true).html(I18n.t('department_schedules.show.buttons.publish.pending'));

      if ($(this).attr('id') == 'publish-range-shifts') {
        var startDate = $('#publishStartDate').val();
        var endDate = $('#publishEndDate').val();
        url += '/?startDate=' + startDate + '&endDate=' + endDate;
      }

      $.ajax({
        url: url,
        method: 'PUT'
      })
        .done(data => {
        // WebSocket will trigger UI update
          return true;
        })
        .fail((xhr, status, error) => {
          bootbox.alert(error);
        // $self.html(buttonText);
        });
    });
  };

  // Handles ajax reloading of page contents from date navigation and back button
  var initPopState = function() {
    History.Adapter.bind(window, 'statechange', () => {
      var state = History.getState();

      // When navigating, disable any date navigation
      // until schedule loading is complete
      canDateNavigate(false);

      loadSchedule(state.url, state.data.rowPosition || 0);
    });
  };

  // Set up table size & table header to float when the table is scrolled
  var initFloatThead = function() {
    var $table = $('.department-schedule-table');
    $table.floatThead({
      scrollContainer: function($table){
        return $table.closest('.box-wrapper');
      },
      getSizingRow: function($table){ // this is only called when using IE
        // this finds the dummy row needed for IE 10/11 compadibility
        return $table.find('tbody tr:not(.add-employees-row):visible:first>*');
      }
    });
  };

  /**
   * Setup for the click handling on copy/paste and repeat week.  Also determines if the functionality should be
   * disabled or enabled based on the current week.
   */
  var initCopyPaste = function() {
    // set initial repeat and target weeks based on current date
    copyPaste.repeatSourceWeekStartDate = moment($('#toolbar-datepicker').attr('data-date'), config.dateFmt).subtract(1, 'weeks').format(config.dateFmt);
    copyPaste.targetWeekStartDate = $('#toolbar-datepicker').attr('data-date');

    toggleCopyFunctionality();

    /** Binds the click action for the copy button and the action for when a week is copied */
    $('#department-schedule-copy-week').on('click', e =>{
      e.preventDefault();
      /** sets the url for paste based on the current week and copies the label to be used in the modal */
      copyPaste.copiedSourceWeekStartDate = $('#toolbar-datepicker').attr('data-date');
      $('#paste-week-label').removeClass('hidden').html(I18n.t('department_schedules.show.copy_week.from_date', {'date': $('#schedule-date-range').text()}));
      // disable paste immediately, since you can't paste onto same week
      $('#department-schedule-paste-week').addClass('disabled');
    });

    /** Binds the click action for the paste and repeat buttons */
    $('#department-schedule-paste-week, #department-schedule-repeat-week').on('click', function(e){
      e.preventDefault();
      var $self = $(this);

      if (!$self.hasClass('disabled')) {
        var params = {
          source_week_start_date: ($self.attr('id') == 'department-schedule-paste-week') ? copyPaste.copiedSourceWeekStartDate : copyPaste.repeatSourceWeekStartDate,
          target_week_start_date: copyPaste.targetWeekStartDate,
          record_type: (config.view == 'employees') ? 'shifts' : 'shift_requirements'
        };

        /** Show the new copy/paste modal with the warning message. This also contains a form that is submitted. */
        $.get(config.routes.newDepartmentCopyPastePath, params)
          .then(html => {
            $('#shift-modal').html(html).modal('show');
            /**
           * On submission of modal form, start the spinner, disable click events to all aspects of the view until its complete, hide the modal, and execute copy/paste.
           */
            $('#paste-schedule').click(function (e) {
              e.preventDefault();
              toggleBlockDisplay(true, '#department-schedule-box', I18n.t('department_schedules.show.copy_week.progress_message'),'#shift-types-box, #drag-and-drop-box');
              var $form = $(this);
              $('.modal').modal('hide');

              $.ajax({
                url: config.routes.createDepartmentCopyPastePath,
                type: 'POST',
                data: params,
                dataType: 'json'
              })
                .fail((xhr, status, error) => {
                  alert(xhr.responseJSON.error_message);
                  var spinner = msUtil.spinner('department-schedule-box');
                  spinner.stop();
                  loadSchedule();
                });
            });
          });
      }
    });
  };

  var initOnboardingPopups = function() {
    var nextItem = config.data.onboarding.progress.nextItem();
    if (nextItem) {
      onboardingPopovers.init({progress: config.data.onboarding.progress, checklistItem: nextItem});
      if (nextItem === 1) { config.selectors.scheduleWrapper.scrollTop(config.selectors.scheduleWrapper[0].scrollHeight); } // needed for demo mode so that schedules with users can still see the popover modal
    }
  };

  var getDepartmentEmployeeCount = function() {
    return $('#employee_count').val();
  };

  var getAJAXCallSplit = function() {
    var employeeCount = getDepartmentEmployeeCount();

    if (employeeCount && (employeeCount > 0)) {
      return Math.ceil(employeeCount / 150);
    } else {
      return 1;
    }
  };

  var buildURLWithRequestParams = function(url, currentRequest, totalRequests) {
    if (_.contains(url, '?')) {
      return url + '&current_request=' + (currentRequest + 1) + '&total_requests=' + totalRequests;
    } else {
      return url + '?current_request=' + (currentRequest + 1) + '&total_requests=' + totalRequests;
    }
  };

  var loadDepartmentShifts = function(url) {

    var callSplit = getAJAXCallSplit();
    var loaderFunctions = [];

    for (let i = 0; i < callSplit; i++) {
      loaderFunctions.push(
        $.ajax({
          url: buildURLWithRequestParams(url, i, callSplit),
          data: { format: 'json' },
          dataType: 'json',
          cache: false
        }).then(response => {
          weekData.header = response.header;
          weekData.current_date = response.current_date;

          if (response.shifts) {
            weekData.shifts = concatShifts(weekData.shifts, response.shifts);
          }

          if (response.unavailables) {
            weekData.unavailables = concatShifts(weekData.unavailables, response.unavailables);
          }

          // will only exist with "positions" and "job sites" scheduler
          if (response.shift_requirements) {
            weekData.shift_requirements = concatShifts(weekData.shift_requirements, response.shift_requirements);
          }
        })
      );
    }

    return loaderFunctions;
  };

  var loadOtherDepartmentShifts = function(url) {

    url = url.replace('employees', 'other_department_shifts');
    var callSplit = getAJAXCallSplit();
    var loaderFunctions = [];

    for (let i = 0; i < callSplit; i++) {
      loaderFunctions.push(
        $.ajax({
          url: buildURLWithRequestParams(url, i, callSplit),
          data: { format: 'json' },
          dataType: 'json',
          cache: false
        }).then(response => {
          weekData.other_department_shifts = concatShifts(weekData.other_department_shifts, response.shifts);
        })
      );
    }

    return loaderFunctions;
  };

  var loadAvailabilities = function(url) {

    url = url.replace('employees', 'availabilities');

    var callSplit = getAJAXCallSplit();
    var loaderFunctions = [];

    for (let i = 0; i < callSplit; i++) {
      loaderFunctions.push(
        $.ajax({
          url: buildURLWithRequestParams(url, i, callSplit),
          data: { format: 'json' },
          dataType: 'json',
          cache: false
        }).then(response => {
          weekData.availabilities = concatShifts(weekData.availabilities, response.availabilities);
        })
      );
    }

    return loaderFunctions;
  };

  var loadAvailableShifts = function(url) {

    url = url.replace('employees', 'available_shifts');

    return $.ajax({
      url: url,
      data: {
        format: 'json'
      },
      dataType: 'json',
      cache: false
    }).then(response => {
      weekData.available_shifts = concatShifts(weekData.available_shifts, response.shifts);
    });
  };

  // Returns number of error_shift_attempts
  var loadErrorShiftExportAttemptsCount = function() {
    var error_shift_attempts = 0;

    var url = config.routes.shiftExportAttemptsPath($('#publishStartDate').val());
    var callSplit = getAJAXCallSplit();
    var loaderFunctions = [];

    for (let i = 0; i < callSplit; i++) {
      loaderFunctions.push(
        $.ajax({
          url: buildURLWithRequestParams(url, i, callSplit),
          data: { format: 'json' },
          dataType: 'json',
          cache: false
        }).then(response => {
          var num_errors = response.aaData.length;
          // if there are any errors, put up a warning
          if (num_errors > 0) {
            shiftSyncAuditButtonWarn();
          } else {
            shiftSyncAuditButtonClear();
          }
        })
      );
    }
    return loaderFunctions;
  };

  var handleShiftExportAttemptErrors = function(response) {
    var num_errors = response.aaData.length;
    // if there are any errors, put up a warning
    if (num_errors > 0) {
      shiftSyncAuditButtonWarn();
    } else {
      shiftSyncAuditButtonClear();
    }
  };


  // Retrieves data for new week and generates visible elements
  var loadSchedule = function(url, rowPosition, callback) {

    // Reset the week data store to make sure nothing is left behind
    initWeekData();

    // Default parameters
    url = url || location.href;
    rowPosition = rowPosition || 0;

    var spinner = msUtil.spinner('department-schedule-box');

    // Get all calendar data for the new week
    // @important The data payload must be passed in order to bypass chrome bug that caches and displays json data on back button
    // @note We add cache false to avoid jQuery caching the schedule data even after we've added content from dragging and dropping shifts.  This makes sure that the calendar is fully up-to-date from the backend.

    // The "employees" view is now broken down into several AJAX calls.
    // The position/job site views are not.
    var loaderFunctions = [];

    if (config.view == 'employees') {
      loaderFunctions = [
        loadAvailableShifts(url)
      ];

      loaderFunctions = loaderFunctions.concat(loadDepartmentShifts(url));
      loaderFunctions = loaderFunctions.concat(loadOtherDepartmentShifts(url));
      loaderFunctions = loaderFunctions.concat(loadAvailabilities(url));
    } else {
      loaderFunctions = loaderFunctions.concat(loadDepartmentShifts(url));
    }

    return $.when.apply($, loaderFunctions).done(() => {
      // Remove prior week divs and reset rendered flags
      resetTable();

      // Updates the table title, day headers, and disabled cells based on new week dates
      updateTableDates(config.beginningOfWeek, weekData.header, weekData.current_date);

      if (config.view == 'employees') {
        // Load available shifts for new week
        renderAvailableShifts();

        // Render the employee schedule
        renderEmployeeSchedule();
      } else {
        // Just render Position data when positions view
        renderPositionOrJobSiteSchedule();
      }

      if (config.view == 'job_sites') {
        sumRowRequiredEmployees(weekData.shift_requirements);
      }

      // Toggle publish button based on presence of unpublished Shifts button
      togglePublishButton(weekData.unpublished_shifts_exist);

      var $end_of_week = moment(config.beginningOfWeek).add(6, 'days').format(config.dateFmt);

      // Update publish this week button start and end date in href
      $('#publish-current-week-shifts').attr('data-href', config.routes.departmentScheduleUpdatePath + '?startDate=' + config.beginningOfWeek + '&endDate=' + $end_of_week);
      if (config.isReadOnlySchedule){
        $('#publishStartDate').val(config.beginningOfWeek);
        $('#publishEndDate').val($end_of_week);
      }else{
        $('#publishStartDate').datepicker('setDate', config.beginningOfWeek);
        $('#publishEndDate').datepicker('setDate', $end_of_week);
      }

      // Revert scroll position to the previously set position
      $('.box-wrapper').scrollTop(rowPosition);

      $(document).trigger('redrawSVG');

      // Kill spinner
      $('.spinner').fadeOut(300, () =>{
        spinner.stop();
        $(document).trigger('redrawSVG');
      });

      // Trigger the event to reload the budget bar with the current beginning of week
      weeklyBudget.budgetEvents.trigger('weeklyBudget:initialized', [config.beginningOfWeek]);

      $('.department-schedule-table').floatThead('reflow');
      // Disable the blockUI that disabled clicking on anything element of the department schedule.
      toggleBlockDisplay(false, '#department-schedule-box', null, '#shift-types-box, #drag-and-drop-box');

      if (config.scheduleIntegrated) {
        loadErrorShiftExportAttemptsCount();
        updateShiftSyncAuditDates();
        flashShiftExportError();
      }

      // Re-enable date navigation on the schedule once it's loaded
      canDateNavigate(true);
    });
  };

  var concatShifts = function(existingShifts, newShifts) {
    var startDate = Date.parse(config.beginningOfWeek);
    var endDate   = addDays(startDate, 6);

    var filteredShifts = newShifts.filter(shift =>{
      return isShiftInDateRange(Date.parse(shift.date), startDate, endDate);
    });

    return existingShifts.concat(filteredShifts);
  };

  var isShiftInDateRange = function(shiftDate, startDate, endDate) {
    return (shiftDate >= startDate) && (shiftDate <= endDate);
  };

  var addDays = function(startDate, numberOfDays) {
    return moment(startDate).add(numberOfDays, 'days');
  };

  var setBeginningOfWeek = function(date) {
    config.beginningOfWeek = date;
  };

  // Resets everything that needs to be cleared when schedule data is reloaded
  var resetTable = function() {
    var $table = $('.department-schedule-table');
    // Unset rendered flag
    // @note Not required for available shifts row since its rendered on every load
    $table.find('tr.user-row, tr.position-row, tr.job-site-row').data('rendered', '0');
    // Remove disabled cell classes
    $table.find('td.nodrop').removeClass('nodrop');
    // Remove past day class for colouring
    $table.find('td.past-day').removeClass('past-day');
    // Unset jquery-droppables
    $table.find('td.ui-droppable').droppable('destroy');
    // Remove rendered event divs
    $table.find('div').remove();
    // Remove availabilities qtip tables
    $table.find('.qtip-availabilities').remove();
  };

  // Updates all date-related fields and cells on the calendar
  var updateTableDates = function(startDate, header, today) {
    // Reset date range header
    $('#schedule-date-range').html(header);

    // Loop through the week range and update the data attributes and names on the table headers
    var $tableHeadFloat = $('table.floatThead-table th');
    for (var i = 0; i < 7; i++) {
      var start = moment(startDate);
      var newDate = start.add(i, 'days');
      var thIndex = i+1;
      $tableHeadFloat.eq(thIndex).attr('data-date', newDate.format(config.dateFmt));
      $tableHeadFloat.eq(thIndex).text(I18n.l('date.formats.a_d', newDate.format()));
      // If date is in the past, add .past-day cell class to colour it grey.
      if (newDate < moment(today).startOf('day')) {
        var nthIndex = thIndex+1;
        $('.department-schedule-table td:nth-child('+ nthIndex +')').addClass('past-day');
        $('.department-schedule-table #available-shifts-row td:nth-child('+ nthIndex +')').addClass('nodrop');
      }
    }
  };

  // Render available Shifts for current week and bind droppables
  var renderAvailableShifts = function() {
    var $availableShiftRow = $('#available-shifts-row');
    $.each(weekData.available_shifts, (i, available_shift) => {
      var $th = $availableShiftRow.find('th').eq(available_shift.day_of_week);
      $th.append(divWriter(available_shift, 'available-shift'));
    });

    // Check for overflow of available shifts
    checkAvailShiftsOverflow();

    // Available shifts cells can only handle Shifts
    $availableShiftRow.find('th.dropcell').not('.nodrop').droppable({
      accept: '.draggable-shift-type, .draggable-custom-shift, .shift',
      hoverClass: 'schedule-hover',
      drop: handleDrop,
    });

    // Adding droppable elements here to catch any drops when the calendar is overflowing
    $availableShiftRow.siblings('tr').find('th.shift-header.dropcell').droppable({
      accept: '.draggable-shift-type, .draggable-custom-shift, .shift',
      drop: handleDrop
    });
  };

  // Render events (shifts, time-off, availabilities) for current week.
  // Bind droppable events.
  var renderEmployeeSchedule = function() {
    $('tr.user-row').each(function() {
      var $row = $(this);

      var userId = parseInt($row.data('user-id'));
      var $rowTds = $row.find('td');

      // Render shifts
      $.each(_.where(weekData.shifts, {user_id: userId}), (i, shift) => {
        $rowTds.eq(shift.day_of_week).append(divWriter(shift, 'shift'));
      });

      // Render other department shifts
      $.each(_.where(weekData.other_department_shifts, {user_id: userId}), (i, shift) => {
        $rowTds.eq(shift.day_of_week).append(divWriter(shift, 'shift'));
      });

      // Render unavailables
      $.each(_.where(weekData.unavailables, {user_id: userId}), (i, unavailable) => {
        $rowTds.eq(unavailable.day_of_week).append(divWriter(unavailable, unavailable.css_class));
      });

      // Render availabilities
      renderAvailabilities($rowTds, _.where(weekData.availabilities, {user_id: userId}));

      // Calculate weekly hours total
      updateHours($row);



      // Make user row droppable unless...
      // Dragging Unavailable event - Prevent dropping when another event is present
      // Dragging Any Event - Prevent dropping when an UnavailableRequest or Unavailable event is present
      $row.find('td.dropcell').not('.nodrop').droppable({
        accept: function(draggable) {
          var isDraggable = draggable.hasClass('ui-draggable');

          var isDroppable = true;
          if (!MKS.allow_partial_time_off) {
            isDroppable = !($(this).has('div.unavailable-request, div.unavailable').length);
          }

          // allow rotations to to be dropped onto time-off's
          // (we won't touch the time-off either way)
          if (draggable.hasClass('draggable-rotation')) {
            isDroppable = true;
          }

          // allow shifts to be dropped onto time-off requests
          // (only if adp_time_off_request_sync is enabled)
          if (
            MKS.adp_time_off_request_sync
            && draggable.is('.draggable-shift-type, .draggable-custom-shift, .shift')
            && $(this).has('div.unavailable-request').length
          ) {
            isDroppable = true;
          }

          return isDraggable && isDroppable;
        },
        hoverClass: 'schedule-hover',
        drop: function (event, ui) {
          handleDrop.call(this, event, ui);
          config.selectors.availableShiftCells.removeClass('skip-drop');
          config.selectors.userCells.removeClass('skip-drop');
        },
        over: function(event, ui) {
          var fixedAvailableShiftRow = $('.floatThead-container.overfilled');
          if (fixedAvailableShiftRow.length > 0) {
            var fixedAvailableShiftRowHeight = fixedAvailableShiftRow.offset().top + fixedAvailableShiftRow.height();
            var draggableBottom = ui.offset.top + ui.draggable.height();
            if (draggableBottom > fixedAvailableShiftRowHeight) {
              config.selectors.availableShiftCells.addClass('skip-drop');
              config.selectors.userCells.removeClass('skip-drop');
            }
          }
        },

        out: function(event, ui) {
          var fixedAvailableShiftRow = $('.floatThead-container.overfilled');
          if (fixedAvailableShiftRow.length > 0) {
            var draggableBottom = ui.offset.top;
            var fixedAvailableShiftRowHeight = fixedAvailableShiftRow.offset().top + fixedAvailableShiftRow.height();
            if (draggableBottom < fixedAvailableShiftRowHeight) {
              config.selectors.availableShiftCells.removeClass('skip-drop');
              config.selectors.userCells.addClass('skip-drop');
            }
          }
        },
      });
    });

    updateScheduleLabels();
  };

  // Render a consolidated availability square on calendar for a single or multiple availabilities
  var renderAvailabilities = function($rowTds, userAvailabilities) {
    $.each(_.groupBy(userAvailabilities, 'day_of_week'), (i, userAvailabilityByDay) => {
      var firstAvailability = userAvailabilityByDay[0];
      var dayOfWeekTd = $rowTds.eq(firstAvailability.day_of_week).append(divWriter(firstAvailability, 'availability'));

      if (userAvailabilityByDay.length > 1) {
        var availabilitiesCount = '<span style=\'display:inline;\'> (' + userAvailabilityByDay.length + ')</span>';
        var userId = firstAvailability.user_id;
        var date = firstAvailability.date;
        var divId = 'availabilities_count_' + userId + date;

        dayOfWeekTd.
          find('.availability').attr('id', divId).
          find('span').replaceWith('<strong>' + I18n.t('department_schedules.js.available') + '</strong>' + availabilitiesCount);

        createAvailabilitiesTip($('#' + divId), userId, date);
      }
    });
  };

  // Render events for Positions or Job Sites for current week and bind droppables
  var renderPositionOrJobSiteSchedule = function() {
    $('tr.position-row, tr.job-site-row').each(function() {
      var $row = $(this);
      // Render data for Position row if not already rendered
      if ($row.data('rendered') == '0') {
        var positionId = $row.data('position-id') ? parseInt($row.data('position-id')) : null;
        var jobSiteId = $row.data('job-site-id') ? parseInt($row.data('job-site-id')) : null;
        var $rowTds = $row.find('td');

        // Render ShiftRequirements
        $.each(
          _.filter(weekData.shift_requirements, shift_requirement => {
            if (positionId) {
              return shift_requirement.position_id == positionId;
            } else if (jobSiteId) {
              return shift_requirement.job_site_id == jobSiteId;
            }
          }),
          (i, shiftRequirement) => {
            $rowTds.eq(shiftRequirement.day_of_week).append(divWriter(shiftRequirement, 'shift-requirement'));
          });

        // Make all Position rows droppable
        $row.find('td.dropcell').not('.nodrop').droppable({
          accept: function(draggable) {
            return draggable.hasClass('ui-draggable');
          },
          hoverClass: 'schedule-hover',
          drop: handleDrop
        });

        // Flag as rendered
        $row.data('rendered', '1');
      }
    });

    updateScheduleLabels();
  };

  // Div builder for all event types
  var divWriter = function(event, eventType) {

    var html = '';
    if (event.replies) {
      html = '<i class="fa fa-reply"></i>';
    }
    html += event.label;

    var cssClass = event.css_class;
    if (event.css_class === 'shift' || event.css_class === 'available-shift') {
      if (event.published_at === null) {
        cssClass += ' unpublished';
      }
    } else if (event.css_class === 'shift-requirement') {
      cssClass +=  shiftRequirementFillCssClass(event);
      if (event.published === false) {
        cssClass += ' unpublished';
      }
    }

    var draggable = true;

    if (config.isReadOnlySchedule){
      draggable = false;
    }
    if (event.css_class !== 'unpublished' && event.css_class !== 'shift') {
      draggable = false;
    }

    var $div = createGlobalShift(draggable,html);

    // adds css class name
    $div.addClass(cssClass);

    // set core ID attribute, with special case for Unavailables that pass
    // in their own ID as well as parent ID of UnavailableRequest
    if (eventType === 'unavailable-request' || eventType === 'unavailable') {
      $div.attr('data-unavailable-id', event.id);
      $div.attr('data-unavailable-request-id', event.unavailable_request_id);
    } else {
      $div.attr('data-'+ eventType + '-id', event.id);
    }
    // extra data needed for Shifts to calculate weekly hours
    if (eventType === 'shift') {
      $div.attr('data-paid-duration', event.paid_duration);
      applyHeightClassToShift($div);
    } else if (eventType === 'available-shift') {
      applyHeightClassToShift($div);
    } else if (eventType === 'shift-requirement' && event.id === null) {
      // Key to associate non-persisted ShiftRequirements to newly persisted ones on click-and-create/drag
      $div.attr('data-composite-key', event.composite_key);
      // Shift ID used to pre-fill ShiftRequirement attributes when clicking on a non-persisted ShiftRequirement
      $div.attr('data-source-shift-id', event.source_shift_id);
      // This is used to pre-fill the modal on non-persisted ShiftRequirements with the current shifts_count
      $div.attr('data-shifts-count', event.shifts_count);
    }

    $div.data('shift-templates-label', event.shift_type_name);
    $div.data('positions-label', event.position_name);
    $div.data('job-sites-label', event.job_site_name);

    return $div;
  };

  function applyHeightClassToShift(element) {
    var checkedLabels = $(scheduleLabels.getScheduleLabelCheckboxes()).filter(':checked');

    var selectedLabels = checkedLabels.map((index, checkedLabel) => {
      return $(checkedLabel).val();
    });

    var labelCount = 0;
    var labelsArray = selectedLabels.get();

    if (labelsArray.includes('shift-templates')) {
      labelCount++;
    }

    if (labelsArray.includes('positions') || labelsArray.includes('job-sites')) {
      labelCount++;
    }

    var heightClass = labelCount < 2 ? 'height-for-one-line-label' : 'height-for-two-line-label';
    $(element).addClass(heightClass);
  }

  // Helper function to check if draggable element is outside droppable container when it is overflowing
  // See issue: https://github.com/AppColony/makeshift/issues/6715
  var isDraggableHelperInsideDroppableContainer = function(event, $droppableContainer) {
    var cTop = $droppableContainer.offset().top;
    var cLeft = $droppableContainer.offset().left;
    var cBottom = cTop + $droppableContainer.height();
    var cRight = cLeft + $droppableContainer.width();
    return (event.pageY >= cTop && event.pageY <= cBottom && event.pageX >= cLeft && event.pageX <= cRight);
  };

  // Shift/Unavailable/Rotations Drop handler
  var handleDrop = function(e, ui) {
    if ($(this).hasClass('skip-drop')) {
      return false;
    }

    // Prevent the double-firing of a drop handler
    if (e.timeStamp == config.lastDrop) {
      return false;
    }
    config.lastDrop = e.timeStamp;

    // Make sure elements are not outside the droppable container
    if (!isDraggableHelperInsideDroppableContainer(e, config.selectors.scheduleWrapper)) {
      return false;
    }

    var shiftId = ui.draggable.data('shift-id');
    var rotationId = ui.draggable.data('rotation-id');
    var isLocked = (ui.draggable.find('.shift-lock-label').length > 0);

    if (isLocked) {
      return;
    } else if (shiftId) {
      if (ui.draggable.parent()[0] != $(this)[0]) {
        moveShift(ui, $(this), shiftId);
      }
    } else if (rotationId) {
      createRotation(ui, $(this));
    } else {
      createShift(ui, $(this));
    }
  };

  var createRotation = function(ui, thisObj) {
    scheduleRotations.openModal(ui, thisObj);
  };

  // creates a new shift
  var createShift = function(ui, thisObj) {

    var shiftTypeId = ui.draggable.data('shift-type-id');
    var shiftTypeLabel;
    var $td = thisObj;
    var $tr = $td.closest('tr');
    var $th = $('.floatThead-table').find('th').eq($td.index()); // Uses re-inserted floatThead thead instead of original table thead
    var draggable = false;

    if (typeof(shiftTypeId) !== 'undefined') {
      shiftTypeLabel = _.findWhere(store.shiftTypes, { id: shiftTypeId }).name;
      draggable = true;
    } else {
      shiftTypeLabel = ui.draggable.data('label');
      draggable = false;
    }

    //var $div = $('<div class="department-10-cal unpublished"><a class="fa fa-move top-right" id="move_shift"></a><span>' + shiftTypeLabel + '</span></div>');
    var $div = createGlobalShift(draggable, shiftTypeLabel);
    $div.addClass('unpublished');

    var date = $th.attr('data-date');
    var $request;


    // Common function to handle a failure (406) for all these different types of requests
    // either it's a fatigue prompt which needs to be handled accordingly or another type
    // of conflict
    function attachRequestFailureHandler($request) {

      $request.fail((xhr, status, error) => {

        if (xhr.responseJSON.error_type == 'FatigueConflictError') {

          fatigueManagement.fatiguePrompt(xhr.responseJSON.error_message, () => {
            delete shiftTypeData.shift.fatigue_validate;
            window.doShiftTypeRequest(shiftTypeData);
          }, () => {
            $div.remove();
          });

        } else {

          $div.fadeOut(config.transitionInterval, function () {
            $(this).remove();
            bootbox.alert(bootboxMsg(xhr.responseJSON));
          });

        }
      });
    }

    if (config.view == 'employees') { //employee scheduler
      var userId = $tr.data('user-id');

      // Workflow for creating User Shifts via ShiftType/custom, and Unavailables
      if (userId) {


        if (ui.draggable.hasClass('draggable-shift-type')) {

          // Prepend to use css selector ~ on availabilities
          $td.prepend($div);

          var shiftTypeData = {
            shift: {
              date: date,
              shift_type_id: shiftTypeId,
              user_id: userId,
              fatigue_validate: true,
              audit_action: 'assign_from_shift_template',
              audit_action_source: 'department_calendar'
            }
          };

          window.doShiftTypeRequest = function doShiftTypeRequest(data) {
            return $.ajax({
              url: config.routes.createDepartmentShiftsPath,
              type: 'POST',
              data: data,
              dataType: 'json',
              success: function(data) {
                var shift = data.shift;
                var showPositionTip = (shift.position_required || shift.job_site_required);
                createShiftElement($div, shift, showPositionTip, true);
                updateBudgets();
                ga('send', 'event', makeshiftGoogleAnalytics.categories.departmentCalendar, makeshiftGoogleAnalytics.actions.drag, makeshiftGoogleAnalytics.label.shift);
              }
            });
          };

          $request = window.doShiftTypeRequest(shiftTypeData);
          attachRequestFailureHandler($request);

        } else if (ui.draggable.hasClass('draggable-custom-shift')) {

          $request = $.get(config.routes.newDepartmentShiftPath, { shift: { date: date, user_id: userId } });

          $request.done(html => {
            $('#shift-modal').html(html).modal('show');
          });

          attachRequestFailureHandler($request);

        }
        // User has drag-and-dropped a time off block
        // We show a modal to allow them to set the options for it
        else if (ui.draggable.hasClass('draggable-unavailable')) {
          $request = $.get(
            config.routes.newUnavailablePath,
            { unavailable: { date: date, user_id: userId } },
            html => { $('#shift-modal').html(html).modal('show'); }
          );
        }
        // Workflow for creating AvailableShifts via ShiftType/custom
      } else {
        $request = $.get(
          config.routes.newDepartmentAvailableShiftPath,
          { available_shift: {
            date: date,
            shift_type_id: shiftTypeId
          }
          });

        $request.done(html => {
          $('#shift-modal').html(html).modal('show');
        });

        attachRequestFailureHandler($request);

      }
    } else if (config.view == 'positions') { // position scheduler
      var positionId = $tr.data('position-id');

      $request = $.get(config.routes.newDepartmentShiftRequirementPath,
        { shift_requirement: { date: date, shift_type_id: shiftTypeId, position_id: positionId } }
      );
      $request.done(html => {
        $('#shift-modal').html(html).modal('show');
      });
      attachRequestFailureHandler($request);
    } else if (config.view == 'job_sites') {
      var jobSiteId = $tr.data('job-site-id');

      $request = $.get(config.routes.newDepartmentShiftRequirementPath,
        { shift_requirement: { date: date, shift_type_id: shiftTypeId, job_site_id: jobSiteId } }
      );
      $request.done(html => {
        $('#shift-modal').html(html).modal('show');
      });
    }
  };


  // moves an existing shift to a new user/new timeslot (deletes original shift, creates shift and assigns to new user/new time)
  var moveShift = function(ui, thisObj, shiftId){
    ui.draggable.removeClass('drag-revert');
    var $td = thisObj;
    var $tr = $td.closest('tr');
    var $th = $('.floatThead-table').find('th').eq($td.index()); // Uses re-inserted floatThead thead instead of original table thead

    if (config.view == 'employees') { //employee scheduler

      var userId = $tr.data('user-id');
      var shiftTypeLabel = ui.draggable.find('span')[0].innerHTML;
      var date = $th.attr('data-date');
      var $request;

      // Workflow for creating User Shifts from existing shifts
      if (userId) {
        $request = moveShiftSave({
          ui: ui,
          shiftId: shiftId,
          userId: userId,
          date: date,
          shiftTypeLabel: shiftTypeLabel,
          fatigueValidate: true,
          td: $td
        });
      }
      // Workflow for creating available Shifts from existing shifts
      else {
        // get existing shift details
        $request = $.ajax({
          url: config.routes.shiftPath(shiftId),
          type: 'GET',
          dataType: 'json'
        });
        $request.done(json_shift => {
          //create an available shift based on the existing shift
          var starts_at = moment(json_shift['starts_at']);
          var ends_at = moment(json_shift['ends_at']);
          var days_diff = moment(date).diff(json_shift['date'],'days');
          var $request = $.ajax({
            url: config.routes.newDepartmentAvailableShiftPath,
            type: 'GET',
            data: {
              available_shift: {
                date: date,
                shift_type_id: json_shift['shift_type_id'],
                starts_at: starts_at.add(days_diff,'days').format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
                ends_at: ends_at.add(days_diff,'days').format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
                position_id: json_shift['position_id'],
                job_site_id: json_shift['job_site_id'],
                breaks: json_shift['breaks'], hide_ends_at: json_shift['hide_ends_at'],
                total_breaks: json_shift['total_breaks'],
                notes: json_shift['notes'],
                time_reporting_code: json_shift['time_reporting_code'],
                name: json_shift['name']
              }
            },
            dataType: 'html'
          });
          $request.done(html => {
            $('#shift-modal').on('show', function(){
              $(this).find('#create-custom-shift-btn').on('click', event => {
                $.ajax({
                  url: config.routes.shiftPath(json_shift.id),
                  type: 'DELETE',
                  dataType: 'json',
                  data: {
                    audit_action: 'reassign_to_available_shift',
                    audit_action_source: 'department_calendar',
                    shift_date_new: date
                  }
                })
                  .then(data => {
                    var original_tr = ui.draggable.parent().closest('tr');
                    ui.draggable.draggable('destroy');
                    ui.draggable.remove();
                    updateBudgets();
                    updateHours(original_tr);
                    ga('send', 'event', makeshiftGoogleAnalytics.categories.departmentCalendar, makeshiftGoogleAnalytics.actions.drag, makeshiftGoogleAnalytics.label.shift);
                  });
              });
              $(this).find('#cancel-custom-shift-btn').on('click', event => {
                ui.draggable.addClass('drag-revert');
                ui.draggable.animate({
                  'left': 0,
                  'top': 0
                });
              });
            });
            $('#shift-modal').html(html).modal('show');
          });
        });
      }
    }

    // $request.fail(moveShiftFailHandler);
  };

  var moveShiftCancel = function() {

    store.lastShiftedShift.ui.draggable.addClass('drag-revert');
    store.lastShiftedShift.ui.draggable.animate({
      'left': 0,
      'top': 0
    });
  };

  var moveShiftFailHandler = function(xhr, status, error) {

    // if we get a fatigue rule error back we throw up a warning prompt
    // if the user choose to continue we remove the "check" flag and
    // resubmit the form.
    if (xhr.responseJSON && xhr.responseJSON.error_type == 'FatigueConflictError') {
      fatigueManagement.fatiguePrompt(xhr.responseJSON.error_message, () => {
        store.lastShiftedShift.fatigueValidate = false;
        moveShiftSave(store.lastShiftedShift);
      }, () => {
        moveShiftCancel();
      });
    } else {

      moveShiftCancel();

      if (xhr.responseJSON) {
        var error_message = xhr.responseJSON.error_message;
        if (error_message != 'no change') {
          bootbox.alert(bootboxMsg(xhr.responseJSON));
        }
      }
    }
  };

  var moveShiftSave = function(params) {

    //get existing shift details
    var $request = $.ajax({
      url: config.routes.shiftPath(params.shiftId),
      type: 'GET',
      async:   false,
      dataType: 'json'
    });

    // if shift is returned create the shift with the existing shift details
    $request.done(json => {

      store.lastShiftedShift = params;

      var starts_at = moment(json['starts_at']);
      var ends_at = moment(json['ends_at']);
      var days_diff = moment(params.date).diff(json['date'],'days');

      var shift = {
        date: params.date,
        user_id: params.userId,
        starts_at: starts_at.add(days_diff,'days').format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
        ends_at: ends_at.add(days_diff,'days').format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
        available_shift_id: json['json'],
        breaks: json['breaks'],
        hide_ends_at: json['hide_ends_at'],
        notes: json['notes'],
        time_reporting_code: json['time_reporting_code'],
        shift_type_id: json['shift_type_id'],
        original_shift_id: json['id'],
        total_breaks: json['total_breaks'],
        wage: json['wage'],
        position_id: json['position_id'],
        job_site_id: json['job_site_id'],
        fatigue_validate: params.fatigueValidate,
        shift_date_previous: json['date'],
        pay_code_id: json['pay_code_id'],
        audit_action: 'reassign',
        audit_action_source: 'department_calendar'
      };

      var $innerRequest = $.ajax({
        url: config.routes.reassignShiftsPath(json['id']),
        type: 'POST',
        data: { shift: shift },
        dataType: 'json'
      });

      $innerRequest.done(data => {

        // update existing shift with position id if relevant.
        var shift = data.shift;
        var possible_positions = shift.position_assignments;
        var possible_job_sites = shift.job_site_assignments;
        var showPositionTip = ((!possible_positions.includes(json['position_id']) && possible_positions.length > 1) || (!possible_job_sites.includes(json['job_site_id']) && possible_job_sites.length > 1));
        params.ui.draggable.draggable({revert: false});
        var $div = createGlobalShift(true, params.shiftTypeLabel);
        $div.addClass('unpublished');

        $div.children('span').children('span').remove();
        if (possible_positions.length == 1)
          $.ajax({ url: config.routes.updateShift(shift.id),
            type: 'PUT',
            data: { shift: { position_id: possible_positions[0], audit_action_source: 'department_calendar' }},
            dataType: 'json'
          })
            .then(data => {
              shift = data.shift;
              createShiftElement($div, shift, showPositionTip, true);
            });

        params.td.prepend($div);
        createShiftElement($div, shift, showPositionTip, true);

        //recalculate hours for the original shift
        var original_tr = params.ui.draggable.parent().closest('tr');

        // Remove the deleted shift from the schedule
        destroyEvent('shift', json['id']);
        params.ui.draggable.draggable('destroy');
        params.ui.draggable.remove();
        updateHours(original_tr);
      });

      $innerRequest.fail(moveShiftFailHandler);

    });

    $request.fail(moveShiftFailHandler);
  };

  // Get the label for a particular schedule item/div
  var scheduleItemLabel = function($div) {
    return $div.find('span.schedule-label');
  };

  // After-create/edit AvailableShift: hide modal, highlight created AvailableShift
  var afterAvailableShift = function(availableShift, isNew) {
    $('#shift-modal').modal('hide');
    var $th = $('.floatThead-table').find('th[data-date='+ availableShift.date +']');
    var $td = $('#available-shifts-row').find('th').eq($th.index());

    var $div = createGlobalShift(false, availableShift.label);

    if (isNew) {
      $td.append($div);
    } else {
      $('#available-shifts-row').find('div[data-available-shift-id='+ availableShift.id +']').replaceWith($div);
    }

    $div.attr('data-available-shift-id', availableShift.id);
    $div.attr('data-positions-label', availableShift.position_name);
    $div.attr('data-job-sites-label', availableShift.job_site_name);
    $div.data('shift-templates-label', availableShift.shift_type_name);

    $div.switchClass(
      'department-10-cal',
      'available-shift',
      config.transitionInterval,
      'easeOutQuad',
      () => {
        checkAvailShiftsOverflow();
        updateScheduleLabels(scheduleItemLabel($div));
        applyHeightClassToShift($div);
      }
    );

    if (availableShift.published_at === null) {
      $div.addClass('unpublished');
      togglePublishButton(true);
    }

    $('.department-schedule-table').floatThead('reflow');
  };

  // *****************************
  // Fix for Avail Shifts overflow
  /**
  * Sets or unsets a class that makes available shifts scrollable.
  */
  var availShiftsScrollable = function(){
    $('#available-shifts-row').closest('.floatThead-container').addClass('overfilled');
    $('.size-row').addClass('overfilled');
  };

  var availShiftsExpanded = function(){
    $('#available-shifts-row').closest('.floatThead-container').removeClass('overfilled');
    $('.size-row').removeClass('overfilled');
  };

  var checkAvailShiftsOverflow = function() {
    if(availShiftsOverfilled(4)){
      availShiftsScrollable();
    } else {
      availShiftsExpanded();
    }
  };

  /**
  * Checks if any of the available shift columns
  * have a certain number of available shifts
  */
  var availShiftsOverfilled = function(overfill_limit) {
    var columns = $('#available-shifts-row').children('th');
    var items_per_col = $.map(columns, (i,v) =>{
      return $(i).children('div').length;
    });
    return (Math.max.apply(Math, items_per_col) >= overfill_limit);
  };

  // End of fix for Avail Shifts overflow
  // *****************************


  // After-create custom Shift: hide modal, locate div placement by date/user and create element
  var createCustomShift = function(shift) {
    $('#shift-modal').modal('hide');
    var $th = $('.floatThead-table').find('th[data-date='+shift.date+']');
    var $td = $('tr.user-row[data-user-id=' + shift.user_id + ']').find('td').eq($th.index());

    var $div = createGlobalShift(true, '');

    $div.addClass('unpublished');

    $td.prepend($div);

    createShiftElement($div, shift, false, true);
    updateBudgets();
  };

  // After-create ShiftRequirement:
  // Locate div by composite keys, remove matching non-persisted ShiftRequirement if present, create/highlight element,
  // and replace create modal with edit modal.
  var createShiftRequirement = function(shiftRequirement) {
    var $th = $('.floatThead-table').find('th[data-date='+ shiftRequirement.date +']');
    if (config.view == 'positions') {
      var $td = $('tr.position-row[data-position-id=' + shiftRequirement.position_id + ']').find('td').eq($th.index());
    } else if (config.view == 'job_sites') {
      var $tr = $('tr.job-site-row[data-job-site-id=' + shiftRequirement.job_site_id + ']');
      $td = $tr.find('td').eq($th.index());

      var required_employees = $tr.data('required-employees');
      $tr.data('required-employees', required_employees + shiftRequirement.required_employees);
      if (Cookies.get('jobSiteSortAttribute') === 'requirements') {
        jobSiteSort.sortJobSitesBySelectedAttribute('requirements');
      }
    }
    var $div = $('<div class="department-10-cal shift-requirement">' + shiftRequirement.label + '</div>');

    var $matchingNonPersistsedShiftRequirement = $td.find('div[data-composite-key=' + shiftRequirement.composite_key + ']');
    if ($matchingNonPersistsedShiftRequirement.length) {
      $matchingNonPersistsedShiftRequirement.replaceWith($div);
    } else {
      $td.append($div);
    }

    var cssClass = 'shift-requirement' + shiftRequirementFillCssClass(shiftRequirement);
    if (!shiftRequirement.published) { cssClass += ' unpublished'; }
    $div.attr('data-shift-requirement-id', shiftRequirement.id);
    $div.data('shift-templates-label', shiftRequirement.shift_type_name);
    $div.data('positions-label', shiftRequirement.position_name);
    $div.data('job-sites-label', shiftRequirement.job_site_name);
    updateScheduleLabels(scheduleItemLabel($div));
    $div.switchClass('department-10-cal', cssClass, config.transitionInterval, 'easeOutQuad', () => {
      $div.trigger('click');
    });
  };

  /*
   * After-update Shift: hide modal/qtip, highlight updated Shift
   */
  var updateShift = function(shift) {
    $('#shift-modal').modal('hide');
    $('div.qtip.position-select').remove();
    var $div = $('div[data-shift-id=' + shift.id + ']');
    var $span = $('div[data-shift-id=' + shift.id + '] span');
    $span.html(shift.label);
    $div.data('paid-duration', shift.paid_duration);
    $div.data('shift-templates-label', shift.shift_type_name);
    $div.data('positions-label', shift.position_name);
    $div.data('job-sites-label', shift.job_site_name);
    updateScheduleLabels(scheduleItemLabel($div));
    $div.effect('highlight', {color: config.highlightColor}, config.highlightInterval);
  };

  /*
   * After-update Unavailable: hide modal/qtip, highlight updated Unavailable
   *
   * Using the data from the response: get new unavailable type name, and the id of the one unavailable edited.
   * Select the one unavailable div. Parse unavailable-request-id data.
   * If unavailable-request-id is present update the selection to be all unavailables of that request.
   * Update all the unavailable(s) divs
   *
   */
  var updateUnavailable = function(unavailable) {
    $('#shift-modal').modal('hide');
    $('div.qtip.unavailable-type-select').remove();

    var selectorId = unavailable.id;
    var selectorDataAttribute = 'unavailable-id';

    $('div[data-' + selectorDataAttribute + '=' + selectorId + ']').each((index, unavailabelDOM) => {
      $(unavailabelDOM).html('<span>' + unavailable.unavailable_type_name + '</span>');
      $(unavailabelDOM).effect('highlight', {color: config.highlightColor}, config.highlightInterval);
    });
  };

  /*
   * After-update ShiftRequirement: hide modal, reset label/css class, highlight updated ShiftRequirement
   */
  var updateShiftRequirement = function(shiftRequirement, hideModal) {
    if (hideModal) { $('#shift-modal').modal('hide'); }
    var $div = $('div[data-shift-requirement-id=' + shiftRequirement.id + ']');
    $div.html(shiftRequirement.label);
    var cssClass = 'shift-requirement' + shiftRequirementFillCssClass(shiftRequirement);
    if (!shiftRequirement.published) { cssClass += ' unpublished'; }
    $div.attr('class', cssClass);
    $div.effect('highlight', {color: config.highlightColor}, config.highlightInterval);
    $div.data('paid-duration', shiftRequirement.paid_duration);
    $div.data('positions-label', shiftRequirement.position_name);
    $div.data('job-sites-label', shiftRequirement.job_site_name);
    updateScheduleLabels(scheduleItemLabel($div));
  };

  /*
   * Generates a css class add on for the ShiftRequirement based on persisted/fill status
   *
   * @example 0/2 => shift-requirement-empty
   * @example 1/2 => shift-requirement-partial
   * @example 2/2 => shift-requirement-filled
   * @example 3/2 => shift-requirement-over
   */
  var shiftRequirementFillCssClass = function(shiftRequirement) {
    if (!shiftRequirement.id) {
      return ' shift-requirement-over';
    } else if (shiftRequirement.shifts_count == shiftRequirement.required_employees) {
      return '';
    } else if (shiftRequirement.shifts_count === 0) {
      return ' shift-requirement-empty';
    } else if (shiftRequirement.shifts_count < shiftRequirement.required_employees) {
      return ' shift-requirement-partial';
    } else {
      return ' shift-requirement-over';
    }
  };

  // After-assign AvailableShift: create Shift div for User
  var assignAvailableShift = function(shift) {
    var $availableShiftDiv = $('div.available-shift[data-available-shift-id=' + shift.available_shift_id + ']');
    var $availableShiftTd = $availableShiftDiv.closest('th');
    var $userTr = $('tr.user-row[data-user-id=' + shift.user_id + ']');
    var $shiftTd = $userTr.find('td').eq($availableShiftTd.index());
    var $shiftDiv = createGlobalShift(true,'shift');

    $shiftTd.prepend($shiftDiv);
    createShiftElement($shiftDiv, shift, false, false);

    ga('send','event', makeshiftGoogleAnalytics.categories.availableShift, makeshiftGoogleAnalytics.actions.assign, makeshiftGoogleAnalytics.label.byAvailability);
  };

  // After-approve UnavailableRequest: Switch class of pending request Unavailables to normal Unavailables, hide modal
  var approveUnavailableRequest = function(unavailableId, unavailableTypeName) {
    $('div[data-unavailable-id=' + unavailableId + ']').each(function(index) {
      var $unavailable = $(this);
      $unavailable.switchClass('unavailable-request', 'unavailable');
      $unavailable.effect('highlight', {color: config.highlightColor}, config.highlightInterval);
      $unavailable.find('strong').text(unavailableTypeName);
    });
    $('#shift-modal').modal('hide');
  };

  /*
   * Handles div creation for a successfully created Shift, via drop or available Shift assignment
   *
   * @param {Object}  $div            Shift div object
   * @param {Object}  shift           Shift
   * @param {Boolean} showPositionTip True if Shift requires position selection
   * @param {Boolean} isUnpublished   True if caller is creating a known unpublished Shift
   */
  var createShiftElement = function($div, shift, showPositionTip, isUnpublished) {
    var label =  ($div.children('span').children('span').length > 0) ? $div.children('span').innerHTML : shift.label;

    $div.children('span').html(label);
    // Remove same-date UserAvailabilities and Unavailables from dom, mirroring Shift#after_save callback that destroys them
    var dayAvailability = $div.closest('td').find('div.availability');
    if (dayAvailability.length > 0) {
      ga('send','event',makeshiftGoogleAnalytics.categories.departmentCalendar, makeshiftGoogleAnalytics.actions.drag, makeshiftGoogleAnalytics.label.shiftOnAvailability);
    }

    $div.attr('data-shift-id', shift.id);
    $div.data('paid-duration', shift.paid_duration);
    $div.data('shift-templates-label', shift.shift_type_name);
    $div.data('positions-label', shift.position_name);
    $div.data('job-sites-label', shift.job_site_name);

    applyHeightClassToShift($div);

    // Show shift position selector if required
    // Update hours for row and queue for notification if required
    // @note Callback on transition is needed to ensure transition class has stuck for later selectors
    $div.switchClass('department-10-cal', 'shift', config.transitionInterval, 'easeOutQuad', () => {
      if (showPositionTip) {
        createPositionTip($div, shift.id);
      }
      updateHours($div.closest('tr'));
      updateScheduleLabels(scheduleItemLabel($div));
    });
    togglePublishButton();
  };

  /*
   * Handles div creation for a successfully created Unavailable
   *
   * @param {Object} $div             Unavailable div object
   * @param {Integer} id              Unavailable ID
   */
  var createUnavailableElement = function($div, id) {
    // Remove same-date UserAvailabilities from dom, mirroring Unavailable#after_save callback that destroys them
    var dayAvailability = $div.closest('td').find('div.availability');
    if (dayAvailability.length > 0) {
      ga('send','event',makeshiftGoogleAnalytics.categories.departmentCalendar, makeshiftGoogleAnalytics.actions.drag, makeshiftGoogleAnalytics.label.shiftOnAvailability);
    }

    // Assign required data attrs to div
    $div.attr('data-unavailable-id', id);
    $div.switchClass('department-10-cal', 'unavailable', config.transitionInterval, 'easeOutQuad').removeClass('unpublished');
  };

  // Renders a position selector qtip for Shifts created via drop
  var createPositionTip = function($div, shiftId) {
    $div.qtip({
      content: {
        text: function(event, api) {
          $.get(config.routes.editShiftPositionPath(shiftId))
            .then(html => {
              api.set('content.text', html);
            }, (xhr, status, error) => {
              api.set('content.text', status + ': ' + error);
            });
          return '<span class="muted" style="font-size:10px;">' + I18n.t('department_schedules.js.loading') + '</span>';
        }
      },
      position: {
        my: 'center left',
        at: 'center right',
        viewport: $('.department-schedule-table'),
        adjust: { x: -10, method: 'flip none'}
      },
      show: true,
      hide: 'unfocus',
      style: {
        classes: 'qtip-bootstrap position-select'
      },
      events: {
        // readjust x on flip
        move: function(event, api, position, viewport) {
          if (position.adjusted.left !== 0) { return position.left += 20; }
        },
        // submit tooltip select position, and job site
        hide: function(event, api) {
          $(this).find('form').submit();
        }
      }
    });
  };

  // Renders qtip with a table of multiple availabilities
  var createAvailabilitiesTip = function($div, userId, date) {
    $div.qtip({
      content: {
        text: function(event, api) {
          $.get(config.routes.departmentAvailabilitiesPath(userId, date))
            .then(html => {
              api.set('content.text', html);
            }, (xhr, status, error) => {
              api.set('content.text', status + ': ' + error);
            });
          return '<span class="muted" style="font-size:10px;">' + I18n.t('department_schedules.js.loading') + '</span>';
        }
      },
      position: {
        my: 'center left',
        at: 'center right',
        viewport: $('.department-schedule-table'),
        adjust: { x: -10, method: 'flip none'}
      },
      show: {
        event: 'mouseover',
        solo: true
      },
      hide: {when: {event:'mouseout unfocus'}, fixed: true, delay: 200},
      style: {
        classes: 'qtip-bootstrap qtip-availabilities-wrapper'
      },
      events: {
        // readjust x on flip
        move: function(event, api, position, viewport) {
          if (position.adjusted.left !== 0) { return position.left += 20; }
        },
      }
    });
  };

  /*
   * Post-destroy events: remove event div, hide modal, optionally recalculate total hours and change publish button state
   * @param  {String} eventType     Event class name
   * @param  {Integer} id           Event ID
   * @param  {Boolean} isPublished  For Shifts only, to determine GA call
   */
  var destroyEvent = function(eventType, id, isPublished) {

    switch(eventType) {
    case 'shift-requirement':
      $('div[data-shift-requirement-id=' + id + ']').fadeOut(config.transitionInterval, function() {
        $(this).remove();
        togglePublishButton();
        updateBudgets(); // Update estimated dollars/planned hours on positions/job sites schedules
      });
      break;

    case 'shift':
      $('div[data-shift-id=' + id + ']').fadeOut(config.transitionInterval, function() {
        var shift, $self = $(this), $tr = $self.closest('tr');
        $self.remove();
        updateHours($tr);
        updateBudgets();
        togglePublishButton();

        var gaEventLabel = isPublished ? makeshiftGoogleAnalytics.label.published_shift : makeshiftGoogleAnalytics.label.unpublished_shift;
        ga('send','event', makeshiftGoogleAnalytics.categories.departmentCalendar, makeshiftGoogleAnalytics.actions.destroy, gaEventLabel);
      });
      break;

    case 'available-shift':

      $('div[data-available-shift-id=' + id + ']').fadeOut(config.transitionInterval, function() {
        $(this).remove();
        $('.department-schedule-table').floatThead('reflow');
        checkAvailShiftsOverflow();
      });
      break;

    case 'unavailable':
      var ids = $.isArray(id) ? id : [id]; // An Unavailable may be part of a group, as part of an UnavailableRequest
      $.each(ids, (i, id) => {
        $('div[data-unavailable-id=' + id + ']').fadeOut(config.transitionInterval, function() {
          $(this).remove();
        });
      });
      break;

    case 'unavailable-request':
      $('div[data-unavailable-request-id=' + id + ']').fadeOut(config.transitionInterval, function() {
        $(this).remove();
      });
      break;
    }

    $('#shift-modal').modal('hide');
  };

  // Adds a 'range' class when mousing over the date picker
  var mouseOverHighlightWeek = function() {
    $('body').on('mouseover', '.department-toolbar .datepicker-days tr', function(){
      var $tds = $(this).find('td');
      $.each($tds, function(){
        $(this).addClass('range');
      });
    }).on('mouseout', '.department-toolbar .datepicker-days tr', function(){
      var $tds = $(this).find('td');
      $.each($tds, function(){
        $(this).removeClass('range');
      });
    });
  };

  // Adds an active class to the current week to show what week is currently selected
  var setActiveWeekSelection = function() {
    var weekDays = $('.day.active').siblings();
    colorActiveWeek(weekDays);
  };

  // Adds active class to all days of the week
  var colorActiveWeek = function(days) {
    days.each(function(){
      $(this).addClass('active');
    });
  };

  // Updates total hours on a user row when an assigned Shift is created or destroyed
  var updateHours = function($row) {
    var $hoursContainer = $row.find('td:first span.hours');
    if ($hoursContainer.length) {
      var totalPaidDuration = 0;
      $row.find('td div.shift, td div.other-department-shift').each(function() {
        totalPaidDuration += parseInt($(this).data('paid-duration'));
      });
      $hoursContainer.html(msUtil.durationToHours(totalPaidDuration, true));
      if ( config.overtimeThreshold && ((totalPaidDuration/3600) > config.overtimeThreshold) ) {
        $hoursContainer.addClass('overtime-hours');
      } else {
        $hoursContainer.removeClass('overtime-hours');
      }
    }
  };

  /**
   * Updates the state of the Publish button based on the existence of unpublished Shifts
   * @param  {Boolean} hasUnpublishedShifts Use if set by calling method, otherwise query
   */
  var togglePublishButton = function(hasUnpublishedShifts) {
    if (hasUnpublishedShifts !== undefined) {
      $('#publish-all-shifts, #publish-range-shifts, #publish-current-week-shifts').prop('disabled', !hasUnpublishedShifts);
    } else {
      $.ajax({ url: config.routes.unpublishedDepartmentShiftsPath, dataType: 'json' })
        .then(data => {
          $('#publish-all-shifts, #publish-range-shifts, #publish-current-week-shifts').prop('disabled', !data.exists);
        });
    }
  };


  /**
   * removing the repetetive creation of shifts with very similar structure and minor class changes.
   * Will create the appropriate shift structure in one centralized location.
   *
   * @param {Boolean} isDraggable marks if the shift div is draggable or not.
   * @param {String} shiftTypeLabel The label that will appear inside the span.
   * @param {integer} availableShiftid The shift id for the div.
   *
   */

  var createGlobalShift = function(isDraggable,shiftTypeLabel) {

    var $div = $('<div class="department-10-cal drag-revert" ></div>');
    var $span = $('<span>' + shiftTypeLabel + '</span>');

    if (isDraggable) {
      $div.attr('id', 'move_shift');
      $div.draggable({
        revert: function() {
          if ($(this).hasClass('drag-revert')) return true;
          $(this).data('uiDraggable').originalPosition = {
            top : 0,
            left : 0
          };
        }, // when dropped on invalid target, the item will revert back to its initial position
        containment: 'document',
        cursor: null,
        opacity: 0.8,
        start: function(event, ui) {
          // Don't leave position/job site tooltips hanging if we're about to drag off somewhere else
          if ($(ui.helper[0]).data().qtip) {
            $(ui.helper[0]).data().qtip.destroy();
          }
        }
      }).on('click' , e => {
        e.preventDefault();
      });
    }

    $div.append($span);
    return $div;
  };

  /**
   * Helper to toggle the copy/paste functionality
   */
  var toggleCopyFunctionality = function() {
    var inPast = moment(copyPaste.targetWeekStartDate).add(1, 'week').subtract(1,'second') < moment(weekData.current_date);

    /**
     * Specific to the copy/paste buttons
     */
    if (copyPaste.copiedSourceWeekStartDate) {
      var isSameWeek = copyPaste.targetWeekStartDate === copyPaste.copiedSourceWeekStartDate;
      if (inPast || isSameWeek) {
        $('#department-schedule-paste-week').addClass('disabled');
      } else {
        $('#department-schedule-paste-week').removeClass('disabled');
      }
    } else {
      $('#department-schedule-paste-week').addClass('disabled');
    }

    /**
     * Specific to repeat week button
     */
    if (inPast) {
      $('#department-schedule-repeat-week').addClass('disabled');
    } else {
      $('#department-schedule-repeat-week').removeClass('disabled');
    }
  };

  /**
   * Helper method to block click events on the view.
   * @param  {Boolean} show               Condition of if it should show the modal or not.
   * @param  {String} primary_selectors   Primary selector as a string to be used in a jQuery lookup.
   * @param  {String} message             Secondary selectors as a string to be used in a jQuery lookup.
   * @param  {String} secondary_selectors Secondary selectors as a string to be used in a jQuery lookup.
   */
  var toggleBlockDisplay = function(show, primary_selector, message, secondary_selectors) {
    if (show) {
      var blockSettings = {
        message: null,
        baseZ: 2e9,
        css: {
          border: 'none',
          padding: '25px 15px',
          backgroundColor: '#000',
          '-webkit-border-radius': '2px',
          '-moz-border-radius': '2px',
          opacity: 0.75,
          color: '#fff'
        },
        overlayCSS: {
          backgroundColor: 'transparent',
          cursor: null,
        }
      };
      $(secondary_selectors).block(blockSettings);

      if (message) {
        blockSettings['message'] = message;
      }

      $(primary_selector).block(blockSettings);

    } else {
      $(primary_selector + ', ' + secondary_selectors).unblock();
    }
  };

  /**
   * Setup Pusher to block/unblock schedule editing during shift premium calculations.
   */
  var initPusherDepartmentNotifs = function() {
    if (!config.companyId || !config.departmentId) {
      console.log(
        'Pusher missing company (' +
          config.companyId +
          ') or department (' +
          config.departmentId +
          ')!'
      );
      return;
    }

    var pusher = window.getPusherInstance(config.pusherKey);
    var channelName = 'department_notification_' + config.companyId + '_' + config.departmentId;
    var pusherChannel = pusher.subscribe(channelName);

    pusherChannel.bind('schedule_lock', data => {
      blockScheduleForProcessing(data.message);
    });

    pusherChannel.bind('schedule_unlock', data => {
      unblockScheduleForProcessing();
    });

    pusherChannel.bind('department_schedule.shift.success', data => {
      onShiftUpdateSuccess(data);
    });

    pusherChannel.bind('department_schedule.shift.error', data => {
      onShiftUpdateError(data);
    });
  };

  // Check if schedule is currently blocked
  // https://stackoverflow.com/questions/7907013/jquery-blockui-tell-if-page-or-specific-element-is-blocked
  var isScheduleBlocked = function() {
    return $('#department-schedule-box').data()['blockUI.isBlocked'] == 1;
  };

  /**
   * Block the schedule UI from being edited.
   */
  var blockScheduleForProcessing = function() {
    toggleBlockDisplay(true, '#department-schedule-box', I18n.t('department_schedules.show.schedule_processing.message'),'#shift-types-box, #drag-and-drop-box');
  };

  /**
   * Unblock the schedule UI from being edited.
   */
  var unblockScheduleForProcessing = function() {
    toggleBlockDisplay(false, '#department-schedule-box', I18n.t('department_schedules.show.schedule_processing.message'),'#shift-types-box, #drag-and-drop-box');
  };

  var initSidebar = function() {

    $(() => {
      $('#schedule-side-bar #side-bar-shift-templates').show();
    });

    $('#schedule-side-bar-content-toggle a.toggle-link').on('click', function(e) {

      e.preventDefault();

      var hiddenLink = $(this).siblings('a.hidden-link');
      var toggleTarget = $(this).attr('href');
      var toggleTitle = $(this).text();

      $('#schedule-side-bar-content-toggle .dropdown-toggle h4').text(toggleTitle);
      $('#schedule-side-bar .side-bar-panel').hide();
      $('#schedule-side-bar #side-bar-edit-link').attr('href', hiddenLink.attr('href'));
      $(toggleTarget).show();
    });
  };

  var sumRowRequiredEmployees = function(shift_requirements) {
    $('tr.job-site-row').data('required-employees', 0); // reset all the rows to 0

    _.each(shift_requirements, shift_requirement => {
      var jobSiteRow = $('tr.job-site-row[data-job-site-id=' + shift_requirement.job_site_id + ']');

      jobSiteRow.data('required-employees', jobSiteRow.data('required-employees') + shift_requirement.required_employees);
    });

    if (Cookies.get('jobSiteSortAttribute') === 'requirements') {
      jobSiteSort.sortJobSitesBySelectedAttribute('requirements');
    }
  };

  // Can pass an optional jQuery object to update
  // If nothing is passed, ALL schedule labels are updated
  var updateScheduleLabels = function($labels) {
    scheduleLabels.updateScheduleLabels({
      labels: $labels,
      departmentId: config.departmentId,
      jobSitesEnabled: config.jobSitesEnabled,
    });
  };

  var getBudgetMode = function() {
    return localStorage.getItem('BudgetBar.mode') || 'dollars';
  };

  var setBudgetMode = function(mode) {
    localStorage.setItem('BudgetBar.mode', mode);
  };

  var initWebSocketListeners = function() {
    config.privatePusherChannel.bind('department-schedules.publish.failure', data => {
      $.growl.error({
        title: 'Error',
        message: data.message,
        duration: 5400
      });
    });

    config.privatePusherChannel.bind('department-schedules.publish.success', data => {
      var unpublishedShiftsExist  = data.unpublished_shifts_exist;
      var publishedAt             = data.department_published_at;
      var startDate               = $('#publishStartDate').val();
      var endDate                 = $('#publishEndDate').val();
      var weekStartsOn            = moment(config.beginningOfWeek)._d;
      var weekEndsOn              = moment(weekStartsOn).add(6, 'days')._d;
      var publishStartsOn         = startDate ? moment(startDate)._d : weekStartsOn;
      var publishEndsOn           = endDate ? moment(endDate)._d : weekEndsOn;

      // refresh the schedule
      if ((publishStartsOn <= weekEndsOn)  &&  (publishEndsOn >= weekStartsOn)) {
        var rowPosition = departmentSchedule.getCurrentRowPosition();
        loadSchedule(null, rowPosition);
      }

      // close dropdown menu if open
      // lastPublishButtonClicked *may not be defined* if you clicked the publish button in a separate browser
      if (lastPublishButtonClicked && (lastPublishButtonClicked.attr('id') == 'publish-all-shifts' || lastPublishButtonClicked.attr('id') == 'publish-range-shifts')) {
        $('button[data-toggle=dropdown]').dropdown('toggle');
      }

      toggleBlockDisplay(true, '#department-schedule-box', '<i class="fa fa-check fa-4x"></i><br/><h4>'+ I18n.t('department_schedules.show.publish.success.message') +'</h4>');

      setTimeout(() => {
        toggleBlockDisplay(false, '#department-schedule-box');
        togglePublishButton(unpublishedShiftsExist);
        if (lastPublishButtonClicked) {
          lastPublishButtonClicked.text(lastPublishButtonText);
        }
        config.selectors.lastPublishedAt.html(publishedAt);
      }, 1500);
    });

    // Listen for copy/paste update message
    const pusher = window.getPusherInstance(config.pusherKey);
    const pusherChannel = pusher.subscribe('user-' + config.pusherUserId);
    pusherChannel.bind('department_schedule.updated', data => {
      msUtil.spinner('department-schedule-box').stop();
      loadSchedule();
    });
  };

  // ********************************************************//
  // ** START of methods for handling ShiftExportAttempts ** //

  // The passed function can only be called once the schedule is unblocked
  // Likely something arriving from a pusher message, which can be sent to us whenever
  // If we're in the middle of a schedule publish, for example,
  // our shifts may not exist in the DOM at the moment
  // Wait until the schedule is finished and then reprocess
  var waitingOnScheduleUnblocked = function(call_when_unblocked, arg) {
    if (!isScheduleBlocked()) {
      return false;
    }

    // Schedule is blocked
    // Wait another second before attempting again
    // Keep waiting until we're not blocked!
    setTimeout(() => {
      call_when_unblocked(arg);
    }, 1000);

    return true;
  };

  var onShiftUpdateSuccess = function(data) {
    if (waitingOnScheduleUnblocked(onShiftUpdateSuccess, data)) {
      return;
    }

    // remove lock
    var lock_identifier = '.shift[data-shift-id=' + data.shift_id + '] i.shift-lock-label';
    var locks = $(lock_identifier);
    locks.remove();

    // remove warning sign
    var warning_identifier = '.shift[data-shift-id=' + data.shift_id + '] i.fa-exclamation-triangle';
    var warning = $(warning_identifier);
    warning.remove();

    var shift = weekData.shifts.find(shift =>{ return shift.id == data.shift_id; });
    if (shift) {
      shift.failed = false;
    }

    if (locks.length > 0) {
      // TODO: This function call spams us with an AJAX request *per returned shift export attempt*
      // If we pubished a schedule with, say, 100 shifts in it, we'd make basically the same request 100 times
      // I'm not entirely clear on how this was intended to work, so for now I'm just commenting it out
      // and we can add it back when there's a better understanding of when/how to update the shift sync audit button state
      // loadErrorShiftExportAttemptsCount();
    } else {
      shiftSyncAuditButtonClear();
    }

    flashShiftExportError();
  };

  var onShiftUpdateError = function(data) {
    if (waitingOnScheduleUnblocked(onShiftUpdateError, data)) {
      return;
    }

    // remove lock
    var lock_identifier = '.shift[data-shift-id=' + data.shift_id + '] i.shift-lock-label';
    $(lock_identifier).remove();

    // add warning sign
    var warning_identifier = '.shift[data-shift-id=' + data.shift_id + '] .time-label';
    var warning = $(warning_identifier);
    var text = warning.text();

    var shift = weekData.shifts.find(shift =>{ return shift.id == data.shift_id; });
    if (shift) {
      shift.failed = true;
    }

    shiftSyncAuditButtonWarn();

    $(warning_identifier).html('<i class=\'fa fa-exclamation-triangle\'></i>' + text);
    flashShiftExportError();
  };

  var flashShiftExportError = function(){
    var num_errors = numFailedShifts();

    if (num_errors == 0){
      clearFlashShiftExportError();
    } else {
      showFlashShiftExportError(num_errors);
    }
  };

  var showFlashShiftExportError = function(num_errors) {
    if (num_errors == 1){
      var msg = I18n.t('integrations.shift_exporter.user_error_banner_single_html', { 'link': shiftExportAttemptsPath()});
    } else if (num_errors > 1){
      msg = I18n.t('integrations.shift_exporter.user_error_banner_plural_html', {'num_errors': num_errors, 'link': shiftExportAttemptsPath()});
    }

    if ($('#flash-messages .shift-exporter').length > 0){
      $('#flash-messages .shift-exporter span').html(msg);
    } else {
      var div = '<div class=\'alert alert-error shift-exporter\'><a class=\'close\' data-dismiss=\'alert\'>×</a><span>' + msg + '</span></div>';
      $('#flash-messages').append(div);
    }
  };

  var openModalWithSpinner = function(modalElement) {
    modalElement.html('<div id="modal-loader"></div>');
    modalElement.modal('show');
    msUtil.spinner('modal-loader');
  };

  var displayModalLoadError = function(modalElement) {
    modalElement.find('#modal-loader').html('Sorry, there was an issue getting this data.');
  };

  var shiftExportAttemptsPath = function(num_errors) {
    var url = config.routes.shiftExportAttemptsPath($('#publishStartDate').val());
    return '<a href=\'' + url + '\'>' + I18n.t('adp.shift_exporter.shift_sync_audit') + '</a>';
  };

  var clearFlashShiftExportError = function(num_errors) {
    $('#flash-messages .shift-exporter').remove();
  };

  var updateShiftSyncAuditDates = function() {
    $('#shift_sync_audit').attr('href',config.routes.shiftExportAttemptsPath($('#publishStartDate').val()));
  };

  var shiftSyncAuditButtonWarn = function() {
    // add warnging sign to shift sync audit button
    $('#shift_sync_audit').html('<i class=\'fa fa-exclamation-triangle\'></i> ' + I18n.t('adp.shift_exporter.shift_sync_audit'));
  };

  var shiftSyncAuditButtonClear = function() {
    // remove warnging sign on shift sync audit button
    $('#shift_sync_audit i.fa-exclamation-triangle').remove();
  };

  var numFailedShifts = function() {
    return weekData.shifts.filter(shift =>{return shift.failed;}).length;
  };

  // ** END of methods for handling ShiftExportAttempts ** //
  // ******************************************************//

  // Enable or disable date navigation
  // We can end up with doubled/needless requests if people double-click
  // through the schedule. We disable navigation while the schedule loads,
  // then enable it again when loading is complete.
  var canDateNavigate = function(allow) {
    var disabled = !allow;
    $('#previous-week').toggleClass('disabled', disabled);
    $('#next-week').toggleClass('disabled', disabled);
    $('#today').toggleClass('disabled', disabled);
    $('#toolbar-datepicker').toggleClass('disabled', disabled);
  };

  return {
    init: init,
    updateShiftRequirement: updateShiftRequirement,
    togglePublishButton: togglePublishButton,
    loadSchedule: loadSchedule,
    blockScheduleForProcessing: blockScheduleForProcessing,
    getCurrentRowPosition: getCurrentRowPosition,
    getBudgetMode: getBudgetMode,
    setBudgetMode: setBudgetMode
  };

})(jQuery, window, document);

window.departmentSchedule = departmentSchedule;
