const PermissionsVue = Vue.extend({
  errors: [],
  data() {
    return {
      selectedPermissionData: null,

      fields: {
        department_id: {
          data: "department_id",
          visible: 0
        },
        location_name: {
          data: "location_name",
          visible: 0
        },
        department_name: {
          title: '<div>Location / Department</div>',
          data: "department_name",
          width: "70%",
          sortable: false
        },
        manageable: {
          title: '<div>Manager</div>',
          data: "manageable",
          width: "15%",
          sortable: false
        },
        schedulable: {
          title: '<div>On Schedule</div>',
          data: "schedulable",
          width: "15%",
          sortable: false
        },
        user_id: {
          data: "user_id",
          visible: 0
        }
      },
    }
  },
  methods: {
    handleScheduleCheckbox(data, row, tr, target) {
      this.selectedPermissionData = data;
      if (data.schedulable) {
        $('#department-users-modal').modal('show');
        return;
      } else {
        this.handleSchedule();
      }
    },

    handleSchedule() {
      let url;
      if (this.selectedPermissionData.schedulable) {
        url = '/users/' + this.selectedPermissionData.user_id + '/departments/' + this.selectedPermissionData.department_id + '/unschedule';
      } else {
        url = '/users/' + this.selectedPermissionData.user_id + '/departments/' + this.selectedPermissionData.department_id + '/schedule';
      }

      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        data: {
          user_id: this.selectedPermissionData.user_id,
          id: this.selectedPermissionData.department_id,
        },
        error: (xhr) => {
          if (xhr.responseJSON.error_message) {
            this.errors = [xhr.responseJSON.error_message];
          } else {
            this.errors = [xhr.responseJSON.error];
          }

          $.each(this.errors, function(i, error) {
            $.growl.error({
              title: 'Error',
              message: error,
              duration: 5400,
            });
          });
        },
        success: (xhr) => {
          this.errors = [];
          this.$refs.managedDatatable.reload();
          this.$refs.otherDatatable.reload();
        },
      });
    },
    handleManage(data, row, tr, target) {
      let url;
      if (data.manageable) {
        url = '/users/' + data.user_id + '/departments/' + data.department_id + '/unmanage';
      } else {
        url = '/users/' + data.user_id + '/departments/' + data.department_id + '/manage';
      }

      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        data: {
          user_id: data.user_id,
          id: data.department_id
        },
        error: (xhr) => {
          if (xhr.responseJSON.error_message) {
            this.errors = [xhr.responseJSON.error_message];
          } else {
            this.errors = [xhr.responseJSON.error];
          }

          $.each(this.errors, function(i, error) {
            $.growl.error({
              title: 'Error',
              message: error,
              duration: 5400
            });
          });
        },
        success: (xhr) => {
          this.errors = [];
          this.$refs.managedDatatable.reload();
          this.$refs.otherDatatable.reload();
        },
      });
    }
  },
});

window.PermissionsVue = PermissionsVue;

export default PermissionsVue;
