<template>
  <header class="navbar">
    <a href="/">
      <img class="h-5" :src="`/assets/${currentBrand}-logo.png`" />
      <span v-if="appLogoText" class="text-gray-500 hover:text-gray-500">{{ appLogoText }}</span>
    </a>
    <div v-if="userSignedIn" class="flex">
      <a v-if="qaControls" class="navbar-link" href="/admin/wa/messaging">
        {{I18n.t('admin.authenticated.nav.qa')}}
      </a>
      <a class="navbar-link" href="/admin/analytics">
        {{I18n.t('admin.authenticated.nav.analytics')}}
      </a>
      <a class="navbar-link" href="/admin/employment_types">
        {{I18n.t('admin.authenticated.nav.employment_types')}}
      </a>
      <a class="navbar-link" href="/admin/companies">
        {{I18n.t('admin.authenticated.nav.companies')}}
      </a>
      <a class="navbar-link" href="/admin/broadcasts">
        {{I18n.t('admin.authenticated.nav.broadcasts')}}
      </a>
      <a class="navbar-link" href="/admin/revenue">
        {{I18n.t('admin.authenticated.nav.revenue')}}
      </a>
      <span class="relative">
        <button
          class="navbar-link"
          @click="open = !open"
          @blur="open = false"
          @keydown.esc="open = false"
        >
          <span>{{ currentUserName }} <i class="fa fa-caret-down"></i></span>
        </button>
        <div v-if="open" class="dropdown">
          <h4 class="text-gray-500 text-base text-center">{{ currentUserName }}</h4>
          <a
            v-if="!disableSupportLink"
            class="px-3 py-1 text-gray-700 font-semibold hover:bg-gray-200"
            href="https://support.makeshift.ca"
          >
            <i class="fa fa-question mr-1 text-gray-400"></i>
            {{I18n.t('admin.authenticated.nav.support')}}
          </a>
          <hr class="my-3"/>
          <a
            class="px-3 py-1 text-gray-700 font-semibold hover:bg-gray-200"
            href="/users/sign_out"
            data-method="delete"
          >
            {{I18n.t('admin.authenticated.nav.logout')}}
          </a>
        </div>
      </span>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TopNavigation',
  props: {
    currentUserName:    String,
    currentBrand:       String,
    appLogoText:        String,
    userSignedIn:       Boolean,
    qaControls:         Boolean,
    enableRevenuePage:  Boolean,
    disableSupportLink: Boolean,
  },
  data() {
    return {
      open: false,
    }
  },
}
</script>

<style lang="scss" scoped>
  .navbar {
    @apply #{ flex justify-between items-center h-12 px-5 outline-none };
    background-image: -webkit-linear-gradient(top, #1b2329, #11161a);
  }

  .navbar-link {
    @apply #{ mr-3 px-2 py-1 rounded bg-black text-xs };
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(255, 255, 255, 0.15);
    color: #999;

    &:hover {
      @apply #{ text-white };
    }
  }

  .dropdown {
    @apply #{ absolute flex flex-col left-auto w-full bg-white rounded-md border border-gray-500 py-2 shadow-md };
    z-index: 1100;

    &:after {
      @apply #{ absolute left-auto inline-block };
      content: '';
      top: -6px;
      right: 13px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
    }
  }
</style>
