window.maps = (function($) {
  /*
  public
  */

  var MAP_ZOOM = 17;
  // Default settings
  var config = {};

  /*
    Initialize Google Map and Search Bar
  */
  var init = function(settings) {
    $.extend(config, {
      latitude: settings.latitude,
      longitude: settings.longitude,
      selectors: {
        latitudeField: $('#hidden_latitude_field'),
        longitudeField: $('#hidden_longitude_field'),
        addressField: $('#location-search')
      }
    });

    initMap();
    initSearchBar();

    // Set value of lat/lng to nil when user clicks turn off geolocation
    $('#turn-off-geolocation-btn').on('click', () => {
      config.selectors.latitudeField.val(null);
      config.selectors.longitudeField.val(null);
      config.selectors.addressField.val(null);
      // $("#department_geofence_radius").val(null);
    });

    //Disable enter key from submitting form (location-search text box interferes with turn off geolocation and update department button)
    $('#location-search').keypress(event => {
      return event.keyCode != 13;
    });
  };

  /*
    Returns the approximate address based on coordinates provided

    @param: [Map::LatLng] coordinates
  */
  var reverseGeocode = function(settings) {
    var latitude = settings.latitude;
    var longitude = settings.longitude;
    var addressField = settings.addressField;
    var coordinates = new google.maps.LatLng(latitude, longitude);
    var geocoder = new google.maps.Geocoder();

    geocoder.geocode({ latLng: coordinates }, (results, status) => {
      if (google.maps.GeocoderStatus.OK && results[0]) {
        $(addressField).text(results[0].formatted_address);
        $(addressField).val(results[0].formatted_address);
      }
    });
  };

  /*
  private
  */

  /*
    Setup Google Maps
  */
  var initMap = function() {
    defaultMap();

    // Click event for current location button
    $('#current-location-btn').on('click', () => {
      initCurrentLocation();
    });
  };

  /*
    Setup Search Bar
  */
  function initSearchBar() {
    var input = document.getElementById('location-search');
    var autocomplete = new google.maps.places.Autocomplete(input);

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      var place = autocomplete.getPlace();
      showSearchBarLocation(place);
    });
  }

  /*
    If browser is compatible with HTML5 geolocation display current location

    @note: Requires user-browser permission
  */
  var initCurrentLocation = function() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showCurrentLocation, handleGeolocationError);
    }
  };

  /*
    On initial page load, map location defaults to a previously set location (department lat/lng) or the map of the world.
  */
  var defaultMap = function() {
    var latitude = config.latitude;
    var longitude = config.longitude;

    var coordinates;
    if (latitude == '' && longitude == '') {
      coordinates = new google.maps.LatLng(0, 0);
      showMap(coordinates, 2, false);
    } else {
      coordinates = new google.maps.LatLng(latitude, longitude);
      showMap(coordinates, MAP_ZOOM, true);
    }
  };

  /*
    Geolocation error handler. When geolocation fails to retrieve your current location.

    @param: [Map::Error] error
  */
  var handleGeolocationError = function(error) {
    switch (error.code) {
    case error.PERMISSION_DENIED:
      alert(I18n.t('maps.js.permission_denied'));
      break;
    case error.POSITION_UNAVAILABLE:
      alert(I18n.t('maps.js.position_unavailable'));
      break;
    case error.TIMEOUT:
      alert(I18n.t('maps.js.timeout'));
      break;
    default:
      alert(I18n.t('maps.js.unknown_error'));
      break;
    }
  };

  /*
    Displays the users current location in map

    @param: [Map::LatLng] position   Coordinates of current location
  */
  var showCurrentLocation = function(position) {
    // Coordinates of current location
    var coordinates = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

    // Set lat/long textbox values with current location coordinates
    updateLatLngText(position.coords.latitude, position.coords.longitude);

    showMap(coordinates, MAP_ZOOM, true);
  };

  /*
    Displays any place searched for via search box

    @param: [Map::Place] place
  */
  var showSearchBarLocation = function(place) {
    if (!place.geometry) {
      return;
    }

    showMap(place.geometry.location, MAP_ZOOM, true);
  };

  /*
    Displays the map with map options, marker, and location of the coordinates provided

    @param: [Map::LatLng] coordinates
    @param: [Integer] zoom
    @param: [Boolean] showMarker
  */
  var showMap = function(coordinates, zoom, showMarker) {
    var options = setOptions(coordinates, zoom);
    var map = createMap('map-canvas', options);

    if (showMarker) {
      var marker = createMarker(map, coordinates);
      markerListener(marker);
    }

    if (coordinates.lat() != 0.0 && coordinates.lng() != 0.0) {
      updateLatLngText(coordinates.lat(), coordinates.lng());
      showGeofence(map, coordinates);
    }
  };

  var showGeofence = function(map, coordinates) {
    var geofenceOptions = {
      strokeColor: '#FF000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.05,
      map: map,
      center: coordinates,
      radius: MKS.geofence_radius
    };

    new google.maps.Circle(geofenceOptions);
  };

  /*
    Set general map options and center map to coordinates

    @param: [Map::LatLng] coordinates
    @param: [Integer] zoom
  */
  var setOptions = function(coordinates, zoom) {
    return {
      zoom: zoom,
      center: coordinates,
      navigationControlOptions: {
        style: google.maps.NavigationControlStyle.SMALL
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      panControl: false,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false
    };
  };

  /*
    Create new google maps with options

    @param: [String] mapId
    @param: [Hash] options
  */
  var createMap = function(mapId, options) {
    return new google.maps.Map(document.getElementById(mapId), options);
  };

  /*
    Create marker on map based on coordinates

    @param: [Map] map
    @param: [Map::LatLng] coordinates
  */
  var createMarker = function(map, coordinates) {
    return new google.maps.Marker({
      draggable: true,
      position: coordinates,
      map: map
    });
  };

  /*
    Event handler for dragging markers in the map

    @param: [Map::Marker] marker
  */
  var markerListener = function(marker) {
    google.maps.event.addListener(marker, 'drag', event => {
      updateLatLngText(event.latLng.lat(), event.latLng.lng());
    });
  };

  /*
    Updates lat/lng textbox fields
  */
  var updateLatLngText = function(lat, lng) {
    config.selectors.latitudeField.val(lat);
    config.selectors.longitudeField.val(lng);
  };

  return {
    init: init,
    reverseGeocode: reverseGeocode
  };
})(jQuery);
