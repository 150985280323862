export var ledgerTableItem = function(tableElement, tableData, type, deptTotals, timeAndAttendance){
  /*

  Role: Creates and instantiates a table (dataTables.js)

  A ledgerTableItem contains:
  ---> a single dataTable (either for position or employee)
  ---> each row in the table reveals any children it has
  ---> table is paginated (part of dataTable.js)
  ---> bottom row of the table is a summed total for the dept.

  @params:
    tableElement: [Element] a HTML table to bind the dataTable to
    tableData: [Array] data from the backend to populate the table
    type: [String] "position" or "employee"
    deptTotals: [JSON object] an object containing a department's totals.

  */

  // Returns formatted columns for dataTables.js
  //
  // @note: First column varies based on "type" (position or employee)
  //
  // @return: [JSON Object] An object of column names (sTitle) and index values (mData)
  function createTableColumns(type, timeAndAttendance) {
    var cols = [];
    if(type === "employee") {
      cols.push({ sTitle: I18n.t('ledger_table_item.js.name'), mData: "name"});
    } else if(type === "position") {
      cols.push({ sTitle: I18n.t('ledger_table_item.js.position'), mData: "position", sDefaultContent: I18n.t('ledger_table_item.js.position_default')});
    }
    if (timeAndAttendance) {
      cols = cols.concat([
        { sTitle: I18n.t('ledger_table_item.js.scheduled_hours'), mData: "hours"},
        { sTitle: I18n.t('ledger_table_item.js.actual_hours'),    mData: "actual_hours"},
        { sTitle: I18n.t('ledger_table_item.js.scheduled_labor'), mData: "cost"},
        { sTitle: I18n.t('ledger_table_item.js.actual_labor'),    mData: "actual_cost"}
      ]);
    } else {
      cols = cols.concat([
        { sTitle: I18n.t('ledger_table_item.js.scheduled_hours'), mData: "hours"},
        { sTitle: I18n.t('ledger_table_item.js.scheduled_labor'), mData: "cost"},
      ]);
    }

    return cols;
  }

  // creates dataTable.js table
  //
  // Summary:
  //  * instantiates dataTable
  //  * formats columns
  //  * adds click callbacks to show subrows
  //  * adds footer root to tables (to show dept. totals)
  function createTable(tableEl, type, data, deptTotals, timeAndAttendance){
    var columns = createTableColumns(type, timeAndAttendance);
    var decimalTargets, currencyTargets;
    if (timeAndAttendance) {
      decimalTargets =  [1,2]
      currencyTargets = [3,4]
    } else {
      decimalTargets =  [1]
      currencyTargets = [2]
    }


    // Create ledger table (version 1.9 of DataTables)
    var table = tableEl.dataTable({
      bPaginate: true,
      bFilter: false,
      sPaginationType: "full_numbers",
      bLengthChange: false,
      iDisplayLength: 10,
      bInfo: false,
      bAutoWidth: false,
      aaData: data,
      aoColumns: columns,
      aoColumnDefs: [
        {
          // Adds the ledger-expandIndicator class to the first column
          // i.e.: for the "+" and "-" icons
          "sClass": "ledger-expandIndicator",
          "aTargets": [0]
        },
        {
          // Ensures number values columns are set to two decimal places
          "mRender" : function(data,type,row){
            return data.toFixed(2);
          },
          "aTargets" : decimalTargets
        },
        {
          // Ensures number values columns are set to two decimal places
          "mRender" : function(data,type,row){
            return I18n.l('currency', data);
          },
          "aTargets" : currencyTargets
        }
      ],

      // Every time there is a redraw of the table,
      // make sure none of the rows are open before starting
      fnPreDrawCallback: function( oSettings ) {
        $(this).find("td.open").removeClass("open");
      },

      // Add the downward triangle arrow after the title of each column
      fnHeaderCallback: function( nHead, aData, iStart, iEnd, aiDisplay ) {
        var headers = $(nHead).children('th');
        if(headers.find('i').length == 0){
          headers.append($('<i class="fa fa-caret-down" aria-hidden="true"></i>'));
          headers.click(function(e){
            $(this).siblings().find("i").removeClass("fa-sort");
            $(this).find("i").addClass("fa-sort");
          });
        }
      },

      // Each time a row is created, also create a click event used to display
      // its subrows (children rows)
      fnCreatedRow: function (nRow, aData, iDisplayIndex) {

        var subData;
        if(type === "position"){ // Gather "employee" subdata for Position table
          subData = aData["employees"];
        } else if (type ==="employee") { // Gather "position" subdata for Employee table
          subData = aData["positions"];
        }

        // Click Event to show subdata (positions for employee; employees for a position)
        $(nRow).on("click", "td.ledger-expandIndicator", {subData: subData, type: type}, function(e) {
          var td = $(this),
            tr = td.closest('tr');

          // If the row is 'open', unopen it and remove subrows
          if(td.hasClass('open')) {
            td.removeClass('open');

            // find the subrows that were inserted and remove them
            var subRows = $(tr.nextUntil('tr:not(.ledger-subRow)'));
            subRows.remove();

          } else { // If the row is NOT 'open', open it and add subrows
            td.addClass('open');

            var data = e.data.subData;
            var type = e.data.type;
            // determine if even or odd to maintain color scheme
            var oddOrEven = tr.hasClass('odd') ? 'odd' : 'even';
            subRows = "";

            // create subrows
            data.forEach(function(subData){
              subRows += createSubRow(subData, type, oddOrEven);
            });

            // Insert new sub rows
            tr.after(subRows);
          }

        });// End of click event
      },

      oLanguage: dataTables_i18n
    });

    // Create and append footer row, which has the department totals
    var footer = createFooter(deptTotals, timeAndAttendance);
    table.append(footer);

    return table;
  }

  // Creates the footer row for the table, which contains the department totals
  //
  // All values set to 2 decimal places
  //
  // @return [String] A table footer with a row of totals
  function createFooter(rowData, timeAndAttendance) {
    var footer = "<tfoot>" +
      "<tr>" +
      "<td>" + I18n.t('ledger_table_item.js.total', { row_name: rowData.name }) + "</td>" +
      "<td>" + rowData.hours.toFixed(2) + "</td>";
    if (timeAndAttendance) {
      footer += "<td>" + rowData.actual_hours.toFixed(2) + "</td>";
    }
    footer += "<td>" + I18n.l('currency', rowData.cost) + "</td>";
    if (timeAndAttendance) {
      footer += "<td>" + I18n.l('currency', rowData.actual_cost) + "</td>";
    }
    footer += "</tr>" +
      "</tfoot>";

    return footer
  }

  // Creates a subrow//child-row item
  //
  // @return: [String] an html table row (<tr>)
  function createSubRow(data, type, oddOrEven) {
    var firstItemProp;
    if(type === "employee"){
      firstItemProp = "position";
    } else if( type === "position"){
      firstItemProp = "name";
    }


    var row = '<tr class="ledger-subRow ' + oddOrEven + '">';
    row +=('<td>'+ ( data[firstItemProp] ?  data[firstItemProp] : I18n.t('ledger_table_item.js.position_default') ) + '</td>');
    row +=('<td>'+ data["hours"].toFixed(2) + '</td>');
    if (timeAndAttendance) { row +=('<td>'+ data["actual_hours"].toFixed(2) + '</td>'); }
    row +=('<td>'+ I18n.l('currency', data.cost) + '</td>');
    if (timeAndAttendance) { row +=('<td>'+ I18n.l('currency', data.actual_cost) + '</td>'); }
    row += '</tr>';

    return row;
  }

  // Initializes a ledgerTableItem after it's been created.
  function init(){
    createTable(tableElement, type, tableData, deptTotals, timeAndAttendance);
  }

  return  { init: init };
}
