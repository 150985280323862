import { analytics } from 'core/analytics/analytics.js';
window.analyticsAvailableShifts = (function($) {

  /*
  public
  */

  var locationGroup, departmentGroup;
  // Initialize Analytics::Available Shifts
  var init = function(settings) {
    var today = new Date();
    $.extend(config, {
      endDate: new Date(today.setFullYear(today.getFullYear(), today.getMonth(), today.getDate() + 7)),
    });
    initAvailableShift();
    locationGroup = $('.available-shifts').find('#location-department-filter optgroup#location');
    departmentGroup = $('.available-shifts').find('#location-department-filter optgroup#department');

  };

  /*
  private
  */

  // Default settings
  var config = {
    chartName: "available-shifts",
    filterName: "shift_type_filter"
  };

  var clearCustomDateRangeForAvailableShifts = function() {
    analytics.clearCustomDateRange(".available-shifts");
    getAvailableShifts();
  };

  var getAvailableShifts = function() {
    var isInactive = $(this).hasClass('inactive');
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();
    var dateRange = analytics.filterDateRange($(this).text());
    var dataSetFilter = $(".available-shifts #data-set-filter :selected").val();
    config.endDate = analytics.filterEndDate($(this).attr('id'), config.endDate, dateRange);
    var dataHash = { locations: locationIds, departments: departmentIds, range: dateRange, endDate: $.datepicker.formatDate('yy/mm/dd', config.endDate), dataFilter: dataSetFilter }

    if (!isInactive) { $('#shift_type_filter').hide(); }

    if (analytics.canMakeChartRequest(config.chartName, isInactive, locationIds, departmentIds)) {
      $('#shift_type_filter').show();
      analytics.makeChartRequest(MKS.available_shifts_url, dataHash);
    } else {
      return;
    }
  };

  var getFilterDataHashForAvailableShifts = function() {
    var dataSetFilter = $(".available-shifts #data-set-filter :selected").val();
    var filterDataHash = { dataFilter: dataSetFilter }

    return filterDataHash;
  };

  var initAvailableShift = function() {
    analytics.initializePage($('.available-shifts'), $('#available-shifts-chart-box'));
    $('#shift_type_filter').hide();
    $('.available-shifts').find('#location-department-filter').select2().on("change", removeAvailableShiftsOptionGroup);
    $('.available-shifts').find('#data-set-filter').on("change", makeRequestForAvailableShifts);
    $(".available-shifts #submit-chart").on("click", makeRequestForAvailableShifts);
    $(".available-shifts #date-range-days, .available-shifts #date-range-weeks, .available-shifts #date-range-months, .available-shifts #date-range-previous, .available-shifts #date-range-next").on("click", getAvailableShifts);
    $('.available-shifts #set-custom-date-range').on("click", selectCustomDateRangeForAvailableShifts);
    $('.available-shifts #clear-custom-date-range').on("click", clearCustomDateRangeForAvailableShifts);
  };

  var makeRequestForAvailableShifts = function() {
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();

    if (locationIds.length == 0 && departmentIds.length == 0) { analytics.clearCustomDateRange(".available-shifts"); }
    if ($(".available-shifts #date-range-days").hasClass('inactive')) {
      selectCustomDateRangeForAvailableShifts();
    } else {
      getAvailableShifts();
    }
  };

  var removeAvailableShiftsOptionGroup = function() {
    analytics.removeOptionGroup($(".available-shifts #location-department-filter"), locationGroup, departmentGroup);
  };

  var selectCustomDateRangeForAvailableShifts = function() {
    var url = MKS.available_shifts_url;
    analytics.selectCustomDateRange(url, ".available-shifts");

    // if the start & end date are already set, execute the ajax call, otherwise listen for a date change event
    if ($(".analytics #start-date").val() != "" && $(".analytics #end-date").val() != "") {
      analytics.requestCustomDateRange(url, getFilterDataHashForAvailableShifts(), config.chartName, config.filterName);
    }
    else {
      $('.analytics .input-daterange').on("changeDate", function(event) {
        analytics.requestCustomDateRange(url, getFilterDataHashForAvailableShifts(), config.chartName, config.filterName);
      });
    }
  };


  return {
    init: init,
  };

})(jQuery);
