// eslint-disable-next-line no-undef
import 'core-js/stable'
import 'stylesheets/application.scss'
import 'vuep/dist/vuep.css'
import '@appcolony/icons/icons.css'

// import all image files from app/javascript/images
require.context('../images', true)

// import all javascript files from app/javascript/core
var req = require.context('../core', true, /\.jsx?$/)
req.keys().forEach(function (key) {
  req(key)
})
