import Vue from 'vue/dist/vue.esm';
import Vuep from 'vuep';

/**
 * Global Vue object to call in ERB templates
 */
Vue.prototype.I18n = window.I18n;
Vue.prototype.moment = window.moment;
Vue.use(Vuep);
window.Vue = Vue;

/**
 * Global Components object for namespacing Vue components
 */
window.Components = {};

// Component registration and namespacing
var req = require.context('.', true, /\.vue$/);
req.keys().forEach((key) => {
  const comp = req(key).default;
  
  // Register UI components globally
  if (comp.name && comp.example) {
    window.Vue.component(comp.name, comp);
  }
  
  // Attach everything else to window.Components
  if (comp.name && !comp.example) {
    window.Components[comp.name] = comp;
  }
});
