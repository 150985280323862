import { ledgerItem } from 'core/analytics/ledger_item.js';
import { analytics } from 'core/analytics/analytics.js';
window.analyticsLedger = (function($) {

  /*
  public
  */

  var locationGroup, departmentGroup, errorDiv, timeAndAttendance;

  // Initialize Analytics::Ledger
  var init = function(config) {
    initLedger();
    $('#datepicker').show();
    $('#checkbox-container').show();
    analytics.subscribeToAnalyticsReportChannel(config.privatePusherChannel, 'ledger', constructLedgers, displayPusherError);
    errorDiv = $('#analytics-error');
    timeAndAttendance = config.timeAndAttendance;
  };

  /*
  private
  */

  var initLedger = function() {
    analytics.initializePage($('.ledger'), $(null));
    $('.ledger').find('#location-department-filter').select2().on("change", removeLedgerOptionGroup);

    // Submit Button
    $(".ledger #submit-chart").on("click", makeRequestForLedger);
    locationGroup = $('.ledger').find('#location-department-filter optgroup#location');
    departmentGroup = $('.ledger').find('#location-department-filter optgroup#department');
  };

  var makeRequestForLedger = function() {
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();

    if (locationIds.length == 0 && departmentIds.length == 0) {
      // Clear away existing ledgers
      cleanLedgerSlate();
    } else { // Else, search!
      beginRequestForNewChart();
    }
  };

  var removeLedgerOptionGroup = function() {
    analytics.removeOptionGroup($('.ledger').find('#location-department-filter'), locationGroup, departmentGroup);
  };

  var beginRequestForNewChart = function() {
    errorDiv.hide();
    errorDiv.text('');
    analytics.hideDateRangeError();
    var url = '/analytics/ledger';
    var archived = $("#ledger-include-archived").is(":checked");

    analytics.selectCustomDateRange(url, ".ledger");
    analytics.showLoadingModal();
    var analyticsPromise = analytics.makeAsyncChartDataRequest(url, { archived: archived }, "ledger", "");
    analyticsPromise.fail(function (error) {
      analytics.hideLoadingModal();
      var errorMessage = error.responseJSON ? error.responseJSON.message : 'There was an error building the report';
      analytics.displayDateRangeError(errorMessage)
    });
  };

  var makeRequestForLedgerXlsx = function() {
    analytics.showLoadingModal();

    var archived = $("#ledger-include-archived").is(":checked");
    var url = analytics.exportURL({ archived: archived });

    $.ajax({
      url: url
    });
  };

  // Clears any existing ledgers from the DOM.
  var cleanLedgerSlate = function() {
    $("#ledgers").html("");
    removeExportButton();
  };

  // Creates Export Button
  var addExportButton = function() {
    var button = "<button class='export-btn'>" + I18n.t('ledger.js.export') + "</button>";
    $(".box-header").append(button);
    $('.export-btn').on('click', makeRequestForLedgerXlsx);
    return button;
  };

  // Removes Export Button
  var removeExportButton = function() {
    $(".export-btn").remove();
  };

  // Checks if ledger should be exportable
  // by looking if the response has any data
  var isExportable = function(ledgerData) {
    var exportable = false;
    ledgerData.locations.forEach(function(location) {
      if (location.hours > 0.0 || location.actual_hours > 0.0) {
        exportable = true;
      }
    });

    return exportable;
  };


  // Creates and initializes ledgers for each location/department
  var constructLedgers = function(analyticsReport) {
    var ledgerData = analyticsReport.data;
    // Clear away existing ledgers and buttons
    cleanLedgerSlate();

    if(isExportable(ledgerData)){
      addExportButton();
    }

    var ledgers = $("#ledgers");
    ledgerData.locations.forEach(function(location){
      // 1. Create Location header
      ledgers.append(buildLocationHeader(location));

      // 2. Create a ledgerItem for each department in a location
      location.departments.forEach(function(department){
        // 2a. create a new ledgerItem (headers, buttons, and tables)
        var ledger = new ledgerItem(department, timeAndAttendance);

        // 2b. At this point the ledgerItem is just a table element.
        // Append it to the DOM.
        ledgers.append(ledger.ledger);

        // 2c. Initialize the ledger item -- plug it in!
        ledger.init();
      });
    });
    analytics.hideLoadingModal();
  };

  var displayPusherError = function(data) {
    analytics.hideLoadingModal();
    errorDiv.show();
    errorDiv.text(data.message);
  };

  // Build Location header.
  //
  // @return: [String] HTML elements consisting of:
  //    Line break + location title + total hours + total cost
  var buildLocationHeader = function(location){
    var locationHeaderHTML = "<hr> <h3>" + location.name + "</h3>";
    locationHeaderHTML += "<span class='ledger-location-total label label-info'>";
    locationHeaderHTML += I18n.t('ledger.js.hours',{ hours: location.hours.toFixed(2) }) + "</span>";
    locationHeaderHTML += "<span class='ledger-location-total label label-info'>";
    locationHeaderHTML += I18n.l('currency', location.cost) + "</span>";
    return locationHeaderHTML;
  };

  return {
    init: init
  };

})(jQuery);
