export var scheduleLabels = (function($) {

  var config = {
    cookieName: Cookies.get('schedule_view') + '_labels',
    selectors: {
      scheduleLabelCheckboxes:  'form#requirement-label-selector input[type=checkbox]',
      scheduleLabels:           '.schedule-label',
      shiftTypeLabels:          '.shift-type-label',
      positionLabels:           '.position-label',
      jobSiteLabels:            '.job-site-label',
    }
  }

  var init = function(settings) {
    $.extend(config, {
      departmentId: settings.departmentId,
      jobSitesEnabled: settings.jobSitesEnabled,
    });
    initLabelSelection();
  };

  var updateScheduleLabels = function(options) {
    // function made public for access by department_schedules.js, because label application needs to happen mutliple times beyond initialization
    var departmentId = config.departmentId || options.departmentId;
    var jobSitesEnabled = config.jobSitesEnabled || options.jobSitesEnabled;
    var scheduleDepartmentLabels = getCookieLabels({
      departmentId: departmentId,
      jobSitesEnabled: jobSitesEnabled,
    });

    // If we don't receive any labels to update specifically, grab all schedule labels
    var $labels = options.labels || $(config.selectors.scheduleLabels);

    // Pull all required labels (position, job site, employee) from parent div and apply it to the span label
    $labels.each((index, scheduleLabel) => {
      var formattedLabels = '';

      scheduleDepartmentLabels.forEach(labelType => {
        var label = $(scheduleLabel).parents('.shift, .shift-requirement, .available-shift, .other-department-shift').data(labelType + '-label');
  
        if (label) {
          if (labelType === 'shift-templates') {
            // Add 'shift-templates' label with a line break
            formattedLabels += label + '<br>';
          } else {
            // Add other labels, with a comma if not immediately following a line break
            if (formattedLabels.endsWith('<br>')) {
              formattedLabels += label;
            } else {
              formattedLabels += (formattedLabels ? ', ' : '') + label;
            }
          }
        }
      });
  
      $(scheduleLabel).html(formattedLabels);
    });
  };

  /* private */

  var initLabelSelection = function() {
    var scheduleDepartmentLabels = getCookieLabels({
      departmentId: config.departmentId,
      jobSitesEnabled: config.jobSitesEnabled,
    });

    // on initialization, this "clicks" the cookied values in order for the schedule to display as it was left
    $(scheduleDepartmentLabels).each((index, scheduleDepartmentLabel) => {
      $('input#' + scheduleDepartmentLabel + '-label').prop('checked', true);
    });

    // bind listeners for checkbox checking
    $(config.selectors.scheduleLabelCheckboxes).on('change', () => {
      var checkedLabels = $(config.selectors.scheduleLabelCheckboxes).filter(':checked');
      var selectedLabels;

      selectedLabels = checkedLabels.map((index, checkedLabel) => {
        return $(checkedLabel).val();
      });

      updateHeightBasedOnLabels(selectedLabels);

      setCookieLabels({
        labels: selectedLabels,
        departmentId: config.departmentId,
        jobSitesEnabled: config.jobSitesEnabled,
      });

      updateScheduleLabels({
        departmentId: config.departmentId,
        jobSitesEnabled: config.jobSitesEnabled,
      });
    });

    // Set initial height of shifts based on selected labels
    var checkedLabels = $(config.selectors.scheduleLabelCheckboxes).filter(':checked');

    var selectedLabels = checkedLabels.map((index, checkedLabel) => {
      return $(checkedLabel).val();
    });

    updateHeightBasedOnLabels(selectedLabels);
  };

  function updateHeightBasedOnLabels(selectedLabels) {

    var labelCount = 0;

    var labelsArray = selectedLabels.get();

    if (labelsArray.includes('shift-templates')) {
      labelCount++;
    }

    if (labelsArray.includes('positions') || labelsArray.includes('job-sites')) {
      labelCount++;
    }

    var $shifts = $('div.ui-draggable.shift, div.drag-revert.available-shift');
    $shifts.removeClass('height-for-one-line-label height-for-two-line-label');

    if (labelCount < 2) {
      $shifts.addClass('height-for-one-line-label');
    } else {
      $shifts.addClass('height-for-two-line-label');
    }
    // Trigger resize event to update available shift row height
    window.dispatchEvent(new Event('resize'));
  }

  var getCookieLabels = function(options) {
    var departmentId = options.departmentId;
    var jobSitesEnabled = options.jobSitesEnabled;
    var cookieLabels = Cookies.get(config.cookieName);
    var cookieDepartmentLabels;

    // possible cookie names based on which schedule_view: `employees_labels`, `positions_labels`, `job_sites_labels`
    // Each cookie is a stringyfied JSON object where the keys are the department IDs, and the values are the arrays of labels that are saved to be displayed
    // if there is a corresponding `{schedule_view}_labels` with the corresponding department ID key, parse the array
    // if there is not a corresponding `{schedule_view}_labels`, set the default value for that department ID key to be an array filled with the first available option
    // if job sites is not enabled, display the first available option on the scheduler

    if (cookieLabels) {
      cookieDepartmentLabels = JSON.parse(cookieLabels)[departmentId];
    }

    // cases where the scheduler should reset to the default (first option) of schedule labels
    if (!cookieDepartmentLabels) {
      cookieDepartmentLabels = ['positions'];

      setCookieLabels({
        labels: cookieDepartmentLabels,
        departmentId: departmentId,
        jobSitesEnabled: jobSitesEnabled,
      });
    }

    return cookieDepartmentLabels;
  };

  var setCookieLabels = function(options) {
    var departmentId = options.departmentId;
    var jobSitesEnabled = options.jobSitesEnabled;
    var cookieLabels = Cookies.get(config.cookieName);

    if (cookieLabels) {
      cookieLabels = JSON.parse(Cookies.get(config.cookieName));
    }

    if (!cookieLabels || Array.isArray(cookieLabels)) {
      // check for the presence of the the cookie entirely, and if it's an array because the cookie data structure has changed
      cookieLabels = {};
    }

    cookieLabels[options.departmentId] = $(options.labels).toArray();

    Cookies.set(config.cookieName, JSON.stringify(cookieLabels), { secure: true });
  };

  return {
    init: init,
    updateScheduleLabels: updateScheduleLabels,
    getScheduleLabelCheckboxes: function() { return config.selectors.scheduleLabelCheckboxes; }
  };

})(jQuery);

window.scheduleLabels = scheduleLabels;
