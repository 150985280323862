<template>
  <div class="flex h-screen">
    <div class="flex flex-col w-64 m-3 mr-0 bg-white border border-gray-600 rounded-sm">
      <div class="box-border flex flex-col w-full h-full p-2 overflow-auto">
        <button
          v-for="component in library"
          :key="component.name"
          :class="listItemClass(component)"
          @click="handleActiveComponent(component)"
        >
          {{component.name}}
        </button>
      </div>
      <button
        @click="reset" 
        class="w-full h-12 p-2 border-t border-gray-600 hover:bg-gray-400 focus:outline-none"
      >
        RESET
      </button>
    </div>
    <div class="w-full h-auto m-3">
      <vuep
        v-if="isMounted"
        :style="{ height: '100%' }"
        :options="{ height: 'auto' }"
        :template="code"
        :scope="scope"
      />
    </div>
  </div>
</template>

<script>
// Import UI components manually in order to list them
const UiComponents = {};
const req = require.context('.', true, /\.vue$/);
req.keys().forEach((key) => {
  const comp = req(key).default;
  if (comp && comp.example) UiComponents[comp.name] = comp;
});

export default {
  name: 'UiLibrary',
  data() {
    return {
      library: [],
      activeComponent: null,

      isMounted: false,
      scope: {},
      code: '',
      
      // Leave indentation
      defaultCode: `
<template>
  <div>
    <h4 class="my-6 border-b">UI Library</h4>
    <p>Here you can mess around with various components, so go wild!</p>
  </div>
</template>

<script>
  export default {}
<\/script>`
    }
  },
  mounted() {
    const localComponent = localStorage.getItem('activeComponent');
    this.library = Object.values(UiComponents).filter((comp) => comp.example);
    if (localComponent) {
      this.handleActiveComponent(this.library.find((comp) => comp.name === localComponent), 500);
    } else {
      this.code = this.defaultCode;
      setTimeout(() => this.isMounted = true, 500);
    } 
  },
  methods: {
    listItemClass(component) {
      const isSelected = this.activeComponent && this.activeComponent.name === component.name;
      return {
        'p-2 mb-2 border-2 border-gray-400 bg-white focus:outline-none break-all': true,
        'border-dashed hover:bg-gray-400': !isSelected,
        'border-solid border-4 cursor-default': isSelected,
      }
    },

    handleActiveComponent(component, timeout = 100) {
      this.isMounted = false;
      this.activeComponent = component;
      this.code = component.example;
      localStorage.setItem('activeComponent', component.name);
      setTimeout(() => this.isMounted = true, timeout);
    },

    reset() {
      this.isMounted = false;
      this.activeComponent = null;
      this.code = this.defaultCode;
      localStorage.removeItem('activeComponent');
      setTimeout(() => this.isMounted = true, 100);
    }
  }
}
</script>

<style lang="scss">
  .vuep-preview {
    background: whitesmoke;
  }
</style>
