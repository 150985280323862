import { User } from 'core/models/user.js';
import { Message } from 'core/models/message.js';
import { Conversation } from 'core/models/conversation.js';
export var messaging = (function($, window, document) {

  /* public */

 /*
  * Initializes all the things
  *
  * @param {Integer} currentUserId
  * @param {Object} settings
  */
  var init = function(currentUserId, settings) {
    store.currentUserId = currentUserId;

    $.extend(true, config, settings, {
      selectors: {                        // Selectors available at init time
        $conversationHeadersContainer:    $(config.elements.conversationHeadersContainer),
        $conversationContentContainer:    $('#conversation-content'),
        $conversationUsersContainer:      $('#conversation-users-container'),
        $conversationUsers:               $('#conversation-users'),
        $newConversationUsersContainer:   $('#new-conversation-users-container'),
        $newConversationUsersInput:       $('#new-conversation-users'),
        $newMessageInput:                 $(config.elements.newMessageInput)
      }
    });

    initControls();
    initEvents();

    loadAndRenderData();

    initPusher();
  };

  var events = $({});

  // Loaded data and associated methods
  var store = {
    // Used for recipient list filtering
    currentUserId: null,

    // Conversation objects for currentUser
    // @see assets/javascripts/models/conversation.js
    conversations: [],

    // Message objects for currentUser
    // @see assets/javascripts/models/message.js
    messages: [],

    // User objects associated with loaded Conversations
    // @see assets/javascripts/models/user.js
    users: [],

    // Pagination boolean that specifies whether or not there are more results
    nextPage: false,

    // Currently active Conversation
    currentConversation: function() {
      var $activeConversationHeader = config.selectors.$activeConversationHeader();
      if ($activeConversationHeader.length) {
        return this.findConversation(parseInt($activeConversationHeader.attr('data-conversation-id')));
      } else {
        return null;
      }
    },

    /*
     * Finds all Conversation objects with conditions
     *
     * @example store.findAllConversations({ rendered: false });
     *
     * @param {Object} conditions
     * @return {Array<Conversation>}
     */
    findAllConversations: function(conditions) {
      return _.where(this.conversations, conditions);
    },

    /*
     * Finds selected Conversation object based on its ID
     *
     * @param {Integer} id
     * @return {Conversation} Conversation
     */
    findConversation: function(id) {
      return _.findWhere(this.conversations, { id: id });
    },

    /*
     * Finds all Message objects with the specified IDs
     *
     * @param {Array} ids       Array of Message IDs
     * @return {Array<Message>}
     */
    findMessages: function(ids) {
      return _.filter(this.messages, function(message) {
        return ids.indexOf(message.id) > -1;
      })
    },

    /*
     * Finds selected User object based on its ID
     *
     * @param {Integer} id
     * @return {User}
     */
    findUser: function(id) {
      return _.findWhere(this.users, { id: id });
    },

    /*
     * Finds all User objects with the specified IDs
     *
     * @param {Array} ids       Array of User IDs
     * @return {Array<User>}
     */
    findUsers: function(ids) {
      return _.filter(this.users, function(user) {
        return ids.indexOf(user.id) > -1;
      })
    },

    /*
     * Creates new Conversation or updates existing Conversation with new Message(s)
     *
     * @param {String} body
     */
    createConversation: function(body) {
      var userIds = config.selectors.$newConversationUsersInput.val();
      if (!userIds) { return; }

      $.ajax({
        url: config.routes.createConversationsPath(),
        data: { format: 'json', conversation: { body: body, user_ids: userIds.split(',') } },
        dataType: 'json',
        method: 'POST'
      })
      .then(function(data) {
        var conversation = store.findConversation(data.conversation.id);
        if (conversation) {
          store.pushMessages(conversation, data.messages);
          conversation.update(data.conversation);
        } else {
          conversation = store.pushConversation(data);
          renderConversationHeader(conversation);
        }

        resetNewMessage();
        renderConversation(conversation);
        config.selectors.$conversationHeadersContainer.scrollTop(0);
      });
    },

    /*
     * Loads Conversation from back end and pushes it/associated data to store
     *
     * @param {Integer} id Conversation ID
     */
    loadConversation: function(id) {
      $.getJSON(config.routes.conversationPath(id))
      .then(function(data) {
        var conversation = store.findConversation(id);
        if (conversation) {
          store.pushMessages(conversation, data.messages);
          var currentConversation = store.currentConversation();
          conversation.update(data.conversation);
          // If new messages are for current Conversation, re-render the Conversation content
          if (currentConversation && currentConversation.id === conversation.id) {
            renderConversation(conversation);
          }
        } else {
          conversation = store.pushConversation(data);
          renderConversationHeader(conversation);
        }
      });
    },

    /*
     * Pushes single Conversation and associated data to the store
     *
     * @param {Object} data JSON data
     * @return {Conversation}
     */
    pushConversation: function(data) {
      var conversation = new Conversation(data.conversation);
      store.conversations.push(conversation);

      _.each(data.messages, function(messageData) {
        store.messages.push(new Message(messageData));
      });

      _.each(data.users, function(userData) {
        if (store.findUser(userData.id)) { return; }
        store.users.push(new User(userData));
      });

      return conversation;
    },

    /*
     * Creates new message for Conversation
     *
     * @param {Conversation} conversation
     * @param {String} body
     */
    createMessage: function(conversation, body) {
      $.ajax({
        url: config.routes.createConversationMessagesPath(conversation.id),
        data: { format: 'json', message: { body: body } },
        dataType: 'json',
        method: 'POST'
      })
      .then(function(data) {
        var message = new Message(data.message);
        store.messages.push(message);
        resetNewMessage();
        renderMessage(message);
        conversation.associateMessage(message);
        config.selectors.$conversationHeader(conversation.id).addClass('active');
        config.selectors.$conversationHeadersContainer.scrollTop(0);
      });
    },

    /*
     * Pushes Messages to the store, ignoring dups
     *
     * @param {Object} data JSON data
     * @return {Array<Message>} New messages that were added to store
     */
    pushMessages: function(conversation, messages) {
      var newMessages = [];
      _.chain(messages)
        .filter(function(messageData) {
          return conversation.messageIds.indexOf(messageData.id) == -1;
        })
        .each(function(messageData) {
          var newMessage = new Message(messageData);
          store.messages.push(newMessage);
          newMessages.push(newMessage);
        });

        return newMessages;
    }
  };

  /* private */

  /*
   * Default settings
   */
  var config = {
    debug:                            false,  // Log to console in Rails.env.development
    elements: {
      conversationHeaders:            'div.conversation-header:not(.new-conversation)',
      conversationHeadersContainer:   '#conversation-headers',
      messages:                       'div.conversation-message',
      newConversationButton:          '#new-conversation-btn',
      newMessageInput:                '#new-message-body',
      paginator:                      '.timestamp-paginator'
    },
    pusherKey:                        null,   // Facilitates live update
    routes: {
      namespace:                      '' ,    // Facilitates shared usage between admins/employees
      conversationsPath:              function() { return config.routes.namespace + '/messages'; },
      existingConversationsPath:      function(userIds) { return config.routes.namespace + '/two_way_conversations/existing?user_ids=' + userIds; },
      conversationPath:               function(id) { return config.routes.namespace + '/two_way_conversations/' + id; },
      readConversationPath:           function(id) { return config.routes.conversationPath(id) + '?read=1'; },
      newConversationPath:            function() { return config.routes.namespace + '/two_way_conversations/new'; },
      createConversationsPath:        function() { return config.routes.namespace + '/two_way_conversations/'; },
      createConversationMessagesPath: function(conversationId) { return config.routes.namespace + '/two_way_conversations/' + conversationId + '/two_way_messages'; }
    },
    selectors: {
      $activeConversationHeader:      function() { return $(config.elements.conversationHeaders + '.active').first(); },
      $conversationHeaders:           function() { return $(config.elements.conversationHeaders); },
      $conversationHeader:            function(id) { return $(config.elements.conversationHeaders + '[data-conversation-id=' + id + ']'); },
      $message:                       function(id) { return $(config.elements.messages + '[data-message-id=' + id + ']'); },
      $newConversationHeader:         function() { return $('.new-conversation'); },
      $paginator:                     function() { return $(config.elements.paginator); }
    },
    ui: {
      highlightColor:                 '#ffcc00',
      highlightInterval:              750,
      transitionInterval:             300
    }
  };

  /*
   * Handles all interactions on headers/content
   */
  var initControls = function() {
    // Auto-complete recipients list
    config.selectors.$newConversationUsersInput.select2({
      minimumInputLength: 3,
      placeholder: I18n.t('messaging.js.placeholder'),
      multiple: true,
      ajax: {
        url: config.routes.newConversationPath(),
        dataType: 'json',
        quietMillis: 250,
        data: function (term, page) {
          return {
            q: term
          };
        },
        results: function (data, page) {
          return {
            results: _.map(data.users, function(user) {
              return { id: user.id, text: user.name };
            })
          }
        },
        cache: true
      }
    })
    // On selecting recipients, query for existing conversations with those recipients.
    // If found, pop in the messages.
    // @note Conversation will still be treated as a new Conversation,
    // but the backend will handle the matching and append a message instead
    .on('change', function(e) {
      var $conversationContentContainer = config.selectors.$conversationContentContainer
      $conversationContentContainer.empty();

      if (e.val.length == 0) { return; }

      $.getJSON(config.routes.existingConversationsPath(e.val))
      .then(function(data) {
        var renderedMessages = [];
        _.each(data.messages, function(messageData) {
          renderedMessages.push(new Message(messageData).render());
        });
        $conversationContentContainer.append(renderedMessages);
        $conversationContentContainer.scrollTop($conversationContentContainer[0].scrollHeight);
      });
    });

    $('body')
      // New Conversation form
      .on('click', config.elements.newConversationButton, function(e) {
        e.preventDefault();
        if (config.selectors.$newConversationHeader().length) { return; }
        renderNewConversation();
      })
      // Render Conversation on header click
      .on('click', config.elements.conversationHeaders, function(e) {
        renderConversation(store.findConversation(parseInt($(this).attr('data-conversation-id'))));
      });

    // Create Conversation/Message on new message input field carriage return
    $(config.elements.newMessageInput).keypress(function(e) {
      var keyCode = (e.keyCode ? e.keyCode : e.which);
      if (keyCode == 13) {
        var body = $(this).val().trim();
        if (body.length == 0) { return; }

        var currentConversation = store.currentConversation();
        if (currentConversation) {
          store.createMessage(currentConversation, body);
        } else {
          store.createConversation(body);
        }
      }
    });
  };

  /*
   * Update DOM in response to events triggered by Conversation and Message objects
   */
  var initEvents = function() {
    events
      // Re-render Conversation header, send request to API
      .on('conversation:read', function(e, conversation) {
        config.selectors.$conversationHeader(conversation.id).replaceWith(conversation.renderHeader());
        $.getJSON(config.routes.readConversationPath(conversation.id));
      })
      // Remove and re-insert updated Conversation header
      .on('conversation:updated', function(e, conversation) {
        config.selectors.$conversationHeader(conversation.id).remove();
        renderConversationHeader(conversation);
      })
      // Handles pagination of Conversation headers
      .on('conversations:paginate', function(e, paginator) {
        var oldestConversation = _.chain(store.conversations)
          .sortBy(function(conversation) {
            return conversation.updatedAt;
          })
          .first()
          .value();
        loadAndRenderData(oldestConversation.updatedAt);
      });
  };

  /*
   * Retrieves paginated Conversations and builds/renders all the things
   *
   * @param {Integer} timestamp Optional pagination timestamp
   */
  var loadAndRenderData = function(timestamp) {
    var params = { format: 'json' };
    if (timestamp != undefined) { params.timestamp = timestamp; }

    $.ajax({
      url: config.routes.conversationsPath(),
      data: params,
      dataType: 'json',
      cache: false
    })
    .then(function(data) {
      // Create Conversation and Message objects
      serializeObjects(data);

      // Render Conversation headers
      renderConversationHeaders();

      // Display newest Conversation on first load
      if (timestamp === undefined) {
        var $firstConversationHeader = config.selectors.$conversationHeaders().first();
        if ($firstConversationHeader.length) {
          renderConversation(store.findConversation(parseInt($firstConversationHeader.attr('data-conversation-id'))));
        }
      }

      // Bump pagination controls
      setPagination();
    });
  };

  /**
   * Initializes Pusher for new Conversation/Message notifications
   */
  var initPusher = function() {
    var pusher = window.getPusherInstance(config.pusherKey);
    var channel = pusher.subscribe('user-' + store.currentUserId);

    channel.bind('messaging.message_created', function(data) {
      store.loadConversation(data.conversation_id);
    });
  };

  /*
   * Serialize JSON data for current page of results into JS Conversation and Message objects
   *
   * @param {Object} data Raw JSON
   */
  var serializeObjects = function(data) {
    _.each(data.conversations, function(conversationData) {
      store.conversations.push(new Conversation(conversationData));
    });

    _.each(data.messages, function(messageData) {
      store.messages.push(new Message(messageData));
    });

    _.each(data.users, function(userData) {
      if (store.findUser(userData.id)) { return; }
      store.users.push(new User(userData));
    });

    store.nextPage = data.meta.next_page;
  };

  /*
   * Renders all unrendered Conversation headers
   */
  var renderConversationHeaders = function() {
    var renderedConversationHeaders = [];
    _.each(store.findAllConversations({ rendered: false }), function(conversation) {
      renderedConversationHeaders.push(conversation.renderHeader());
    });
    config.selectors.$conversationHeadersContainer.append(renderedConversationHeaders);
  };

  /*
   * Renders Conversation header at top
   *
   * @param {Conversation} conversation
   */
  var renderConversationHeader = function(conversation) {
    var $conversationHeadersContainer = config.selectors.$conversationHeadersContainer;
    var $conversationHeader = $(conversation.renderHeader());
    $conversationHeader.prependTo($conversationHeadersContainer);
  };

  /*
   * Renders Conversation content
   *
   * @param {Conversation} conversation
   */
  var renderConversation = function(conversation) {
    resetNewConversation();

    // Flag as read
    conversation.read();

    // Highlight selected Conversation
    if (conversation != store.currentConversation()) {
      config.selectors.$conversationHeaders().removeClass('active');
      config.selectors.$conversationHeader(conversation.id).addClass('active');
    }

    // Clear prior view
    config.selectors.$conversationContentContainer.empty();

    // Render recipients, excluding currentUser
    config.selectors.$conversationUsers.text(_.pluck(conversation.recipients(), 'name').join(', '));

    // Render Messages
    var renderedMessages = [];
    _.each(conversation.messages(), function(message) {
      renderedMessages.push(message.render());
    });
    var $conversationContentContainer = config.selectors.$conversationContentContainer;
    $conversationContentContainer.append(renderedMessages);
    $conversationContentContainer.scrollTop($conversationContentContainer[0].scrollHeight);

    // Focus on new Message field
    config.selectors.$newMessageInput.focus();
  };

  /*
   * Sets up new Conversation placeholder header and form
   */
  var renderNewConversation = function() {
    // clear current Conversation view
    config.selectors.$conversationHeaders().removeClass('active');
    config.selectors.$conversationContentContainer.empty();
    config.selectors.$conversationUsersContainer.hide();

    // render new Conversation
    var newConversation = new Conversation({});
    var $conversationHeadersContainer = config.selectors.$conversationHeadersContainer;
    $(newConversation.renderHeader()).prependTo($conversationHeadersContainer).addClass('active');
    $conversationHeadersContainer.scrollTop(0);

    // render new Conversation form
    config.selectors.$newConversationUsersContainer.show();
  };

  /*
   * Renders Message in current content container
   *
   * @param {Message} message
   */
  var renderMessage = function(message) {
    var $conversationContentContainer = config.selectors.$conversationContentContainer;
    $conversationContentContainer.append(message.render());
    $conversationContentContainer.scrollTop($conversationContentContainer[0].scrollHeight);
    config.selectors.$message(message.id).effect('highlight', {color: config.ui.highlightColor}, config.ui.highlightInterval);
  };

  /*
   * Resets new Conversation placeholder and form
   */
  var resetNewConversation = function() {
    config.selectors.$newConversationHeader().remove();
    config.selectors.$newConversationUsersContainer.hide();
    config.selectors.$conversationUsersContainer.show();
    config.selectors.$newConversationUsersInput.select2('val',null);
  };

  /*
   * Resets new Message input
   */
  var resetNewMessage = function() {
    config.selectors.$newMessageInput.val('');
  }

  /*
   * Sets up paginator control based on current page load
   */
  var setPagination = function() {
    var $conversationHeadersContainer = config.selectors.$conversationHeadersContainer;
    $conversationHeadersContainer.onScreen('remove');
    config.selectors.$paginator().remove();

    if (store.nextPage) {
      var $paginator = $('<div class="timestamp-paginator">&hellip;</div>');
      $conversationHeadersContainer.append($paginator);
      $paginator.onScreen({
        container: config.elements.conversationHeadersContainer,
        direction: 'vertical',
        doIn: function() {
          messaging.events.trigger('conversations:paginate', [$paginator]);
        },
        tolerance: 0,
        throttle: 50
      });
    }
  };

  return {
    init:   init,
    events: events,
    store:  store
  };

})(jQuery, window, document);
