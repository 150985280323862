<template>
  <div>
    <a :href="new_link" class="btn btn-success pull-right"><i class="fa fa-plus"/> {{ I18n.t('support_users.create') }}</a>
    <div style="clear: both"></div>
    <div class="box">
      <table class="dataTable">
        <thead>
          <tr>
            <th><td>{{ I18n.t('activerecord.attributes.user.name') }}</td></th>
            <th><td>{{ I18n.t('activerecord.attributes.user.email') }}</td></th>
            <th><td>{{ I18n.t('activerecord.attributes.user.role') }}</td></th>
            <th><td>{{ I18n.t('activerecord.attributes.user.last_sign_in_at') }}</td></th>
            <th><td>{{ I18n.t('support_users.actions') }}</td></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users">
            <td>
              <a v-if="user.limited_super_admin" :href="user.link">{{ user.name }}</a>
              <span v-else>{{ user.name }}</span>
            </td>
            <td>{{ user.email }}</td>
            <td>{{ user.limited_super_admin ? I18n.t('support_users.limited') : I18n.t('support_users.global') }}</td>
            <td>{{ user.last_sign_in_at }}</td>
            <td>
              <a v-if="user.limited_super_admin" :href="user.welcome_link" data-method="post" class="btn btn-success" style="margin: 0">{{ I18n.t('users.form.send_welcome_email') }}</a>
              <span v-else></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SupportUsers',
    props: {
      users: Array,
      new_link: String
    }
  };
</script>

<style scoped>
  .btn {
    margin-bottom: 1em;
  }

  table.dataTable thead tr th {
    border-top: 0;
    text-align: left;
  }
</style>
