import BudgetBar from 'core/vue/budget_bar/budget_bar.vue';
import { renderModalAlert } from 'core/makeshift.js';
import { makeshiftGoogleAnalytics } from 'core/google_analytics.js';
import { getCookie } from 'core/cookies.js';
import { departmentSchedule } from 'core/department_schedules.js';
import { spanBudgetBatches } from 'core/span_budget_batches.js';
export var weeklyBudget = (function($, window, document) {

  /*
  public
  */

  /**
   * Generic event handling object
   * @type {jQuery Object}
   */
  var budgetEvents = $({});

  /**
   * Initializes the WeeklyBudget
   * @param  {Object} settings Basic settings to setup the WeeklyBudget
   */
  var init = function(settings) {
    $.extend(config, {
      view:                               settings.view,
      departmentId:                       settings.departmentId,
      routes: {
        newWeeklyBudgetPath:              '/departments/' + settings.departmentId + '/weekly_budgets/new',
        createWeeklyBudgetPath:           '/departments/' + settings.departmentId + '/weekly_budgets',
        editWeeklyBudgetPath:             function(id) { return '/weekly_budgets/' + id + '/edit'; },
        calculateWeeklyBudget:            '/departments/' + settings.departmentId + '/weekly_budgets/calculate'
      },
      budgetBarMount: $("#budget-bar")[0],
      selectors: {
        departmentScheduleContainer: $('.main-content .row-fluid:first'),
        budgetBar: function(){ return $('#budget-bar'); },
        dropdown: $('[data-weekly-budget]'),
        modal: $('#shift-modal'),
        shiftTemplatesBox: $('#shift-templates.box-content'),
        calendarBox: $('.box-wrapper')
      }
    });

    var cookie = findOrCreateCoookie(function(cookie){
      if (cookie === 'show') {
        initBudgetBar();
        toggleHiddenBudgetBarClass(true);
      } else {
        config.selectors.budgetBar().css({ minHeight: 0 });
        toggleHiddenBudgetBarClass(false);
      }
    });

    initDropDown();

    // Only work with span budgets if they're relevant
    if (spanBudgetsEnabled()) {
      initSpanBudgets();
    }
  };

  /*
  private
  */

  /**
   * BudgetData Object
   * @type {Object}
   */
  var budgetData = {
    scheduledCosts: 0,
    scheduledHours: 0,
    estimatedCosts: 0,
    weeklyBudget: {
      amount: 0
    },
    spanBudgets: {}
  };

  /**
   * Array of Event Titles to avoid missing them during unbinding.
   * @type {Array}
   */
  var budgetEventsTitles = ['weeklyBudget:initialized', 'weeklyBudget:calculate'];

  /**
   * Default settings
   * @type {Object}
   */
  var config = {
    view:               'employees',
    cookieName:         'labourBudget',
    transitionInterval: 300,
  };

  /**
   * Initializes the clicking of Weekly Budget Form Details
   */
  var initBudgetActions = function() {
    // Had a bug that sometimes there would be multiple callbacks from the same click event.
    // .off('click', ...).on('click', ...) ensures that the function will run only once per click.
    $('body').off('click', '#weekly-budget-details').on('click', '#weekly-budget-details', function(e) {
      $('#weekly-budget-details').prop('disabled', true);
      e.preventDefault();
      var request, $self = $(this);
      if (budgetData.weeklyBudget.id === undefined || budgetData.weeklyBudget.id === null) {
        request = $.get(config.routes.newWeeklyBudgetPath, {start: budgetData.beginning_of_week});
      } else {
        request = $.get(config.routes.editWeeklyBudgetPath(budgetData.weeklyBudget.id));
      }

      request.then(function(html) {
        config.selectors.modal.html(html).modal('show');
      }, function(xhr, status, error) {
        alert(error);
      });
    });
  };

  /**
   * Initializes the BudgetBar within the Department Calendar
   * @return {[type]} [description]
   */
  var initBudgetBar = function() {
    config.selectors.budgetBar().fadeIn();
    initModalForms();
    initBudgetEvents();
    initBudgetBarSelectors();
    ga('send','event',makeshiftGoogleAnalytics.categories.departmentCalendar, makeshiftGoogleAnalytics.actions.displayed, makeshiftGoogleAnalytics.label.weeklyBudgetBar);
  };

  /**
   * Helper to cache BudgetBar selectors when the bar is shown
   */
  var initBudgetBarSelectors = function() {
    $.extend(config.selectors, {
      budgetEstimate: $('#labour-estimate'),
      progressContainer: $('#budget-bar .meter'),
      progressBar: $('#budget-bar .meter').find('span'),
      weeklyBudgetValue: $('#labour-weekly-budget')
    });
  };

  /**
   * Handles Event bindings for Weekly Budget and performs actions on those events when they are observed.
   */
  var initBudgetEvents = function() {
    budgetEvents
      .on('weeklyBudget:initialized', function(event, beginningOfWeek){
        $.extend(true, budgetData, { beginning_of_week: beginningOfWeek });
        initBudgetActions(); // initBudgetActions requires the beginningOfWeek variable set to work correctly
        calculateCostsAndUpdateUI();
      })
      .on('weeklyBudget:calculate', function(event){
        calculateCostsAndUpdateUI();
      });
  };

  var requestInFlight = false; // `true` if we are awaiting a response from the server
  var updateRequired  = false; // `true` if additional budget updates were requested while one was in flight

  /**
   * Obtains all new cost calcuations from the backend, sets budgetData with that information then updates the UI
   * @return {Object} budgetData
   */
  var calculateCostsAndUpdateUI = function() {

    // Create an empty div for the Vue object to replace
    const vueContainer = document.createElement('div');
    config.budgetBarMount.innerHTML = '';
    config.budgetBarMount.appendChild(vueContainer);

    // Start by displaying a loading bar for the budget, then replace it once the response returns
    const budgetBarLoading = new Vue({ 
      el: vueContainer,
      data: {
        view: config.view,
        isLoading: true,
      },
      components: { BudgetBar },
      template: '<budget-bar v-bind="$data" />'
    });

    // We already have a request in-flight.
    // We will wait until it returns to update so we don't flood the server with requests
    if (requestInFlight) {
      updateRequired = true;
      return;
    }

    requestInFlight = true;

    return calculateLabourCosts().then(function(response){
      budgetData.spanBudgets = response.spanBudgets;
      $.extend(true, budgetData, { spanBudgets: response.span_budget });

      // We're done the budget request, so we mark it as no longer in flight
      requestInFlight = false;

      // We just finished calculating but we already need a new budget, so just
      // start the request process again without trying to draw this version
      if (updateRequired) {
        updateRequired = false;
        calculateCostsAndUpdateUI();
      }

      $.extend(true, budgetData, {
        weeklyBudget: response.weekly_budget,
        estimatedCosts: response.weekly_budget_calculations.estimated_costs,
        scheduledCosts: response.weekly_budget_calculations.scheduled_costs,
        scheduledHours: response.weekly_budget_calculations.scheduled_hours
      });

      budgetBarLoading.$destroy();
      config.budgetBarMount.removeChild(budgetBarLoading.$el);
      config.budgetBarMount.appendChild(vueContainer);
      new Vue({ 
        el: vueContainer,
        data: {
          budgetDollars: response.weekly_budget.amount,
          budgetHours: response.weekly_budget.hours,
          estimatedCosts: response.weekly_budget_calculations.estimated_costs,
          estimatedHours: response.weekly_budget_calculations.estimated_hours,
          scheduledCosts: response.weekly_budget_calculations.scheduled_costs,
          scheduledHours: response.weekly_budget_calculations.scheduled_hours,
          view: config.view,
          isLoading: false,
        },
        components: { BudgetBar },
        template: '<budget-bar v-bind="$data" />'
      });

      updateSpanBudgets(departmentSchedule.getBudgetMode(), config.beginningOfWeek, budgetData.spanBudgets);
    });
  };

  var spanBudgetsEnabled = function() {
    return $('#labor-budget-row').length === 1;
  };

  var initSpanBudgets = function() {
    spanBudgetBatches.init(config.departmentId, config.beginningOfWeek);
    updateSpanBudgets(departmentSchedule.getBudgetMode(), config.beginningOfWeek, {});
    spanBudgetBatches.onBudgetUpdated(function() {
      calculateCostsAndUpdateUI();
    });
    var checkbox = $('#show-daily-budget');
    if (!Cookies.get('show_daily_budget')) {
      Cookies.set('show_daily_budget', 'true', { secure: true });
    }
    checkbox.prop('checked', Cookies.get('show_daily_budget') == 'true');
    if (checkbox.prop('checked')) {
      spanBudgetBatches.show();
    } else {
      spanBudgetBatches.hide();
    }
    checkbox.change(function(event) {
      spanBudgetBatches.toggle();
      Cookies.set('show_daily_budget', $(this).prop('checked'), { secure: true });
      departmentSchedule.loadSchedule();
    });
  };

  var updateSpanBudgets = function(budgetMode, beginningOfWeek, spanBudgetData) {
    if (!spanBudgetsEnabled()) {
      return;
    }
    spanBudgetBatches.setBudgetMode(budgetMode);
    spanBudgetBatches.updateValues(beginningOfWeek, spanBudgetData);
  };

  /**
   * Initializes the drop-down option for Displaying/Hiding the BudgetBar
   */
  var initDropDown = function() {
    var text = fetchInverseDisplayValue(getCookie(config.cookieName));
    config.selectors.dropdown.attr('data-weekly-budget', text).html(I18n.t('department_schedules.show.weekly_budget' + '.' + text));

    config.selectors.dropdown.on('click', function(e) {
      e.preventDefault();
      var inverseBudgetState, newBudgetBarState = $(this).attr('data-weekly-budget');
      setWeeklyBudgetCookie(newBudgetBarState);

      inverseBudgetState = fetchInverseDisplayValue(newBudgetBarState);

      config.selectors.dropdown.attr('data-weekly-budget', inverseBudgetState).html(I18n.t('department_schedules.show.weekly_budget' + '.' + inverseBudgetState));

      if (newBudgetBarState === 'show') {
        initBudgetBar();
        departmentSchedule.loadSchedule();
        toggleHiddenBudgetBarClass(true);
      } else {
        config.selectors.budgetBar().fadeOut(config.transitionInterval, function(){
          //$(this).remove();
          toggleHiddenBudgetBarClass(false);
        });
        destroyBudgetBarBindings();
        ga('send','event',makeshiftGoogleAnalytics.categories.departmentCalendar, makeshiftGoogleAnalytics.actions.hidden, makeshiftGoogleAnalytics.label.weeklyBudgetBar);
      }
    });
  };

  /**
   * Initializes the modalForms for when 'View Details' is clicked
   */
  var initModalForms = function() {
    $('body')
      .on('ajax:success', '.new_weekly_budget, .edit_weekly_budget', function(e, data){
        updateWeeklyBudget(data.weekly_budget);
        calculateCostsAndUpdateUI();
      })
      .on('ajax:error', '.new_shift_requirement, .new_shift, .new_available_shift, .edit_shift, .new_weekly_budget, .edit_weekly_budget', function(e, xhr, status, error) {
        if (xhr.responseJSON.error_type != 'FatigueConflictError') {
          renderModalAlert(xhr.responseJSON);
        }
      });
  };

  /**
   * Returns a server calculation of Weekly Budget Costs
   * @return {Promise} jQuery Promise
   */
  var calculateLabourCosts = function() {
    return $.getJSON(config.routes.calculateWeeklyBudget, { start: budgetData.beginning_of_week});
  };

  /**
   * Unbinds all event bindings on the BudgetBar to avoid unnecessary actions happening while the user has opted not to show it.
   */
  var destroyBudgetBarBindings = function() {
    _.each(budgetEventsTitles, function(eventTitle){
      budgetEvents.unbind(eventTitle);
    });
  };

  /**
   * Conditional to return the opposite of the current show/hide value
   * @param  {String} cookieSetting Value returned from the cookie
   * @return {String}               Opposite value of the incomign CookieSetting
   */
  var fetchInverseDisplayValue = function(cookieSetting) {
    return cookieSetting === 'show' ? 'hide' : 'show';
  };

  /**
   * Lookup for the WeeklyBudget cookie
   * @return {String} Cookie setting
   */
  var findOrCreateCoookie = function(callback) {
    var cookie = getCookie(config.cookieName);
    if (cookie.length === 0) {
      cookie = setWeeklyBudgetCookie('show');
      cookie = cookie.split('=')[1];
    }

    if (callback && typeof callback === 'function') {
      return callback(cookie);
    }

    return cookie;
  };

  /**
   * Sets the WeeklyBudget cookie to the specified value
   * @param {String} value Show/Hide
   */
  var setWeeklyBudgetCookie = function(value) {
    var cookieParams = config.cookieName + '=' + value;
    document.cookie = cookieParams;
    return cookieParams;
  };

  /**
   * Updates the Weekly Budget Numbers based on a user's changes to a WeeklyBudget Object in the backend.
   * @param  {Object} weeklyBudgetData Data returned from the backend
   */
  var updateWeeklyBudget = function(weeklyBudgetData) {
    $('#weekly-budget-details').prop('disabled', false);
    config.selectors.modal.modal('hide');
    budgetData.weeklyBudget = weeklyBudgetData;
  };

  var toggleHiddenBudgetBarClass = function(budgetBarPresent) {
    if (budgetBarPresent) {
      config.selectors.shiftTemplatesBox.removeClass('hidden-labour-budget');
      config.selectors.calendarBox.removeClass('hidden-labour-budget');
    } else {
      config.selectors.shiftTemplatesBox.addClass('hidden-labour-budget');
      config.selectors.calendarBox.addClass('hidden-labour-budget');
    }

  }

  return {
    init: init,
    budgetEvents: budgetEvents,
    spanBudgets: budgetData.spanBudgets
  };

})(jQuery, window, document);


window.add_tooltip = function(row_name){
  var $tna_tooltip = '<i href="#" data-original-title="' + I18n.t("weekly_budget.js.tna_required") + '" class="fa fa-question-sign top-right tna_tooltip" title="" data-placement="top"></i>';
  $("#"+ row_name +" td:first").append($tna_tooltip);
  $('.tna_tooltip').on('mouseover', function(e) {
    $('.tna_tooltip').tooltip('show');
  });
  $('.tna_tooltip').on('mouseout', function(e) {
    $('.tna_tooltip').tooltip('hide');
  });
}



window.checkOvertimeAndNullify = function(source_row, compare_row, table, is_time_and_attendance) {

   table.find('tr#' + source_row + ' td').each(function(j, col) {
    var $col = $(col);
    if ($col.html().replace('$','').toNum() > $("#"+ compare_row +" td")[j].innerHTML.replace('$','').toNum()) $col.addClass('overtime-hours');
    if (!is_time_and_attendance)  if ($.isNumeric($col.html().replace('$','').toNum())) $col.html('n/a');
  })
}

window.weeklyBudget = weeklyBudget;
