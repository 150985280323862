import { msUtil } from 'core/ms_util.js';
window.pBJController = ( function(){
  var pBJTable = null;
  var init = function(){
    quarterlyButtonClick();
    submitButtonClick();
    yearDropdownChange();
  };

  // SETUP: Quarterly Buttons (Q1, Q2, Q3, Q4)
  var quarterlyButtonClick = function(){
    $("#quarterly-selector .btn").click( function(e){ getPBJTableData({"quarter": $(e.currentTarget).attr('val') })});
  }

  //SETUP: Location "Submit" button
  var submitButtonClick = function() {
    $("#location-dropdown .btn[type=submit-location]").click(function(){getPBJTableData();});
  }

  //SETUP: Year Dropdown
  var yearDropdownChange = function(){
    $("#year-dropdown").change( function(e){getPBJTableData({ "year": $(e.currentTarget).find('option:selected').val() })});
  }

  //SETUP: warning modal when exporting csv
  var exportWarningClick = function(){
    $('#export-csv-btn').click(function(){$("#pbj-warning-modal").modal('show');});
  }

  // DEFAULT: Default Quarter is 1
  var defaultQuarter = function(){
    $("#quarterly-selector .btn[val=1]").addClass('active');
    updateDate();
    return 1;
  }

  // GET FROM DOM: Retreives selected location name and value from DOM.
  var getSelectedLocation = function(){
    var location = $("#location-dropdown option:selected");
    return { name: location.text(), id: location.val() };
  }

  // GET FROM DOM: Retreives selected quarter name and value from DOM.
  var getSelectedQuarter = function(){
    return $('#quarterly-selector .active').attr('val');
  }
  // GET FROM DOM: Retreives selected year name and value from DOM.
  var getSelectedYear = function(){
    return $("#year-dropdown option:selected").val();
  }



  // DATA TABLE: Create new dataTable
  var newDataTable = function(columns, data){
    pBJTable = $("#pbj-table").dataTable({
      bPaginate: true,
      sPaginationType: "full_numbers",
      bLengthChange: false,
      iDisplayLength: 20,
      bInfo: false,
      bAutoWidth: false,
      aaSorting: [[0,'asc'], [3,'asc']],
      aaData: data,
      aoColumns: columns,
      oLanguage: dataTables_i18n
    });
  }

  // DATA TABLE: Update the DataTable with new data, or make a new table if one doesn't exist.
  var updateDataTable = function(columns,data){
    if(!pBJTable){ // if a table doesn't exist, create one.
      newDataTable(columns, data);
    } else { // else, update the existing table
      pBJTable.fnClearTable();
      pBJTable.fnAddData(data);
    }
  }

  // AJAX: Request for displayed PBJ data.
  var getPBJTableData = function(params){
    params = params || {};
    var location = getSelectedLocation();
    var year = params.year || getSelectedYear();
    var quarter = params.quarter || getSelectedQuarter() || defaultQuarter();
    var url = '/analytics/pbj/page_data';
    var data = {
      year: year,
      quarter: quarter,
      location_id: location.id
    };

    var spinner = showLoading();
    $.ajax({
        url: url,
        data: data,
        dataType: "json"
      })
      .done(function(res){
        $('.spinner').fadeOut(300, function(){
          hideLoading(spinner);
          updateDate(); // update the date displayed under quarterly selector
          if(res.data.length == 0){
            resultEmptyWarning();
          } else {
            updateLocationTitle(location.name, res.data[0].facilityId); // update the location and facility id
            updateExportButton(location.id, year, quarter, res.warnings ) // show the export button
            updateDataTable(res.columns, res.data); // populate table with results
            showResults(); //show results
          }
        });
      })
      .fail(function(e){
        $('.spinner').fadeOut(300, function(){
          hideLoading(spinner);
          updateDate(); // update the date displayed under quarterly selector
          if(e.status == 422){
            showPayPeriodModal(location.id);
          } else {
            // console.log("Oops - something went wrong.");
          }
        });
      });
  }

  // UPDATE DOM: Main container
  var mainContainerID = "#pbj-data-container";

  // UPDATE DOM: Location and Facility ID on page
  var updateLocationTitle = function(location, facilityID){
    $(mainContainerID + " .location").text(location);
    $(mainContainerID + " .facility").text(I18n.t('pbj.js.facility', { id: facilityID }));
  }

  // UPDATE DOM: Export a CSV button
  var updateExportButton = function(locationID, year, quarter, warnings){
     var url = "/analytics/pbj/csv?location_id=" + locationID + "&year=" + year + "&quarter=" + quarter;

    // if there are export warnings, create a modal
    if(warnings.length > 0){
      var list_items =  warnings.map(function(w){
        return "<li class='item'>"+w+"</li>";
      });
      $("#pbj-warning-modal .warnings").html(list_items.join(""));
      $("#pbj-warning-btn").attr("href", url);
      $("#export-csv-btn").html("<a class='show-warning'>" + I18n.t('pbj.js.export') + "</a>");
      exportWarningClick(); // add click event
    }
    // else let the user simply export a CSV
    else {
      $("#export-csv-btn").html("<a href='"+url+"'>" + I18n.t('pbj.js.export') + "</a>");
    }
    $("#export-csv-btn").show();
  }

  // UPDATE DOM: Quarterly Months
  var updateDate = function(){
    var quarter = getSelectedQuarter();
    var year = getSelectedYear();

    var monthSelector = $(mainContainerID + " .date");
    switch(quarter) {
    case "1":
        monthSelector.text(I18n.t('pbj.js.q1', { year: year }));
        break;
    case "2":
        monthSelector.text(I18n.t('pbj.js.q2', { year: year }));
        break;
    case "3":
        monthSelector.text(I18n.t('pbj.js.q3', { year: year }));
        break;
    case "4":
        monthSelector.text(I18n.t('pbj.js.q4', { year: year }));
        break;
    }
  }

  // UPDATE DOM: Show modal
  var showPayPeriodModal =function(locationID) {
    $("#payroll-modal-btn").attr('href',"/locations/" + locationID + "/payroll");
    $('#payroll-modal').modal('show');
  }

  // UPDATE DOM: Display results empty warning, hide results
  var resultEmptyWarning = function(locationID){
    $(mainContainerID + " .results").hide();
    $("#export-csv-btn").hide();
    $(mainContainerID + " .resultEmpty-warning").fadeIn();
  }
  // UPDATE DOM: Display results, hide warning
  var showResults = function(locationID){
    $(mainContainerID + " .resultEmpty-warning").hide();
    $(mainContainerID + " .results").fadeIn();
  }

  // UPDATE DOM: Show loading (and hide results)
  var showLoading = function() {
    $(mainContainerID + " .resultEmpty-warning").hide();
    $(mainContainerID + " .results").hide();
    $('#pbj-loading').show();
    return msUtil.spinner('pbj-loading');
  }

  // UPDATE DOM: Hide loading
  var hideLoading = function(spinner) {
    $('#pbj-loading').hide();
    spinner.stop();
  }

  return {
    init: init
  };

})(jQuery);
