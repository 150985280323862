import AvailabilityPreferences from './availability_preferences.vue';
import AvailabilityCalendar from './availability_calendar.vue';
import CalendarSidebar from 'core/vue/common/calendar_sidebar.vue';
import BaseModal from 'core/vue/common/base_modal.vue';
import { remoteFormErrors } from 'core/remote_form_errors';

const AvailabilityVue = Vue.extend({
  data() {
    return {
      userId:         null,
      preferences:    [],
      
      // UI
      modalKey:       0,
      repeatsVisible: false,
      currentModal:   null,
      
      // Default form values
      eventInfo: {
        id:          null,
        date:        null,
        date_string: '',
        start_time:  null,
        end_time:    null,
        starts_on:   null,
        ends_on:     null,
        repeats_on:  [],
        repeats:     false,
      },
    };
  },
  mounted() {
    // Grab user id through data attribute
    this.userId = $(this.$el).data('userId');
    this.initModalStates();
    this.initAjaxHandlers();
    this.initDatepickers();
    this.getPreferences();
  },
  methods: {
    initModalStates() {
      $(this.$el).on('click', '[data-dismiss="modal"]', () => {
        // Add timeout to wait for modal animation to finish
        // Triggers a re-render of all modals to reset state
        setTimeout(() => {
          this.modalKey += 1;
          this.repeatsVisible = false;
        }, 100);
      });
    },

    initAjaxHandlers() {
      $(this.$el)
        .on('ajax:beforeSend', '.modal', () => {
          this.setButtonDisabled(true);
        })
        .on('ajax:complete', '.modal', () => {
          this.setButtonDisabled(false);
        })
        .on('ajax:success', '.modal', (e) => {
          this.refetchPage(e.currentTarget);
        })
        .on('ajax:error', '.modal', (e, xhr) => {
          this.showErrors(e.currentTarget, xhr);
        });
    },

    initDatepickers() {
      // Due to the modals re-rendering, we need to re-initialize the datepicker inputs as well
      $(this.$el)
        .on('focus', 'input.datepicker[name="recurring_availability[recurrence_start]"]', function() {
          $(this).datepicker({
            format:      'yyyy-mm-dd',
            orientation: 'right auto',
            language:    I18n.locale,
            autoclose:   true,
            startDate:   Date.today(),
          })
          .on('click', function() {
            $(this).datepicker('setDate', $(this).val());
          })
        })
        .on('focus', 'input.datepicker[name="recurring_availability[recurrence_end]"]', function() {
          const startsOnVal = $(this).parents().filter('form').find('input.datepicker[name="recurring_availability[recurrence_start]"]').val();
          const defaultDate = startsOnVal ? moment(startsOnVal).add(1, 'd').toDate() : Date.today();
          $(this).datepicker({
            format:          'yyyy-mm-dd',
            orientation:     'right auto',
            language:        I18n.locale,
            autoclose:       true,
            defaultViewDate: defaultDate, 
          })
          .on('click', function() {
            $(this).datepicker('setDate', $(this).val())
                   .datepicker('setStartDate', defaultDate)
          });
        });
    },

    getPreferences() {
      $.ajax({
        url: `/availabilities/preferences?user_id=${this.userId}`,
        success: (res) => this.preferences = res.data.preferences
      });
    },

    deleteAvailability(modalEl) {
      const { id, repeats } = this.eventInfo;
      $.ajax({
        url: `/availabilities?user_id=${this.userId}&` + (repeats ? `recurring_id=${id}` : `availability_id=${id}`),
        method: 'delete',
        success: () => this.refetchPage(modalEl),
        error: (err) => this.showErrors(modalEl, err)
      });
    },

    deleteSingleAvailability(modalEl) {
      const { id, date } = this.eventInfo;
      $.ajax({
        url: `/availabilities/soft_destroy?user_id=${this.userId}&recurring_id=${id}&date=${date}`,
        method: 'put',
        success: () => this.refetchPage(modalEl),
        error: (err) => this.showErrors(modalEl, err)
      });
    },

    refetchPage(modalEl) {
      $(modalEl).find('[data-dismiss="modal"]').click();
      this.getPreferences();
      this.$refs.availabilityCalendar.fullCalendarApi.refetchEvents();
    },

    showErrors(modalEl, xhr) {
      const res = xhr.responseJSON;
      const alert = $(modalEl).find('.alert-error');
      alert.hide();
      if (res.klass) {
        remoteFormErrors.errorFields($(modalEl), res.klass, res.errors);
      }
      if (res.error_message || res.errors.base) {
        alert.show()
            .find('#flash-message')
            .html(res.error_message || res.errors.base.join('. '));
      }
    },

    openModal(modalEl, eventInfo) {
      this.currentModal = modalEl;
      if (eventInfo) {
        this.eventInfo = eventInfo;
        this.repeatsVisible = eventInfo.repeats;
      }
      this.$nextTick(() => $(modalEl).modal('show'));
    },

    submitForm(modalEl) {
      $(`${modalEl} form`).submit();
    },

    toggleRepeats() {
      this.repeatsVisible = !this.repeatsVisible;
    },

    setButtonDisabled(bool) {
      $('.modal .btn-primary').prop('disabled', bool);
    },
  },
  // Need to import all necessary components here.
  // This is necessary in order to render them in ERB templates.
  components: {
    AvailabilityPreferences,
    AvailabilityCalendar,
    CalendarSidebar,
    BaseModal
  },
});

window.AvailabilityVue = AvailabilityVue;

export default AvailabilityVue;
