import { analytics } from 'core/analytics/analytics.js';
window.analyticsTurnover = (function($) {

  /*
  public
  */

  var locationGroup, departmentGroup;

  // Initialize Analytics::Turnover
  var init = function(settings) {
    var today = new Date();
    $.extend(config, {
      endDate: new Date(today.setFullYear(today.getFullYear(), today.getMonth(), today.getDate() + 7)),
    });
    initTurnover();

    locationGroup = $('.turnover').find('#location-department-filter optgroup#location')
    departmentGroup = $('.turnover').find('#location-department-filter optgroup#department')
  };

  /*
  private
  */

  // Default settings
  var config = {
    chartName: "turnover",
    filterName: "data-set-filter"
  };

  var clearCustomDateRangeForTurnover = function() {
    analytics.clearCustomDateRange(".turnover");
    getTurnover();
  };

  var getFilterDataHashForTurnover = function() {
    var employmentType = $('option:selected', '#data-set-filter').val();
    var filterDataHash = { employmentType: employmentType }

    return filterDataHash;
  };

  var getTurnover = function() {
    var isInactive = $(this).hasClass('inactive');
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();
    var dateRange = analytics.filterDateRange($(this).text());
    var employmentType = $('option:selected', '#data-set-filter').val();
    config.endDate = analytics.filterEndDate($(this).attr('id'), config.endDate, dateRange);
    var dataHash = { locations: locationIds, departments: departmentIds, employmentType: employmentType, range: dateRange, endDate: $.datepicker.formatDate('yy/mm/dd', config.endDate) }

    if (!isInactive) { $('#employment_type_filter').hide(); }

    if (analytics.canMakeChartRequest(config.chartName, isInactive, locationIds, departmentIds)) {
      $('#employment_type_filter').show();
      analytics.makeChartRequest(MKS.turnover_url, dataHash);
    } else {
      return;
    }
  };

  var initTurnover = function() {
    analytics.initializePage($('.turnover'), $('#turnover-chart-box'));
    $('#employment_type_filter').hide();
    $('.turnover').find('#btn-group-date-range #date-range-days').removeClass('active');
    $('.turnover').find('#btn-group-date-range #date-range-months').addClass('active');
    $('.turnover').find('#data-set-filter').on('change', makeRequestForTurnover);
    $('.turnover').find('#location-department-filter').select2().on('change', removeTurnoverOptionGroup);
    $('.turnover #submit-chart').on("click", makeRequestForTurnover);
    $('.turnover #date-range-days, .turnover #date-range-weeks, .turnover #date-range-months, .turnover #date-range-previous, .turnover #date-range-next').on('click', getTurnover);

    $('.turnover #set-custom-date-range').on("click", selectCustomDateRangeForTurnover);
    $('.turnover #clear-custom-date-range').on("click", clearCustomDateRangeForTurnover);
  };

  var makeRequestForTurnover = function() {
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();

    if (locationIds.length == 0 && departmentIds.length == 0) { analytics.clearCustomDateRange(".turnover"); }
    if ($(".turnover #date-range-days").hasClass('inactive')) {
      selectCustomDateRangeForTurnover();
    } else {
      getTurnover();
    }
  };

  var removeTurnoverOptionGroup = function() {
    analytics.removeOptionGroup($('.turnover').find('#location-department-filter'), locationGroup, departmentGroup);
  };

  var selectCustomDateRangeForTurnover = function() {
    var url = MKS.turnover_url;
    analytics.selectCustomDateRange(url, ".turnover");

    // if the start & end date are already set, execute the ajax call, otherwise listen for a date change event
    if ($(".analytics #start-date").val() != "" && $(".analytics #end-date").val() != "") {
      analytics.requestCustomDateRange(url, getFilterDataHashForTurnover(), config.chartName, config.filterName);
    }
    else {
      $('.analytics .input-daterange').on("changeDate", function(event) {
        analytics.requestCustomDateRange(url, getFilterDataHashForTurnover(), config.chartName, config.filterName);
      });
    }
  };


  return {
    init: init,

  };

})(jQuery);
