import { remoteFormErrors } from 'core/remote_form_errors.js'
import { flashMessages } from 'core/helpers/flash_messages.js'
// Handles model-agnostic interface for standardized ajax table-based CRUD
window.tableCrud = (function ($, window, document) {
  // public

  var init = function (settings) {
    if (settings) {
      $.extend(true, config, settings)
    }

    initAddButton()
    initForms()
    initActions()
  }

  // private

  var config = {
    maxRecords: null, // Maximum number of associated records of the current type allowed
    reloadOnEmpty: false // Reload page when last record is destroyed
  }

  var initAddButton = function () {
    // set initial add button state based on presence of max records
    toggleAddButton()

    $('body').on('click', 'li.add-btn a', function (e) {
      e.preventDefault()
      var $self = $(this)

      // prevent concurrent additions
      if ($self.closest('li.add-btn').hasClass('disabled')) {
        return false
      }

      // disable add/edit buttons
      toggleAddButton(false)
      toggleEditButtons(false)

      // load new form and focus
      $.get($self.attr('href')).then((html) => {
        $('#records tbody').not('.position-group-sub-table').prepend(html)
        $('.new-record-row form input[type="text"]:first').focus()

        // sending out an event so we can start hooking into this and perhaps
        // begin refactoring into Vue components and/or something like that.
        $(document).trigger('tablecrud:added_row')
      })
    })
  }

  // Handles form submission, success, and errors
  // On success, load created/updated record template via the returned #show action link
  // On error, display record errors or error message depending on what's returned
  var initForms = function () {
    $('body')
      .on('ajax:success', '.new-record-row form', (e, data) => {
        $.get(data.link).then((html) => {
          $('.new-record-row').replaceWith(html)
          toggleAddButton()
          toggleEditButtons(true)
        })
      })
      .on('ajax:success', '.edit-record-row form', (e, data) => {
        $.get(data.link).then((html) => {
          var $row = $('.edit-record-row')
          $row.prev('tr').replaceWith(html)
          $row.remove()
          toggleAddButton()
          toggleEditButtons(true)
        })
      })
      .on('ajax:error', '.new-record-row form', (e, xhr, status, error) => {
        var responseJSON = xhr.responseJSON
        if (responseJSON.errors) {
          // record error
          remoteFormErrors.errorFields('.new-record-row form', responseJSON.klass, responseJSON.errors)
        } else if (responseJSON.error_message) {
          // combined error message
          remoteFormErrors.errorMessage('.new-record-row form', responseJSON.error_message)
        } else {
          // fallback to a page reload
          location.reload()
        }
      })
      .on('ajax:error', '.edit-record-row form', (e, xhr, status, error) => {
        var responseJSON = xhr.responseJSON
        if (responseJSON.errors) {
          // record error
          remoteFormErrors.errorFields('.edit-record-row form', responseJSON.klass, responseJSON.errors)
        } else if (responseJSON.error_message) {
          // combined error message
          remoteFormErrors.errorMessage('.edit-record-row form', responseJSON.error_message)
        } else {
          // fallback to a page reload
          location.reload()
        }
      })
  }

  // Handles remote links present on record rows and forms

  var initActions = function() {
    $('body').on('click', 'a[data-tr-action], button[data-tr-action]', function(e) {
      e.preventDefault();
      var $self = $(this);

      switch($self.data('tr-action')) {

      case 'cancel-new':
        $self.closest('tr.new-record-row').remove();
        toggleAddButton();
        toggleEditButtons(true);
        break;

      case 'edit':
        if (!$self.hasClass('disabled')) {
          toggleAddButton(false);
          toggleEditButtons(false);
          $.get($self.attr('href'))
          .then(function(html) {
            var $row = $self.closest('tr');
            $row.after(html);
            $row.hide();
            $(document).trigger('tablecrud:edit_row');
          });
        }
        setTimeout(function() {$(document).trigger('redrawSVG')}, 500);
        break;

      case 'cancel-edit':
        var $row = $self.closest('tr.edit-record-row');
        $row.prev('tr').show();
        $row.remove();
        toggleAddButton();
        toggleEditButtons(true);
        break;

      case 'destroy':

        // Give a chance for the confirm box to be overridden
        if (!$.rails.fire($self, 'confirm')) {
          return;
        }

        var deleteButtonText = I18n.t("buttons.delete");
        if ($self.data('tr-confirm-button-text')) {
          deleteButtonText = $self.data('tr-confirm-button-text');
        }

        // TODO: Should this use the normal UJS data-confirm-message stuff? Not sure why we've baked our own here...
        var message = $self.data('tr-action-confirm-message');
        var buttons = [
          {
            "label": I18n.t("buttons.cancel"),
            "class": 'btn-secondary'
          },
          {
            "label": deleteButtonText,
            "class": 'btn-danger',
            "callback": function() {
              if ($self.data('tr-confirm-action-url')) {
                // Custom action URL is provided, use it with DELETE or specified method
                var url = $self.data('tr-confirm-action-url');
                var type = $self.data('tr-confirm-action-type') || 'DELETE';
                $.ajax({
                  url: url,
                  type: type,
                  dataType: 'json'
                }).done(response => {
                  flashMessages.success(response.message);
                  $self.closest('tr').remove();
                  if (config.reloadOnEmpty && rowCount() === 0) {
                    location.reload();
                  } else {
                    toggleAddButton();
                  }
                }).fail(jqXHR => {
                  var errorResponse = jqXHR.responseJSON;
                  flashMessages.error(errorResponse.error_message);
                });
              } else {
                $.ajax({
                  url: $self.attr('href'),
                  type: 'DELETE',
                  dataType: 'json'
                }).then(() => {
                  $self.closest('tr').remove();
                  if (config.reloadOnEmpty && rowCount() === 0) {
                    location.reload();
                  } else {
                    toggleAddButton();
                  }
                }, (data, status, xhr) => {
                  flashMessages.error(data.responseJSON.error_message);
                });
              }
            } // function
          } // callback
        ];

        bootbox.dialog(message, buttons);

        break;
      }
    })
  }

  // Number of objects in play, including persisted and new_record
  var rowCount = function () {
    return $('#records').find('tbody tr:not(.empty-row)').length
  }

  // Controls state of add button based on optional enforced maximum number of objects, with optional override state
  var toggleAddButton = function (state) {
    var $button = $('.add-btn')
    var enabled
    if (state != undefined) {
      enabled = state
    } else {
      enabled = config.maxRecords === null || rowCount() < config.maxRecords
    }
    enabled ? $button.removeClass('disabled') : $button.addClass('disabled')
  }

  // Controls state of edit buttons
  var toggleEditButtons = function (enabled) {
    var $buttons = $('a[data-tr-action="edit"]')
    enabled ? $buttons.removeClass('disabled') : $buttons.addClass('disabled')
  }

  return {
    init: init
  }
})(jQuery, window, document)
