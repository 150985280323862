import { msUtil } from 'core/ms_util.js'
window.timeClock = (function ($, global) {
  var nextShift
  var position
  var currentPunch
  var currentUser
  var departments
  var locations
  var job_sites
  var positions
  var breakPunch
  var timeoutInterval
  var flashInterval
  var clearIDInterval
  var clearPasswordInterval
  var employeeIDInterval
  var timeoutLength = 15000
  var initialized = false
  var countdownTimerLength = 14000
  var countdownInterval

  var initTimeClock = function () {
    moment.locale(I18n.locale)

    if (!initialized) {
      init()

      //back button killer so users in safari can't use the back button to go
      //through history and access the admin tools. (Of course this won't stop
      //someone from viewing the browser history and going in that way, server
      //side session checks should prevent that method).
      var _hash = '!'
      var noBackPlease = function () {
        global.location.href += '#'
        global.setTimeout(function () {
          global.location.href += '!'
        }, 50)
      }

      global.onhashchange = function () {
        if (global.location.hash !== _hash) {
          global.location.hash = _hash
        }
      }

      global.onload = function () {
        noBackPlease()

        // disables backspace on page except on input fields and textarea..
        document.body.onkeydown = function (e) {
          var elm = e.target.nodeName.toLowerCase()
          if (e.which === 8 && elm !== 'input' && elm !== 'textarea') {
            e.preventDefault()
          }
          e.stopPropagation()
        }
      }
    }
  }

  var init = function () {
    if (!initialized) {
      initialized = true
      updateClock()
      setInterval(updateClock, 1000)

      initializeButtons()
      resetClockInFlow()
      initAjaxSetup()

      $(document).off('load.time_clock.show', init)
      $(document).off('load.time_clock.time_clock_sign_out.show', init)
    }
  }

  var initAjaxSetup = function () {
    $.ajaxSetup({
      complete: function (jqXHR, textStatus, errorThrown) {
        if (jqXHR.status == 401) {
          location.reload();
        }
      }
    })
  }

  var setClockInButtonLabel = function (label) {
    var punchInButton = $('body.time_clock .clock-in-employee-id')
    if (_.isElement(punchInButton[0])) {
      punchInButton.html(label)
    }
  }

  var resetClockInFlow = function (skipResetFlash) {
    //reset UI
    $('body.time_clock .next-employee-id').addClass('active')
    $('body.time_clock .employee-id-lookup').addClass('active')
    $('body.time_clock .punch-card').removeClass('active')
    $('body.time_clock .punch-in-card').removeClass('active')
    $('body.time_clock .punch-out-card').removeClass('active')
    $('body.time_clock .start-break-card').removeClass('active')
    $('body.time_clock .start-break-card').removeClass('active')
    $('body.time_clock span.title').html('&nbsp;')
    if ($('body.time_clock.time_clock_sign_out').length == 0) {
      $('.separate-sections li:last-child').removeClass('active')
    }
    $('.time-interval-warning label').text('')

    //clear idField
    clearEmployeeID()

    $('body.time_clock #time-clock-department-filter').select2('close')
    $('body.time_clock #time-clock-job-site-filter').select2('close')
    $('body.time_clock #time-clock-position-filter').select2('close')

    var idField = $('body.time_clock input[name="employee_id"]')
    idField.removeAttr('disabled')
    clearEmployeeIDInterval()

    //clear data store
    nextShift = null
    position = null
    currentPunch = null
    currentUser = null
    departments = null
    locations = null
    job_sites = null
    positions = null

    setClockInButtonLabel(I18n.t('timeclock.js.clock_in'))

    //clear department select box
    $('body.time_clock #time-clock-department-filter').val($('body.time_clock #time-clock-department-filter option:first').val())

    //$('.separate-sections li').css('width', '385px');
    $('body.time_clock .position').html('')
    $('body.time_clock .job_site').html('')
    $('body.time_clock .breaks').html('')

    //clear any living reset timeouts
    clearTimeOut()
    clearEmployeeInterval()
    if (!skipResetFlash) {
      clearFlashInterval()
    }
  }

  //get initial server time, and updated it by 1000ms every second.
  var updateClock = function () {
    //null check clock time if it isn't instantiated yet
    if (typeof document.ctoday !== 'undefined') {
      var utcSeconds = Date.now() / 1000
      //create new epoch date
      var d = new Date(0)

      var currentTime = new Date(d.setUTCSeconds(utcSeconds))
      var currentHours = currentTime.getHours()
      var currentMinutes = currentTime.getMinutes()
      var currentSeconds = currentTime.getSeconds()

      //pad with zeros
      currentMinutes = (currentMinutes < 10 ? '0' : '') + currentMinutes
      currentSeconds = (currentSeconds < 10 ? '0' : '') + currentSeconds

      var timeOfDay = currentHours < 12 ? I18n.t('time.am') : I18n.t('time.pm')
      currentHours = currentHours > 12 ? currentHours - 12 : currentHours
      currentHours = currentHours == 0 ? 12 : currentHours

      var currentTimeString = currentHours + ':' + currentMinutes + ':' + currentSeconds + ' '

      var timeOfDayElement = document.getElementById('time_of_day')
      if (timeOfDayElement == null) {
        timeOfDayElement = document.createElement('span')
        var timeAttribute = document.createAttribute('id')
        timeAttribute.value = 'time_of_day'
        timeOfDayElement.setAttributeNode(timeAttribute)
      }
      timeOfDayElement.innerHTML = timeOfDay

      var domTime = document.getElementById('time-clock-active-clock')
      if (typeof domTime !== 'undefined') {
        document.getElementById('time-clock-active-clock').firstChild.nodeValue = currentTimeString
        document.getElementById('time-clock-active-clock').appendChild(timeOfDayElement)
      }
    }
  }

  var initializeButtons = function () {
    var nextButton = $('body.time_clock .next-employee-id')
    if (_.isElement(nextButton[0])) {
      nextButton.on('click', validateEmployeeIdEntry)
    }

    var submitButton = $('body.time_clock .submit-employee-id')
    if (_.isElement(submitButton[0])) {
      submitButton.on('click', onEmployeeFormSubmit)
    }

    var idField = $('body.time_clock input[name="employee_id"]')
    if (_.isElement(idField[0])) {
      idField.on('input', onEmployeeIdInput)
    }

    var deptField = $('body.time_clock select#time-clock-department-filter')
    if (_.isElement(deptField[0])) {
      deptField.on('change', onEmployeeDeptSelection)
    }

    var startBreakButton = $('body.time_clock button.start-break')
    if (_.isElement(startBreakButton[0])) {
      startBreakButton.on('click', startBreakPunch)
    }

    var endBreakButton = $('body.time_clock button.end-break')
    if (_.isElement(endBreakButton[0])) {
      endBreakButton.on('click', endBreakPunch)
    }

    var punchOutButton = $('body.time_clock .clock-out-employee-id')
    if (_.isElement(punchOutButton[0])) {
      punchOutButton.on('click', punchOutEmployee)
    }

    var cancelPunchInButton = $('body.time_clock .cancel-clock-in')
    if (_.isElement(cancelPunchInButton[0])) {
      cancelPunchInButton.on('click', cancelPunchIn)
    }

    var cancelSubmitPunchInButton = $('body.time_clock .cancel-submit-clock-in')
    if (_.isElement(cancelSubmitPunchInButton[0])) {
      cancelSubmitPunchInButton.on('click', cancelPunchIn)
    }

    var punchInButton = $('body.time_clock .clock-in-employee-id')
    if (_.isElement(punchInButton[0])) {
      punchInButton.on('click', onPunchInClicked)
    }

    var employeeForm = $('body.time_clock form.employee-id-lookup')
    if (_.isElement(employeeForm[0])) {
      $('body.time_clock form.employee-id-lookup').submit(onEmployeeFormSubmit)
    }

    var departmentDrop = $('body.time_clock #time-clock-department-filter')
    if (_.isElement(departmentDrop[0])) {
      departmentDrop.on('select2-focus select2-blur select2-change change', setUpDepartmentTimeout)
    }

    var signOutButton = $('body.time_clock_sign_out button.btn.sign-out-employee-id')
    if (_.isElement(signOutButton[0])) {
      signOutButton.on('click', signOutOfTimeClock)
    }

    var signOutPasswordField = $('body.time_clock_sign_out #password')
    if (_.isElement(signOutPasswordField[0])) {
      signOutPasswordField.on('focus', function () {
        signOutPasswordField.on('keypress', onEnterTapped)
        signOutPasswordField.removeAttr('readonly')
      })

      signOutPasswordField.on('blur', function () {
        signOutPasswordField.off('keypress', onEnterTapped)
        signOutPasswordField.attr('readonly', 'readonly')
        signOutPasswordField.focus()
      })

      signOutPasswordField.on('input', function () {
        clearPasswordTimeout()
        //adding a comment to trigger a build.
        //this hack is so FF doesn't auto fill in the sign out password.
        if ($(this).attr('type') != 'password') {
          $(this).attr('type', 'password')
        }
      })

      signOutPasswordField.focus()
    }

    var passwordScreen = $('.time_clock.time_clock_sign_out.show')
    if (_.isElement(passwordScreen[0])) {
      setUpClearPasswordInterval()
    }
  }

  var onEnterTapped = function (e) {
    if (e.which === 13) {
      signOutOfTimeClock()
      e.preventDefault()
    }
  }

  var signOutOfTimeClock = function () {
    $('form#form-admin-sign-out').submit()
  }

  var onEmployeeFormSubmit = function (e) {
    if ($('.separate-sections li:last-child').hasClass('active')) {
      validateDepartmentSelection(e)
    } else {
      validateEmployeeIdEntry(e)
    }
    if (typeof e !== 'undefined') e.preventDefault()

    //re-enable clock in button
    $('body.time_clock .clock-in-employee-id').prop('disabled', false)
  }

  var validateEmployeeIdEntry = function (e) {
    var idField = $('body.time_clock input[name="employee_id"]')
    if (_.isElement(idField[0])) {
      if (idField.val().trim() == '') {
        displayFlashError(I18n.t('timeclock.js.valid_punch_id'))
        clearEmployeeIdInterval()
      } else {
        retrieveEmployeePunches(idField.val().trim())
      }
    }
    e.preventDefault()
  }

  var validateDepartmentSelection = function (e) {
    var deptField = $('body.time_clock select#time-clock-department-filter')
    var idField = $('body.time_clock input[name="employee_id"]')
    if (_.isElement(deptField[0])) {
      if (deptField.val().trim() == '') {
        displayFlashError(I18n.t('timeclock.js.please_select_dept'))
      } else {
        showShift()
      }
    }
  }

  var getBaseDomain = function () {
    return window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/'
  }

  var startBreakPunch = function (e) {
    $('body.time_clock button.start-break').prop('disabled', true)
    var url = getBaseDomain() + 'api/live/v3/kiosk_punches/' + currentPunch.id + '/start_break/?kiosk_desktop_mode=true'
    setEndpointHeaders()
    var sendData = {
      kiosk_punch: {
        user_id: currentUser.id.toString(),
        punch_id: currentPunch.id,
        shift_id: currentPunch.shift_id
      }
    }
    $.ajax({
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      url: url,
      data: JSON.stringify(sendData),
      success: onStartBreakPunchSuccess,
      error: onStartBreakPunchFail
    })
    clearTimeOut()
  }

  var onStartBreakPunchSuccess = function (response) {
    displayFlashSuccess(
      I18n.t('timeclock.js.started_break_at_success', {
        user_name: '<strong>' + currentUser.name + '</strong>',
        time: '<strong>' + moment.unix(response.break_punch.starts_at).tz(getDepartmentTimeZone(response.punch.department_id)).format('h:mm A') + '</strong>'
      })
    )
    resetClockInFlow(true)
  }

  var onStartBreakPunchFail = function (error) {
    displayFlashError('Error: ' + error.responseJSON.error_message)
    timeoutInterval = setInterval(resetClockInFlow, 10000)
  }

  var endBreakPunch = function (e) {
    $('body.time_clock button.end-break').prop('disabled', true)
    var url = getBaseDomain() + 'api/live/v3/kiosk_punches/' + currentPunch.id + '/end_break/?kiosk_desktop_mode=true'
    setEndpointHeaders()
    var sendData = { kiosk_punch: { user_id: currentUser.id.toString(), punch_id: currentPunch.id } }
    $.ajax({
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      url: url,
      data: JSON.stringify(sendData),
      success: onEndBreakPunchSuccess,
      error: onEndBreakPunchFail
    })
    clearTimeOut()
  }

  var onEndBreakPunchSuccess = function (response) {
    displayFlashSuccess(
      I18n.t('timeclock.js.ended_break_at_success', {
        user_name: '<strong>' + currentUser.name + '</strong>',
        time: '<strong>' + moment.unix(response.break_punch.ends_at).tz(getDepartmentTimeZone(response.punch.department_id)).format('h:mm A') + '</strong>'
      })
    )
    resetClockInFlow(true)
  }

  var onEndBreakPunchFail = function (error) {
    displayFlashError('Error: ' + error.responseJSON.error_message)
    timeoutInterval = setInterval(resetClockInFlow, 10000)
  }

  var punchOutEmployee = function (e) {
    $('body.time_clock button.clock-out-employee-id').prop('disabled', true)
    var url = getBaseDomain() + 'api/live/v3/kiosk_punches/' + currentPunch.id + '?kiosk_desktop_mode=true'
    setEndpointHeaders()
    var sendData = { kiosk_punch: { user_id: currentUser.id.toString() } }
    $.ajax({
      method: 'PUT',
      dataType: 'json',
      contentType: 'application/json',
      url: url,
      data: JSON.stringify(sendData),
      success: onEmployeePunchedOutSuccess,
      error: onEmployeePunchedOutFail
    })
    clearTimeOut()
  }

  var onEmployeePunchedOutSuccess = function (response) {
    displayFlashSuccess(
      I18n.t('timeclock.js.punch_out_success', {
        user_name: '<strong>' + currentUser.name + '</strong>',
        time: '<strong>' + moment.unix(response.punch.punched_out_at).tz(getDepartmentTimeZone(response.punch.department_id)).format('h:mm A') + '</strong>'
      })
    )
    resetClockInFlow(true)
  }

  var onEmployeePunchedOutFail = function (error) {
    displayFlashError(I18n.t('errors.error', { message: error.responseJSON.error_message }))
    timeoutInterval = setInterval(resetClockInFlow, 10000)
  }

  var cancelPunchIn = function (e) {
    clearFlashMessage()
    resetClockInFlow()
    e.preventDefault()
  }

  var onPunchInClicked = function (e) {
    var deptID = currentUser.department_ids[0]
    var job_site_id
    var position_id
    var punchInButton = $('body.time_clock .clock-in-employee-id')

    punchInButton.prop('disabled', true)

    if (nextShift != null) {
      deptID = nextShift.department_id
      job_site_id = nextShift.job_site_id
      position_id = nextShift.position_id
    } else {
      var deptField = $('body.time_clock select#time-clock-department-filter')
      deptID = deptField.val()
      job_site_id = $('body.time_clock select#time-clock-job-site-filter').val()
      position_id = $('body.time_clock select#time-clock-position-filter').val()
    }

    punchEmployee(currentUser.id.toString(), deptID.toString(), job_site_id, position_id)
  }

  var onEmployeeIdInput = function (e) {
    var idField = $('body.time_clock input[name="employee_id"]')
    if (_.isElement(idField[0])) {
      clearFlashMessage()
      clearEmployeeInterval()
      setUpTimeoutInterval()
    }
  }

  var onEmployeeDeptSelection = function (e) {
    var deptField = $('body.time_clock select#time-clock-department-filter')
    if (_.isElement(deptField[0])) {
      clearFlashMessage()
    }
  }

  var setEndpointHeaders = function () {
    $.ajaxSetup({
      beforeSend: function (xhr, options) {
        xhr.setRequestHeader('Authorization', 'Token ' + document.ctoken)
        return true
      },
      headers: {
        'X-APP-NAME': window.server_config.admin_mobile_app || 'makeshift_live',
        'X-APP-VERSION': '2.7.0',
        'X-DEVICE-TYPE': 'ios'
      }
    })
  }

  var punchEmployee = function (id, deptID, job_site_id, position_id) {
    var url = getBaseDomain() + 'api/live/v3/departments/' + deptID + '/kiosk_punches?kiosk_desktop_mode=true'
    setEndpointHeaders()
    var sendData = { kiosk_punch: { user_id: id, job_site_id: job_site_id, position_id: position_id } }
    if (nextShift != null) {
      sendData.kiosk_punch.shift_id = nextShift.id
    }
    $.ajax({
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      url: url,
      data: JSON.stringify(sendData),
      success: onEmployeePunchSuccess,
      error: onEmployeePunchFail
    })
    clearTimeOut()
  }

  var onEmployeePunchSuccess = function (response) {
    displayFlashSuccess(
      I18n.t('timeclock.js.punch_in_success', {
        user_name: '<strong>' + currentUser.name + '</strong>',
        time: '<strong>' + moment.unix(response.punch.punched_in_at).tz(getDepartmentTimeZone(response.punch.department_id)).format('h:mm A') + '</strong>'
      })
    )
    resetClockInFlow(true)
  }

  var onEmployeePunchFail = function (error) {
    displayFlashError('Error: ' + error.responseJSON.error_message)
    resetClockInFlow(true)
  }

  var retrieveEmployeePunches = function (id) {
    var url = getBaseDomain() + 'api/live/v3/kiosk_punches'
    setEndpointHeaders()
    $.ajax({
      method: 'GET',
      dataType: 'json',
      contentType: 'application/json',
      url: url,
      data: { user_lookup_key: id },
      success: onEmployeePunchRetrieveSuccess,
      error: onEmployeePunchRetrieveFail
    })
  }

  var getDepartmentTimeZone = function (id) {
    var dept = getDepartmentById(id)
    var loc = getLocationById(dept.location_id)
    return loc.time_zone
  }

  var getLocationById = function (id) {
    var len = locations.length
    var rtnLoc
    for (var i = 0; i < len; i++) {
      if (id == locations[i].id) {
        rtnLoc = locations[i]
        break
      }
    }
    return rtnLoc
  }

  var getLocationName = function (id) {
    var len = locations.length
    var rtnName
    for (var i = 0; i < len; i++) {
      if (id == locations[i].id) {
        rtnName = locations[i].name
        break
      }
    }
    return rtnName
  }

  var getDepartmentById = function (id) {
    var len = departments.length
    var rtnDept
    for (var i = 0; i < len; i++) {
      if (id == departments[i].id) {
        rtnDept = departments[i]
        break
      }
    }
    return rtnDept
  }

  var getDepartmentName = function (id) {
    var len = departments.length
    var rtnDept
    for (var i = 0; i < len; i++) {
      if (id == departments[i].id) {
        rtnDept = {
          name: departments[i].name,
          location_id: departments[i].location_id
        }
        break
      }
    }
    return rtnDept
  }

  var onEmployeePunchRetrieveSuccess = function (response) {
    currentUser = response.user
    departments = response.departments
    locations = response.locations
    position = response.position
    job_sites = response.job_sites
    positions = response.positions
    breakPunch = response.break_punch

    $('#title-with-job-site').hide()
    $('#title-with-position').hide()
    $('body.time_clock #time-clock-job-site-filter').select2('destroy')
    $('body.time_clock #time-clock-position-filter').select2('destroy')
    $('body.time_clock #time-clock-job-site-filter').hide()
    $('body.time_clock #time-clock-position-filter').hide()
    $('body.time_clock .clock-in-employee-id').prop('disabled', false)

    if (typeof response.shift !== 'undefined') {
      nextShift = response.shift
    }

    if (typeof response.punch !== 'undefined') {
      currentPunch = response.punch
      showPunch()
    } else if (typeof response.shift !== 'undefined') {
      showShift()
    } else {
      var len = response.departments.length
      var job_sites_length = response.job_sites.length
      var positions_length = response.positions.length
      if (len == 0) {
        displayFlashError(I18n.t('timeclock.js.employee_not_eligible'))
        clearEmployeeIdInterval()
      } else {
        $('body.time_clock .next-employee-id').removeClass('active')
        $('body.time_clock .employee-id-lookup').addClass('active')
        $('body.time_clock .punch-card').removeClass('active')
        $('body.time_clock .punch-in-card').removeClass('active')
        $('body.time_clock .punch-out-card').removeClass('active')
        $('body.time_clock .start-break-card').removeClass('active')
        $('body.time_clock .start-break-card').removeClass('active')
        $('body.time_clock span.title').html('Clock In')

        var html = '<option></option>'
        for (var i = 0; i < len; i++) {
          html += '<option value="' + response.departments[i].id + '">' + response.locations[i].name + ' - ' + response.departments[i].name + '</option>'
        }

        $('body.time_clock #time-clock-department-filter').html(html)

        $('body.time_clock #time-clock-department-filter')
          .select2({
            placeholder: I18n.t('timeclock.js.select_your_department')
          })
          .on('change', function (event) {
            var department_id = parseInt(event.val)
            enableAndPopulateJobSiteSelect(department_id, job_sites)
            enableAndPopulatePositionSelect(department_id, positions)
          })

        setUpDepartmentTimeout()
        clearEmployeeIDInterval()

        var idField = $('body.time_clock input#employee_id')
        idField.attr('disabled', 'disabled')

        //$('.separate-sections li').css('width','300px');
        //$('.separate-sections li:last-child').css('width','385px');
        $('.separate-sections li:last-child').addClass('active')
        setClockInButtonLabel(I18n.t('timeclock.js.manual_clock_in'))

        if (len === 1) {
          $('body.time_clock #time-clock-department-filter').val(response.departments[0].id).trigger('change')

          if (job_sites_length === 0 && positions_length === 0) {
            onEmployeeFormSubmit()
          } else {
            enableAndPopulateJobSiteSelect(response.departments[0].id, job_sites)
            enableAndPopulatePositionSelect(response.departments[0].id, positions)
          }
        }
      }
    }
  }

  var enableAndPopulateJobSiteSelect = function (department_id, job_sites) {
    var department_job_sites = _.where(job_sites, { department_id: department_id })
    var job_sites_html = '<option></option>'

    $('body.time_clock #time-clock-job-site-filter').select2('val', '')
    for (var i = 0; i < department_job_sites.length; i++) {
      job_sites_html += '<option value="' + department_job_sites[i].id + '">' + department_job_sites[i].name + '</option>'
    }
    $('body.time_clock #time-clock-job-site-filter').html(job_sites_html)

    if (department_job_sites.length > 0) {
      $('body.time_clock #time-clock-job-site-filter').prop('disabled', false)
      $('body.time_clock #time-clock-job-site-filter').show()
      $('#title-with-job-site').show()
      $('body.time_clock #time-clock-job-site-filter')
        .select2({
          placeholder: I18n.t('timeclock.js.select_job_site'),
          allowClear: true
        })
        .on('change', function () {
          setUpDepartmentTimeout()
          clearEmployeeIDInterval()
        })
    } else {
      $('body.time_clock #time-clock-job-site-filter').select2('destroy')
      $('body.time_clock #time-clock-job-site-filter').hide()
      $('#title-with-job-site').hide()
    }

    setUpDepartmentTimeout()
    clearEmployeeIDInterval()
  }

  var enableAndPopulatePositionSelect = function (department_id, positions) {
    var department_positions = _.where(positions, { department_id: department_id })
    var positions_html = '<option></option>'

    $('body.time_clock #time-clock-position-filter').select2('val', '')
    for (var i = 0; i < department_positions.length; i++) {
      positions_html += '<option value="' + department_positions[i].id + '">' + department_positions[i].name + '</option>'
    }
    $('body.time_clock #time-clock-position-filter').html(positions_html)

    if (department_positions.length > 0) {
      $('body.time_clock #time-clock-position-filter').prop('disabled', false)
      $('body.time_clock #time-clock-position-filter').show()
      $('#title-with-position').show()
      $('body.time_clock #time-clock-position-filter')
        .select2({
          placeholder: I18n.t('timeclock.js.select_position'),
          allowClear: true
        })
        .on('change', function () {
          setUpDepartmentTimeout()
          clearEmployeeIDInterval()
        })
    } else {
      $('body.time_clock #time-clock-position-filter').select2('destroy')
      $('body.time_clock #time-clock-position-filter').hide()
      $('#title-with-position').hide()
    }
  }

  var setUpDepartmentTimeout = function () {
    clearTimeOut()
    timeoutInterval = setInterval(resetDepartmentSelection, timeoutLength)
    initCountdownTimer()
  }

  var resetDepartmentSelection = function () {
    clearTimeOut()
    $('body.time_clock .next-employee-id').addClass('active')
    $('.separate-sections li:last-child').removeClass('active')
    clearEmployeeID()
    clearEmployeeIDInterval()
    $('body.time_clock #time-clock-department-filter').select2('close')
    $('body.time_clock #time-clock-job-site-filter').select2('destroy')
    $('body.time_clock #time-clock-position-filter').select2('destroy')
    //$('.separate-sections li').css('width','385px');
  }

  var onEmployeePunchRetrieveFail = function (error) {
    displayFlashError(I18n.t('errors.error', { message: error.responseJSON.error_message }))
    clearEmployeeIdInterval()
  }

  var showShift = function () {
    $('body.time_clock .employee-id-lookup').removeClass('active')
    $('body.time_clock .punch-card').addClass('active')
    $('body.time_clock .punch-in-card').addClass('active')
    $('body.time_clock .punch-out-card').removeClass('active')
    $('body.time_clock span.title').html('Clock In')

    var endTime
    var startTime
    if (nextShift != null) {
      endTime = moment.unix(nextShift.ends_at).tz(getDepartmentTimeZone(nextShift.department_id)).format('h:mm A')
      startTime = moment.unix(nextShift.starts_at).tz(getDepartmentTimeZone(nextShift.department_id)).format('h:mm A')
      if (nextShift.hide_ends_at == true || nextShift.hide_ends_at == 'true') {
        endTime = I18n.t('timeclock.js.to_be_determined')
      }

      $('body.time_clock .nameAndTime').html(currentUser.name + ' <div class="shift-details"><span class="medium-text">' + startTime + ' - ' + endTime + '</span></div>')
    } else {
      $('body.time_clock .nameAndTime').html(currentUser.name)
    }

    var dept
    var job_site_id
    var position_id
    if (nextShift != null) {
      dept = getDepartmentName(nextShift.department_id)
      job_site_id = nextShift.job_site_id
      position_id = nextShift.position_id
    } else {
      var deptField = $('body.time_clock select#time-clock-department-filter')
      dept = getDepartmentName(deptField.val())
      job_site_id = parseInt($('body.time_clock select#time-clock-job-site-filter').val())
      position_id = parseInt($('body.time_clock select#time-clock-position-filter').val())
    }

    if (dept != null) {
      if (nextShift != null) {
        $('body.time_clock .date').html('<span class="punch-card-label">' + I18n.t('timeclock.js.date') + '</span><span class="punch-card-value">' + moment.unix(nextShift.starts_at).format(I18n.t('date.formats.moment')) + '</span>')
      } else {
        $('body.time_clock .date').html('<span class="punch-card-label">' + I18n.t('timeclock.js.date') + '</span><span class="punch-card-value">' + moment().format(I18n.t('date.formats.moment')) + '</span>')
      }
      $('body.time_clock .department').html('<span class="punch-card-label">' + I18n.t('timeclock.js.department') + '</span><span class="punch-card-value">' + truncateString(dept.name, 25) + '</span>')

      if (job_site_id) {
        var job_site_name = _.find(job_sites, { id: job_site_id }).name
        $('body.time_clock .job_site').html('<span class="punch-card-label">' + I18n.t('timeclock.js.job_site') + '</span><span class="punch-card-value"> ' + truncateString(job_site_name, 25) + '</span>')
      }

      if (position_id) {
        var position_name = _.find(positions, { id: position_id }).name
        $('body.time_clock .position').html('<span class="punch-card-label">' + I18n.t('timeclock.js.position') + '</span><span class="punch-card-value"> ' + truncateString(position_name, 25) + '</span>')
      }

      if (nextShift && nextShift.breaks != null) {
        $('body.time_clock .breaks').html(getBreaksList(nextShift.breaks))
      }

      var location = getLocationById(dept.location_id)
      if (location != null) {
        if (location.requires_break_punches) {
          // Location uses break punches
          if (breakPunch) {
            // Employee is currently on a break
            $('body.time_clock .clocked-in-at').append('<br><span class="break-started-at-details">' + I18n.t('timeclock.js.started_break_at') + moment.unix(breakPunch.starts_at).tz(getDepartmentTimeZone(currentPunch.department_id)).format('h:mm A') + '</span>')
            $('body.time_clock .start-break-card').removeClass('active')
            $('body.time_clock .start-break').removeClass('active')
            $('body.time_clock .clock-out-employee-id').removeClass('active')
            $('body.time_clock button.clock-out-employee-id').prop('disabled', false)
            $('body.time_clock .end-break').addClass('active')
          } else {
            // Employee is currently punched in and not on break
            $('body.time_clock .start-break-card').addClass('active')
            $('body.time_clock .start-break').addClass('active')
            $('body.time_clock .clock-out-employee-id').addClass('active')
            $('body.time_clock .end-break').removeClass('active')
            $('body.time_clock button.start-break').prop('disabled', false)
          }
        } else {
          // Location does not use break punches
          $('body.time_clock .start-break-card').removeClass('active')
          $('body.time_clock .start-break').removeClass('active')
          $('body.time_clock .clock-out-employee-id').addClass('active')
        }
      }
    }

    clearTimeOut()
    timeoutInterval = setInterval(resetClockInFlow, timeoutLength)
    initCountdownTimer()
  }

  var showPunch = function () {
    $('body.time_clock .employee-id-lookup').removeClass('active')
    $('body.time_clock .punch-card').addClass('active')
    $('body.time_clock .punch-in-card').removeClass('active')
    $('body.time_clock .punch-out-card').addClass('active')
    $('body.time_clock span.title').html('Clock Out')

    var endTime
    var startTime
    if (!_.isUndefined(nextShift) && nextShift !== null) {
      endTime = moment.unix(nextShift.ends_at).tz(getDepartmentTimeZone(nextShift.department_id)).format('h:mm A')
      startTime = moment.unix(nextShift.starts_at).tz(getDepartmentTimeZone(nextShift.department_id)).format('h:mm A')
      if (nextShift.hide_ends_at == true || nextShift.hide_ends_at == 'true') {
        endTime = I18n.t('timeclock.js.to_be_determined')
      }

      $('body.time_clock .nameAndTime').html(
        currentUser.name +
          '<p class="clocked-in-at">' +
          I18n.t('timeclock.js.clocked_in_at', { time: moment.unix(currentPunch.punched_in_at).tz(getDepartmentTimeZone(currentPunch.department_id)).format('h:mm A') }) +
          '</p> <div class="shift-details"><span class="medium-text">' +
          startTime +
          ' - ' +
          endTime +
          '</span></div>'
      )
    } else {
      $('body.time_clock .nameAndTime').html(currentUser.name + '<p class="clocked-in-at">' + I18n.t('timeclock.js.clocked_in_at', { time: moment.unix(currentPunch.punched_in_at).tz(getDepartmentTimeZone(currentPunch.department_id)).format('h:mm A') }) + '</p>')
    }

    var dept = getDepartmentById(currentPunch.department_id)
    var location = getLocationById(dept.location_id)
    if (location != null) {
      if (location.requires_break_punches) {
        // Location uses break punches
        if (breakPunch) {
          // Employee is currently on a break
          $('body.time_clock .clocked-in-at').append('<br><span class="break-started-at-details">' + I18n.t('timeclock.js.started_break_at') + moment.unix(breakPunch.starts_at).tz(getDepartmentTimeZone(currentPunch.department_id)).format('h:mm A') + '</span>')
          $('body.time_clock .start-break-card').removeClass('active')
          $('body.time_clock .start-break').removeClass('active')
          $('body.time_clock .clock-out-employee-id').removeClass('active')
          $('body.time_clock .end-break').addClass('active')
          $('body.time_clock button.end-break').prop('disabled', false)
        } else {
          // Employee is currently punched in and not on break
          $('body.time_clock .start-break-card').addClass('active')
          $('body.time_clock .start-break').addClass('active')
          $('body.time_clock .clock-out-employee-id').addClass('active')
          $('body.time_clock button.clock-out-employee-id').prop('disabled', false)
          $('body.time_clock .end-break').removeClass('active')
          $('body.time_clock button.end-break').prop('disabled', true)
          $('body.time_clock button.start-break').prop('disabled', false)
        }
      } else {
        // Location does not use break punches
        $('body.time_clock .start-break-card').removeClass('active')
        $('body.time_clock .start-break').removeClass('active')
        $('body.time_clock .clock-out-employee-id').addClass('active')
        $('body.time_clock button.clock-out-employee-id').prop('disabled', false)
      }
    }

    dept = getDepartmentName(currentPunch.department_id)
    if (dept != null) {
      if (!_.isUndefined(nextShift) && nextShift !== null) {
        $('body.time_clock .date').html('<span class="punch-card-label">' + I18n.t('timeclock.js.date') + '</span><span class="punch-card-value">' + moment.unix(nextShift.starts_at).format(I18n.t('date.formats.moment')) + '</span>')
      } else {
        $('body.time_clock .date').html('<span class="punch-card-label">' + I18n.t('timeclock.js.date') + '</span><span class="punch-card-value">' + moment.unix(currentPunch.punched_in_at).format(I18n.t('date.formats.moment')) + '</span>')
      }
      $('body.time_clock .department').html('<span class="punch-card-label">' + I18n.t('timeclock.js.department') + '</span><span class="punch-card-value">' + truncateString(dept.name, 25) + '</span>')

      var job_site_id = currentPunch.job_site_id
      if (job_site_id) {
        var job_site_name = _.find(job_sites, { id: job_site_id }).name
        $('body.time_clock .job_site').html('<span class="punch-card-label">' + I18n.t('timeclock.js.job_site') + '</span><span class="punch-card-value"> ' + truncateString(job_site_name, 25) + '</span>')
      }

      var position_id = currentPunch.position_id
      if (position_id) {
        var position_name = _.find(positions, { id: position_id }).name
        $('body.time_clock .position').html('<span class="punch-card-label">' + I18n.t('timeclock.js.position') + '</span><span class="punch-card-value"> ' + truncateString(position_name, 25) + '</span>')
      }

      if (nextShift && nextShift.breaks != null) {
        $('body.time_clock .breaks').html(getBreaksList(nextShift.breaks))
      }
    }

    clearTimeOut()
    timeoutInterval = setInterval(resetClockInFlow, timeoutLength)
    initCountdownTimer()
  }

  var clearFlashInterval = function () {
    if (flashInterval != null) {
      clearInterval(flashInterval)
      flashInterval = null
    }
  }

  var clearTimeOut = function () {
    if (timeoutInterval != null) {
      clearInterval(timeoutInterval)
      timeoutInterval = null
    }

    if (countdownInterval != null) {
      clearInterval(countdownInterval)
      countdownInterval = null
      $('.time-interval-warning label').text('')
    }
  }

  var displayFlashError = function (msg) {
    if (!_.isEmpty(msg)) {
      var flashHTML = '<div class="alert alert-error"><a class="close" data-dismiss="alert">×</a><div id="flash_error">' + msg + '</div></div>'
      $('body.time_clock #flash-messages').html(flashHTML)
    }
    clearFlashInterval()
    flashInterval = setInterval(clearFlashMessage, 10000)
  }

  var displayFlashSuccess = function (msg) {
    if (!_.isEmpty(msg)) {
      var flashHTML = '<div class="alert alert-success"><a class="close" data-dismiss="alert">×</a><div id="flash_success">' + msg + '</div></div>'
      $('body.time_clock #flash-messages').html(flashHTML)
    }
    clearFlashInterval()
    flashInterval = setInterval(clearFlashMessage, 10000)
  }

  var clearFlashMessage = function () {
    $('body.time_clock #flash-messages').html('')
  }

  var clearEmployeeIdInterval = function () {
    clearEmployeeInterval()
    clearIDInterval = setInterval(clearEmployeeID, 10000)
    initCountdownTimer()
  }

  var clearEmployeeInterval = function () {
    if (clearIDInterval != null) {
      clearInterval(clearIDInterval)
      clearIDInterval = null
    }
  }

  var clearEmployeeID = function () {
    var idField = $('body.time_clock input#employee_id')
    idField.removeAttr('disabled')
    idField.val('')
    idField.focus()
  }

  var setUpTimeoutInterval = function () {
    clearEmployeeIDInterval()
    employeeIDInterval = setInterval(resetEmployeeID, timeoutLength)
    initCountdownTimer()
  }

  var clearEmployeeIDInterval = function () {
    if (employeeIDInterval != null) {
      clearInterval(employeeIDInterval)
      employeeIDInterval = null
    }
  }

  var resetEmployeeID = function () {
    clearEmployeeID()
  }

  var setUpClearPasswordInterval = function () {
    clearPasswordInterval = setInterval(passwordEntryTimedOut, timeoutLength)
    initCountdownTimer()
  }

  var clearPasswordTimeout = function () {
    if (clearPasswordInterval != null) {
      clearInterval(clearPasswordInterval)
      clearPasswordInterval = null

      setUpClearPasswordInterval()
    }
  }

  var passwordEntryTimedOut = function () {
    var passwordScreen = $('.time_clock.time_clock_sign_out.show')
    if (_.isElement(passwordScreen[0])) {
      var cancelButton = $('button.cancel-clock-in', passwordScreen)
      cancelButton.click()
    }
  }

  var initCountdownTimer = function () {
    if (countdownInterval != null) {
      clearInterval(countdownInterval)
      countdownInterval = null
      $('.time-interval-warning label').text('')
    }

    countdownTimerLength = 14000
    countdownInterval = setInterval(countdownTimer, 1000)
  }

  var countdownTimer = function () {
    if (countdownTimerLength < 10000) {
      var seconds = parseInt(Math.round(countdownTimerLength / 1000), 10)
      $('.time-interval-warning label').text(I18n.t('timeclock.js.dismiss_in_seconds', { seconds: seconds }))
    }

    if (countdownTimerLength === 0) {
      clearInterval(countdownInterval)
      $('.time-interval-warning label').text('')
      resetClockInFlow()
    }

    if ((countdownTimerLength -= 1000) < 1000) {
      countdownTimerLength = 0
    }
  }

  // Returns span wrapped list of scheduled breaks
  // We are limiting 3 breaks per line.
  var getBreaksList = function (breaks) {
    var str = ''
    if (breaks.length > 0) {
      str += '<span class="punch-card-label">BREAKS</span><span class="punch-card-value">'
      var items_per_row = 0
      for (var i = 0; i < breaks.length; i++) {
        if (i > 0 && items_per_row != 3) {
          str += ', '
        }
        if (items_per_row == 3) {
          str += '<br>'
          items_per_row = 0
        }
        str += msUtil.durationToMinutes(breaks[i], true, ' min')
        items_per_row++
      }
      str += '</span>'
    }

    return str
  }

  $(document).on('load.time_clock.show', initTimeClock)
  $(document).on('load.time_clock.time_clock_sign_out.show', init)
})(jQuery, window)

function truncateString(str, num) {
  if (num < str.length) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}
