/*
 * Model that represents a User
 *
 * @param {Object} data JSON attributes
 *
 * @return {User}
 */
export var User = function(data) {
  this.id   = data.id;
  this.name = data.name;
};