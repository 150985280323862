window.newShiftDialog = (function($, window, document) {
  var init = function(settings) {
    $.extend(config, settings, {
      breaks:     settings.breaks,
      selectors: {
        breaksField:      $('#available_shift_breaks_in_minutes'),
        modalBody:        $('#shift-modal .modal-body')
      }
    });

    config.selectors.breaksField.select2('data', formatBreaks(config.breaks));
    initClearBreaks();
  };

  // Format breaks the same way they are formatted in department_schedule.js
  var formatBreaks = function(breaks) {
   return $.map(breaks, function(value, index) {
      var valueInMinutes = value / 60;

      return {id: valueInMinutes + '___' + _.uniqueId(), text: valueInMinutes};
    });
  };


  // Attach `change` handler on the breaks field.
  // If it changes it clears the in-memory breaks array so subsequent closing, and openings of the shift modal have clean break arrays.
  var initClearBreaks = function() {
    config.selectors.breaksField.on('change', function() {
      config.breaks = [];
    });
  };


  return {
    init: init
  }

})(jQuery, window, document);
