window.premiumRules = (function($, window, document) {

  var init = function(settings) {
    $.extend(config, {
      routes: {}
    });
  };

  var config = {};

  var saveRule = function(rule, departmentId) {

    var whitelistedRule = {
      premium_rule: _.pick(rule,
      ['name',
       'description',
       'days_of_week',
       'start_time',
       'end_time',
       'operator',
       'modifier',
       'position_ids',
       'employment_type_id'
      ])
    };

    if (whitelistedRule.premium_rule.days_of_week.length == 0) {
      whitelistedRule.premium_rule.days_of_week = [""];
    }

    if (rule.id) {
      var httpVerb = "PUT";
      var url = "/departments/" + departmentId + "/premium_rules/" + rule.id;
    } else {
      httpVerb = "POST";
      url = "/departments/" + departmentId + "/premium_rules/";
    }

    return $.ajax({
      method: httpVerb,
      dataType: 'json',
      contentType: 'application/json; charset=UTF-8',
      url: url,
      data: JSON.stringify(whitelistedRule)
    });
  };

  var deleteRule = function(rule) {
    var httpVerb = "DELETE";
    // reeally weird how departmentId is used here..
    var url = "/departments/" + departmentId + "/premium_rules/" + rule.id;

    return $.ajax({
              method: httpVerb,
              url: url
           });
  }

  return {
    init: init,
    saveRule: saveRule,
    deleteRule: deleteRule
  }

})(jQuery, window, document);
