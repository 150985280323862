export var calendarHelper = {}

calendarHelper.isUnavailable = function(type) {
  return (type === "unavailable");
};

calendarHelper.isCustomShift = function(type) {
  return (type === "custom-shift");
};

calendarHelper.isRotation = function(type) {
  return (type === "rotation");
};

calendarHelper.persistedEvent = function(event) {
  return event.original_id !== undefined;
};

calendarHelper.isNotWorking = function(shiftTypeId) {
  return (shiftTypeId ===  "");
};

calendarHelper.isInCurrentDepartment = function(event) {
  return (event.department_id === MKS.current_department)
};

calendarHelper.getTime = function(date) {
  return Math.round(date.getTime() / 1000);
};

calendarHelper.getToday = function(date) {
  return new Date(date.setHours(0,0,0,0));
};

calendarHelper.getTomorrow = function(date) {
  var tomorrow = new Date(date);
  tomorrow.setHours(24,0,0,0);
  return tomorrow;
};

calendarHelper.sortEvents = function(eventsToSort) {
  var sortedEvents = eventsToSort.sort(function(a,b) {
    return a.start - b.start;
  });
  return sortedEvents;
};

calendarHelper.getRotationPattern = function(event) {
  if (event.type == "rotation") {
    return event.rotation_pattern;
  } else {
    return null;
  }
};

calendarHelper.getEarliestDate = function(sortedEvents) {
  return sortedEvents[0].start;
};

calendarHelper.getLatestDate = function(sortedEvents){
  return sortedEvents[sortedEvents.length - 1].end;
};

calendarHelper.earlierTimeThanToday = function(event_starts_at) {
  return (event_starts_at < Date.today().getTime());
};

calendarHelper.earlierDayThanToday = function(date) {
  return (date < moment(MKS.current_time_in_timezone));
};

calendarHelper.setToEndOfDay = function(date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 0);
};
