<template>
  <calendar-sidebar
    content-style="overflow: auto; height: 600px;"
    @add-event="$emit('add-event')"
    :read-only="readOnly"
  >
    <template v-slot:header>
      {{I18n.t('user_availability.preferences.title')}}
    </template>
    <template v-slot:content>
      <div v-if="preferences.length">
        <div
          v-for="pref in preferences"
          :key="pref.id"
          class="availability-preference"
        >
          <div>
            <b>{{pref.start_time}} - {{pref.end_time}}</b>
            <a
              v-if="showEditButton"
              class="pull-right"
              @click="handleClick(pref)"
            >
              {{I18n.t('user_availability.preferences.edit')}}
            </a>
          </div>
          <div>{{pref.days}}</div>
          <div v-if="pref.recurrence_end">
            {{moment(pref.recurrence_start).format(I18n.t('date.formats.moment_preference2'))}} - {{moment(pref.recurrence_end).format(I18n.t('date.formats.moment_preference'))}}
          </div>
          <div v-else>{{I18n.t('date_range.start_date')}} {{moment(pref.recurrence_start).format(I18n.t('date.formats.moment_preference'))}}</div>
        </div>
      </div>
      <div v-else class="availability-no-preference">
        <p>{{ emptyMessage }}</p>
      </div>
    </template>
  </calendar-sidebar>

</template>

<script>
import CalendarSidebar from 'core/vue/common/calendar_sidebar';

export default {
  name: 'AvailabilityPreference',
  props: {
    preferences: Array,
    readOnly:   {
      type:     Boolean,
      default: false,
    }
  },
  methods: {
    handleClick(preference) {
      this.$emit('edit-event', {
        id:         preference.id,
        date:       moment(preference.recurrence_start).format('YYYY-MM-DD'),
        start_time: preference.start_time,
        end_time:   preference.end_time,
        starts_on:  moment(preference.recurrence_start).format('YYYY-MM-DD'),
        ends_on:    preference.recurrence_end && moment(preference.recurrence_end).format('YYYY-MM-DD'),
        repeats_on: preference.repeats_on,
        repeats:    true,
      });
    }
  },
  components: {
    CalendarSidebar
  },
  computed:{
    showEditButton() { return this.readOnly !== true },
    emptyMessage() { return this.readOnly !== true ? this.I18n.t('user_availability.preferences.no_data') : this.I18n.t('user_availability.preferences.no_data_readonly')},
  }
}
</script>

<style lang="scss">
  .availability {

    &-preference {
      padding: 15px;
      border-bottom: 1px solid #cdcdcd;

      a {
        text-decoration: underline;
      }
    }

    &-no-preference {
      padding: 15px;
      display: flex;
      height: calc(100% - 30px);
      align-items: center;

      p {
        margin: auto;
        text-align: center;
      }
    }
  }
</style>
