import { am_pm_to_hours, tConvert, user_prefers_12hr } from 'core/helpers/format_helpers.js';
/*
 * Model that represents a ShiftType
 *
 * @param {Object} data JSON attributes
 *
 * @return {Object} ShiftType instance
 */
export var ShiftType = function(data) {

  var startTime = data.start_time;
  var endTime = data.end_time;

  if (user_prefers_12hr())
  {
    startTime = tConvert(startTime).replace('am','a').replace('pm','p');
    endTime = tConvert(endTime).replace('am','a').replace('pm','p');
  }

  this.id                   = data.id;
  this.name                 = data.name;
  this.departmentId         = data.department_id;
  this.hideEndsAt           = data.hide_ends_at;
  this.startTime            = startTime;
  this.endTime              = data.hide_ends_at ? "("+endTime+")" : endTime;
  this.duration             = data.duration;
  this.secondsSinceMidnight = data.seconds_since_midnight;
  this.breaks               = data.breaks;
  this.totalBreaks          = data.total_breaks;
  this.notes                = data.notes;
  this.time_reporting_code  = data.time_reporting_code;
  this.pay_code_id          = data.pay_code_id;

};


/*
 * Function that convert both the startTime and the endTime fields to a 12 hours
 * or 24 hours format.
 *
 * @param {startTime} {object} text input field
 * @param {endTime}   {object} text input field
 * @param {format}    integer 12/24
 */
export function reverse_shifts_time(startTime,endTime,format) {
  if (format === 12) {
    if (startTime.val()) {
      startTime.val(am_pm_to_hours(startTime.val()));
    }
    if (endTime.val()) {
      endTime.val(am_pm_to_hours(endTime.val()));
    }
  };
  if (format === 24) {
    if (startTime.val()) {
      startTime.val(tConvert(startTime.val()));
    }
    if (endTime.val()) {
      endTime.val(tConvert(endTime.val()));
    }
  };
}

window.reverse_shifts_time = reverse_shifts_time;

/*
 * Function the converts a pair of time units ( 'xx:xx - xx:xx')
 * from a 24 hours format to a 12 hours format
 *
 * @param {eventTime} string
 * returns eventTime  string ('xx:xx pm/am - xx:xx pm/am')
 */

export function convert_pair(eventTime) {

  if (eventTime.match(/\d\d:\d\d - \d\d:\d\d/)){
    eventTime = (user_prefers_12hr()) ?
    tConvert(eventTime.split('-')[0].trim()) + ' - ' + tConvert(eventTime.split('-')[1].trim()) :
    eventTime ;
  }

  return eventTime;
}

window.convert_pair = convert_pair;
