<template>
  <button
    :class="buttonClasses" 
    :disabled="state.loading"
    @click="handleClick"
  >
    {{buttonLabel}}
  </button>
</template>

<script>
export default {
  name: 'ConnectionTester',
  props: {
    endpoint: String,
  },
  data() {
    return {
      buttonLabel: I18n.t('connection_tester.js.test'),
      state: {
        success: false,
        failure: false,
        loading: false,
      }
    }
  },
  computed: {
    buttonClasses() {
      const { success, failure } = this.state;
      return {
        'btn': true,
        'btn-gray': !failure && !success,
        'btn-red': failure,
        'btn-green': success,
      }
    },
  },
  methods: {
    handleClick(event) {
      event.preventDefault();
      this.buttonLabel = I18n.t('connection_tester.js.testing');
      this.state = {
        loading: true,
        success: false,
        failure: false,
      };

      $.ajax({
        url: this.endpoint,
        data: {
          client_id:  $('#adp_subscription_client_id').val(),
          secret_key: $('#adp_subscription_secret_key').val(),
        },
        success: (data) => {
          this.state.loading = false;
          if (data) {
            this.buttonLabel = I18n.t('connection_tester.js.successful');
            this.state.success = true;
          } else {
            this.buttonLabel = I18n.t('connection_tester.js.error');
            this.state.failure = true;
          }
        }
      });
    }
  }
}
</script>
