import {flashMessages} from 'core/helpers/flash_messages.js';

window.adminRevenue = (function ($, window, document) {

  var revenuePageState = {
    revenueDataStartDate: null,
    revenueDataEndDate: null
  };
  var revenueDatatable = null

  var init = function () {
    initDatatable();
    initPusher();
    initDatepickers();
    initExport();
  }

  function initDatatable() {
    revenueDatatable = $('table.dataTable').DataTable({
      autoWidth: false,
      order: [[ 5, "desc" ]],
      columns: [
        { 'width': '120px'},
        { 'width': '150px'},
        { 'width': '100px'},
        { 'width': '100px' },
        { 'width': '100px'},
        { 'width': '100px'},
        { 'width': '120px', 'orderable': false},
        { 'width': '130px', 'orderable': false},
        { 'width': '180px'},
        { 'width': '180px', 'orderable': false },
        { 'width': '80px'},
        { 'width': '180px'},
        { 'width': '70px'},
        { 'width': '90px'},
        { 'width': '120px', 'orderable': false },
        { 'width': '80px', 'orderable': false },
        { 'width': '100px'},
        { 'width': '100px' },
        { 'width': '100px'},
        { 'width': '150px', 'orderable': false }
      ],
      processing: true,
      serverSide: true,
      ajax: {
        url: $('.dataTable').data('source'),
        data: function (data) {
          // send the dates with the rest of the data
          return $.extend(data, getValidDates());
        }
      },
      pagingType: "full_numbers",
      pageLength: 25,
      dom: "<\"table-header\"flr><\"table-responsive\"t><\"table-footer\"ip>",
      language: dataTables_i18n,
      searchDelay: 600
    });

    // use check wether or not the export button should appear based on data from each request
    revenueDatatable.on('xhr.dt', function (e, settings, data, xhr) {
      var atLeastOneRow = data["data"].length > 0
      if (atLeastOneRow) {
        showExportButton()
      } else {
        hideExportButton()
      }
      return data
    })

    $("select.uniform, input:file, .dataTables_length select").uniform()
  }

  function initPusher() {
    window.msPusherChannel.bind('revenue-export-success', function handleRevenueExportSuccess(pusherData) {
      var export_url = pusherData.export_uri
      window.location.href = export_url
      hideLoadingModal()
    })

    window.msPusherChannel.bind('revenue-export-failure', function handleRevenueExportFail(pusherData) {
      flashMessages.setFlash(pusherData.message, 'danger');
      hideLoadingModal();
    })
  }

  function initDatepickers() {
    $('#start-datepicker').datepicker({ language: I18n.locale })
    $('#end-datepicker').datepicker({ language: I18n.locale })

    $('#start-datepicker').on('change', function handlePickStart(e) {
      revenuePageState.revenueDataStartDate = e.target.value
      revenueDatatable.ajax.reload()
    })

    $('#end-datepicker').on('change', function handlePickEnd(e) {
      revenuePageState.revenueDataEndDate = e.target.value
      revenueDatatable.ajax.reload()
    })
  }

  function initExport() {
    $('#revenue-export-btn').on('click', function handleExportClick() {
      var settings = $('table.dataTable').dataTable().fnSettings()
      var sorting = settings.aaSorting[0]

      var data = {
        sort_column: sorting[0],
        sort_order: sorting[1]
      }

      $.get({
        url: '/admin/revenue/export',
        dataType: "json",
        data: $.extend(data, getValidDates())
      });

      showLoadingModal()
    });
  }


  var showLoadingModal = function showLoadingModal() {
    var loadingModal = HandlebarsTemplates['shared/processing_modal']({
      loadingHeader: I18n.t('admin.revenue.modal_header'),
      loadingMessage: I18n.t('admin.revenue.modal_message'),
      loadingWarning: I18n.t('admin.revenue.modal_warning'),
      loadingWarningMessage: I18n.t('admin.revenue.modal_warning_msg'),
    });
    var loadingDiv = $('#revenue-export-loading');

    loadingDiv.html(loadingModal);
    loadingDiv.show();
  };

  function hideLoadingModal() {
    $('#revenue-export-loading').hide();
  };

  function hideExportButton() {
    $('#revenue-export-btn').hide()
  }

  function showExportButton() {
    $('#revenue-export-btn').show()
  }

  function getValidDates() {
    var startDate = moment(revenuePageState.revenueDataStartDate)
    var endDate = moment(revenuePageState.revenueDataEndDate)

    const dates = {}
    if (startDate.isValid()) {
      dates.revenue_data_start_date = startDate.format('YYYY-MM-DD')
    }
    if (endDate.isValid()) {
      dates.revenue_data_end_date = endDate.add(1, 'd').format('YYYY-MM-DD')
    }

    return dates
  }

  return {
    init: init
  }

})(jQuery, window, document);
