<template>
  <csv-file-uploader :template-link="templateLink" @drop="handleDrop">
    <template #content>
      <div>{{ I18n.t('user_imports.new.description_1') }}</div>
      <div v-if="companyHasUsers" class="row-fluid">
        <div class="span8 offset2">{{ I18n.t('user_imports.new.update_warning') }}</div>
      </div>
      <div>{{ I18n.t('user_imports.new.description_2') }}</div>
      <div class="user-attributes row-fluid">
        <ul>
          <li>{{ I18n.t('activerecord.attributes.user.first_name') }} <span class="required"
                                                                            style="text-transform: capitalize;">({{ I18n.t('simple_form.required.text')
            }})</span>
          </li>
          <li>{{ I18n.t('activerecord.attributes.user.middle_name') }}</li>
          <li>{{ I18n.t('activerecord.attributes.user.last_name') }} <span class="required"
                                                                           style="text-transform: capitalize;">({{ I18n.t('simple_form.required.text')
            }})</span>
          </li>
          <li>{{ I18n.t('activerecord.attributes.user.employee_id') }} <span v-if="timeAndAttendance" class="required"
                                                                             style="text-transform: capitalize;">({{ I18n.t('simple_form.required.text')
            }})</span>
          </li>
          <li>{{ I18n.t('activerecord.attributes.user.email') }}</li>
        </ul>
        <ul>
          <li>{{ I18n.t('activerecord.attributes.user.phone_number') }}</li>
          <li>{{ I18n.t('simple_form.labels.user.wage') }}</li>
          <li>{{ I18n.t('activerecord.attributes.user.pay_type') }}</li>
          <li>{{ I18n.t('activerecord.attributes.user.hire_date') }}</li>
          <li>{{ I18n.t('activerecord.attributes.user.skill_names') }}</li>
        </ul>
      </div>
    </template>
    <template #file-uploader>
      <user-import-file-uploader
        :pusher-key="pusherKey"
        :company-id="companyId"
        :initial-processing="initialProcessing"
        ref="fileUploader"
      />
    </template>
  </csv-file-uploader>
</template>

<script>
  import UserImportFileUploader from './user_import_file_uploader'
  import CsvFileUploader from '../uploaders/csv_file_uploader'

  export default {
    name: 'NewUserImport',
    props: {
      companyId: Number,
      pusherKey: String,
      templateLink: String,
      initialProcessing: Boolean,
      timeAndAttendance: Boolean,
      companyHasUsers: Boolean
    },
    methods: {
      handleDrop(event) {
        const { files } = event.dataTransfer
        if (files.length > 1) {
          $('.error-status').html(I18n.t('user_imports.errors.multiple_files'))
        } else {
          this.$refs.fileUploader.handleFileSubmit(files[0])
        }
      }
    },
    components: {
      UserImportFileUploader,
      CsvFileUploader
    }
  }
</script>

<style lang="scss">
  .user-attributes {
    margin: 40px 30%;

    ul {
      display: inline-block;
      float: left;
      margin: 0 auto;
      text-align: left;
      width: 30%;

      li {
        margin: 15px 0;
      }
    }
  }
</style>
