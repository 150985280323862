// Broadcasts banners are hidden by default
// When the page loads, find out which ones we've already requested to hide
$(function() {
  // Timeclock doesn't use normal layout so CURRENT_USER_ID isn't defined
  if (typeof(CURRENT_USER_ID) === 'undefined') { return; }

  // The local storage key
  var key = 'hidden_broadcasts';

  var newHiddenCookie = function() {
    Cookies.set(key, JSON.stringify({}), { secure: true });
    setHidden([]);
  };

  var getHidden = function() {
    var content = JSON.parse(Cookies.get(key));
    return content[CURRENT_USER_ID] || [];
  };

  var setHidden = function(hidden) {
    var content = JSON.parse(Cookies.get(key));
    content[CURRENT_USER_ID] = hidden;
    Cookies.set(key, JSON.stringify(content), { secure: true });
  };

  // Default value
  if (!Cookies.get(key)) {
    newHiddenCookie();
  }

  // If we click to dismiss a broadcast, mark it as hidden
  var hidden = getHidden();
  $('.broadcast a.close').click(function(value) {
    var broadcast = $(this).closest('.broadcast');
    var broadcastId = broadcast.data('broadcast-id');

    hidden.push(broadcastId);
    setHidden(hidden);
    broadcast.hide();
  });
});
