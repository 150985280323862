import { msUtil } from 'core/ms_util.js';
window.locationProfiles = (function($, window, document) {
  /*
  public
  */

  // Initialize Location including Datepicker and Exporter.
  var init = function(settings) {
    $.extend(config, {
      scheduleProviders:                  settings.scheduleProviders,
      startOfWeek:                        settings.startOfWeek,
      endOfWeek:                          settings.endOfWeek,
      ignoreLocationWeekStartsOn:         settings.ignoreLocationWeekStartsOn || false,
      privatePusherChannel:               settings.privatePusherChannel,
      selectors: {
        exportButton: $('#printable-schedule'),
        exportForProviderButton: $('#export-schedule'),
        scheduleProviderFilter: $('#schedule-provider-filter'),
        scheduleCheckbox: $('#checkbox-container'),
        modal: $('#export-modal'),
        downloadModal: $('#export-download-modal')
      },
      routes: {
        exportSchedulePath: function(providerName, start_date, archived) {
          var path = settings.exportURL + '?provider=' + providerName + '&start='+ start_date;
          if (providerName == 'raw_excel' || providerName == 'raw_excel_month') path += '&archived=' + archived;
          return path;
        },
      }
    });

    initMoment();
    initDatepicker();
    initExportScheduleFields();
    initExportButton();
  };

  var initMoment = function() {
    moment.locale(I18n.locale);
    moment.updateLocale(I18n.locale, { week: { dow: config.startOfWeek } });
  };

  var showDownloadModal = function() {
    config.selectors.downloadModal.modal('show');
  };

  var setDownloadModalLoading = function() {
    // hide in case it's already displayed
    hideDownloadModalLink();
    var loader = document.querySelector('#export-download-loader');
    loader.style.display = 'block';
    msUtil.spinner('export-download-loader');
  };

  var setDownloadModalLink = function(url) {
    var wrapper = document.querySelector('#export-download-link-wrapper');
    var link = document.querySelector('#export-download-link');
    var loader = document.querySelector('#export-download-loader');
    loader.style.display = 'none';
    link.setAttribute('href', url);
    wrapper.style.display = 'block';
  };

  var hideDownloadModalLink = function(url) {
    var wrapper = document.querySelector('#export-download-link-wrapper');
    wrapper.style.display = 'none';
  };

  // Set up listeners for web socket events used in async exports
  // (Export is handled in a background job on the server)
  var initAsyncWebSocketListeners = function() {
    config.privatePusherChannel.bind('location-schedule-export-excel.processing', data => {
      showDownloadModal();
    });

    config.privatePusherChannel.bind('location-schedule-export-excel.success', data => {
      setDownloadModalLink(data.download_url);
    });
  };

  // Set up date pickers to quickly select a week in the past or future using the initDateNavigation functions.
  // Delegates actual date setting to $(#previous-week)
  var initDatepicker = function() {
    var $datepicker = $('#export-schedule-datepicker');

    $datepicker.datepicker({
      format: 'yyyy-mm-dd',
      weekStart: config.startOfWeek,
      startDate: $(this).attr('data-date'),
      orientation: 'right auto',
      container: '#export-modal',
      autoclose: true,
      language: I18n.locale
    }).on('changeDate', e => {
      if (config.selectors.scheduleProviderFilter.val().length) {
        config.selectors.exportForProviderButton.attr('href', exportButtonPath());
      }
    }).on('changeMonth', e =>{
      setTimeout(() => {
        setActiveWeekSelection();
      }, 100);
    });

    // Highlight current week within the calendar popover.
    $datepicker.on('click', () =>{
      setActiveWeekSelection();
    });

    // Highlight week while mousing over
    mouseOverHighlightWeek();
  };

  var exportButtonPath = function() {
    var providerName = config.selectors.scheduleProviderFilter.select2('data').name;
    var startDate = $('#export-schedule-datepicker').datepicker('getDate');
    var archived = $('#schedule-include-archived').is(':checked');
    return config.routes.exportSchedulePath(providerName, startDate, archived);
  };

  // Adds a 'range' class when mousing over the date picker
  var mouseOverHighlightWeek = function() {
    $('body').on('mouseover', '.department-toolbar .datepicker-days tr', function(){
      var $tds = $(this).find('td');
      $.each($tds, function(){
        $(this).addClass('range');
      });
    }).on('mouseout', '.department-toolbar .datepicker-days tr', function(){
      var $tds = $(this).find('td');
      $.each($tds, function(){
        $(this).removeClass('range');
      });
    });
  };

  // Adds an active class to the rest of the week. Depending on
  // one's settings, that is either:
  //
  // - the next 7 days
  // - the current week based on a locations "week starts on" value
  var setActiveWeekSelection = function() {
    // set the next 7 days as "active"
    if (config.ignoreLocationWeekStartsOn) {
      var start_day = $('.day.active').first();

      var rest_of_week = start_day.nextAll();
      colorDays(rest_of_week);

      if (rest_of_week.length < 6) {
        var next_week_days = start_day.parent().next('tr').find('td:lt(' + (6 - rest_of_week.length) + ')');
        colorDays(next_week_days);
      }

    // otherwise, use "this week"
    } else {
      var weekDays = $('.day.active').siblings();
      colorDays(weekDays);
    }
  };

  // Adds active class to all days of the week
  var colorDays = function(days) {
    days.each(function(){
      $(this).addClass('active');
    });
  };

  /**
   * Initializes the Export button to show Export Modal and schedule file Selection
   */
  var initExportButton = function() {
    config.selectors.exportButton.on('click', e => {
      e.preventDefault();
      window.scrollTo(0, 0);
      config.selectors.modal.modal('show');
      $('.alert.alert-error').remove();
    });
  };

  // Make the "Export" button do a regular form POST and trigger a synchronous
  // call to the controller.
  var setupExportButtonForPOST = function(path) {
    config.selectors.exportForProviderButton.off('click');
  };

  // Make the "Export" button do an AJAX call to the controller instead of a regular form POST.
  // The controller will queue a job and the rest of the flow will be handled via sockets.
  var setupExportButtonForAJAX = function() {
    config.selectors.exportForProviderButton.on('click', e => {
      e.preventDefault();

      // Prepare to listen for responses from the background workers
      initAsyncWebSocketListeners();

      $.ajax({
        url: exportButtonPath(),
        type: 'GET',
        success: function() {
          config.selectors.modal.modal('hide');
          showDownloadModal();
          setDownloadModalLoading();
        },
        error: function() {
          config.selectors.modal.modal('hide');
        },
      });
    });
  };

  /**
   * Initializes the export schedule fields
   */
  var initExportScheduleFields = function() {

    // Provider Select2
    config.selectors.scheduleProviderFilter.select2({
      data: buildSelectionsForscheduleProviders(config.scheduleProviders),
      allowClear: true,
      placeholder: I18n.t('department_schedules.show.schedule.placeholder'),
    })
      .on('change', e => {
        if (e.val.length) {
        // "excel" has been converted to run as a background job
          if (e.added.name == 'raw_excel' || e.added.name == 'raw_excel_month') {
            setupExportButtonForAJAX();
            config.selectors.scheduleCheckbox.show();
            config.selectors.exportForProviderButton.attr('href', '#');
          } else {
            setupExportButtonForPOST();
            config.selectors.scheduleCheckbox.hide();
            config.selectors.exportForProviderButton.attr('href', exportButtonPath());
          }

          config.selectors.exportForProviderButton.removeClass('disabled');
        } else {
          config.selectors.scheduleCheckbox.hide();
          config.selectors.exportForProviderButton.attr('href', '#');
          config.selectors.exportForProviderButton.addClass('disabled');
        }
      });

    // Include Archived Users Checkbox
    config.selectors.scheduleCheckbox.hide().on('change', e => {
      if (config.selectors.scheduleProviderFilter.select2('data')) {
        config.selectors.exportForProviderButton.attr('href', exportButtonPath());
      } else {
        config.selectors.exportForProviderButton.attr('href', '#');
      }
    });
  };

  /**
   * Builds selection values for the schedule file Filter
   * @param  {Array} scheduleProviders schedule file names
   * @return {Array} Array with provider names and their index as an id
   */
  var buildSelectionsForscheduleProviders = function(scheduleProviders) {
    return _.map(scheduleProviders, provider => {
      return { id: scheduleProviders.indexOf(provider) + 1, text: I18n.t('department_schedules.show.schedule' + '.' + provider), name: provider };
    });
  };

  return {
    init: init
  };

})(jQuery, window, document);
