<template>
  <div :class="['csv-file-uploader new-file-upload', { 'border': draggedOver }]">
    <div>
      <slot name="content"></slot>
      <div class="row-fluid">{{ I18n.t('user_imports.new.description_3') }}</div>
      <div v-html="I18n.t('user_imports.new.template_download_html', {template_link: templateLink})"></div>
      <div class="row-fluid">
        <slot name="file-uploader"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CsvFileUploader',
    props: {
      templateLink: String
    },
    data() {
      return {
        draggedOver: false
      }
    },
    mounted() {
      document.addEventListener('drop', this.handleDrop, false)
      document.addEventListener('dragover', this.handleDragEnter, false)
      document.addEventListener('dragleave', this.handleDragLeave, false)
    },
    methods: {
      handleDrop(event) {
        event.preventDefault()
        this.$emit('drop', event)
      },

      handleDragEnter(event) {
        event.preventDefault()
        this.draggedOver = true
        this.$emit('dragenter', event)
      },

      handleDragLeave(event) {
        event.preventDefault()
        // "dragleave" fires all the time (in Chrome anyway)
        // This ensures we only care when the user has truly dragged the file away
        // from the document/body
        if (event.fromElement == null) {
          this.draggedOver = false
        }
        this.$emit('dragleave', event)
      }
    }
  }
</script>

<style lang="scss">
  .csv-file-uploader {
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 20px;


    &.new-file-upload {
      padding: 30px;
      text-align: center;
      margin: 20px;
      border: 1px solid transparent;

      &.border {
        border: 1px dashed black;
      }

      .error-status {
        font-size: 18px;
      }

      button {
        margin: 40px;
      }
    }
  }
</style>
