import BaseModal from 'core/vue/common/base_modal.vue';
import { remoteFormErrors } from 'core/remote_form_errors';

const CustomizationModalVue = Vue.extend({
  props: {
    companyId: Number,
  },
  data() {
    return {
      modalKey: 0,
      customization: {},
    }
  },
  mounted() {
    this.initModalStates();
    this.initAjaxHandlers();
  },
  methods: {
    initModalStates() {
      $(this.$el).on('click', '[data-dismiss="modal"]', () => {
        // Add timeout to wait for modal animation to finish
        // Triggers a re-render of the modal to reset state
        setTimeout(() => this.modalKey += 1, 100);
      });

      // Disallows any non-integer character from being inputted into the number field
      $(this.$el).on('input', 'input[type="number"]', function() {
        const nonNumReg = /[^0-9]/g;
        $(this).val($(this).val().replace(nonNumReg, ''));
      });
    },

    initAjaxHandlers() {
      $(this.$el)
        .on('ajax:beforeSend', () => {
          this.setButtonDisabled(true);
        })
        .on('ajax:complete', () => {
          this.setButtonDisabled(false);
        })
        .on('ajax:success', () => {
          location.reload();
        })
        .on('ajax:error', (e, xhr) => {
          this.showErrors(xhr);
        });
    },

    getCustomization() {
      $.ajax({
        url: `/company_customizations/${this.companyId}`,
        success: (res) => {
          this.customization = res.data
            ? res.data 
            : {
                company_name_override: '',
                company_industry_override: '',
                company_size: '',
                logo_url: '',
              };
        },
        error: (e) => console.log(e)
      });
    },

    showErrors(xhr) {
      // When the logo has errors, they are stored in responseText instead of responseJSON
      const res = xhr.responseJSON || JSON.parse(xhr.responseText);
      const alert = $(this.$el).find('.modal .alert-error');
      alert.hide();
      if (res.klass) {
        remoteFormErrors.errorFields($(this.$el).find('.modal'), res.klass, res.errors);
      }
      if (res.error_message || res.errors.base) {
        alert.show().find('#flash-message').html(res.error_message || res.errors.base.join('. '));
      }
    },

    openModal() {
      this.getCustomization();
      this.$nextTick(() => $(this.$el).find('.modal').modal('show'));
    },

    submitForm() {
      $(this.$el).find('.modal form').submit();
    },
    
    setButtonDisabled(bool) {
      $(this.$el).find('.modal .btn-primary').prop('disabled', bool);
    },

    setNoLogoParam(bool) {
      $(this.$el).find('.modal form').attr('action', `/company_customizations?no_logo=${bool}`);
    },

    setImage(event) {
      if (event.target.files.length > 0) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.setNoLogoParam(false);
          this.$set(this.customization, 'logo_url', e.target.result);
        }
        reader.readAsDataURL(event.target.files[0]);
      }
    },

    removeImage() {
      this.setNoLogoParam(true);
      this.$set(this.customization, 'logo_url', null);
      $(this.$el).find('.modal input[type="file"]').val('');
    },
  },
  components: {
    BaseModal
  },
});

window.CustomizationModalVue = CustomizationModalVue;

export default CustomizationModalVue;
