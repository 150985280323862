const PAGE_LENGTH = 25;

const bindPusher = () => {
  window.msPusherChannel.bind('activity-export-success', function handleAdminExportSuccess(pusherData) {
    hideLoadingModal();
    var export_url = pusherData.export_uri
    window.location.href = export_url
  });

  window.msPusherChannel.bind('activity-export-failure', function handleAdminExportFail(pusherData) {
    hideLoadingModal();
    displayFlashError(pusherData.message);
  });
};

const showLoadingModal = () => {
  var loadingModal = HandlebarsTemplates['shared/processing_modal']({
    loadingHeader: I18n.t('admin.activity.modal_header'),
    loadingMessage: I18n.t('admin.activity.modal_message'),
    loadingWarning: I18n.t('admin.activity.modal_warning'),
    loadingWarningMessage: I18n.t('admin.activity.modal_warning_msg'),
  });

  var loadingDiv = $('#activity-export-loading');
  loadingDiv.html(loadingModal);
  loadingDiv.show();
};
  
const hideLoadingModal = () => {
  $('#activity-export-loading').hide();
};
  
const clearError = () => {
  $('#flash-messages').html('');
};
  
const displayFlashError = (error) => {  
  $('#flash-messages').html(`<div class="alert alert-error">
    <a class="close" data-dismiss="alert">×</a>
    <div id="flash_error">${error.responseJSON.message}</div>
  </div>`);
};

module.exports = { 
  PAGE_LENGTH : PAGE_LENGTH,
  clearError  : clearError,
  bindPusher  : bindPusher,
  hideLoadingModal  : hideLoadingModal,
  showLoadingModal  : showLoadingModal,
  displayFlashError : displayFlashError
}
