<template>
  <csv-file-upload-form
    ref="fileUploader"
    :error-message.sync="errorMessage"
    :processing.sync="processing"
    @submit="handleSubmit"
  >
    {{ I18n.t('user_imports.new.file_upload_modal') }}
  </csv-file-upload-form>
</template>

<script>
  import CsvFileUploadForm from '../uploaders/csv_file_upload_form'

  export default {
    name: 'UserImportFileUploader',
    components: {
      CsvFileUploadForm
    },
    props: {
      pusherKey: String,
      companyId: Number,
      initialProcessing: Boolean
    },
    data() {
      return {
        processing: this.initialProcessing,
        errorMessage: null
      }
    },
    mounted() {
      const pusher = window.getPusherInstance(this.pusherKey)
      const pusherChannel = pusher.subscribe(`csv-user-import-for-company-${this.companyId}`)
      this.subscribeToChannelEvents(pusherChannel)
    },
    methods: {
      subscribeToChannelEvents(channel) {
        channel.bind('csv-user-import-processing', () => {
          this.processing = true
          this.errorMessage = null
        })
        channel.bind('csv-user-import-failure', (data) => {
          console.debug('response', data)
          this.processing = false
          this.errorMessage = data.status_error
        })
        channel.bind('csv-user-import-complete', (data) => {
          // Set timeout to ensure job is marked as complete on the server, before redirecting to show page.
          // 5 seconds seems like an awfully long time, but I was running into race conditions locally with shorter timeouts.
          setTimeout(() => window.location.href = `/user_imports/${data.id}`, 5000)
        })
      },

      handleFileSubmit(file) {
        this.$refs.fileUploader.handleFileSubmit(file)
      },

      handleSubmit(file) {
        const data = new FormData()
        data.append('file', file, file.name + Math.random().toString(36))

        $.ajax({
          url: '/user_imports',
          method: 'POST',
          data,
          contentType: false,
          processData: false,
          error(data) {
            console.log(data)
          }
        })
      }
    }
  }
</script>
