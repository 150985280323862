import { flashMessages } from 'core/helpers/flash_messages.js';

window.departmentUsers = (function($) {

  /*
  public
  */

  // Initialize Department Users Datatable
  // Place for config variables if provided
  var init = function(settings) {
    $.extend(config, {
      departmentId:  settings.departmentId,
      editPositionPath: settings.editPositionPath,
      resetSortPath: settings.resetSortPath,
      totalUsers: settings.totalUsers,
      sortBy: settings.sortBy,
      privatePusherChannel: settings.privatePusherChannel
    });

    initDataTable();
    initButtonToggles();
    initPusherChannel();
  };

  /*
  private
  */

  // Default settings
  var config = {
  };

  var tables = {
  };

  var initPusherChannel = function() {
    if (config.privatePusherChannel) {
      config.privatePusherChannel.bind('department-user.success', function(data) {
        tables.inDepartmentDataTable.DataTable().draw();
        tables.notInDepartmentDataTable.DataTable().draw();
      });

      config.privatePusherChannel.bind('department-user.error', function(data) {
        enableTableButton($("#delete_user_" + data.user_id), I18n.t('buttons.remove'));
      });
    }
  };

  // Setup department user datatable
  var initDataTable = function() {

    // Extend datatables to use a styled wrapper to conform to style.
    $.extend($.fn.dataTableExt.oStdClasses, {
      "sWrapper": "dataTables_wrapper form-inline"
    });

    tables.inDepartmentDataTable = setupDataTable("#in-department");
    tables.notInDepartmentDataTable = setupDataTable("#not-in-department");

  	tables.inDepartmentDataTable.sortable({
  		axis: 'y',
  		cursor: 'move',
  		items: 'tbody > tr',
      opacity: 0.8,
      forcePlaceholderSize: true,
      scroll: true,

      update: function() {
         updateUserOrder();
      },

      stop: function(e, tr) {
        tr.item.closest("tbody").find("tr:even").removeClass("odd").addClass("even");
        tr.item.closest("tbody").find("tr:odd").removeClass("even").addClass("odd");
      },

      helper: function(e, tr) {
        var $tr = tr.clone();
        $tr.find("td").not(":first").remove();
        $tr.css("position", "absolute");

        $tr.css("margin", "0");
        $tr.css("offset", "0");
        $tr.css("height", "50px !important");
        $tr.css("background", "#DDD");

        $tr.removeClass("even");
        $tr.removeClass("odd");

        return $tr;
      }
    }).disableSelection();

    // Inititialize Uniform for select picker
    $("select.uniform, input:file, .dataTables_length select").uniform()

    // Add a div wrapper so that headers get proper styling
    $.each($("th[class|='header'],th[class|='sorting']"), function() {
      return $(this).wrapInner("<div />");
    });
  };

  // https://stackoverflow.com/questions/7738117/html-text-overflow-ellipsis-detection
  function isEllipsisActive(e) {
     return e.offsetWidth < e.scrollWidth;
  }

  var inDepartmentDrawCallback = function() {
    if ($("#reset-sort-order-button").length === 0 && (config.sortBy == 1))  {
      var button = '<div id="reset-sort-order-button"><a href="#" class="btn btn-small">' + I18n.t('department_users.js.reset_order') + '</a></div>';
      var $header = $('#in-department_wrapper ').find('.table-header');
      $header.append(button);

      $("#reset-sort-order-button").click(function(event) { resetSortOrder(event); } );
    }

    // Allow employee moving/shuffling if they're sorting manually and have no filter inputted
    if ($('#in-department_filter input').val() === '' && config.sortBy == 1) {
      $('#in-department').sortable('enable');
      $('#in-department .fa-arrows').css('display', 'inline-block');
    }
    // Hide the move icon otherwise
    else {
      $('#in-department').sortable('disable');
      $('#in-department .fa-arrows').css('display', 'none');
    }

    initPopoverHandlers();
  };

  var notInDepartmentDrawCallback = function() {
    initPopoverHandlers();
  };

  var initPopoverHandlers = function() {
    $(".job-sites-truncated").hover(
      function () {
        if (!isEllipsisActive(this)) return;
        $(this).nextAll("div").eq(0).fadeIn();
      },
      function () {
        $(this).nextAll("div").eq(0).fadeOut();
      }
    );

    $(".positions-truncated").hover(
      function () {
        if (!isEllipsisActive(this)) return;
        $(this).nextAll("div").eq(0).fadeIn();
      },
      function () {
        $(this).nextAll("div").eq(0).fadeOut();
      }
    );

    $(".skills-truncated").hover(
      function () {
        if (!isEllipsisActive(this)) return;
        $(this).nextAll("div").eq(0).fadeIn();
      },
      function () {
        $(this).nextAll("div").eq(0).fadeOut();
      }
    );
  };

  var resetSortOrder = function(event) {
    event.preventDefault();

    var request = $.ajax(config.resetSortPath, {
      type: 'PUT',
      dataType: 'json',

      complete: function() {
        tables.inDepartmentDataTable.DataTable().draw();
      }
    });
  };

  var updateUserOrder = function() {
    var $trs = tables.inDepartmentDataTable.find("tbody > tr");
    var userIds = [];

    $trs.each(function() {
      var $tr = $(this);
      var userId = $tr.attr('id').split("_")[1];
      userIds.push(userId);
    });

    $.ajax({
      url: config.editPositionPath,
      type: 'PUT',
      dataType: 'json',
      data: {user_ids: userIds}
    });
  };

  var setupDataTable = function(selector) {
    var $dataTable = $(selector);

    var dataTableOptions = {
      processing: true,
      ordering: false,
      serverSide: true,
      ajax: {
        url: $dataTable.data('source'),
        data: { scope: $dataTable.data('scope') }
      },
      language: dataTables_i18n,
      // Ellipsize extra-long positions, job sites, and skills
      // Must have fixed width to do this
      autoWidth: false,
      searchDelay: 600
    };

    if (selector === "#not-in-department") {
      // Show pagination, each page defaults to 50 users
      $.extend(dataTableOptions, {
        pagingType: "full_numbers",
        pageLength: 50,
        dom: "<\"table-header\"flr><\"table-responsive\"t><\"table-footer\"ip>",
        drawCallback: notInDepartmentDrawCallback
      });
    } else {
      // No pagination, shows all users
      $.extend(dataTableOptions, {
        pageLength: config.totalUsers,
        dom: "<\"table-header\"fr><\"table-responsive\"t><\"table-footer\"i>",
        drawCallback: inDepartmentDrawCallback
      });
    }

    $dataTable.DataTable(dataTableOptions);

    return $dataTable;
  };

  // sets up the buttons for adding / removing
  var initButtonToggles = function() {
    var self = this;

    // Add a user to the department
    $('body').on('click', 'tbody a.btn.btn-create', function(e) {
      e.preventDefault();

      var $self = $(this);
      var $url = $self.attr('href');
      var $url_method = $self.data('url-method');

      var request = $.ajax({
        url: $url,
        type: $url_method,
        cache: false,
        dataType: 'json'
      });

      disableTableButton($self);

      // This will disappear the row, so we don't have to re-enable the button
      request.done(function(data) {
        tables.inDepartmentDataTable.DataTable().draw();
        tables.notInDepartmentDataTable.DataTable().draw();
      });

      request.fail(function(xhr, status, error) {
        enableTableButton($self, I18n.t('buttons.add'));

        var $modal = $('.bootbox.modal.fade.in');
        if (!$modal.length) {
          bootbox.alert('<h4>' + I18n.t('department_users.js.error') + '</h4><p>' + xhr.responseText + '</p>',
            I18n.t("buttons.ok"));
        }
      });
    });

    // Click the remove button (opens up the confirmation modal)
    $('body').on('click', 'tbody a.btn.btn-delete', function(e) {
      e.preventDefault();
      $('#department-users-modal').modal('show');
      $('#remove-button').data('table-button-id', $(this).attr('id'))
      $('#remove-button').prop('disabled', false);
    });

    $('body').on('click', '#remove-button', function(e) {
      e.preventDefault();
      $(this).prop('disabled', true);
      var removeButton = $("#" + $(this).data('table-button-id'));
      disableTableButton(removeButton);
      submitAction(removeButton);
    });

  };

  var enableTableButton = function(button, text) {
    $.rails.enableElement(button);
    button.removeClass("disabled").text(text);
  };

  var disableTableButton = function(button) {
    $.rails.disableElement(button);
    button.addClass("disabled").text("...");
  };

  // Submit form for deletion of users from departments.
  var submitAction = function($button) {
    var $url = $button.attr('href');
    var $url_method = $button.data('url-method');
    var userId = $button.data('user-id');

    var request = $.ajax({
      url: $url,
      type: $url_method,
    });

    request.done(function(data) {
      $('#department-users-modal').modal('hide');
    });

    request.fail(function(data){
      enableTableButton($button, I18n.t('buttons.remove'))
      flashMessages.error(data.responseJSON.error)
      $('#department-users-modal').modal('hide');
    });

  };

  return {
    init: init
  };

})(jQuery);
