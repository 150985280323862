import { userCalendarModals } from 'core/user_calendar_modals.js';
import { calendarHelper } from 'core/calendar_helper.js';
import { msUtil } from 'core/ms_util.js';
import { am_pm_to_hours, formatPartialUnavailableTimes, shorten_ampm, tConvert, user_prefers_12hr } from 'core/helpers/format_helpers.js';
import { makeshiftGoogleAnalytics } from 'core/google_analytics.js';

// The user calendar uses a library called FullCalendar 1.6v
// The documentation can be found here: https://fullcalendar.io/docs/v1

// NOTES:
//
// Time values on this calendar are VERY CONFUSING
// - the calendar itself has *no concept of time zones*, which means it operates in the browser's local time zone
// - as a result, all epoch timestamps sent to the back end to retrieve events are based on the browser's local time zone
// - when grabbing events, that generally works
//   - fullcalendar sends timestamps based on *browser's local timezone*
//   - the backend *assumes they're in UTC* (mostly incorrectly)
//   - but because the timestamps are converted to *dates* on the backend, they still encompass the calendar dates and it works out OK
// - however, when *clearing* events on the calendar, we need to factor in two things:
//   1. - persisted: the timestamps we send to the backend should be date-convertible to the correct dates when treated as UTC timestamps
//   2. - in-memory: events we clear locally should match be found based on *browser's local timestamps*
window.userCalendar = {};

(function(calendar) {
  var addedEvents;
  var unavailableEvents;
  var deletedEvents;
  var addedRotations;

  var rejectDupes;

  var calendar_reloaded = false;

  var priorEventColor = '#F4F3F6';
  var priorEventColorDark = '#CCCCCC';

  var draggableBlockConfig = {
    containment: 'document',
    cursor: 'move',
    opacity: 0.8,
    zIndex: 9999,
    revert: true,
    revertDuration: 0,
    helper: function() {
      var helper = $(this).clone();
      helper.css('height','45px');
      helper.css('width','100px');
      helper.find('span.shift-type-line2').hide();
      return helper;
    }
  };

  calendar.init = function(opts) {
    $('#save_changes').on('click', function() {
      if ($(this).hasClass('disabled')) return;
      userCalendar.saveCalendarChanges();
    });

    $('#discard_changes').on('click', function() {
      if ($(this).hasClass('disabled')) return;
      discardCalendarChanges();
    });

    $('#clear_dates').on('click', () =>{ userCalendarModals.clearDatesDialog(); });
    $(window).bind('beforeunload', confirmReload);

    // Handle new record link on tab switch
    $('a[data-toggle="tab"]').on('shown', function (e) {
      $('#new-record-link').attr('href', $(this).data('new-record-url'));
    });

    rejectDupes = initRejectDuplicateShifts(opts); // reject dragged shifts if there are duplicates

    // Initialize calendar with shift types, rotations, unavailable blocks and all events on the calendar
    if (MKS.show_time_off) initializeUnavailable();
    initializeCustomShifts();
    initializeTrackingArrays();
    initializeShiftTypes();
    initializeRotations();
    initializeLastCalendarLocation();
    setupCalendar(opts);
  };

  // Tracks any event changes on the calendar
  function initializeTrackingArrays() {
    addedEvents = [];
    unavailableEvents = [];
    deletedEvents = [];
    addedRotations = [];
  }

  // Sets the height for the shift types container tab and creates the shift type draggable blocks
  function initializeShiftTypes() {
    var shiftTypes = MKS.shift_types;

    $.each(shiftTypes, (index, item) => {
      $('#external-events').append('<li class=\'span6 external-event\' style=\'margin-left: 2px;\' id=\'' + 'shift_type_id_' + item.shift_type_id + '\'>' + item.title + '</li>');
      $('#shift_type_id_'+item.shift_type_id).data('keyword', item.title_text.toLowerCase());
      $('#shift_type_id_'+item.shift_type_id).data('details', item);
      $('#shift_type_id_'+item.shift_type_id).draggable(draggableBlockConfig);
    });
  }

  // Creates the unavailable (time-off) draggable block
  function initializeUnavailable() {
    var unavailable = MKS.unavailable;

    $('#drag-and-drop-blocks').append('<li class=\'span6 unavailable\'>' + unavailable.title + '</li>');
    $('.unavailable').data('details', unavailable);
    $('.unavailable').draggable(draggableBlockConfig);
  }

  // Creates a custom shift draggable block
  function initializeCustomShifts() {
    var customShift = MKS.custom_shift;

    $('#drag-and-drop-blocks').append('<li class=\'span6 custom-shift-block\'>' + customShift.title + '</li>');
    $('.custom-shift-block').data('details', customShift);
    $('.custom-shift-block').draggable(draggableBlockConfig);
  }

  // Creates the rotation draggable blocks
  function initializeRotations() {
    var rotations = MKS.rotations;

    $.each(rotations, (index, item) => {
      $('#external-rotations').append('<li class=\'span6 long-names\' id=\'' + 'rotation_id_' + item.rotation_id + '\'>' + item.title + '</li>');
      $('#rotation_id_'+item.rotation_id).data('keyword', item.title.toLowerCase());
      $('#rotation_id_'+item.rotation_id).data('details', item);
      $('#rotation_id_'+item.rotation_id).draggable(draggableBlockConfig);
    });
  }

  function initializeLastCalendarLocation() {
    if(getURLParameter('year')) {
      MKS.lastSetYear = parseInt(getURLParameter('year'));
    } else {
      MKS.lastSetYear = moment().year();
    }

    if(getURLParameter('month')) {
      MKS.lastSetMonth = parseInt(getURLParameter('month'));
    } else {
      MKS.lastSetMonth = moment().month();
    }
  }

  // Sets up pusher channel to listen for trigger when the delayed ShiftExtension#update_schedule function is done.
  function initializePusher() {
    var calendarDate = moment($('#calendar').fullCalendar('getDate'));
    var rememberedCalendarMonth = MKS.department_user_schedule_url + '?' + $.param({month: calendarDate.month(), year: calendarDate.year()});

    // Pusher event triggered by successful timesheet update
    window.msPusherChannel.bind('schedule_update.processed', data => {
      calendar_reloaded = true;
      window.location = rememberedCalendarMonth;
    });

    window.msPusherChannel.bind('schedule_update.failed', data => {
      $('.bootbox').modal('hide');
      bootbox.alert(data.message, () => {
        calendar_reloaded = true;
        window.location = rememberedCalendarMonth;
      });
    });
  }

  // Sets a flag if dragging onto duplicate shifts should raise a modal
  function initRejectDuplicateShifts(opts) {
    return opts.scheduleIntegrated;
  }

  // Used to show the loading state of the calendar
  var spinner;

  function setupCalendar(opts) {
    $('#calendar').fullCalendar({
      firstDay: MKS.departmentStartOfWeek,
      year: MKS.lastSetYear,
      month: MKS.lastSetMonth,
      header: {
        left: 'prev,next today',
        center: 'title',
        right: false
      },
      buttonText: {
        today: I18n.t('department_schedules.show.toolbar.today')
      },
      monthNames: I18n.t('date.month_names').slice(1),
      monthNamesShort: I18n.t('date.abbr_month_names').slice(1),
      dayNames: I18n.t('date.day_names'),
      dayNamesShort: I18n.t('date.abbr_day_names'),
      columnFormat: {
        month: 'dddd'
      },
      eventClick: function(event, jsEvent, view) {
        if(event.type === 'availability') { return; }

        var conflictingRotation = conflictsWithRotation(event.start);
        if(conflictingRotation.length > 0) {
          userCalendarModals.rotationClickDialog(conflictingRotation[0], event);
          return;
        }
        userCalendarModals.eventClickDialog(event,opts.isReadOnlySchedule);
      },
      slotMinutes: 15,
      dragOpacity: '0.5',
      timeFormat: '',
      editable: true,
      disableDragging: true,
      disableResizing: true,
      selectable: false,
      unselectAuto: true,
      droppable: true,
      dayRender: function(date, element, view) {
        if(calendarHelper.earlierDayThanToday(date)){
          $(element).css('background-color', priorEventColor);
        }
        else if (date.toDateString() === moment(MKS.current_time_in_timezone)._d.toDateString()) {
          $(element).addClass('fc-today fc-state-highlight');
        }
      },
      eventRender: function( event, element, view ) {
        renderEvent(event, element);
      },
      eventDrop: function(event, dayDelta, minuteDelta, allDay, jsEvent, ui, view) {
        eventDrop(event);
      },
      drop: function(date, allDay) {
        var draggedEvent = $(this).data('details');
        var conflictingRotation = conflictsWithRotation(date);

        if (rejectDupes && duplicateDragEventShift(draggedEvent, date)) {
          userCalendarModals.duplicateShiftDialog();
          return;
        }

        if(conflictingRotation.length > 0) {
          userCalendarModals.promptForDeleteRotationDialog(conflictingRotation[0]);
          return;
        }

        createEvent(draggedEvent, date);
      },
      select: function(start, end, allDay) {
      },
      eventSources: loadEvents(true),
      loading: function(isLoading) {
        // Loading has started (async call has been made)
        if (isLoading) {
          spinner = msUtil.spinner('calendar');
        }
        // Loading has finished (async call has returned)
        else {
          spinner.stop();
          deleteUnpersistedRotationEventsWithConflict();
        }
      }
    });
  }

  // Walk through client events and delete new rotation events with conflicts
  // Say we have another department shift two months in the future when we lay down a long rotation
  // FullCalendar doesn't have any knowledge outside the current month, so it could well conflict
  // without knowing until we scroll over to that month. In that case, we want to dynamically remove
  // our unpersisted rotation event for the day because that's what the final outcome of publishing will be
  function deleteUnpersistedRotationEventsWithConflict() {
    var fullCalendarEventsToDelete = $('#calendar').fullCalendar('clientEvents', event => {
      if (event.original_id) return false;  // Only if unpersisted
      if (!event.rotation_id) return false; // Only if rotation event

      // Compare against all *other* events on the calendar
      var unpersistedRotationEvent = event;
      var foundOverlap = false;
      $('#calendar').fullCalendar('clientEvents', event => {
        // Don't try to delete ourselves
        if (event._id == unpersistedRotationEvent._id)
          return false;

        // We will destroy shift events in our own department, so we shouldn't destroy ourselves on their account
        if (event.type === 'shift' && event.department_id === unpersistedRotationEvent.department_id)
          return false;

        if (unpersistedRotationEvent.start >= event.start && unpersistedRotationEvent.start < new Date(event.real_end)) {
          foundOverlap = true;
        }
      });

      return foundOverlap;
    });
    userCalendar.deleteEvents(fullCalendarEventsToDelete);
  }

  function loadEvents(lazyFetching) {
    return [{
      url: MKS.department_user_schedule_url,
      lazyFetching: lazyFetching,
      editable: false
    },
    {
      url: MKS.unavailables_department_user_schedule_url,
      lazyFetching: lazyFetching,
      editable: false,
      className: 'unavailable'
    },
    {
      url: MKS.availabilities_department_user_schedule_url,
      lazyFetching: lazyFetching,
      className: 'availability',
      editable: false
    }];
  }

  function isDeletableEvent(event, allowUnavailableDelete) {
    allowUnavailableDelete = !!allowUnavailableDelete;

    // Never deletable
    // - stuff outside of the current department
    // - availabilities (don't conflict anyway)
    // - non-MS time off or time off requests (synced from ADP, for instance)
    if (!calendarHelper.isInCurrentDepartment(event)
     ||  event.type === 'availability'
     || (event.type === 'unavailable' && event.import_source !== 'ms')
     || (event.type === 'unavailable-request' && event.import_source !== 'ms')
    )
    {
      return false;
    }

    // Deletable in some circumstances
    if (allowUnavailableDelete) {
      return true;
    } else {
      // Treat unavailable requests and unavailables as unavailables
      var isUnavailable = event.type === 'unavailable' || event.type === 'unavailable-request';
      return !isUnavailable;
    }
  }

  // Called by createRotation and also by the Clear Dates dialog box (in user_calendar_modals.js.es6)
  // Previously we would retrieve events directly out of FullCalendar, but we'd have to do extra work to get non-visible ones (outside this month)
  // Instead, we just make a separate request between the dates and mark for deletion that way
  // Inputs are two date strings (YYYY-MM-DD).
  // We parse them as UTC since we don't have a time zone context (browser's local time zone could be anything!)
  // Two classes of items to delete: in-memory and persisted
  // We accept a parameter to determine if we clear unavailables, because clearing dates DOES clear time off but rotations DO NOT
  userCalendar.deleteEventsBetween = function(startDate, endDate, allowUnavailableDelete, auditAction, rotationTitle = '') {

    // FullCalendar works based on *browser's local time*, so we convert the date strings accordingly
    var localStart = new Date(Date.parse(startDate));
    var localEnd   = calendarHelper.setToEndOfDay(new Date(Date.parse(endDate)));

    // FullCalendar events are dated based on the browser's *local time zone*
    // Refuse to delete events that are:
    //   - not in this department
    //   - availabilities
    //   - any unavailables (if allowUnavailableDelete is falsey)
    //   - unavailables from a source other than MakeShift
    var fullCalendarEventsToDelete = $('#calendar').fullCalendar('clientEvents', event => {
      var overlappingTime = event.start >= localStart && event.start <= localEnd;
      if (!overlappingTime) return false;
      return isDeletableEvent(event, allowUnavailableDelete);
    });

    fullCalendarEventsToDelete.forEach(event => {
      event.audit_action = auditAction;
      event.audit_action_source = 'user_calendar';
      event.rotation_name = rotationTitle;
    });

    userCalendar.deleteEvents(fullCalendarEventsToDelete);

    // Backend assumes UTC timestamps to convert from Unix time to a date string
    var startTime = moment.utc(startDate, 'YYYY-MM-DD').unix();
    var endTime   = moment.utc(endDate,   'YYYY-MM-DD').unix();

    // Persisted events expect an epoch timestamp that will
    // *successfully date-convert in this department's timezone*
    $.getJSON(MKS.department_user_schedule_url, { start: startTime, end: endTime })
      .success(events => {
        userCalendar.deleteEvents(_.filter(events, isDeletableEvent, allowUnavailableDelete));
      });

    $.getJSON(MKS.unavailables_department_user_schedule_url, { start: startTime, end: endTime })
      .success(events => {
        userCalendar.deleteEvents(_.filter(events, isDeletableEvent, allowUnavailableDelete));
      });
  };

  userCalendar.submitNotes = function(event, notes) {
    $.ajax({
      type: 'PUT',
      url: MKS.update_shift_url+event.original_id,
      data: {shift: {notes: notes, audit_action_source: 'user_calendar'}},
      success: function(data) {
        $('#calendarModal').modal('hide');
        event.notes = notes;
      }
    });
  };

  function modify_schedule(calendar_events) {
    var calendarDate = moment($('#calendar').fullCalendar('getDate'));
    var rememberedCalendarMonth = MKS.department_user_schedule_url + '?' + $.param({month: calendarDate.month(), year: calendarDate.year()});

    userCalendarModals.waitMessageDialog();

    $.ajax({
      type: 'PUT',
      url: MKS.update_department_user_schedule_url,
      data: calendar_events,
    }).error(data => {
      var alert_message = I18n.t('calendar.js.change_failed');
      if (data.responseText)
        alert_message = data.responseText;

      $('.bootbox').modal('hide');
      bootbox.alert(alert_message, () => {
        calendar_reloaded = true;
        window.location = rememberedCalendarMonth;
      });
    });
  }

  // Called when Publish & Notify button is clicked
  userCalendar.saveCalendarChanges = function() {
    if(!calendarHasChanged()) { return false; }

    if (user_prefers_12hr()) {
      addedEvents.forEach(event => {
        // if this is a new custom shift convert the hours for saving
        if (event.end_time && event.start_time) {
          event.end_time   = am_pm_to_hours(event.end_time);
          event.start_time = am_pm_to_hours(event.start_time);
        // if it's a shift template we won't have start_time and end_time
        } else {
          event.end_time = event.end.getHours() + ':' + event.end.getMinutes();
          event.start_time = event.start.getHours() + ':' + event.start.getMinutes();
        }
      });
    }

    $.merge(addedEvents, deletedEvents);
    var nonRotationEvents = $.merge($.merge([], addedEvents), unavailableEvents);
    var sortedEvents = calendarHelper.sortEvents($.merge($.merge([], nonRotationEvents), addedRotations));

    initializePusher();

    modify_schedule({
      shifts: addedEvents,
      unavailables: unavailableEvents,
      patterns: addedRotations,
      earliestEventDate: calendarHelper.getEarliestDate(sortedEvents),
      latestEventDate: calendarHelper.getLatestDate(sortedEvents)
    });
  };

  function conflictsWithRotation(eventDate) {
    var conflictingRotation = $.grep(addedRotations, (item, index) => {
      return (eventDate >= item.start && eventDate <= item.end);
    });

    return conflictingRotation;
  }

  function conflictsWithEventFromAnotherDepartment(eventDate) {
    var today = calendarHelper.getToday(eventDate);
    var tomorrow = calendarHelper.getTomorrow(eventDate);

    var isConflicting = $('#calendar').fullCalendar('clientEvents', event => {
      return event.start >= today && event.start < tomorrow && !calendarHelper.isInCurrentDepartment(event);
    });

    return (isConflicting.length > 0);
  }

  // Prevent creation of event when dragging block onto a time off event
  function conflictsWithUnavailable(eventDate) {
    var eventDateStr = moment(eventDate).format('YYYY-MM-DD');
    var isConflicting = $('#calendar').fullCalendar('clientEvents', event => {
      return ( (event.type === 'unavailable' || event.type === 'unavailable-request') && moment(event.start).format('YYYY-MM-DD') === eventDateStr );
    });

    return (isConflicting.length > 0);
  }

  function duplicateRotationShift(draggedEvent, date, rotationEndDate) {
    var hasConflicts = false;

    date = date.clone();
    while(date < rotationEndDate) {
      $.each(calendarHelper.getRotationPattern(draggedEvent), (index, shifts) => {
        var start;
        var end;

        if(date >= rotationEndDate) { return false; }

        if (shifts && shifts.length && shifts[0].hasOwnProperty('unavailable_type_id')) {
          start = date;
          end = date.clone().add(1).days();
        } else {
          $.each(shifts, (index, shift_position) => {
            var shiftType = $('#shift_type_id_'+shift_position.shift_type_id).data('details');
            start = calendarHelper.isUnavailable(draggedEvent.type) ? userCalendar.dateString(date) : new Date(date.getTime()+(shiftType.start_from_midnight*1000));
            end = calendarHelper.isUnavailable(draggedEvent.type) ? userCalendar.dateString(date.clone().add(1)) : new Date(date.getTime()+(shiftType.start_from_midnight*1000)+(shiftType.duration*1000));
          });
        }

        if (start && end && duplicateShift(start, end, null)) {
          hasConflicts = true;
        }

        date.addDays(1);
      });
    }

    return hasConflicts;
  }

  function duplicateDragEventShift(draggedEvent, date) {
    var newEvent = $.extend({}, draggedEvent);

    if (!newEvent.start_from_midnight) {
      return false;
    }

    var draggedEventStart = new Date(date.getTime()+(newEvent.start_from_midnight*1000));
    var draggedEventEnd   = new Date(date.getTime()+(newEvent.start_from_midnight*1000)+(newEvent.duration*1000));

    return duplicateShift(draggedEventStart, draggedEventEnd, draggedEvent.time_reporting_code);
  }

  function duplicateShift(start, end, trc) {
    var isConflicting = $('#calendar').fullCalendar('clientEvents', event => {
      var eventStart = event.start;
      var eventEnd = new Date(event.start.getTime() + event.duration*1000);

      // Check TRCs too if both event and draggedEvents have em
      if (!!event.time_reporting_code && !!trc) {
        return (
          (eventStart.getTime() === start.getTime()) &&
          (eventEnd.getTime() ===   end.getTime()) &&
          (event.time_reporting_code === trc)
        );
      } else {
        return (
          (eventStart.getTime() === start.getTime()) &&
          (eventEnd.getTime() === end.getTime())
        );
      }
    });

    return (isConflicting.length > 0);
  }

  userCalendar.deleteRotation = function deleteRotation(addedRotation) {
    deleteRotationEvents(addedRotation);
    $('#calendarModal').modal('hide');
    addedRotations = $.grep(addedRotations, (element, i) => { return element.start === addedRotation.start; }, true);
    updateButtonStates();
  };

  // Deletes any client-side events that interfere with an added rotation
  // We know they're client-side because they have no persisted ID
  function deleteRotationEvents(addedRotation){
    $('#calendar').fullCalendar('removeEvents', event => {
      var conflict = event.start >= addedRotation.start
                    && event.start <= addedRotation.end
                    && event.original_id === undefined;

      if (conflict) {
        // console.log("deleteRotationEvents", event, "because it conflicts with rotation", addedRotation);
      }

      return conflict;
    });
  }

  function removeFullCalendarEvent(event) {
    // If we remove something with an undefined _id, it'll clear the whole calendar
    if (event._id === undefined) return;
    // console.log("removeFullCalendarEvent", event);
    $('#calendar').fullCalendar('removeEvents', event._id);
  }

  function renderEvent(fcEvent, element) {
    // Hide an event from the frontend
    // if we're being asked to render it and we know it's deleted
    if (inDeletedEvents(fcEvent)) {
      removeFullCalendarEvent(fcEvent);
    } else {
      element.find('.fc-event-title').html(fcEvent.title);
      setCurrentAndFutureEventColor(fcEvent, element);
    }

    // Show all labels
    $('.shift-type-label, .position-label, .job-site-label').show();

    // Show the position select qtip, if it hasn't been shown and needs to
    if (!fcEvent.positionSelectShown && (fcEvent.positions || fcEvent.job_sites)) {
      // Add a small delay to give time for the calendar event to fully render
      setTimeout(() => showPositionsAndJobSitesQtip(element, fcEvent), 50);
    }
  }

  function createSelectElement(id, items) {
    const $select = $('<select>').attr('id', id).css({'margin': 0, 'font-size': '10px'});
    $select.append($('<option value="">')); // For the placeholder value to show up
    items.forEach(item => {
      $select.append($('<option>').attr('value', item.id).text(item.name));
    });
    return $select;
  }

  function showPositionsAndJobSitesQtip($element, fcEvent) {
    $element.qtip({
      content: {
        text: function() {
          const { _id, positions, job_sites } = fcEvent;
          const wrapper = $('<div>');
          const $form = $('<form>').css('width', 'min-content');
          if (positions) {
            const $positionSelect = createSelectElement('position-' + _id, positions);
            $positionSelect.appendTo($form);
          }
          if (job_sites) {
            const $jobSiteSelect = createSelectElement('job-site-' + _id, job_sites);
            if (positions) {
              $jobSiteSelect.css({margin: '5px 0 0 0'});
            }
            $jobSiteSelect.appendTo($form);
          }
          wrapper.append($form);
          return wrapper.html();
        }
      },
      position: {
        my: 'center left',
        at: 'center right',
        viewport: $('#calendar'),
        target: $element,
        adjust: { x: -10, method: 'flip none'}
      },
      show: true,
      hide: 'unfocus',
      style: {
        classes: 'qtip-bootstrap position-select'
      },
      events: {
        // readjust x on flip
        move: function(event, api, position, viewport) {
          if (position.adjusted.left !== 0) { return position.left += 20; }
        },
        // Apply the select2 styles to the select menus
        visible: function(event, api) {
          const $positionSelect = $(event.currentTarget).find('select[id^="position"]');
          $positionSelect.select2({ placeholder: I18n.t('simple_form.placeholders.shift.position') });
          // Bind an event listener that will reposition the qtip when the selection changes
          // This takes care of repositioning the qtip when the new selection
          // changes the width of the qtip
          $positionSelect.on('change', () => {
            api.reposition();
          });

          const $jobSiteSelect = $(event.currentTarget).find('select[id^="job-site"]');
          $jobSiteSelect.select2({ placeholder: I18n.t('simple_form.placeholders.shift.job_site') });
          $jobSiteSelect.on('change', () => {
            api.reposition();
          });

          // Get the tooltip back into the right place after resizing the select menus
          api.reposition();
        },
        // submit tooltip select position, and job site
        hide: function(event, api) {
          const updatedEvent = updateEventWithPositionAndJobSiteSelection(this);
          updateFullCalendarEvent(updatedEvent);
          clearQtip();
        }
      }
    });
  }

  // Populates the event with information from the
  // position and job site qtip, and returns the updated event
  function updateEventWithPositionAndJobSiteSelection($element) {
    const $form            = $($element).find('form');
    let position_id       = null,
      job_site_id       = null,
      event_id          = null,
      position_name     = '',
      job_site_name     = '';
    const $position = $form.find('select[id^=\'position\']');
    const $jobSite  = $form.find('select[id^=\'job-site\']');

    if ($position.length) {
      const $selectedPosition = $position.find(':selected');
      position_id   = $selectedPosition.val();
      position_name = position_id ? $selectedPosition.text() : '';
      event_id      = $position.attr('id').split('-')[1];
    }
    if ($jobSite.length) {
      const $selectedJobSite = $jobSite.find(':selected');
      job_site_id   = $selectedJobSite.val();
      job_site_name = job_site_id ? $selectedJobSite.text() : '';
      if (!event_id) {
        event_id = $jobSite.attr('id').split('-')[2];
      }
    }

    const event = addedEvents.find(e => e._id === event_id);
    if (event) {
      event.position_id   = position_id;
      event.position_name = position_name;
      event.job_site_id   = job_site_id;
      event.job_site_name = job_site_name;
    }
    return event;
  }

  function clearQtip() {
    $('div.qtip-bootstrap.position-select').remove();
  }

  // Updates the event stored in memory within FullCalendar with details
  // from the positions and job site qtip
  function updateFullCalendarEvent(event) {
    const $calendar = $('#calendar');
    const [fcEvent] = $calendar.fullCalendar('clientEvents', event._id);
    if (fcEvent) {
      const updatedEvent = $.extend(fcEvent, event);

      // Append the position and/or job site names to the full calendar event title
      // to be consistent with the custom shift events
      const {position_name, job_site_name} = updatedEvent;
      if (position_name) {
        updatedEvent.title += '<span>' + position_name + '</span>';
      }
      if (job_site_name) {
        updatedEvent.title += '<span>' + job_site_name + '</span>';
      }

      // Set flag as to not show the qtip on the next render phase
      updatedEvent.positionSelectShown = true;

      $calendar.fullCalendar('updateEvent', updatedEvent);
    }
  }

  function setCurrentAndFutureEventColor(event, element) {
    // Anything that cannot show as unpublished
    // e.g. unavailable requests and availabilities
    if (event.type === 'unavailable-request') {
      if (calendarHelper.earlierTimeThanToday(event.start.getTime())) {
        element.addClass('unavailable');
      } else {
        element.addClass('unavailable-request');
      }
    }
    else if (event.type === 'availability') {
      if (calendarHelper.earlierTimeThanToday(event.start.getTime())) {
        element.addClass('availability-light');
      }
    }
    // Anything that can show as unpublished
    // e.g. rotations, assigned shifts and unavailables
    else {
      var isUnpublishedShift = event.type === 'shift' && event.published_at === undefined;
      if (event.original_id === undefined || isUnpublishedShift) {
        element.addClass('unpublished');
      }

      // Unavailables or unavailables within rotations
      if (event.type === 'unavailable' ||
         (event.type === 'rotation' && event.shift_type_id === '0')) {
        if (calendarHelper.earlierTimeThanToday(event.start.getTime())) {
          element.addClass('unavailable-light');
        } else {
          element.addClass('unavailable');
        }
      }
      // Shifts or shifts within rotations
      else {
        var colorCode = MKS.department_ids.indexOf(event.department_id);
        var shadeOfColor = '-cal';
        if (calendarHelper.earlierTimeThanToday(event.start.getTime())) {
          event.editable = false;
          shadeOfColor = '-cal-light';
        }
        if (colorCode === -1) {
          colorCode = 'non-accessible';
        }
        element.addClass('department-' + colorCode + shadeOfColor);
      }
    }
  }

  // Checks if a FullCalendar event is in the set of all deleted events
  // Compares FullCalendar IDs (different from MakeShift IDs!)
  // A special case is if we "delete" an event that's off the current calendar view but don't yet publish
  // It won't match on _id since the fcEvent that's now showing will be given a new ID that didn't exist when we deleted it
  function inDeletedEvents(fcEvent) {
    var isDeleted = $.grep(deletedEvents, item => {
      // The deleted event had a persisted ID, so use that for comparison
      // Important to check type also, because IDs can be shared across shifts/unavailables
      if (item.original_id !== undefined) {
        return item.original_id === fcEvent.original_id
            && item.type        === fcEvent.type;
      } else {
        return fcEvent._id === item._id;
      }
    });
    return isDeleted.length > 0;
  }

  // Creates the dragged event based on its block type
  // date is a JS Date object representing midnight on the event date in the *browser's timezone*
  // This is what's given to us by FullCalendar
  function createEvent(draggedEvent, date) {
    draggedEvent.audit_action_source = 'user_calendar';
    if (calendarHelper.isCustomShift(draggedEvent.type)) {
      draggedEvent.audit_action = 'custom_shift';
      setupCustomShiftEvent(date, draggedEvent);
    } else if (calendarHelper.isRotation(draggedEvent.type)) {
      setupRotationEvent(date, draggedEvent);
    } else if (calendarHelper.isUnavailable(draggedEvent.type)) {
      setupUnavailableEvent(date, draggedEvent);
    } else {
      draggedEvent.audit_action = 'assign_from_shift_template';
      createShiftEvent(date, draggedEvent);
    }
  }

  // Create Shift from dragging a Shift Template bock
  function createShiftEvent(date, draggedEvent) {
    //Google Analytics
    ga('send','event',makeshiftGoogleAnalytics.categories.userCalendar, makeshiftGoogleAnalytics.actions.drag, makeshiftGoogleAnalytics.label.shift);

    var newEvent = $.extend({}, draggedEvent);

    // Resulting start and real_end represent JS Dates *in the browser's timezone*
    newEvent.start = new Date(date.getTime()+(newEvent.start_from_midnight*1000));
    newEvent.real_end = new Date(date.getTime()+(newEvent.start_from_midnight*1000)+(newEvent.duration*1000));

    var positions = MKS.department_positions[MKS.current_department];
    if (positions && positions.length > 1) {
      newEvent.positions = positions;
    }

    var job_sites = MKS.department_job_sites[MKS.current_department];
    if (job_sites && job_sites.length > 0) {
      newEvent.job_sites = job_sites;
    }

    newEvent.notes = draggedEvent.notes;
    newEvent.time_reporting_code = draggedEvent.time_reporting_code;
    newEvent.title = newEvent.dropTitle;
    newEvent.department_id = MKS.current_department;
    newEvent.department_name = MKS.current_department_name;
    newEvent.location_name = MKS.current_location_name;
    newEvent.shift_type_name = '';
    newEvent.deleted =  false;
    newEvent.hide_ends_at = draggedEvent.hide_ends_at;
    newEvent.pay_code = draggedEvent.pay_code;
    newEvent.pay_code_id = draggedEvent.pay_code_id;

    if(displayedConflictingEvents(newEvent)){
      return;
    }

    $('#calendar').fullCalendar('renderEvent', newEvent, true);
    addedEvents.push(formatEvent(newEvent));
    updateButtonStates();
  }

  // Create Custom Shift from dragging Custom Shift block
  function createCustomShiftEvent(date, formattedDate, start_time, end_time, hide_ends_at, notes, time_reporting_code, position_id, position_name, job_site_id, job_site_name, breaks, draggedEvent, pay_code_id, pay_code) {
    var newEvent = $.extend({}, draggedEvent);
    newEvent.start    = moment(formattedDate + ' ' + start_time)._d;
    newEvent.real_end = moment(formattedDate + ' ' + end_time)._d;

    if (newEvent.real_end <= newEvent.start) {
      newEvent.real_end.setDate(newEvent.real_end.getDate() + 1);
    }

    if (rejectDupes && duplicateShift(newEvent.start, newEvent.real_end, time_reporting_code)) {
      userCalendarModals.duplicateShiftDialog();
      return;
    }

    if (user_prefers_12hr()){
      start_time = tConvert(start_time);
      end_time = tConvert(end_time);
    }

    newEvent.start_time = start_time;
    newEvent.end_time   = end_time;

    var end_time_label = '(' + end_time + ')';
    if (hide_ends_at === false) {
      end_time_label = end_time;
    }

    newEvent.title           = start_time + ' - ' + end_time_label ;
    if (position_name.length) {
      newEvent.title += '<span>' + position_name + '</span>';
    }
    if (job_site_name.length) {
      newEvent.title += '<span>' + job_site_name + '</span>';
    }
    newEvent.notes             = notes;
    newEvent.time_reporting_code = time_reporting_code;
    newEvent.department_id     = MKS.current_department;
    newEvent.department_name   = MKS.current_department_name;
    newEvent.location_name     = MKS.current_location_name;
    newEvent.shift_type_name   = '';
    newEvent.deleted           =  false;
    newEvent.type              = 'shift';
    newEvent.hide_ends_at      = hide_ends_at;
    newEvent.position_id       = position_id;
    newEvent.job_site_id       = job_site_id;
    newEvent.breaks_in_minutes = breaks;
    newEvent.pay_code_id       = pay_code_id;
    newEvent.pay_code          = pay_code;

    if(displayedConflictingEvents(newEvent)){
      return;
    }

    $('#calendar').fullCalendar('renderEvent', newEvent, true);
    addedEvents.push(formatEvent(newEvent));
    updateButtonStates();
  }

  // Display custom shift modal from dragging Custom Shift block
  function setupCustomShiftEvent(date, draggedEvent) {
    var new_shift_url = '/departments/' + MKS.current_department + '/shifts/new';
    var formattedDate = moment(date).format('YYYY-MM-DD');

    var $request = $.get(new_shift_url, { referrer: 'userCalendar', shift: { date: formattedDate, user_id: MKS.calendar_user_id } });

    $request.done(html => {
      $('#shift-modal').html(html).modal('show');

      $('#create-custom-shift-btn').on('click', event => {
        var start_time, end_time, hide_ends_at, notes, time_reporting_code, position_id, position_name, job_site_id, job_site_name, pay_code_id, pay_code, breaks;
        start_time = (0 + $('#shift_start_time').val()).slice(-5);
        end_time = (0 + $('#shift_end_time').val()).slice(-5);
        hide_ends_at = $('#shift_hide_ends_at').is(':checked');
        notes = $('#shift_notes').val();
        time_reporting_code = $('#shift_time_reporting_code').val();
        position_id = $('.shift_position').find(':selected').val();
        position_name = $('.shift_position').find(':selected').text();
        job_site_id = $('.shift_job_site').find(':selected').val();
        job_site_name = $('.shift_job_site').find(':selected').text();
        pay_code_id = $('.shift_pay_code').find(':selected').val();
        pay_code = $('.shift_pay_code').find(':selected').text();
        breaks = _.chain($('#shift_breaks_in_minutes').select2('data')).map(breakObject =>{
          return breakObject.text;
        }).flatten().value().join(',');

        // Check the format of start/end time
        if (!start_time.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]/) || !end_time.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]/)) {
          bootbox.alert(I18n.t('calendar.js.invalid_start_or_end'));
          $('#shift-modal').modal('hide');
          return false;
        }

        // Prevent Shift#create from getting called
        event.preventDefault();
        $('#shift-modal').modal('hide');
        createCustomShiftEvent(date, formattedDate, start_time, end_time, hide_ends_at, notes, time_reporting_code, position_id, position_name, job_site_id, job_site_name, breaks, draggedEvent, pay_code_id, pay_code);
      });
    });
  }

  function alertUnavailableModal(msg) {
    $('#unavailable-modal .modal-body .alert-error').html(msg);
  }

  function setupUnavailableEvent(date, draggedEvent){
    var new_unavailable_url  = '/unavailables/new';
    var formattedDate = moment(date).format('YYYY-MM-DD');

    var $request = $.get(new_unavailable_url,  { referrer: 'userCalendar', unavailable: { date: formattedDate, user_id: MKS.calendar_user_id } });

    $request.done(html => {
      $('#unavailable-modal').html(html).modal('show');
      $('#unavailable-modal input[type="Submit"]').on('click', event => {
        var title, notes, numDays, unavailable_type_id, unavailable_type_title;

        event.preventDefault();

        if ($('#unavailable-modal .unavailable_unavailable_type select').val() === '') {
          alertUnavailableModal(I18n.t('unavailable.require_type'));
          return;
        }

        unavailable_type_id = $('#unavailable-modal .unavailable_unavailable_type select').val();

        unavailable_type_title = $('#unavailable-modal .unavailable_unavailable_type select option:selected').text();

        notes = $('#unavailable-modal .unavailable_notes textarea').val();

        var isPartial = $('input#unavailable_partial').is(':enabled');

        if (isPartial) {
          var date      = moment($('#unavailable-modal input[name=\'unavailable[date]\']').val()).toDate();
          var startTime = $('#unavailable-modal input[name=\'unavailable[start_time]\']').val();
          var endTime   = $('#unavailable-modal input[name=\'unavailable[end_time]\']').val();

          var newEvent = createPartialTimeOffEvent(
            date,
            startTime,
            endTime,
            draggedEvent,
            unavailable_type_title,
            unavailable_type_id, notes
          );

          if (displayedConflictingEvents(newEvent)){
            return;
          }

          // console.log('setupUnavailableEvent (partial)', newEvent);

          $('#calendar').fullCalendar('renderEvent', newEvent, true);
          unavailableEvents.push(formatPartialTimeOffEvent(newEvent));
        } else {
          // Full Day Time Off
          var startDate = moment($('#unavailable-modal input[name=\'unavailable[start_date]\']').val());
          var endDate = moment($('#unavailable-modal input[name=\'unavailable[end_date]\']').val());

          if (endDate < startDate) {
            alertUnavailableModal(I18n.t('unavailable.date_error'));
            return;
          }

          // For each day in the unavailable's date range, create an event
          numDays = endDate.diff(startDate, 'days') + 1;

          // Create event objects
          var events = [];
          for (var day = 0; day < numDays; day++) {
            newEvent = createUnavailableEvent(
              startDate.clone().add(day, 'days').toDate(),
              draggedEvent,
              unavailable_type_title,
              unavailable_type_id,
              notes
            );
            events.push(newEvent);
          }

          // Check for conflicts across entire range first
          // That way, we fail to create any unavailables if there's a conflict on a single day (like a transaction)
          var foundConflicts = false;
          $.each(events, (index, event) => {
            if (displayedConflictingEvents(event)) {
              foundConflicts = true;
              return false;
            }
          });

          // If there were no conflicts, create the events
          if (!foundConflicts) {
            $.each(events, (index, event) => {
              // console.log('setupUnavailableEvent (all-day)', event);
              $('#calendar').fullCalendar('renderEvent', event, true);
              unavailableEvents.push(formatEvent(event));
            });
          }
        }

        updateButtonStates();

        $('#unavailable-modal').modal('hide');
      });
    });
  }


  // Create Unavailable from dragging time off block
  function createUnavailableEvent(date, draggedEvent, unavailable_type_name, unavailable_type_id, notes) {
    // Title is used on the Calendar, not the modal.
    var title = ('<strong>' + unavailable_type_name + '</strong>') || newEvent.dropTitle;
    title += '</br>' + I18n.t('unavailable.full_day');

    var newEvent = $.extend({}, draggedEvent);
    newEvent.start           = date;
    newEvent.real_end        = date.clone().add(1).days();
    newEvent.notes           = notes;
    newEvent.unavailable_type_name = unavailable_type_name;
    newEvent.unavailable_type_id = unavailable_type_id;
    newEvent.title           = title;
    newEvent.shift_type_name = draggedEvent.title_text;
    newEvent.deleted         = false;
    newEvent.duration        = I18n.t('calendar.js.one_day'); // for the time being, always 1 day
    newEvent.department_id   = MKS.current_department;

    return newEvent;
  }

  // Create Unavailable from dragging time off block
  function createPartialTimeOffEvent(date, startTime, endTime, draggedEvent, unavailable_type_name, unavailable_type_id, notes) {
    // Title is used on the Calendar, not the modal.
    var title = ('<strong>' + unavailable_type_name + '</strong>') || newEvent.dropTitle;

    var startShowTime = startTime;
    var endShowTime = endTime;

    if (user_prefers_12hr()) {
      startShowTime = shorten_ampm(tConvert(startTime));
      endShowTime   = shorten_ampm(tConvert(endTime));
    }

    var duration = startShowTime + ' - ' + endShowTime;
    title += '</br>' + duration;

    /*
      Notes:
      - "start" and "real_end" are required event params
      - "real_end" will get converted into "end" when it's formatted
      - They're used later for "getEarliestDate" and "getLatestDate" respectively
    */
    var realStartDate = userCalendar.dateString(date); // Same day
    var realEndDate;
    if (startTime < endTime) {
      realEndDate = userCalendar.dateString(date); // Same day
    } else {
      realEndDate = userCalendar.dateString(date.clone().add(1).days()); // Crosses midnight
    }

    var newEvent = $.extend({}, draggedEvent);
    newEvent.start           = moment(realStartDate + ' ' + startTime)._d;
    newEvent.real_end        = moment(realEndDate + ' ' + endTime)._d;
    newEvent.start_time      = startTime;
    newEvent.end_time        = endTime;
    newEvent.notes           = notes;
    newEvent.unavailable_type_name = unavailable_type_name;
    newEvent.unavailable_type_id = unavailable_type_id;
    newEvent.title           = title;
    newEvent.shift_type_name = draggedEvent.title_text;
    newEvent.deleted         = false;
    newEvent.partial         = true;
    newEvent.duration        = duration;
    newEvent.department_id   = MKS.current_department;

    return newEvent;
  }

  // Return an array of (visible!) FullCalendar events that conflict with a new event
  function getConflictingEvents(newEvent) {
    return $('#calendar').fullCalendar('clientEvents', event => {

      // Nothing conflicts with an existing availability
      if (event.type === 'availability') {
        return false;
      }

      // Do not conflict with unavailable request if company_setting.adp_time_off_request_sync is enabled
      if (event.type == 'unavailable-request' && MKS.adp_time_off_request_sync) {
        return false;
      }

      var involvesAllDayUnavailable =
        (newEvent.type === 'unavailable' && !newEvent.partial) ||
        (event.type    === 'unavailable' && !event.partial);

      // If one of the objects is an all-day unavailable, we do a different style of conflict checking
      if (involvesAllDayUnavailable) {
        return userCalendar.dateString(newEvent.start) == userCalendar.dateString(event.start);
      }
      // Otherwise, just compare times directly
      else {
        return (event.start < newEvent.real_end) && (new Date(event.real_end) > newEvent.start);
      }
    });
  }

  /*
    Checks for conflicts between a start and end time against all events on the
    user schedule page.

    If there are any conflicts, show the appropriate modal based on the first
    conflict enountered.
  */
  function displayedConflictingEvents(newEvent) {
    /*
       Using proof from: https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    */
    var conflictingEvents = getConflictingEvents(newEvent);

    // Walk through all conflicting events
    for (var i = 0; i < conflictingEvents.length; ++i) {
      if (conflictingEvents[i].type == 'unavailable') {
        userCalendarModals.conflictsWithUnavailableDialog();
      } else if (!calendarHelper.isInCurrentDepartment(conflictingEvents[i])) {
        userCalendarModals.conflictsWithAnotherDepartmentDialog();
      } else {
        userCalendarModals.conflictsWithEventDialog();
      }

      return true;
    }

    return false;
  }

  // Create shifts from dragging Rotation block
  function setupRotationEvent(date, draggedEvent) {
    var rotation_pattern = calendarHelper.getRotationPattern(draggedEvent);
    userCalendarModals.datePicker(date, draggedEvent, rotation_pattern);
  }

  userCalendar.createRotation = function createRotation(startDate, endDateStr, draggedEvent, rotation) {
    var rotationEndDate = calendarHelper.setToEndOfDay(new Date(Date.parse(endDateStr)));
    var rotationTitle = $(draggedEvent.title).text();

    if (rejectDupes && duplicateRotationShift(draggedEvent, startDate, rotationEndDate)) {
      userCalendarModals.duplicateShiftDialog();
      return;
    }

    userCalendar.deleteEventsBetween(
      moment(startDate).format('YYYY-MM-DD'),
      endDateStr,
      false, // Rotations DO NOT clear time off/time off requests
      'delete_from_rotation',
      rotationTitle
    );

    while(startDate < rotationEndDate) {
      // If createEventsFromPattern returns false it means we've hit a conflict and we should stop
      // to prevent us from showing multiple conflict modals
      if (!createEventsFromPattern(draggedEvent, startDate, rotationEndDate)) {
        break;
      }
    }

    addedRotations.push(formatRotation(draggedEvent, startDate, rotationEndDate));

    //Google Analytics
    ga('send','event',makeshiftGoogleAnalytics.categories.userCalendar, makeshiftGoogleAnalytics.actions.drag, makeshiftGoogleAnalytics.label.rotation);
    updateButtonStates();
  };

  userCalendar.deleteEvents = function deleteEvents(events) {
    $.each(events, (index, event) => {
      markEventForDeletion(event);
      removeFullCalendarEvent(event);
    });
    updateButtonStates();
  };

  // Walk through a rotation and create and add corresponding events to the calendar
  // Skip over creating events that conflict with existing events
  function createEventsFromPattern(draggedEvent, date, rotationEndDate) {

    // We mutate this value, so make a copy of it
    date = date.clone();

    var pattern = calendarHelper.getRotationPattern(draggedEvent);
    var idx = 0;

    // Keep placing items while our date is less than the rotation end date
    while (date < rotationEndDate) {

      // Index of item we're placing -- keep wrapping around
      var rotationItems = pattern[idx];
      idx = (idx + 1) % Object.keys(pattern).length;

      // Iterate through all shifts/unavailables on a given day
      $.each(rotationItems || [], (index, rotationItem) => {
        var newEvent;

        // Unavailable
        if (rotationItem.hasOwnProperty('unavailable_type_id')) {
          newEvent = $.extend({}, draggedEvent);
          newEvent.deleted = false;
          newEvent.department_id = MKS.current_department;
          newEvent.department_name = MKS.current_department_name;
          newEvent.notes = null;
          newEvent.time_reporting_code = null;
          newEvent.location_name = MKS.current_location_name;

          newEvent.shift_type_id = '0';
          var title = '<strong>';
          if (rotationItem.unavailable_type_id) {
            title += MKS.unavailable_types[rotationItem.unavailable_type_id];
          } else {
            title += I18n.t('calendar.js.time_off');
          }
          title += '</strong>';

          if (rotationItem.partial) {
            newEvent.start_from_midnight = userCalendar.timeStringToSeconds(rotationItem.start_time);
            newEvent.duration            = userCalendar.timeStringToSeconds(rotationItem.end_time) - newEvent.start_from_midnight;
            newEvent.start               = new Date(date.getTime()  + newEvent.start_from_midnight * 1000);
            newEvent.real_end            = new Date(newEvent.start.getTime() + newEvent.duration * 1000);
            title += '<span>' + formatPartialUnavailableTimes(rotationItem.start_time, rotationItem.end_time) + '</span>';
          } else {
            newEvent.start    = userCalendar.dateString(date);
            newEvent.real_end = userCalendar.dateString(date.clone().add(1).days());
            newEvent.duration = 86400;
            newEvent.start_from_midnight = 0;
          }

          newEvent.dropTitle = newEvent.shift_type_name = newEvent.title = title;
        }
        // Shift
        else {
          var shiftType = $('#shift_type_id_'+rotationItem.shift_type_id).data('details');
          newEvent = $.extend({}, draggedEvent);
          newEvent.start = new Date(date.getTime()+(shiftType.start_from_midnight*1000));
          newEvent.real_end = new Date(date.getTime()+(shiftType.start_from_midnight*1000)+(shiftType.duration*1000));
          newEvent.notes = null;
          newEvent.time_reporting_code = null;
          newEvent.pay_code = shiftType.pay_code;
          newEvent.pay_code_id = shiftType.pay_code_id;
          newEvent.title = shiftType.dropTitle;
          newEvent.shift_type_name = '';
          newEvent.department_id = MKS.current_department;
          newEvent.deleted = false;
          newEvent.duration = shiftType.duration;
          newEvent.start_from_midnight = shiftType.start_from_midnight;
          newEvent.department_name = MKS.current_department_name;
          newEvent.location_name = MKS.current_location_name;
          newEvent.type = 'shift';
          newEvent.audit_action = 'rotation';
          newEvent.audit_action_source = 'user_calendar';

          var requirementLabels = [];
          if (rotationItem.position_id)
            requirementLabels.push(MKS.positions[rotationItem.position_id]);
          if (rotationItem.job_site_id)
            requirementLabels.push(MKS.job_sites[rotationItem.job_site_id]);
          if (requirementLabels.length > 0) {
            newEvent.title = newEvent.title.replace(/<span.*span>/, '<span>'+requirementLabels.join('<br>')+'</span>');
          }
        }

        // Don't add a visible event for anything that conflicts with existing items
        if (getConflictingEvents(newEvent).length > 0) {
          return;
        }

        // console.log("createEventsFromPattern", newEvent);

        $('#calendar').fullCalendar('renderEvent', newEvent, true);
      });

      date.addDays(1);
    }
  }

  // Mutate addedEvents and unavailableEvents to trim out a given event
  // Compares FullCalendar IDs (different from MakeShift IDs!)
  function removeEventFromTrackingArrays(fcEvent){
    addedEvents       = $.grep(addedEvents,       item => { return item._id === fcEvent._id; }, true);
    unavailableEvents = $.grep(unavailableEvents, item => { return item._id === fcEvent._id; }, true);
  }

  function markEventForDeletion(event) {
    if(calendarHelper.persistedEvent(event)) {
      event.deleted = true;
      if (event.type === 'unavailable' || event.type === 'unavailable-request') {
        unavailableEvents.push(formatEvent(event));
      } else {
        deletedEvents.push(formatEvent(event));
      }
      return;
    }

    removeEventFromTrackingArrays(event);
  }

  function formatEvent(item) {
    if (item.shift_type_id == null) {
      return { // custom shift
        _id: item._id,
        original_id: item.original_id,
        start: item.start,
        end: item.real_end,
        shift_type_id: item.shift_type_id,
        department_id: item.department_id,
        start_time: item.start_time,
        end_time: item.end_time,
        notes: item.notes,
        time_reporting_code: item.time_reporting_code,
        deleted: item.deleted,
        hide_ends_at: item.hide_ends_at,
        position_id: item.position_id,
        job_site_id: item.job_site_id,
        pay_code_id: item.pay_code_id,
        breaks_in_minutes: item.breaks_in_minutes,
        type: item.type,
        audit_action: item.audit_action,
        audit_action_source: item.audit_action_source,
        rotation_name: item.rotation_name
      };
    } else {
      return { // shift template or unavailable
        _id: item._id,
        original_id: item.original_id,
        start: item.start,
        end: item.real_end,
        shift_type_id: item.shift_type_id,
        department_id: item.department_id,
        notes: item.notes,
        time_reporting_code: item.time_reporting_code,
        deleted: item.deleted,
        hide_ends_at: item.hide_ends_at,
        breaks: item.breaks,
        unavailable_type_id: item.unavailable_type_id,
        duration: item.duration,
        type: item.type,
        audit_action: item.audit_action,
        audit_action_source: item.audit_action_source,
        rotation_name: item.rotation_name,
        pay_code_id: item.pay_code_id,
      };
    }
  }

  function formatPartialTimeOffEvent(item) {
    /*
      Notes:
      - "start" and "end" are required event params
      - They're used later for "getEarliestDate" and "getLatestDate" respectively
    */
    return {
      _id: item._id,
      original_id: item.original_id,
      start: item.start,
      end: item.real_end,
      start_time: item.start_time,
      end_time: item.end_time,
      department_id: item.department_id,
      notes: item.notes,
      deleted: item.deleted,
      unavailable_type_id: item.unavailable_type_id,
      partial: true,
      duration: item.duration,
      type: item.type
    };
  }

  function formatRotation(item, startDate, endDate) {
    return {
      start: startDate,
      end: endDate,
      pattern_id: item.rotation_id,
      department_id: item.department_id,
      audit_action: 'assign_from_rotation',
      audit_action_type: 'create',
      audit_action_source: 'user_calendar'
    };
  }

  function eventDrop(event){
    if (calendarHelper.earlierDayThanToday(event.start)) {
      return;
    }
    event.real_end = new Date(event.start.getTime()+(event.duration*1000));
  }

  function discardCalendarChanges() {
    if(!calendarHasChanged()) { return false; }
    bootbox.confirm(
      I18n.t('calendar.js.discard_unsaved'),
      I18n.t('buttons.cancel'),
      I18n.t('buttons.ok'),
      bootbox_result => {
        if(bootbox_result) {
          var calendarDate = moment($('#calendar').fullCalendar('getDate'));
          var rememberedCalendarMonth = MKS.department_user_schedule_url + '?' + $.param({month: calendarDate.month(), year: calendarDate.year()});
          calendar_reloaded = true;
          window.location = rememberedCalendarMonth;
        // location.reload();
        }
      });
  }

  function calendarHasChanged(){
    return ((addedEvents.length + unavailableEvents.length + deletedEvents.length + addedRotations.length) > 0);
  }

  function confirmReload() {
    if((calendarHasChanged() === true) && !calendar_reloaded) {
      return 'You have unsaved changes.';
    }
  }

  function getURLParameter(name) {
    var regex = new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)');
    var matched = regex.exec(location.search);
    if (matched && matched.length > 1) {
      var value = matched[1];
      value.replace(/\+/g, '%20');
      return decodeURIComponent(value);
    } else {
      return null;
    }
  }

  function updateButtonStates() {
    var discard_button = $('#discard_changes');
    var publish_button = $('#save_changes');

    if (calendarHasChanged()) {
      discard_button.removeClass('disabled');
      publish_button.removeClass('disabled');
    } else {
      discard_button.addClass('disabled');
      publish_button.addClass('disabled');
    }
  }

  // Convert a 24hr time string to a number of seconds since midnight
  // Example: "13:01" => 46860
  calendar.timeStringToSeconds = function(timeString) {
    var hoursAndMinutes = timeString.split(/:/);
    return hoursAndMinutes[0] * 3600 + hoursAndMinutes[1] * 60;
  };

  // Convert a Date object into a representative date string
  // If it's already a string, assume it's already formatted!
  // Example: JS Date -> "2018-11-13"
  calendar.dateString = function(date) {
    if (typeof(date) === 'string') {
      return date;
    }

    return $.fullCalendar.formatDate(date, 'yyyy-MM-dd');
  };

})(userCalendar);
