import { onboardingPopovers } from 'core/onboarding_popovers.js';
export var DepartmentScheduleOnboardingProgress = (function() {

  var config = {};
  var currentProgress;

  var init = function(currentProgress) {
    this.currentProgress = currentProgress;

    $.extend(true, config, {
      selectors: {
        fullDocument:         $(document),
        addEmployeeLink:      $('#add-employee-link'),
        newShiftTemplate:     $('[data-onboarding-item="2"]'),
        publishButton:        $('.publish-and-notify'),
        availability:         $('.label.availability'),
        scheduleWrapper:      $('.box-wrapper'),
        availableShiftsRow:   $('#available-shifts-row'),
        budgetBar:            function() { return $('#budget-bar .box-header'); },
        gearDropdown:         $('[data-onboarding-item="6"]'),
        navDashboard:         $('[data-ga-label="dashboard"]'),
        navAnalytics:         $('[data-ga-label="analytics"]'),
        navLocations:         $('[data-ga-label="locations"]'),
        navDepartments:       $('[data-ga-label="departments"]'),
        navTimeOff:           $('[data-ga-label="unavailableRequests"]'),
        navAnnouncements:     $('[data-ga-label="messages"]'),
        navUsers:             $('[data-ga-label="users"]'),
        navProfile:           $('.nav.pull-right .dropdown-toggle'),
      }
    });

    return this;
  };

  var updateProgress = function(completedItem) {
    this.currentProgress.push(completedItem);
    return this.currentProgress;
  };

  var nextItem = function() {
    return this.nextItems()[0];
  };

  var nextItems = function() {
    return _.difference($.map(this.allItems(), function(value, index) { return [parseInt(index)]; }), this.currentProgress);
  };

  var incomplete = function(itemNumber) {
    return (this.currentProgress.indexOf(itemNumber) <= -1);
  };

  var allSteps = function(checklistItem) {
    var steps = this.allItems()[checklistItem];
    $.map(steps, function(step, index) {
      if (step && step.target.length === 0 && steps.length > 1) {
        steps.splice(index, 1);
      }
    });

    return steps;
  };

  var defaultValues = function() {
    return {
      target: null,
      title: null,
      content: null,
      greyButtonText: I18n.t('buttons.skip'),
      position: {
        my: 'top center',
        at: 'bottom center',
        offsetY: 0
      },
      zPosition: 'relative',
      prerenderEvent: function() { return false; },
      eventHide: 'click',
      bindAjaxUpdateProgress: function() { return false; }
    };
  };

  var allItems = function() {
    return {
      1: [
        $.extend(true, this.defaultValues(), {
          target: config.selectors.addEmployeeLink,
          title: I18n.t('onboarding.js.add_new'),
          content: I18n.t('onboarding.js.add_new_detail'),
          prerenderEvent: function() {
            var departmentSchedule = config.selectors.scheduleWrapper;
            departmentSchedule.scrollTop(departmentSchedule[0].scrollHeight);
          },
          bindAjaxUpdateProgress: function(ajaxUpdateProgress) {
            config.selectors.fullDocument.one('click', '#create-department-schedule-user-btn', function() {
              ajaxUpdateProgress(1);

            });
          }
        })
      ],

      2: [
        $.extend(true, this.defaultValues(), {
          target: config.selectors.newShiftTemplate,
          title: I18n.t('onboarding.js.new_shift'),
          content: I18n.t('onboarding.js.new_shift_detail'),
          position: {
            offsetY: -15
          },
          eventHide: 'mousedown',
          bindAjaxUpdateProgress: function(ajaxUpdateProgress, progress) {
            config.selectors.fullDocument.one('hide', '#shift-modal', function() {
              ajaxUpdateProgress(2);
              progress.updateProgress(2);
              onboardingPopovers.init({progress: progress, checklistItem: 3});
            });
          }
        })
      ],

      3: [
        $.extend(true, this.defaultValues(), {
          target: config.selectors.publishButton,
          title: I18n.t('onboarding.js.publish'),
          content: I18n.t('onboarding.js.publish_detail'),
          bindAjaxUpdateProgress: function(ajaxUpdateProgress, progress) {
            config.selectors.publishButton.one('click', function() {
              ajaxUpdateProgress(3);
              progress.updateProgress(3);
              onboardingPopovers.init({progress: progress, checklistItem: 4});
            });
          }
        })
      ],

      4: [
        $.extend(true, this.defaultValues(), {
          target: config.selectors.availability,
          title: I18n.t('onboarding.js.availability'),
          content: I18n.t('onboarding.js.availability_detail'),
          greyButtonText: I18n.t('buttons.done')
        })
      ],

      // See js_includes.erb for URL source
      5: [
        $.extend(true, this.defaultValues(), {
          target: config.selectors.availableShiftsRow,
          title: I18n.t('onboarding.js.available_shifts'),
          content: (MKS.show_support_links) ?
            I18n.t('onboarding.js.available_shifts_detail') + ' ' + '<a href="' + document.config.urls.creating_available_shifts_url + '" target="_blank">' + I18n.t('onboarding.js.learn_more') + '</a>' :
            I18n.t('onboarding.js.available_shifts_detail'),
          greyButtonText: I18n.t('buttons.done'),
          prerenderEvent: function() {
            config.selectors.scheduleWrapper.scrollTop(0);
          },
        })
      ],

      6: [
        $.extend(true, this.defaultValues(), {
          target: config.selectors.budgetBar(),
          title: I18n.t('onboarding.js.budget_bar'),
          content: I18n.t('onboarding.js.budget_bar_detail'),
          bindAjaxUpdateProgress: function(ajaxUpdateProgress, progress) {
            config.selectors.budgetBar().one('remove', function() {
              ajaxUpdateProgress(6);
              progress.updateProgress(6);
              onboardingPopovers.init({progress: progress, checklistItem: 7});
            });
          }
        }),

        $.extend(true, this.defaultValues(), {
          target: config.selectors.gearDropdown,
          title: I18n.t('onboarding.js.extra'),
          content: I18n.t('onboarding.js.extra_detail'),
          greyButtonText: I18n.t('buttons.done'),
          position: {
            my: 'right center',
            at: 'left center',
            offsetY: 0
          },
          zPosition: '',
          prerenderEvent: function(progress) {
            this.target.parents('li').addClass('open');
          }
        })
      ],

      7: [
        $.extend(true, this.defaultValues(), {
          target: config.selectors.gearDropdown,
          title: I18n.t('onboarding.js.copy_paste'),
          content: I18n.t('onboarding.js.copy_paste_detail_1') + '<br><br>' + I18n.t('onboarding.js.copy_paste_detail_2'),
          greyButtonText: I18n.t('buttons.done'),
          position: {
            my: 'right center',
            at: 'left center',
            offsetY: 0
          },
          zPosition: '',
          prerenderEvent: function(progress) {
            this.target.parents('li').addClass('open');
          }
        })
      ],

      8: [
        $.extend(true, this.defaultValues(), {
          target: config.selectors.navDashboard,
          title: I18n.t('onboarding.js.dashboard'),
          content: I18n.t('onboarding.js.dashboard_detail'),
        }),
        $.extend(true, this.defaultValues(), {
          target: config.selectors.navAnalytics,
          title: I18n.t('onboarding.js.analytics'),
          content: I18n.t('onboarding.js.analytics_detail'),
        }),
        $.extend(true, this.defaultValues(), {
          target: config.selectors.navLocations,
          title: I18n.t('onboarding.js.locations'),
          content: I18n.t('onboarding.js.locations_detail'),
        }),
        $.extend(true, this.defaultValues(), {
          target: config.selectors.navDepartments,
          title: I18n.t('onboarding.js.departments'),
          content: I18n.t('onboarding.js.departments_detail'),
        }),
        $.extend(true, this.defaultValues(), {
          target: config.selectors.navTimeOff,
          title: I18n.t('onboarding.js.time_off'),
          content: I18n.t('onboarding.js.time_off_detail'),
        }),
        $.extend(true, this.defaultValues(), {
          target: config.selectors.navAnnouncements,
          title: I18n.t('onboarding.js.announcements'),
          content: I18n.t('onboarding.js.announcements_detail'),
        }),
        $.extend(true, this.defaultValues(), {
          target: config.selectors.navUsers,
          title: I18n.t('onboarding.js.users'),
          content: I18n.t('onboarding.js.users_detail'),
        }),
        $.extend(true, this.defaultValues(), {
          target: config.selectors.navProfile,
          title: I18n.t('onboarding.js.profile'),
          content: MKS.product_name === "MakeShift" 
            ? I18n.t('onboarding.js.profile_detail', { name: MKS.product_name })
            : I18n.t('onboarding.js.profile_detail_2', { name: MKS.product_name }),
          greyButtonText: null,
        }),
      ]
    }
  }

  return {
    init: init,
    updateProgress: updateProgress,
    nextItem: nextItem,
    nextItems: nextItems,
    incomplete: incomplete,
    allSteps: allSteps,
    defaultValues: defaultValues,
    allItems: allItems
  };
})();
