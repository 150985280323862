<template>
  <transition v-if="show" name="ui-modal">
    <div class="ui-modal-mask">
      <div
        class="ui-modal-wrapper"
        ref="wrapper"
        @click="handleWrapperClose"
      >
        <div class="ui-modal-container">

          <div class="ui-modal-body">
            <button
              class="ui-modal-close"
              @click="$emit('close')"
            >
              <img class="p-2" src="~images/icons/modal-close.svg" />
            </button>
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="ui-modal-footer">
            <slot name="footer">
              default footer
            </slot>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UiModal',
  props: {
    show: Boolean
  },
  methods: {
    handleWrapperClose(event) {
      if (event.target === this.$refs.wrapper) {
        this.$emit('close');
      }
    }
  },
  example: `
<template>
  <div>
    <h4 class="my-6 border-b">UiModal</h4>
    <button class="p-1 border" @click="showModal = true">Open Default Modal</button>
    <ui-modal :show="showModal" @close="showModal = false"></ui-modal>

    <h4 class="my-6 border-b">Custom</h4>
    <button class="p-1 border" @click="showModal2 = true">Open Custom Modal</button>
    <ui-modal :show="showModal2" @close="showModal2 = false">
      <div slot="body">
        <h4 class="m-0">This is a custom body</h4>
      </div>
      <div slot="footer">
        <p>This is a custom footer</p>
        <button @click="showModal2 = false">Close</button>
      </div>
    </ui-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showModal: false,
        showModal2: false,
      }
    }
  }
<\/script>
`
}
</script>

<style lang="scss">
  .ui-modal-mask {
    @apply #{ fixed top-0 left-0 table w-full h-full transition-opacity duration-300 };
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .ui-modal-wrapper {
    @apply #{ flex items-center h-screen p-8 box-border overflow-auto };
  }

  .ui-modal-container {
    @apply #{ box-border bg-background m-auto p-8 box-border rounded-md shadow-md transition-all duration-200 };
    width: 600px;
  }

  .ui-modal-body {
    @apply #{ relative };
  }

  .ui-modal-footer {
    @apply #{ pt-8 };
  }

  .ui-modal-close {
    @apply #{ absolute flex items-center rounded-full };
    top: -10px;
    right: -10px;
    background-color: rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  /* Vue transition classes */

  .ui-modal-enter,
  .ui-modal-leave-active {
    @apply #{ opacity-0 };

    .ui-modal-container {
      transform: scale(1.1);
    }
  }
</style>
