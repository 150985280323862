window.privatePusherChannel = (function($, window, document) {

  var init = function(settings) {
    $.extend(config, {
      pusherKey: settings.pusherKey,
      pusherUserId: settings.pusherUserId,
      csrfToken: settings.csrfToken
    });

    var pusher = window.getPusherInstance(config.pusherKey, {
      authEndpoint: '/pusher/auth',
      auth: {
        headers: {
          'X-CSRF-Token': config.csrfToken
        }
      }
    });

    var channelName = 'private-user-' + config.pusherUserId;
    var timeoutId = null;
    var growlMessage = null;

    function startInactivityCheck() {
      timeoutId = window.setTimeout(function(){
        pusherDisconnect();
      }, 30 * 60 * 1000); // 30 minute timer
    };

    function userActivityDetected(){
      pusherConnect();
      window.clearTimeout(timeoutId);
      startInactivityCheck();
    };

    function pusherDisconnect() {
      pusher.disconnect();
    };

    function pusherConnect() {
      pusher.connect();
    };

    $(document.body).on("mousemove", _.debounce(userActivityDetected, 3000, true));
    $(document.body).on("keypress", _.debounce(userActivityDetected, 3000, true));
    window.msPusherChannel = pusher.subscribe(channelName);
    window.msPusherChannel.bind('pusher:subscription_succeeded', function() {
      if (growlMessage) {
        growlMessage.remove();
        growlMessage = null;
      }
    });
    window.msPusherChannel.bind('pusher:subscription_error', function() {
      if (!growlMessage) {
        growlMessage = $.growl({title: I18n.t('growls.pusher.disconnected.title'), message: I18n.t('growls.pusher.disconnected.message'), fixed: true, location: "tc", style: "error", size: "large"});
      }
    });
    startInactivityCheck();
  };

  return {
    init: init
  }

})(jQuery, window, document);
