import { weeklyBudget } from 'core/weekly_budget.js';
import { fatigueManagement } from 'core/fatigue_management.js';
import { departmentSchedule } from 'core/department_schedules.js';
import { clearModalAlerts, renderModalAlert } from 'core/makeshift.js';
import { bootboxMsg } from 'core/helpers/bootbox.js';
window.shiftRequirements = (function($, window, document) {

  var init = function(settings) {
    initTabs();
    initModalForms();
    initModalActions();
  };

  // Default settings
  var config = {
    highlightColor:     '#ffcc00',
    highlightInterval:  750
  };

  // Initializes ajax tabs and hiding of action buttons when on non-details tab
  var initTabs = function() {
    $('#shift-modal').on('show', 'a[data-toggle="tab"]', function(e) {
      var $self = $(e.target);

      // Toggle action buttons
      if ($self.data('target') == '#shift-details') {
        $('#delete-requirement').removeClass('hidden');
        $('#update-requirement').removeClass('hidden');
      } else {
        $('#delete-requirement').addClass('hidden');
        $('#update-requirement').addClass('hidden');
      }
    });
  };

  // Callback hooks for create/update modal forms and qtip forms
  var initModalForms = function() {
    $('body')
      .on('ajax:success', '.edit_shift_requirement', function(e, data) {
        updateShiftRequirement(data.shift_requirement);
      })
      .on('ajax:error', 'edit_shift_requirement', function(e, xhr, status, error) {
        if (xhr.responseJSON.error_type != 'FatigueConflictError') {
          renderModalAlert(xhr.responseJSON);
        }
      })
      .on('ajax:error', 'edit_shift_requirement', function(e, xhr, status, error) {

        // if we get a fatigue rule error back we throw up a warning prompt
        // if the user choose to continue we remove the "check" flag and
        // resubmit the form.
        if (xhr.responseJSON.error_type == 'FatigueConflictError') {
          fatigueManagement.fatiguePrompt(xhr.responseJSON.error_message, function() {
            $(e.target).find('[id*=fatigue]').remove();
            $(e.target).submit();
          }, function() {
            // do nothing will cancel prompt
          });
        }
      })
      .on('ajax:error', '.edit_shift_requirement', function(e, xhr, status, error) {
        renderModalAlert(xhr.responseJSON);
      });
  };

  // Initializes JSON behaviors performed within ShiftRequiremnt modal
  var initModalActions = function() {
    $('#shift-modal').on('click', 'a[data-shift-requirement-modal-action], button[data-shift-requirement-modal-action]', function(e) {
      e.preventDefault();
      var $self = $(this);

      switch($self.data('shift-requirement-modal-action')) {

        // Assign User Shift to ShiftRequirement (i.e. create a Shift)
        case 'assign':
          var url = $self.data('href') + '&check_fatigue=true';
          var assignShift = function(url) {

            $.ajax({ url: url, type: 'PUT', dataType: 'json' })
            .then(function(data) {
              $('#unscheduled-users-table').DataTable().ajax.reload(null, false);
              updateShiftRequirementShifts($self.data('id'), true);
            }, function(xhr, status, error) {
              $self.prop('disabled', true);
              renderModalAlert(xhr.responseJSON);

              // if we get a fatigue rule error back we throw up a warning prompt
              // if the user choose to continue we remove the "check" flag and
              // resubmit the form.
              if (xhr.responseJSON.error_type == 'FatigueConflictError') {
                // we don't need to standard alert box error
                clearModalAlerts();
                fatigueManagement.fatiguePrompt(xhr.responseJSON.error_message, function() {
                  assignShift($self.data('href'));
                }, function() {
                  // re-enable the "Assign" button
                  $self.prop('disabled', false);
                });
              }
            });

          };

          assignShift(url);

          break;

        // Unassign ShiftRequirement Shift (i.e. destroy the Shift)
        case 'unassign':
          $.ajax({
            url: $self.data('href'),
            type: 'DELETE',
            dataType: 'json',
            data: {
              audit_action: 'delete_from_shift_requirement',
              audit_action_source: 'department_calendar'
            }
          })
          .then(function(data) {
            $('#scheduled-users-table').DataTable().ajax.reload(null, false);
            updateShiftRequirementShifts($self.data('id'));
          }, function(xhr, status, error) {
            $self.prop('disabled', true);
            renderModalAlert(xhr.responseJSON);
          });
          break;
      }
    });
  };

  /**
   * Update of ShiftRequirement attributes
   * Update tab counters, move to next tab, and delegate div element update and publish button toggle to departmentSchedule
   * @param {Object} shiftRequirement       ShiftRequirement
   */
  var updateShiftRequirement = function(shiftRequirement) {
    clearModalAlerts();
    var $requiredEmployeeCount = $('#required-employees-count');
    var previousEmployeeCount = parseInt($requiredEmployeeCount.html());

    updateCounter($requiredEmployeeCount, shiftRequirement.required_employees);
    departmentSchedule.updateShiftRequirement(shiftRequirement, false); // delegate cell update but don't close modal
  };

  /**
   * Creation/deletion of ShiftRequirement Shifts
   * Update tab counters, and delegate div element update and publish button toggle to departmentSchedule
   * @param {Integer} id ShiftRequirement   id
   * @param {Boolean} hasUnpublishedShifts  True when assigning new Shifts
   */
  var updateShiftRequirementShifts = function(id, hasUnpublishedShifts) {
    return $.getJSON('/shift_requirements/' + id)
    .then(function(data) {
      var shiftRequirement = data.shift_requirement;
      updateCounter($('#scheduled-users-count'), shiftRequirement.shifts_count);
      updateCounter($('#unscheduled-users-count'), shiftRequirement.unscheduled_users_count);
      departmentSchedule.updateShiftRequirement(shiftRequirement, false); // delegate cell update but don't close modal
      departmentSchedule.togglePublishButton(hasUnpublishedShifts);
      return shiftRequirement;
    });
  };

  /**
   * Update and highight tab counters
   * @param {Object} $label   Span that contains the counter
   * @param {Integer} count   User count
   */
  var updateCounter = function($label, count) {
    $label.text(count);
    $label.closest('span.label').effect('highlight', {color: config.highlightColor}, config.highlightInterval);
  };

  return {
    init: init,
  };

  // Common function to handle a failure (406) for all these different types of requests
  // either it's a fatigue prompt which needs to be handled accordingly or another type
  // of conflict
  function attachRequestFailureHandler($request) {

    var $div = window.$div;

    $request.fail(function(xhr, status, error) {

      if (xhr.responseJSON.error_type == 'FatigueConflictError') {

        fatigueManagement.fatiguePrompt(xhr.responseJSON.error_message, function () {
          delete window.shiftTypeData.shift.fatigue_validate;
          window.doShiftTypeRequest(window.shiftTypeData);
        }, function() {
          $div.remove();
        });

      } else {

        $div.fadeOut(config.transitionInterval, function () {
          $(this).remove();
          bootbox.alert(bootboxMsg(xhr.responseJSON));
        });

      }
    });
  }

})(jQuery, window, document);
