import { analytics } from 'core/analytics/analytics.js';
window.analyticsAvailability = (function($) {

  /*
  public
  */

  // Initialize Analytics::Availability
  var init = function(settings) {
    var today = new Date();
    $.extend(config, {
      endDate: new Date(today.setFullYear(today.getFullYear(), today.getMonth(), today.getDate() + 7)),
    });

    initAvailability();

  };

  /*
  private
  */

  // Default settings
  var config = {
    chartName: "availability",
    filterName: ""
  };

  var clearCustomDateRangeForAvailability = function() {
    analytics.clearCustomDateRange(".availabilities");
    getAvailability();
  };

  var getAvailability = function() {
    var isInactive = $(this).hasClass('inactive');
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();
    var dateRange = analytics.filterDateRange($(this).text());
    config.endDate = analytics.filterEndDate($(this).attr('id'), config.endDate, dateRange);
    var dataHash = {
      locations: locationIds,
      departments: departmentIds,
      range: dateRange,
      endDate: $.datepicker.formatDate('yy/mm/dd', config.endDate)
    }

    if (analytics.canMakeChartRequest(config.chartName, isInactive, locationIds, departmentIds)) {
      analytics.makeChartRequest(MKS.availability_url, dataHash);
    } else {
      return;
    }
  };

  var initAvailability = function() {
    analytics.initializePage($('.availabilities'), $('#availability-chart-box'), true);
    $(".availabilities #location-department-title").text(I18n.t('analytics.loc_dept_filter.dept_title'));
    $(".availabilities #submit-chart").on("click", makeRequestForAvailability);
    $(".availabilities #date-range-days, .availabilities #date-range-weeks, .availabilities #date-range-months, .availabilities #date-range-previous, .availabilities #date-range-next").on("click", getAvailability);

    $('.availabilities #set-custom-date-range').on("click", selectCustomDateRangeForAvailability);
    $('.availabilities #clear-custom-date-range').on("click", clearCustomDateRangeForAvailability);
  };

  var makeRequestForAvailability = function() {
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();

    if (locationIds.length == 0 && departmentIds.length == 0) {
      analytics.clearCustomDateRange(".availabilities");
    }
    if ($(".availabilities #date-range-days").hasClass('inactive')) {
      selectCustomDateRangeForAvailability();
    } else {
      getAvailability();
    }
  };

  var selectCustomDateRangeForAvailability = function() {
    var url = MKS.availability_url;
    analytics.selectCustomDateRange(url, ".availabilities");

    // if the start & end date are already set, execute the ajax call, otherwise listen for a date change event
    if ($(".analytics #start-date").val() != "" && $(".analytics #end-date").val() != "") {
      analytics.requestCustomDateRange(url, {}, config.chartName, config.filterName);
    } else {
      $('.analytics .input-daterange').on("changeDate", function(event) {
        analytics.requestCustomDateRange(url, {}, config.chartName, config.filterName);
      });
    }
  };

  return {
    init: init,
  };

})(jQuery);
