import {analytics} from 'core/analytics/analytics.js';
import {am_pm_to_hours} from 'core/helpers/format_helpers.js';

window.analyticsShiftAudits = (function ($) {
  var config, errorDiv;
  var init = function (settings) {
    config = settings;
    $.extend(config, {
      selectors: {
        employeeArchivedDiv: $('#archived-employees-filter'),
        employeeArchivedSelectBox: $('#archived-employee-select'),
        employeeSelectBox: $('#active-employee-select'),
        datePicker: $('#datepicker'),
        startDate: $('input#start-date'),
        endDate: $('input#end-date'),
        actionFilters: $('#shift-audit-action-filters'),
        includeArchived: $('#shift-audits-include-archived-users'),
        includePublished: $('#shift-audits-include-published'),
        includeUnpublished: $('#shift-audits-include-unpublished'),
        submitButton: $('.shift-audits #submit-chart'),
        shiftAudits: $('#shift-audits'),
      },
    });

    errorDiv = $('#analytics-error');

    initReportForm();
    initShiftAuditsReport();
    analytics.subscribeToAnalyticsReportChannel(config.privatePusherChannel, 'shift-audits', constructShiftAuditsReport, displayPusherError);
  };

  var initReportForm = function () {
    config.selectors.datePicker.show();
    // Set date limit
    var mindate = "01/01/2020";
    $(".analytics #start-date").datepicker('setStartDate', mindate);
    $(".analytics #end-date").datepicker('setStartDate', mindate);

    toggleSubmitButtonState();
    initActionFilters();
  }

  var initShiftAuditsReport = function () {
    analytics.initializePage($('.shift-audits'), $(null));

    config.selectors.employeeSelectBox.select2();
    config.selectors.employeeArchivedSelectBox.select2();

    if (config.selectors.includeArchived.is(':checked')) {
      config.selectors.employeeArchivedDiv.removeClass('hidden');
    }

    config.selectors.includeArchived.on('change', function () {
      config.selectors.employeeArchivedDiv.toggleClass('hidden');
      toggleSubmitButtonState();
    });

    config.selectors.includePublished.on('click', verifyOnePublishedCheckboxSelected);
    config.selectors.includeUnpublished.on('click', verifyOnePublishedCheckboxSelected);

    $('#active-employee-select, #archived-employee-select, input#start-date, input#end-date').on('change', function () {
      toggleSubmitButtonState();
    });

  }

  var getSelectedEmployeeIds = function () {
    var employeeIds = config.selectors.employeeSelectBox.val() || [];
    var showArchivedEmployees = config.selectors.includeArchived.is(':checked');
    if (showArchivedEmployees) {
      var archivedEmployeeIds = config.selectors.employeeArchivedSelectBox.val() || [];
      employeeIds = employeeIds.concat(archivedEmployeeIds);
    }
    return employeeIds;
  }

  var toggleSubmitButtonState = function () {
    var selectedEmployeeIds = getSelectedEmployeeIds();
    if ((selectedEmployeeIds.length > 0) && config.selectors.startDate.val() && config.selectors.endDate.val()) {
      config.selectors.submitButton.removeClass('disabled');
      config.selectors.submitButton.unbind('click').on('click', getShiftAudits);
    } else {
      config.selectors.submitButton.addClass('disabled');
      config.selectors.submitButton.unbind('click');
      removeExportButton();
    }
  }

  var initActionFilters = function () {
    config.selectors.actionFilters.on('click', function minOneBoxChecked(e) {
      var amount_checked = $(this).find('input:checked').length;
      if (amount_checked === 0) {
        e.preventDefault();
      }
    })
  }


  var verifyOnePublishedCheckboxSelected = function (event) {
    if (!config.selectors.includePublished.is(':checked') && !config.selectors.includeUnpublished.is(':checked')) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  var getShiftAudits = function () {
    var employeeIds = getSelectedEmployeeIds();
    if (employeeIds.length === 0) {
      return;
    }
    hidePusherError();
    analytics.hideDateRangeError();
    var url = '/analytics/shift_audits';
    analytics.showLoadingModal();
    var reportOptions = {
      'include_published': config.selectors.includePublished.is(":checked"),
      'include_unpublished': config.selectors.includeUnpublished.is(":checked"),
      'employees': employeeIds,
      'action_filters': action_filters_list()
    };
    analytics.selectCustomDateRange(url, ".shift-audits");
    var analyticsPromise = makeAsyncShiftAuditRequest(url, reportOptions);
    analyticsPromise.fail(function (error) {
      analytics.hideLoadingModal();
      var errorMessage = error.responseJSON ? error.responseJSON.message : 'There was an error building the report';
      analytics.displayDateRangeError(errorMessage);
    });
  };

  var action_filters_list = function () {
    const list = [];
    if ($('#action-filters-include-create:checked').length) {
      list.push('create');
    }
    if ($('#action-filters-include-update:checked').length) {
      list.push('update');
    }
    if ($('#action-filters-include-delete:checked').length) {
      list.push('delete');
    }
    return list;
  }

  var makeAsyncShiftAuditRequest = function (url, filterDataHash) {
    var dataHash = buildDataForShiftAuditsRequest(filterDataHash);

    if (dataHash.employees.length === 0) {
      $('#shift-audits-chart-box').hide();
      return;
    }
    return $.ajax({
      url: encodeURI(url),
      data: dataHash,
      dataType: "json",
    });
  }

  var buildDataForShiftAuditsRequest = function (filterDataHash) {
    // Retrieve the date formatting used for this datepicker so we can "undo"
    var startDate = $(".analytics #start-date").datepicker('getDate');
    var endDate = $(".analytics #end-date").datepicker('getDate');

    // Default is today to a week from now
    if (!startDate && !endDate) {
      startDate = new Date();
      endDate = addSevenDays(new Date());
      $(".analytics #start-date").datepicker('setDate', startDate);
      $(".analytics #end-date").datepicker('setDate', endDate);
    }

    var dateRange = "Days";
    var dataHash = {
      range: dateRange,
      startDate: startDate,
      endDate: endDate
    };
    // merge the two hashes together
    dataHash = $.extend(dataHash, filterDataHash);
    return dataHash;
  };

  function addSevenDays(date) {
    return new Date(date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 7));
  }


  var constructShiftAuditsReport = function (analyticsReport) {
    var employeesData = analyticsReport.data;
    var exportableRowCount = 0;
    errorDiv.hide();
    config.selectors.shiftAudits.html('');

    _.each(employeesData.employees, function (employeeDataGroup) {
      var employeeTable = $("<div class='shift-audits table-container'><table></table></div>");
      exportableRowCount += employeeDataGroup.employee_shift_audits.length;
      config.selectors.shiftAudits.append(
        "<hr><h4>" + employeeDataGroup.employee_name + "</h4>"
      ).append(
        employeeTable
      ).append(
        shiftAuditsTable(employeeTable.find('table'), employeeDataGroup)
      );
    });
    analytics.hideLoadingModal();

    removeExportButton();
    if (exportableRowCount > 0) {
      addExportButton();
    }
  };

  var addExportButton = function () {
    var button = "<button class='export-btn'>" + I18n.t('buttons.export') + "</button>";
    $(".box-header").append(button);
    $('.export-btn').on('click', makeRequestForShiftAuditsXlsx);
    return button;
  };

  var removeExportButton = function () {
    $(".box-header").find('.export-btn').remove();
  };

  var makeRequestForShiftAuditsXlsx = function () {
    analytics.showLoadingModal();
    var exportOptions = {
      'employees': JSON.stringify(getSelectedEmployeeIds()),
      'include_archived_users': config.selectors.includeArchived.is(":checked"),
      'include_published': config.selectors.includePublished.is(":checked"),
      'include_unpublished': config.selectors.includeUnpublished.is(":checked"),
      'action_filters': JSON.stringify(action_filters_list()),
      'report_type': 'shift_audits'
    };
    var url = analytics.exportURL(exportOptions);
    $.ajax({
      url: encodeURI(url)
    });
  };

  var displayPusherError = function (data) {
    analytics.hideLoadingModal();
    errorDiv.show();
    errorDiv.text(data.message);
  };

  var hidePusherError = function () {
    $('#shift-audits-error').hide();
    $('#shift-audits-error').text('');
  };

  var shiftAuditsTable = function (table, employeeData) {
    var columns = [
      { mData: "rawTimestamp", bVisible: false, bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.shift_id'), bVisible: true, mData: "shiftId", bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.action'), mData: "action", bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.timestamp'), mData: "timestamp", bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.administrator'), mData: "administratorName", bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.location'), mData: "locationName", bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.department'), mData: "departmentName", bSortable: false} ,
      { sTitle: I18n.t('analytics.shift_audits.js.shift_date'), mData: "shiftDate", bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.shift_start_time'), mData: "shiftStartsAt", bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.shift_end_time'), mData: "shiftEndsAt", bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.position'), mData: "position", bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.job_site'), mData: "jobSite", bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.source'), mData: "source", bSortable: false },
      { sTitle: I18n.t('analytics.shift_audits.js.published'), mData: "shiftPublishedAt", bSortable: false }
    ];

    table.dataTable({
      bPaginate: true,
      bFilter: false,
      sPaginationType: "full_numbers",
      bLengthChange: false,
      iDisplayLength: 10,
      bInfo: false,
      bAutoWidth: false,
      aaData: employeeData.employee_shift_audits,
      aoColumns: columns,
      aaSorting: [[7, 'asc'], [1, 'asc'], [0, 'asc']], // Sort by shift date, shift id, and raw timestamp
      oLanguage: dataTables_i18n,
      rowCallback: handleCreateRowClosure()
    });
  };

  var handleCreateRowClosure = function () {
    var colorHexs = ['#F3F4F8', '#FFFFFF'];
    var previousRowState = {
      shiftId: null,
      color: 0
    };
    return function handleCreatedRow(row, data, dataIndex) {
      var rowShiftId = data.shiftId;
      if (rowShiftId === previousRowState.shiftId) {
        $(row).css({ backgroundColor: colorHexs[previousRowState.color] })
        return null;
      }
      previousRowState.shiftId = rowShiftId;
      previousRowState.color = (previousRowState.color + 1) % 2;
      $(row).css({ backgroundColor: colorHexs[previousRowState.color] })
    };
  };

  return {
    init: init,
    constructShiftAuditsReport: constructShiftAuditsReport,
  }
})(jQuery);

