import { analytics } from 'core/analytics/analytics.js';
window.analyticsWorkforce = (function($) {

  /*
  public
  */

  var locationGroup, departmentGroup;

  // Initialize Analytics::Workforce
  var init = function(settings) {
    var today = new Date();
    $.extend(config, {
      endDate: new Date(today.setFullYear(today.getFullYear(), today.getMonth(), today.getDate() + 7)),
    });
    initWorkforce();

    locationGroup = $('.workforce').find('#location-department-filter optgroup#location');
    departmentGroup = $('.workforce').find('#location-department-filter optgroup#department');

    // if department id is present
    if (settings.deptId != 0) {
      $('.workforce').find('#location-department-filter').select2('val', 'department'+settings.deptId);
      $(".workforce #submit-chart").trigger("click");
      removeWorkforceOptionGroup();
    }
  };
  /*
  private
  */

  // Default settings
  var config = {
  };

  var getWorkforce = function() {
    $('#workforce-chart-box').hide();
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();

    if(locationIds.length === 0 && departmentIds.length === 0) { return; }

    $('#workforce-chart-box').show();
    $.ajax({
      url: MKS.workforce_url,
      data: { locations: locationIds, departments: departmentIds },
      dataType: "script"
    });
  };

  var initWorkforce = function() {
    analytics.initializePage($('.workforce'), $('#workforce-chart-box'));
    $('.workforce').find('#location-department-filter').select2().on("change", removeWorkforceOptionGroup);
    $(".workforce #submit-chart").on("click", getWorkforce);
  };

  var removeWorkforceOptionGroup = function() {
    analytics.removeOptionGroup($('.workforce').find('#location-department-filter'), locationGroup, departmentGroup);
  };


  return {
    init: init,

  };

})(jQuery);
