window.announcements = (function($, window, document) {

  // public

  var init = function(data, settings) {
    if (settings) { $.extend(config, settings); }

    modifiedData = data;

    // called whenever a change is made to who you are sending the message to in groups.
    // we are going to modify the source data so that if a location is selected we will
    // no longer display the departments for that location, and if a company is selected you
    // won't be able to pick other options
    $('#recipients').on('change', function(e) {
      // deep copy of original data for us to modify
      modifiedData = $.extend(true, [], data);

      // find the departments section so we can subtract elements from it
      var departmentSectionIndex;
      for (let i = 0; i < modifiedData.length; i++) {
        if (modifiedData[i].text == I18n.t('department.departments')) {
          departmentSectionIndex = i;
          break;
        }
      }

      var departmentSectionChildren = modifiedData[departmentSectionIndex].children;

      // for all current values that don't contain a "D" (i.e. locations), remove the corresponding departments
      var recipientId;
      for (let i = 0; i < e.val.length; i++) {
        recipientId = e.val[i];

        if (recipientId == '0') { // a company was selected
          // remove all locations and departments
          modifiedData.splice(1,2);
          break;
        } else if (recipientId.indexOf('D' == -1)) { // a location was selected
          // iterate in reverse so we can remove elements as we go
          for (var j = departmentSectionChildren.length-1; j > -1; j--) {
            //if this department is in the selected location
            if (departmentSectionChildren[j].id.split('D')[0] == recipientId) {
              departmentSectionChildren.splice(j,1); //remove the found element from the array
            }
          }
        }
      }

      // if only a single department is shown, show the positions for that Department
      var $positionsContainer = $('#user-positions-container');
      var $positionsSelect = $('#user-positions');

      if (e.val.length == 1 && e.val[0].indexOf('D') > -1) {
        var departmentId = e.val[0].split('D')[1];
        $.getJSON('/departments/' + departmentId + '/positions')
        .then(function(data) {
          if (data.positions.length) {
            $.each(data.positions, function(i, position) {
              $positionsSelect.append('<option value="' + position.id + '">' + position.name + '</option>');
            });
            $positionsContainer.show();
          }
        });
      } else {
        $positionsContainer.hide();
        $positionsSelect.find('option').remove();
        $positionsSelect.select2('data', null);
      }

      // if we've removed all the departments from options, remove the department section
      if (departmentSectionChildren.length == 0) {
        modifiedData.splice(departmentSectionIndex,1);
      }
    });
  };

  // Module global reference to current data set
  var modifiedData = [];

  /**
   * This query function was taken directly from the select2 query and modified to use our custom matcher and work
   * with the filtered data set
   *
   * Produces a query function that works with a local array
   *
   * @param {query} options object containing configuration parameters. The options parameter can either be an array or an
   * object.
   *
   * If the array form is used it is assumed that it contains objects with 'id' and 'text' keys.
   *
   * If the object form is used it is assumed that it contains 'data' and 'text' keys. The 'data' key should contain
   * an array of objects that will be used as choices. These objects must contain at least an 'id' key. The 'text'
   * key can either be a String in which case it is expected that each element in the 'data' array has a key with the
   * value of 'text' which will be used to match choices. Alternatively, text can be a function(item) that can extract
   * the text.
   */
  var recipientsQuery = function(query) {

    //new matcher that will search on each word entered (space delimited) rather than the whole term
    function updatedMatcher(term, text) {
      var words = term.split(" ");
      for (var i = 0; i < words.length; i++) {
        if (!$.fn.select2.defaults.matcher(words[i], text)) {
          return false;
        }
      }
      return true;
    }

    var data = modifiedData, // data elements
      dataText,
      tmp,
      text = function (item) { return ""+item.text; }; // function used to retrieve the text portion of a data item that is matched against the search

      if ($.isArray(data)) {
        tmp = data;
        data = { results: tmp };
      }

      if ($.isFunction(data) === false) {
        tmp = data;
        data = function() { return tmp; };
      }

      var dataItem = data();
      if (dataItem.text) {
        text = dataItem.text;
        // if text is not a function we assume it to be a key name
        if (!$.isFunction(text)) {
          dataText = dataItem.text; // we need to store this in a separate variable because in the next step data gets reset and data.text is no longer available
          text = function (item) { return item[dataText]; };
        }
      }

      var t = query.term, filtered = { results: [] }, process;
      if (t === "") {
        query.callback(data());
        return;
      }

      process = function(datum, collection) {
        var group, attr;
        datum = datum[0];
        if (datum.children) {
          group = {};
          for (attr in datum) {
            if (datum.hasOwnProperty(attr)) group[attr]=datum[attr];
          }
          group.children=[];
          $(datum.children).each2(function(i, childDatum) { process(childDatum, group.children); });
          if (group.children.length || updatedMatcher(t, text(group), datum)) {
            collection.push(group);
          }
        } else {
          if (updatedMatcher(t, text(datum), datum)) {
            collection.push(datum);
          }
        }
      };

      $(data().results).each2(function(i, datum) { process(datum, filtered.results); });
      query.callback(filtered);
  };

  // private

  var config = {};

  return {
    init: init,
    recipientsQuery: recipientsQuery
  };

})(jQuery, window, document);
