window.userTimeOff = (function($) {

  /*
  public
  */

  var init = function(settings) {
    $.extend(config, {
      ajax: settings.ajax,
      summaryAjax: settings.summaryAjax,
      select2Id: "#user_time_off_types",
      datePickerStart: "#start-date",
      datePickerEnd: "#end-date",
    });

    initDatePicker("#start-date", settings.start_date);
    initDatePicker("#end-date", settings.end_date);
    initUnavailablesDataTable();
    initUnavailablesSummaryDataTable();
    initSelect2(settings.select2Data);
    initSelect2Listener();
  };

  /*
  private
  */

  // Default settings
  var config = {
  };

  var initUnavailablesSummaryDataTable = function(id) {
    var table = $("#user-time-off-summary-table").DataTable({
        "ajax": config.summaryAjax,
        "columnDefs": [
          { "orderable": false, "targets": 2 }
        ],
        "columns": [
            { data: 'name' },
            { data: 'external_id' },
            { data: 'duration', render: function (data, type, row) {
              var hours = data[0];
              var days = data[1];

              return formatApprovedTimeOff(hours, days);
            }}
        ],
        "autoWidth": false,
        "lengthChange": false,
        "info": false,
        "searching": false,
        "footerCallback": function ( row, data, start, end, display ) {
          var api = this.api();

          // Gather Page Totals
          var pageTotal = api
            .column(2)
            .data()
            .reduce( function (a, b) {
              a[0] += b[0];
              a[1] += b[1];
              return a;
            }, [0,0] );

          // Add totals to footer
          $( api.column(2).footer() ).html(
              formatTotalApprovedTimeOff(pageTotal[0], pageTotal[1])
          );
        },
        "oLanguage": dataTables_i18n
    });

    $.extend(config, {
      summaryTable: table
    });

    // Inititialize Uniform for select picker
    $("select.uniform, input:file, .dataTables_length select").uniform();

    // Add a div wrapper so that headers get proper styling
    $.each($("th[class|='header'],th[class|='sorting']"), function() {
      return $(this).wrapInner("<div />");
    });
  };

  var initUnavailablesDataTable = function() {
    var table = $("#user-time-off-table").DataTable({
        "ajax": config.ajax,
        "columnDefs": [
          { "orderable": false, "targets": 3 }
        ],
        "columns": [
            { data: 'date', render: function (data, type, row) {
              var date = row.date;
              if (row.partial) {
                date += formatHours(row.formatted_hours);
              }
              return date;
            }},
            { data: 'name' },
            { data: 'external_id' },
            { data: 'duration', render: function (data, type, row) {
              var hours = data[0];
              var workdayHours = data[1];
              var days  = (hours/workdayHours);

              return formatApprovedTimeOff(hours, days);
            }}
        ],
        "autoWidth": false,
        "lengthChange": false,
        "info": false,
        "searching": false,
        "footerCallback": function ( row, data, start, end, display ) {
          var api = this.api();

          // Gather Page Totals
          var pageTotal = api
            .column(3)
            .data()
            .reduce( function (a, b) {
              a[0] += b[0];
              a[1] += b[0]/b[1];
              return a;
            }, [0,0] );

          // Add totals to footer
          $( api.column(3).footer() ).html(
              formatTotalApprovedTimeOff(pageTotal[0], pageTotal[1])
          );
        },
        "oLanguage": dataTables_i18n
    });

    $.extend(config, {
      table: table
    });

    // Inititialize Uniform for select picker
    $("select.uniform, input:file, .dataTables_length select").uniform();

    // Add a div wrapper so that headers get proper styling
    $.each($("th[class|='header'],th[class|='sorting']"), function() {
      return $(this).wrapInner("<div />");
    });
  };

  var initSelect2 = function(data){
    $(config.select2Id).select2({
      data: { results: data, text: 'tag' },
      formatResult: function(res) { return res.name; },
      formatSelection: function(res) { return res.name; },
      formatNoMatches: I18n.t("user_time_off.index.no_matches"),
      matcher: function(term, text, res) {
        return res.name.toLowerCase().indexOf(term.toLowerCase())>=0;
      },
      tags: data,
      placeholder: I18n.t("user_time_off.index.place_holder"),
      selectOnBlur: true
    }).on('select2-selecting', function (e, v) {
      if (e.val === 0) {
        e.preventDefault();
        $(this).val(0).change();
      }
    });
  };


  var initSelect2Listener = function(){
    $(config.select2Id).on("select2-selecting", function(e) {
      updateAjax(e);
    });

    $(config.select2Id).on("select2-removed", function(e) {
      updateAjax(null);
    });
  };

  var initDatePicker = function(id, date) {
    var datePicker = $(id)
      .datepicker({
        format: 'yyyy-mm-dd',
        container: '#date-picker',
        autoclose: true,
        language: I18n.locale,
      })
      .datepicker("setDate", date)
      .on('keydown', function preventDelete(e) {
        // do not allow user to delete
        const key = e.keyCode || e.charCode;
        if( key === 8 || key === 46 ) {
          e.preventDefault();
        }
      });

    $(id).on("change", function(e) {
      $(this).datepicker("hide");
      enforceValidDates(id);
      updateAjax(null);
    });
  };

  var enforceValidDates = function(id) {
    var startDate = new Date($(config.datePickerStart).val());
    var endDate = new Date($(config.datePickerEnd).val());

    if (endDate < startDate) {
      if (id === config.datePickerStart) {
        $(config.datePickerEnd).datepicker( "setDate" , addDayToDate(startDate));
      } else {
        $(config.datePickerStart).datepicker( "setDate" , addDayToDate(endDate));
      }
    }
  };

  const checkValidDate = function(dateString) {
    const dateObj = moment(dateString);
    return dateObj.isValid();
  };

  var updateAjax = function(e) {
    var url = config.ajax;
    var summaryUrl = config.summaryAjax;

    // Collect values from Select2Input (new and existing)
    var select2Values = $(config.select2Id).select2("data");
    if (e) {
      select2Values.push(e.object);
    }

    // Collect Dates
    var start = $(config.datePickerStart).val();
    var end   = $(config.datePickerEnd).val();

    // Do not continue if the dates are not valid
    if (!checkValidDate(start) || !checkValidDate(end)) {
      return;
    }

    var dateParams = "?start_date=" + start + "&end_date=" + end;

    url += dateParams;
    summaryUrl += dateParams;

    // Collect Names
    select2Values = _.map(select2Values, function(v){return v.id;});

    if (select2Values.length > 0) {
      var typeParams = "&types=" + select2Values;
      url += typeParams;
    }

    config.table.ajax.url(url).load();
    config.summaryTable.ajax.url(summaryUrl).load();
  };

  var addDayToDate = function(date) {
    return new Date(date.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 1));
  };

  var formatApprovedTimeOff = function(hours, days) {
    var hoursStr, daysStr;

    hours = formatRound(hours);
    days  = formatRound(days);

    if (hours == 1) {
      hoursStr = I18n.t("user_time_off.js.hour");
    } else {
      hoursStr = I18n.t("user_time_off.js.hours", { "hours": hours });
    }

    if (days == 1) {
      daysStr = I18n.t("user_time_off.js.day");
    } else {
      daysStr = I18n.t("user_time_off.js.days", { "days": days });
    }

    return I18n.t("user_time_off.js.hours_and_days", { "hours": hoursStr, "days": daysStr });
  };

  var formatTotalApprovedTimeOff = function(hours, days) {
    var hoursStr, daysStr;

    hours = formatRound(hours);
    days  = formatRound(days);

    if (hours == 1) {
      hoursStr = I18n.t("user_time_off.js.hour");
    } else {
      hoursStr = I18n.t("user_time_off.js.hours", { "hours": hours });
    }

    if (days == 1) {
      daysStr = I18n.t("user_time_off.js.day");
    } else {
      daysStr = I18n.t("user_time_off.js.days", { "days": days });
    }

    return I18n.t("user_time_off.js.total_hours_and_days", { "hours": hoursStr, "days": daysStr });
  };

  var formatHours = function(hoursStr) {
    return "<div class='hours'>" + hoursStr + "</div>";
  };

  var formatRound = function(num) {
    if(Math.round(num) !== num) {
      return num.toFixed(2);
    } else {
      return num;
    }
  };

  return {
    init: init
  };

})(jQuery);
