window.positionExternalNamesCsvs = (function($, window, document) {

  var init = function(settings) {
    $.extend(true, config, settings, {
      pusherKey:  settings.pusherKey,
      companyId: settings.companyId,
      selectors: {
        selectCsvButton: $('#select-csv-link'),
        uploadCsvButton: $('#upload-csv-button'),
        csvFile:         $('#position-code-file'),
        csvFilename:     $('#csv-filename')
      },
    });

    initPositionsExternalNamesCsvUploader();
  };

  var config = {};

  var initPusherChannel = function() {
    var pusher = window.getPusherInstance(config.pusherKey);
    var pusherChannel = pusher.subscribe('position-codes-import-' + config.companyId);
    var uploadButton = config.selectors.uploadCsvButton;

    pusherChannel.bind('position-codes-import-success', function() {
      console.log('success');
      displaySuccess();
      uploadButton.val(I18n.t('department.index.upload_csv'));
      uploadButton.prop('disabled', false);
    });

    pusherChannel.bind('position-codes-import-failed', function(data) {
      displayErrors(data);
      uploadButton.val(I18n.t('department.index.upload_csv'));
      uploadButton.prop('disabled', false);
    });
  };

  var initPositionsExternalNamesCsvUploader = function() {
    var selectButton = config.selectors.selectCsvButton,
        uploadButton = config.selectors.uploadCsvButton,
        csvFile      = config.selectors.csvFile,
        filename     = config.selectors.csvFilename;

    selectButton.on('click', function(e) {
      csvFile.click();
      e.preventDefault();
    });

    csvFile.on('change', function() {
      // eslint-disable-next-line no-useless-escape
      filename.html(this.value.replace(/.*[\/\\]/, ''));
      if (filename.html() === '') {
        uploadButton.hide();
      } else {
        uploadButton.show();
        uploadButton.val(I18n.t('department.index.upload_csv'));
        uploadButton.prop('disabled', false);
      }

      initPusherChannel();
    });

    uploadButton.on('click', function() {
      uploadButton.prop('disabled', true);
      uploadButton.val(I18n.t('department.index.processing'));
      var statusBar = $('#position-code-status');
      var self = this;
      var file = csvFile[0].files[0];
      var isCSV = (
        file.type === 'text/csv' ||
        file.name.split('.').pop() === 'csv'
      );

       if (!isCSV) {
         statusBar.html("<div class='alert alert-error'>" + I18n.t('department.index.csv_only') + "</div>");
        return;
      } else {
        statusBar.html("");
      }

      $.ajax({
        url: 'upload_position_external_names',
        type: 'POST',
        data: new FormData($('#position-code-form')[0]),
        cache: false,
        contentType: false,
        processData: false,
        xhr: function() {
          var myXhr = $.ajaxSettings.xhr();
          return myXhr;
        }
      });
    });
  };

  var displaySuccess = function() {
    var statusBar = $('#position-code-status');
    statusBar.html("<div class='alert alert-success'>" + I18n.t('department.index.position_codes_success') + "</div>");
  };

  var displayErrors = function(errors) {
    var statusBar = $('#position-code-status');
    var html = "";
    html += "<div class='alert alert-error'>";
    html += "<h5>" + I18n.t('department.index.position_codes_failure') + "</h5>";
    html += "<ul>";
    _.each(errors, function(error){
      html += "<li>" + error + "</li>";
    });
    html += "</ul>";
    html += "</div>";

    statusBar.html(html);
  };

  return {
    init: init,
  };

})(jQuery, window, document);

