<template>
  <div :class="containerClasses">
    <span class="budget-title">
      {{header}}
    </span>
    <span :id="customId">
      {{content}}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    customId:    String,
    customClass: String,
    header:      String,
    content:     String,
  },
  computed: {
    containerClasses() {
      return {
        'span1': true,
        [this.customClass]: true
      }
    }
  },
}
</script>
