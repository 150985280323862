$(document).ready(function(){
  var formID = "#broadcast-form";
  var broadcastTimeRadioButtons = formID + " .broadcast-time input[name=schedule]";
  var integrationDropdown = "#broadcast_integration";

  var disableFeature = function() {
    $(".broadcast_feature input[value=all]").prop("checked", true);
    $(".broadcast_feature input[value!=all]").attr("disabled", true);
  };

  var enableFeature = function() {
    $(".broadcast_feature input").attr("disabled", false);
  };

	var showCompaniesFilter = function() {
      $("#companies-filter").removeClass("hidden");
      $("#companies-filter input").prop('disabled', false);
      disableFeature();
	};

	var hideCompaniesFilter = function() {
      $("#companies-filter").addClass("hidden");
      $("#companies-filter input").prop('disabled', true);
      enableFeature();
	};

  // Handle clicking of "Publish Now" versus "Schedule" radio buttons
  $(broadcastTimeRadioButtons).on("click", function(e) {
    var input = e.target;

    switch(input.value) {
      case "now":
        $("#now-datepicker input").prop('disabled', false);
        $("#schedule-datepickers input").prop('disabled', true);
        $("#schedule-datepickers").addClass("hidden");
        break;
      case "schedule":
        $("#now-datepicker input").prop('disabled', true);
        $("#schedule-datepickers input").prop('disabled', false);
        $("#schedule-datepickers").removeClass("hidden");
        break;
      default:
        return;
    }
  });

  // Handle selecting of "Company Type" to show an input field when
  // selecting "Specific Companies in the dropdown.
  $(integrationDropdown).on("change", function(e) {
    var integration = e.target.value;

    if (integration === "companies") {
			showCompaniesFilter();
    } else {
			hideCompaniesFilter();
    }
  });

  // Show input field if "custom companies" is select on page load
	if ($(".broadcast_integration select").val() === "companies") {
		showCompaniesFilter();
	}
});
