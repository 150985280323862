window.payPeriods = (function($, window, document) {

  /*
  public
  */

  /**
   * Initializes PayPeriod Editing
   * @param  {Object} settings Initial settings
   */
  var init = function(settings) {
    $.extend(true, config, settings, {
      selectors: {
        datepickerInput:                  $('input.pay-period-datepicker'),
        payPeriodType:                    $('#location_pay_period_type'),
        payPeriodTypeContainer:           $('.location_pay_period_type'),
        payPeriodInput:                   $('#location_pay_period_starts_at'),
        payPeriodDateSelector:            $("#pay-period-starts-at"),
        payPeriodWeeklyInput:             $('#location_pay_period_start_day'),
        payPeriodWeeklyDaySelector:       $("#pay-period-start-day")
      },
      routes: {
        locationPayPeriods: '/locations/' + settings.locationId + '/pay_period'
      }
    });

    togglePayPeriodContent(config.selectors.payPeriodType.val());
    initPayPeriodActions();
    updateHelpBlock();
  };

  /**
   * Default settings
   * @type {Object}
   */
  var config = {
    payPeriod: {
      endDate: null,
      includesPunches: false  // Used to determine if bi-weekly start date range
    },
    allowArbitraryMonthlyDate: false,
    allowArbitrarySemiMonthlyDate: false
  };

  // Cheap shot to keep this from firing 3 times. Known issue.
  // https://stackoverflow.com/questions/22614041/bootstrap-datepicker-on-change-firing-3-times
  var lastSentDate = null;

  /*
  private
  */

  /**
   * Initializes the datepicker UI according to specified pay period type.
   * @param  {String} payPeriodType
   */
  var initDatePicker = function(payPeriodType) {

    clearDatePicker();

    if (payPeriodType == "monthly") {
      // flipper flag
      if (config.allowArbitraryMonthlyDate) {
        initDatePickerForMonthly();
      }
    } else if (payPeriodType == "biweekly") {
      initDatePickerForBiWeekly();
    } else if (payPeriodType == "semimonthly") {
      // flipper flag
      if (config.allowArbitrarySemiMonthlyDate) {
        initDatePickerForSemiMonthly();
      }
    }
  }

  /**
   * Initializes the datepicker UI for setting up a monthly pay period.
   */
  var initDatePickerForMonthly = function() {

    if (config.payPeriod.nextPayPeriodStart && config.payPeriod.includesPunches) {
      var startDate = moment(config.payPeriod.nextPayPeriodStart)._d;
      var endDate = moment(config.payPeriod.nextPayPeriodStart).add(28, 'days')._d;
    } else {
      // new pay period
      startDate = moment().subtract(1, 'month').add(1, 'day')._d;
      endDate = moment().add(28, 'days')._d;
    }

    config.selectors.datepickerInput.datepicker({
      autoclose: true,
      format: 'yyyy-mm-dd',
      startDate: startDate,
      endDate: endDate,
      todayBtn: true,
      beforeShowDay: function(d) {
        var day = d.getDate();
        if (day == 29 || day == 30 || day == 31) {
          return false;
        } else {
          return true;
        }
      },
      language: I18n.locale
    });

    config.selectors.datepickerInput.datepicker("setDate", moment().startOf('month')._d);
  };

  /**
   * Initializes the datepicker UI for setting up a biweekly pay period.
   */
  var initDatePickerForBiWeekly = function() {

    var currentPayPeriodStartDate = config.selectors.datepickerInput.val();

    if (currentPayPeriodStartDate.length && config.payPeriod.includesPunches) {
      currentPayPeriodStartDate = moment(config.payPeriod.nextPayPeriodStart)._d;
    } else {
      currentPayPeriodStartDate = moment().add(-13, 'days')._d;
    }

    config.selectors.datepickerInput.datepicker({
      autoclose: true,
      format: 'yyyy-mm-dd',
      startDate: currentPayPeriodStartDate,
      endDate: moment().add(13, 'days')._d,
      todayBtn: true,
      language: I18n.locale
    });
  };


  /**
   * Initializes the datepicker UI for setting up a semi-monthly pay period.
   */
  var initDatePickerForSemiMonthly = function() {

    if (config.payPeriod.nextPayPeriodStart && config.payPeriod.includesPunches) {
      var startDate = moment(config.payPeriod.nextPayPeriodStart)._d;
      var endDate = moment(startDate).add(13, 'days')._d;
    } else {
      startDate = moment().add(-14, 'days')._d;
      endDate = moment().add(13, 'days')._d;
    }

    config.selectors.datepickerInput.datepicker({
      autoclose: true,
      format: 'yyyy-mm-dd',
      startDate: startDate,
      endDate: endDate,
      todayBtn: true,
      language: I18n.locale
    });

    config.selectors.datepickerInput.datepicker("setDate", moment(-1, 'days')._d);
  };

  /**
   * Destroys datepicker and clears it's values
   * (Needed before re-initializing with new options)
   */
  var clearDatePicker = function() {
    config.selectors.payPeriodInput.val('')
    config.selectors.datepickerInput.datepicker('remove');
  }

  /**
   * Initializes PayPeriod field forms with bindings and UI components.
   */
  var initPayPeriodActions = function() {
    var currentPayPeriodStartDate = config.selectors.datepickerInput.val();

    if (currentPayPeriodStartDate.length && config.payPeriod.includesPunches) {
      currentPayPeriodStartDate = moment(config.payPeriod.nextPayPeriodStart)._d;
    } else {
      currentPayPeriodStartDate = moment().add(-13, 'days')._d;
    }

    initDatePicker(config.selectors.payPeriodType.val());

    config.selectors.payPeriodType.on("change", function(e) {
      togglePayPeriodContent(config.selectors.payPeriodType.val());
      updateHelpBlock();
    });

    config.selectors.payPeriodWeeklyInput.on("change", function(e) {
      updateHelpBlock();
    });

    config.selectors.datepickerInput.on("change", function(e) {
      // Cheap shot to keep this from firing 3 times. Known issue.
      // https://stackoverflow.com/questions/22614041/bootstrap-datepicker-on-change-firing-3-times
      var selectedStartDate = config.selectors.payPeriodInput.val();
      if (selectedStartDate != lastSentDate) {
        updateHelpBlock();
        lastSentDate = selectedStartDate;
      }
    });
  };

  /**
   * Requests date from the server for display as a helpful hint.
   * @param  {String} type      PayPeriod Type ['weekly', 'bi-weekly', 'semi-monthly', 'monthly']
   * @param  {Integer} start_day Day of the week as an Integer
   * @param  {String} starts_at Date
   * @return {Promise}
   */
  var loadPayPeriodRangeDates = function(type, start_day, starts_at) {
    return $.getJSON(config.routes.locationPayPeriods, {
      type: type,
      start_day: start_day,
      starts_at: starts_at
    });
  };

  /**
   * Toggles the display of Day/Date fields
   * @param  {String} payPeriodType PayPeriod Type
   */
  var togglePayPeriodContent = function(payPeriodType) {

    hideAllPayPeriodContent();

    switch(payPeriodType) {
    case "weekly":
      config.selectors.payPeriodWeeklyDaySelector.show();
      break;
    case "biweekly":
      initDatePicker(payPeriodType);
      config.selectors.payPeriodDateSelector.show();
      break;
    case "semimonthly":
      // flipper
      if (config.allowArbitrarySemiMonthlyDate) {
        initDatePicker(payPeriodType);
        config.selectors.payPeriodDateSelector.show();
      }
      break;
    case "monthly":
      // flipper
      if (config.allowArbitraryMonthlyDate) {
        initDatePicker(payPeriodType);
        config.selectors.payPeriodDateSelector.show();
      }
      break;
    default:
      hideAllPayPeriodContent();
      break;
    }
  };

  /**
   * Hides the display of Day/Date fields
   * @param  {String} payPeriodType PayPeriod Type
   */
  var hideAllPayPeriodContent = function() {
    clearDatePicker();
    config.selectors.payPeriodDateSelector.hide();
    config.selectors.payPeriodWeeklyDaySelector.hide();
  }

  /**
   * Handles updating the PayPeriod help block with expected date range
   */
  var updateHelpBlock = function() {
    var payPeriodStartDay = config.selectors.payPeriodWeeklyInput.val(),
        payPeriodStartsAt = config.selectors.payPeriodInput.val(),
        payPeriodType     = config.selectors.payPeriodType.val(),
        dateRange;

    // If the "custom monthly date" feature is on then we need to check
    // that I date has been selected before sending out for date ranges.
    var previewMonthly = function() {
      if (config.allowArbitraryMonthlyDate) {
        return payPeriodStartsAt.length > 0
      } else {
        return true;
      }
    }

    // If the "custom semi-monthly date" feature is on then we need to check
    // that I date has been selected before sending out for date ranges.
    var previewSemiMonthly = function() {
      if (config.allowArbitrarySemiMonthlyDate) {
        return payPeriodStartsAt.length > 0
      } else {
        return true;
      }
    }

    // Here we're just deciding, based on pay period type, if we have the needed
    // UI information to warrant querying the server for valid date ranges.
    if ((payPeriodType === "monthly" && previewMonthly()) ||
        (payPeriodType === "semimonthly" && previewSemiMonthly()) ||
        (payPeriodType === "weekly" && payPeriodStartDay.length > 0) ||
        (payPeriodType === "biweekly" && payPeriodStartsAt.length > 0)) {

      loadPayPeriodRangeDates(payPeriodType, payPeriodStartDay, payPeriodStartsAt).then(function(response){
          dateRange = response.pay_period.date_range;

          if (dateRange != null) {
            config.selectors.payPeriodTypeContainer.find('.help-block').text(I18n.t('pay_periods.js.create_new', { range: dateRange }));
          }
        }, function(xhr, status, error){
          alert(I18n.t('pay_periods.js.error'));
        }
      );
    } else {
      config.selectors.payPeriodTypeContainer.find('.help-block').text("");
    }
  };

  return {
    init: init
  };

})(jQuery);
