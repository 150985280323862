function user_preferred_clock() {
  return $("#user_data").data('timeformat');
}

export function user_prefers_12hr() {
  return user_preferred_clock() == 12;
}

export function user_prefers_24hr() {
  return !user_prefers_12hr();
}

 /*
 * Function that converts time from am/pm format to 24 hours format (for display)
 *
 * @param {time} string "h:m A" format
 *
 * @return {time} string "HH:MM" format
 */
export function am_pm_to_hours(time) {
  var parsed = moment(time, 'h:mma', true);
  return parsed.isValid() ? parsed.format('HH:mm') : time;
}

// Input:  A time format string optionally having am/pm case-insensitively ("12:34pm", "12:34AM", "17:49")
// Output: A time format string optionally having a/p in lowercase         ("12:34p",  "12:34a",  "17:49")
export function shorten_ampm(time) {
  time = time.toLowerCase();
  time = time.replace(/am/g, "a");
  time = time.replace(/pm/g, "p");
  return time;
}

/*
 * Function that converts time from  24 hours format to 12 hours format (for display)
 * Accepts times with brackets, for some strange reason...
 * Leaving it because I don't want to change its functionality
 * Existing functionality matches *both* 12:34 style *and* 12:34:56 style (seconds)
 *
 * @param {time} string "HH:MM" format
 *
 * @return {time} string "h:m A" format
 */
export function tConvert(time, format24hr = false) {
  var has_brackets = false;
  if (time.indexOf('(') > -1)
  {
    has_brackets = true;
    time = time.replace(/[()]/g, '');
  }

  var parsed, formatted;

  // Attempt to parse without seconds
  parsed = moment(time, 'HH:mm', true);
  if (parsed.isValid()) {
    if (format24hr) {
      formatted = parsed.format('HH:mm');
    } else {
      formatted = parsed.format('h:mma');
    }
    return has_brackets ? '(' + formatted + ')' : formatted;
  }

  // Attempt to parse with seconds
  parsed = moment(time, 'HH:mm:ss', true);
  if (parsed.isValid()) {
    if (format24hr) {
      formatted = parsed.format('HH:mm');
    } else {
      formatted = parsed.format('h:mma');
    }
    return has_brackets ? '(' + formatted + ')' : formatted;
  }

  return has_brackets ? '(' + time + ')' : time;
}

/*
 * add commas to numbers
 */
String.prototype.commatise = function(){
  return this.replace(/(\d)(?=(\d{3})+($|\.))/g, "$1,");
}

/*
 * just enabling a better looking parsing of string to integer.
 */
String.prototype.toNum = function(){
    return parseInt(this, 10);
}

/*
 * Simplify display of floating point numbers
 * Whole numbers will display without decimal places
 * Non-whole numbers will display to a requested number of decimal places (default is 2)
 */
export function basic_format(val, decimals) {
  decimals = decimals || 2

  // If adding the decimal places wouldn't change the number
  // do the whole number display
  if (parseFloat(val.toFixed()).toFixed(decimals) == val.toFixed(decimals)) {
    return val.toFixed().commatise();
  } else {
    return val.toFixed(decimals).commatise();
  }
}

// Similar to basic_format, but specific to currencies
export function basic_currency_format(val) {
  if (parseFloat(val.toFixed()).toFixed(2) == val.toFixed(2)) {
    return I18n.toCurrency(val, { precision: 0 });
  } else {
    return I18n.toCurrency(val);
  }
}

/*
 * Undo basic_format to return a float
 */
export function basic_unformat(val) {
  return parseFloat(val.replace(/,/g, ""));
}

/*
 * Useful for comparing numbers that are displayed simple_formatted
 * Just format and unformat to get float value
 */
export function basic_round(val, decimals) {
  return basic_unformat(basic_format(val, decimals));
}

// Convert start_time and end_time from the unavailable data (*always* in 24hr)
// to a string for display to the user (could be 12/24hr depending on preference)
// Input:  start_time (String for a time in 24hr format, e.g. "14:00")
// Input:  end_time   (String for a time in 24hr format, e.g. "18:00")
// Output: String representing the time *range* in 12/hr hour format
export function formatPartialUnavailableTimes(start_time, end_time) {
  return shorten_ampm(
    (user_prefers_24hr() ? start_time : tConvert(start_time)) +
    " - " +
    (user_prefers_24hr() ? end_time   : tConvert(end_time))
  );
}
