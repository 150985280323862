window.userSchedulePreview = (function($) {

  /*
  public
  */

  var init = function(settings) {
    $.extend(config, {
      startOfWeek: settings.startOfWeek
    });
    initPreview();
  };

  /*
  private
  */

  var buildCalendar = function($calendar, $originatingLink, $newShiftName, $newShiftDate) {
    $calendar.fullCalendar({
      firstDay: config.startOfWeek,
      year: $newShiftDate.getUTCFullYear(),
      month: $newShiftDate.getUTCMonth(),
      aspectRatio: 1.5,
      header: {
        left: false,
        center: "title",
        right: false
      },
      monthNames: I18n.t('date.month_names').slice(1),
      monthNamesShort: I18n.t('date.abbr_month_names').slice(1),
      dayNames: I18n.t('date.day_names'),
      dayNamesShort: I18n.t('date.abbr_day_names'),
      columnFormat: {
        month: 'dddd'
      },
      timeFormat: '',
      eventRender: function( event, element, view ) {
        element.find('span.fc-event-title').html(element.find('span.fc-event-title').text());
      },
      eventSources:
      [{
        // Schedule Shifts
        url: $originatingLink.data("user-calendar-url"),
        lazyFetching: true,
        className: "modal-calendar",
        textColor: "#FFF",
        backgroundColor: config.greyHex,
        editable: false
      },
      {
        // Unavailables
        url: $originatingLink.data("user-calendar-url") + "?filter=unavailables",
        lazyFetching: true,
        textColor: "#FFF",
        backgroundColor: config.greyHex,
        className: "modal-calendar",
        editable: false
      }],
      loading: function(loaded) {
        if (!loaded && ($originatingLink.data("calendar-type") === "exchange" || ($originatingLink.data("calendar-type") === "drop"))) {
          var exchangedShift = $calendar.fullCalendar("clientEvents", function(event) {
            return parseInt($originatingLink.data("exchanged-shift-id")) === event.id;
          });
          exchangedShift[0].backgroundColor = null;
          exchangedShift[0].className = "old-exchange";
          $calendar.fullCalendar("updateEvent", exchangedShift[0]);
        }
      }
    });
  };

  var createLegend = function($calendarType) {
    $(".bootbox .modal-body").addClass("nopadding").attr("style", "font-size:10px;");
    switch ($calendarType)
    {
      case "drop":
        $('.bootbox .modal-footer').prepend("<div class='pull-left' style='font-size: 11px;'><span style='margin-right: 5px;' class='label old-exchange'>&nbsp;</span>" + I18n.t("user_schedule_preview.js.dropped_shift"));
        break;
      case "pickup":
        $('.bootbox .modal-footer').prepend("<div class='pull-left' style='font-size: 11px;'><span style='margin-right: 5px;' class='label department-0-cal'>&nbsp;</span>" + I18n.t("user_schedule_preview.js.new_shift"));
        break;
      case "offer":
        $('.bootbox .modal-footer').prepend("<div class='pull-left' style='font-size: 11px;'><span style='margin-right: 5px;' class='label department-0-cal'>&nbsp;</span>" + I18n.t("user_schedule_preview.js.new_shift"));
        break;
      default:
        $('.bootbox .modal-footer').prepend("<div class='pull-left' style='font-size: 11px;'><span style='margin-right: 5px;' class='label department-0-cal'>&nbsp;</span>" + I18n.t("user_schedule_preview.js.new_shift"));
        $('.bootbox .modal-footer').find('.pull-left').append("<span style='margin: 0px 5px 0px 10px;' class='label old-exchange'>&nbsp;</span>"+ I18n.t("user_schedule_preview.js.old_shift") +"</div>");
        break;
    }
  };


  var initPreview = function() {
    $(".user-calendar").on("click", function(event) {
      event.preventDefault();
      // TODO: Look into removing bootbox and using modal within page.
      bootbox.alert("<div id='user_calendar'></div>", I18n.t('buttons.ok'));
      var $originatingLink = $(this);
      var $newShiftDate = Date.parse($originatingLink.data("shift-date"));
      var $newShiftName = $originatingLink.data("shift-name");
      var $calendar = $("div#user_calendar");

      buildCalendar($calendar, $originatingLink, $newShiftName, $newShiftDate);

      createLegend($originatingLink.data("calendar-type"));

      if (($originatingLink.data("calendar-type") !== "drop")) {
        $calendar.fullCalendar("renderEvent", { title: $newShiftName, start: $newShiftDate, className: "modal-calendar department-0-cal" }, true ); // adds the new shift
      }
      $("#user_calendar .fc-header-left").replaceWith('<td class="fc-header-left"><span class="fc-button fc-state-default fc-corner-right" unselectable="on"><span class="fc-button-inner"><span class="fc-button-content"><button type="button" class="close" data-dismiss="modal" style="margin-top:8px">×</button></span></span></span></td>');
    });
  };

  // Default settings
  var config = {
    greyHex: "#CCC"
  };

  return {
    init: init,
    buildCalendar: buildCalendar
  };

})(jQuery);
