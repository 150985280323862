// Generate a message for a bootbox alert
// We try to glean additional information from the error_type
// so that we don't always have an alert that starts with "Error"
export function bootboxMsg(responseJSON) {

  // If no responseJSON exists, give it a default value
  if (responseJSON === null || typeof responseJSON !== 'object') {
    responseJSON = {};
  }

  // Provide a default error message
  if (!responseJSON.hasOwnProperty('error_message')) {
    responseJSON.error_message = I18n.t('department_schedules.js.error_occurred');
  }

  return '<h4>' + I18n.t('department_schedules.js.error') + '</h4><p>' + responseJSON.error_message + '</p>';
}
