$(function() {
  $.tablesorter.addParser({
    id: 'date',

    is: function(s) {
      return false;
    },

    format: function(s) {
      return parseInt(s);
    },

    type: 'numeric'
  });

  $.tablesorter.addParser({
    id: 'datetime',

    is: function(s) {
      return false;
    },

    format: function(s) {
      return parseInt(s);
    },

    type: 'numeric'
  });

  $.each($('.boot_sort'), function() {
    var table = $(this);
    // Do not attempt to initialize table sorter if there are no rows in the table
    var numberOfRows = table.find('tbody tr').length;
    if (numberOfRows < 1) {
      return;
    }
    table.tablesorter({
      theme: "bootstrap",
      widthFixed: true,
      headerTemplate: "{content} {icon}",
      sortList: tablesorterSortOrder(table),
      headers: tableDisableSort(table),
      textExtraction: tablesorterExtraction
    });
  });

  if ($("th[class~='header'],th[class|='sorting']").length > 0) {
    $.each($("th[class|='header'],th[class|='sorting'],th[class~='headerSortDown'],th[class~='headerSortUp']"), function() {
      return $(this).wrapInner("<div />");
    });
  }

  $('.selectpicker').selectpicker({
      style: 'btn-blue',
      size: 4,
      width: "50%",
  });
});

window.tablesorterExtraction = function(node) {
  // Sort dates and times by a timestamp so we don't have to parse i18n-ed dates
  // Needs to be wrapped in a span
  // Example: <td><span data-timestamp="<%= user.last_sign_in_at.to_time.to_i %>"><%= user.last_sign_in_at %></span></td>
  var timestamp = $(node).find('[data-timestamp]');
  if (timestamp.length) {
    return timestamp.attr('data-timestamp');
  } else {
    return node.textContent;
  }
};

// a general function to converion a query string to an array.
window.queryConvert = function(){
    var queryStr = window.location.search,
      queryArr = queryStr.replace('?','').split('&'),
      queryParams = {};

    for (var q = 0, qArrLength = queryArr.length; q < qArrLength; q++) {
        var qArr = queryArr[q].split('=');
        queryParams[qArr[0]] = qArr[1];
    }

    return queryParams;
};

window.tablesorterSortOrder = function(table) {
  var tableHeaders = table.find('th[data-default-sort=true]');
  var sortOrder = [];
  var tempOrder = [];

  if (tableHeaders.length > 0) {
    sortOrder   = [];

    $.each(tableHeaders, function() {
      var $header = $(this);
      tempOrder.push([$header.index(), 0, parseInt($header.data('sort-order'))]);
    });

    var sortedHeaders = tempOrder.sort(function(a, b) {
      var aOrder = a[2];
      var bOrder = b[2];

      if (aOrder < bOrder) {
        return -1;
      } else {
        return 1;
      }
    });

    $.each(sortedHeaders, function() {
      var $element = $(this);
      sortOrder.push([$element[0], 0]);
    });
  } else {
    sortOrder.push([0, 0]);
  }

  return sortOrder;
};

window.tableDisableSort = function(table) {
  var disabledHeaders = table.find('th[data-sorter=false]');
  var customHeadersSort = table.find('th[data-sorter]');
  var headersSettings = {};

  if (disabledHeaders.length > 0) {
    $.each(disabledHeaders, function() {
      headersSettings[$(this).index()] = { sorter: false };
    });
  };

  if (customHeadersSort.length > 0) {
    $.each(customHeadersSort, function() {
      var $th = $(this);
      var sortName = $th.data('sorter');

      if (sortName) {
        headersSettings[$th.index()] = { sorter: sortName };
      }
    });
  }
  return headersSettings;
};


// Clears all modal alerts
export var clearModalAlerts = function() {
  $('.modal div[class~="alert"]').remove();
};

// Renders a formatted error message into modal
// Expects an object like the following:
// {
//   error_message: 'Hello',
//   error_style:   'warn'
// }
// The field `error_style` is optional and defaults to `error`
export var renderModalAlert = function(alertInfo) {
  alertInfo.error_style = alertInfo.error_style || 'error';
  clearModalAlerts();
  $('.modal-body').prepend('<div class="alert alert-' + alertInfo.error_style + '">' + alertInfo.error_message + '</div>');
  $('.modal-body').scrollTop(0);
};

// override vanilla rails confirms with bootbox
$(function() {
  $.rails.allowAction = function(element) {
    var message = element.data('confirm'),
      cancelButtonText = element.data('cancel-button-text'), // this attribute allows for overriding the bootbox cancel button text
      confirmButtonText = element.data('confirm-button-text'), // this attribute allows for overriding the bootbox confirm button text
      answer = false, callback;

    if (!message) { return true; }

    if ($.rails.fire(element, 'confirm')) {
      myCustomConfirmBox(message, cancelButtonText, confirmButtonText, function() {
      callback = $.rails.fire(element,
        'confirm:complete', [answer]);
        if(callback) {
          var oldAllowAction = $.rails.allowAction;
          $.rails.allowAction = function() { return true; };
          element.trigger('click');
          $.rails.allowAction = oldAllowAction;
        }
      });
    }
    return false;
  }

  // DANGER: `message` can contain HTML, so bootbox messages are rife for XSS possibilities
  function myCustomConfirmBox(message, cancelButtonText, confirmButtonText, callback) {
    cancelButtonText = (typeof cancelButtonText === "undefined") ? I18n.t('buttons.cancel') : cancelButtonText;
    confirmButtonText = (typeof confirmButtonText === "undefined") ? I18n.t('buttons.yes') : confirmButtonText;

    bootbox.confirm(message, cancelButtonText, confirmButtonText, function(confirmed) {
      if(confirmed){
        callback();
      }
    });
  }
});
