import { msUtil } from 'core/ms_util.js';

window.userImporter = (function($, window, document) {

  /**
   * Initializes User Importer
   * @param  {Hash} settings Location specific configurations
   */
  var init = function(settings) {

    $.extend(config, {
      data: {
        currentCompanyId: settings.companyId,
        currentUserId: settings.currentUserId,
        errorCount: settings.errorCount,
        statusPath: settings.statusPath,
        currentlyWorking: settings.currentlyWorking,
        impersonating: settings.impersonating,
        brand: settings.brand
      },
      privatePusherChannel: settings.privatePusherChannel,
      spinner: null,
      multipleSingleImports: {
        triggeredSincePageLoad: false,
        anyImportsFailed: settings.errorCount > 0,
        processingCount: 0
      }
    });

    config.spinner = msUtil.spinner('adp-loading-spinner');

    initDataTable();
    initListeners();
    initPusher();
    initResyncPrompt();
    initImportPrompt();
    initImportButtons();
    initDismissButtons();
    initTooltips();

  };

  var initDataTable = function() {

    var initialSorting = [2, 'asc'];

    if (config.data.errorCount > 0) {
      initialSorting = [1, 'asc'];
    }

    var initImportSelectedButton = function initImportSelectButton() {
      var importSelectedBtnHtml =
        '<button id="import-sel-emp-btn" class="btn btn-success">' + I18n.t('user_importer.js.import_selected') + '</button>';

      $('#DataTables_Table_0_filter').append(importSelectedBtnHtml);
      $('#import-sel-emp-btn').on('click', handleImportSelected)
    }

    var table = $('table.adp-user-importer').DataTable({
      bProcessing: true,
      bServerSide: true,
      sAjaxSource: $('.adp-user-importer').data('source'),
      sPaginationType: "full_numbers",
      iDisplayLength: 10,
      sDom: "<\"table-header\"flr><\"table-responsive\"t><\"table-footer\"ip>",
      aaSorting: [ initialSorting ],
      aoColumns: [
        { bSortable: false },
        { bSortable: true },
        { bSortable: true },
        { bSortable: true, bVisible: config.data.impersonating }, // Associate OID column
        { bSortable: true },
        { bSortable: true },
        { bSortable: true }
      ],
      fnCreatedRow: function( nRow, aData, iDataIndex ) {
        // alter rows here
      },
      fnInitComplete: function() {
        initImportSelectedButton();
        resetAndInitCheckboxes();
      },
      fnDrawCallback: function() {
        $('#import-sel-emp-btn').hide()
        resetAndInitCheckboxes();
      },
      oLanguage: dataTables_i18n
    });

    $("select.uniform, input:file, .dataTables_length select").uniform();

    // Trick to disable the auto "search on key up" DataTable behaviour
    // We don't need to encourage that many requests!
    $('.dataTables_filter input').unbind();
    $('.dataTables_filter input').bind('keyup', function(e) {
      if(e.keyCode == 13) {
        table.search(this.value).draw();
      }
    });
  };

  var initDismissButtons = function() {
    $(document).on("click", ".dismiss-error-button", function(e) {
      e.preventDefault();

      var dismissButton = $(this);
      var userError = $(this).parent('.adp-user-error');

      var promptContent = '<h4>' + I18n.t('user_importer.js.are_you_sure') + '</h4>';
      promptContent += '<div>' + I18n.t('user_importer.js.this_will_dismiss') + '</div>';

      var aoid = dismissButton.data("aoid");

      bootbox.dialog(promptContent, [
        {
          "label" : I18n.t('buttons.cancel'),
          "class" : "btn"
        },
        {
          "label" : I18n.t('buttons.dismiss_error'),
          "class" : "btn-danger",
          "callback": function() {
            $.ajax({
              method: "DELETE",
              dataType: "json",
              url: '/integrations/adp/employees/' + aoid + '/dismiss_error',
              success: function(data) {
                userError.hide();
                $(document).trigger('adp_error_messages.decrement', data);
              }
            });
          }
        }
      ]);
    });
  };

  var initTooltips = function() {
    $(document).on("mouseenter", "tr", function(){
      $(this).find("[data-toggle='tooltip']").tooltip();
    });
  };

  var initImportButtons = function() {
    $("body").on("click", ".user-import-button", function(e) {
      var button = $(this);
      var aoid = button.data('aoid');

      if (button.data("state") == "deactivate") {
        return deactivateEmployeeModal(aoid);
      }

      if (button.data("state") == "import") {
        return importEmployee(aoid);
      }
    });
  };

  function linkName(aoid) {
    var el = $("#aoid-" + aoid);
    var name = el.text();
    el.html("<a target=\"_blank\" href='/integrations/adp/employees/" + aoid + "'>" + name + "</a>");
  }

  function unlinkName(aoid) {
    var el = $("#aoid-" + aoid);
    var name = el.find('a').text();
    el.html(name);
  }

  function addCheckmark(aoid) {
    var el = $("#status-" + aoid);
    el.html("<i class='fa fa-check'></i>");
  }

  function removeCheckmark(aoid) {
    var el = $("#status-" + aoid);
    el.html("<span></span>");
  }

  function importEmployee(aoid) {
    config.multipleSingleImports.triggeredSincePageLoad = true
    config.multipleSingleImports.processingCount += 1
    var button = $('.user-import-button[data-aoid="' + aoid + '"]');
    setButtonLoading(button);

    $.ajax({
      method: "POST",
      dataType: "json",
      url: '/integrations/adp/employees/' + aoid + '/import_employee',
      success: function(data) {
        // UI handled by websocket
      },
      error: function(e, xhr) {
        // UI handled by websocket
      }
    });
  }

  function deactivateEmployee(aoid) {
    $.ajax({
      method: "POST",
      dataType: "json",
      url: '/integrations/adp/employees/' + aoid + '/deactivate_employee',
      success: function(data) {
        var button = $('.user-import-button[data-aoid="' + aoid + '"]');
        setButtonImport(button);
        unlinkName(aoid);
        removeCheckmark(aoid);
        enableCheckbox(aoid);
      }
    });
  }

  function deactivateEmployeeModal(aoid) {
    var promptContent = '<h4 class="text-error">' + I18n.t('adp.js.warning_please_read') + '</h4>';
    promptContent += '<div>';

    if (config.data.brand == 'adp') {
      promptContent += I18n.t('adp.js.adp_deactivating_will');
    } else {
      promptContent += I18n.t('adp.js.deactivating_will');
      promptContent += ' ';
      promptContent += I18n.t('adp.js.unable_to_schedule');
      promptContent += ' ';
      promptContent += I18n.t('adp.js.will_free_up');
    }

    promptContent += '</div>';

    bootbox.dialog(promptContent, [
      {
        "label" : I18n.t('buttons.cancel'),
        "class" : "btn",
        "callback": function() {
        }.bind(this)
      },
      {
        "label" : I18n.t('adp.js.deactivate_user'),
        "class" : "btn-danger",
        "callback": function() {
          deactivateEmployee(aoid);
        }.bind(this)
      }
    ]);
  }

  var setButtonLoading = function(button) {
    button.removeClass("btn-success btn-danger").addClass("btn-gray");
    button.data("state", "loading");
    button.text(I18n.t('adp.js.loading'));
  };

  var setButtonImport = function(button) {
    button.removeClass("btn-gray btn-danger").addClass("btn-success");
    button.data("state", "import");
    button.text(I18n.t('adp.js.import'));
  };

  var setButtonDeactivate = function(button) {
    button.removeClass("btn-gray btn-success").addClass("btn-danger");
    button.data("state", "deactivate");
    button.text(I18n.t('adp.js.deactivate'));
  };

  var setButtonError = function(button) {
    button.removeClass("btn-danger btn-success").addClass("btn-gray");
    button.data("state", "error");
    button.text(I18n.t('adp.js.error'));
  };

  var initListeners = function() {
    $(document).on('adp_error_messages.decrement', function(event, data) {
      config.data.errorCount--;
      var newCount = config.data.errorCount;

      if (newCount == 0) {
        $("#import-failure-alert").hide();
      } else {
        $("#import-failure-count").text(newCount);
      }
    });
  };

  var initResyncPrompt = function() {
    var promptContent = '<h4>'  + I18n.t("user_importer.js.are_you_sure")            + '</h4>';
    promptContent    += '<div>' + I18n.t("user_importer.js.are_you_sure_resync_all") + '</div>';

    $("#adp-resync-all").on("submit", function(e) {
      e.preventDefault();
      var form = $(this);

      bootbox.dialog(promptContent, [{
          "label" : I18n.t("buttons.cancel"),
          "class" : "btn"
        }, {
          "label": I18n.t("user_importer.js.resync_all"),
          "class": "btn-danger",
          "callback": function() {
            form.get(0).submit();
          }
        }
      ]);
    });
  }

  var initImportPrompt = function() {
    var promptContent = '<h4>'  + I18n.t("user_importer.js.are_you_sure")            + '</h4>';
    promptContent    += '<div>' + I18n.t("user_importer.js.are_you_sure_import_all") + '</div>';

    $("#adp-import-all").on("submit", function(e) {
      e.preventDefault();
      var form = $(this);

      bootbox.dialog(promptContent, [{
          "label" : I18n.t("buttons.cancel"),
          "class" : "btn"
        }, {
          "label": I18n.t("user_importer.js.import_all"),
          "class": "btn-danger",
          "callback": function() {
            form.get(0).submit();
          }
        }
      ]);
    });
  }

  /**
   * Initializes Pusher
   */
  var initPusher = function() {
    var pusherChannel = config.privatePusherChannel;

    // Subscribe to private user pusherChannel and listen for a refresh event
    subscribeImportAllUpdates(pusherChannel);
    subscribeImportSingleUpdates(pusherChannel);
    subscribeListingUpdates(pusherChannel);
    subscribeResyncingUpdates(pusherChannel);
    subscribeResyncSingleUpdates(pusherChannel);

    if (config.data.currentlyWorking) {
      setupFallback();
    }
  };

  // If the user is stuck on the loading screen because the resync/refresh job finished
  // but websocket "success" message never came through (it's not guaranteed) then this
  // polling should get them back in the right state.
  function setupFallback() {
    setInterval(function() {
      $.getJSON(config.data.statusPath, function(data) {
        if (data.loading == false) {
          window.location.reload();
        }
      });
    }, 15000);
  }

  function showDeactivationPrompt() {
    var promptContent = '<h4 class="text-error">' + I18n.t('adp.js.warning_please_read') + '</h4>';
    promptContent += '<div>';

    if (config.data.brand == 'adp') {
      promptContent += I18n.t('adp.js.adp_deactivating_will');
    } else {
      promptContent += I18n.t('adp.js.deactivating_will');
      promptContent += ' ';
      promptContent += I18n.t('adp.js.unable_to_schedule');
      promptContent += ' ';
      promptContent += I18n.t('adp.js.will_free_up');
    }

    promptContent += '</div>';

    bootbox.dialog(promptContent, [
      {
        "label" : I18n.t('buttons.cancel'),
        "class" : "btn",
        "callback": function() {
        }.bind(this)
      },
      {
        "label" : I18n.t('adp.js.deactivate_user'),
        "class" : "btn-danger",
        "callback": function() {
        }
      }
    ]);
  }

  function handleUserImportFailureChecks() {
    var msi = config.multipleSingleImports;
    msi.processingCount -= 1;
    if (msi.processingCount === 0) {
      $('#import-sel-emp-btn').prop('disabled', false);
      if (msi.anyImportsFailed) { window.location.reload(); }
    }
  }

  function subscribeImportSingleUpdates(pusherChannel) {
    pusherChannel.bind('adp_import_single.success', function(data) {

      var button = $('.user-import-button[data-aoid="' + data.associate_oid + '"]');

      if (data.import_attempt.status == "failed") {
        setButtonError(button);
        config.multipleSingleImports.anyImportsFailed = true;
      } else {
        setButtonDeactivate(button);
        linkName(data.associate_oid);
        addCheckmark(data.associate_oid);
        uncheckByAoid(data.associate_oid);
        disableCheckbox(data.associate_oid);
      }
      handleUserImportFailureChecks()
    });

    pusherChannel.bind('adp_import_single.failed', function(data) {
      var button = $('.user-import-button[data-aoid="' + data.associate_oid + '"]');
      setButtonError(button);
      handleUserImportFailureChecks()
    });
  }

  function subscribeResyncSingleUpdates(pusherChannel) {
    pusherChannel.bind('adp_resync_single.success', function(data) {
      var p = window.location.pathname + "?resync_success=true";
      if (data.user_import_id) {
        p += "&user_import=" + data.user_import_id;
      }

      setTimeout(function() {
        window.location = p;
      }, 3000);
    });

    pusherChannel.bind('adp_resync_single.failed', function(data) {
      setTimeout(function() {
        window.location = window.location.pathname + "?resync_failure=true";
      }, 3000);
    });
  }

  function subscribeImportAllUpdates(pusherChannel) {
    pusherChannel.bind('adp_import_all.success', function(data) {
      var p = window.location.pathname + "?import_success=true";
      if(data.user_import_id) {
        p += "&user_import=" + data.user_import_id;
      }

      // delay 3 seconds to ensure the background job is marked as done on the server
      setTimeout(function() {
        window.location = p;
      }, 3000);
    });

    pusherChannel.bind('adp_import_all.failed', function(data) {
      setTimeout(function() {
        window.location = window.location.pathname + "?import_failure=true&error=" + data.error_message;
      }, 3000);
    });

    pusherChannel.bind('adp_import_all.progress', function(data) {
      $("#adp-loading #adp-loading-status").append(
        "<p>" + data.progress_message + "</p>"
      )
    });
  }

  function subscribeListingUpdates(pusherChannel) {
    pusherChannel.bind('adp_worker_list.failed', function(data) {
      // delay 3 seconds to ensure the background job is marked as done on the server
      setTimeout(function() {
        window.location = window.location.pathname + "?indexing_failed=true";
      }, 3000);
    });

    pusherChannel.bind('adp_worker_list.loaded', function(data) {
      // delay 3 seconds to ensure the background job is marked as done on the server
      setTimeout(function() {
        window.location = window.location.pathname;
      }, 3000);
    });

    pusherChannel.bind('adp_worker_list.progress', function(data) {
      $("#adp-loading #adp-loading-status").append(
        "<p>" + data.progress_message + "</p>"
      )
    });
  }

  function subscribeResyncingUpdates(pusherChannel) {
    pusherChannel.bind('adp_resync_all.success', function(data) {
      var p = window.location.pathname + "?resync_success=true";

      if (data.user_import_id) {
        p += "&user_import=" + data.user_import_id;
      }

      setTimeout(function() {
        window.location = p;
      }, 3000);
    });

    pusherChannel.bind('adp_resync_all.failed', function(data) {
      setTimeout(function() {
        window.location = window.location.pathname + "?resync_failure=true";
      }, 3000);
    });

    pusherChannel.bind('adp_resync_all.progress', function(data) {
      $("#adp-loading #adp-loading-status").append(
        "<p>" + data.progress_message + "</p>"
      )
    });
  }

  function handleImportSelected() {
    $('#import-sel-emp-btn').prop('disabled', true);
    $('input.employeeCheckbox:checked').each(function(i, el) {
      importEmployee(el.value);
    })
  }

  function getBtnStateFromByCheckbox(checkboxElement) {
    return $($(checkboxElement).closest('tr')).find('button').first().data('state')
  }

  function resetAndInitCheckboxes() {
    $('#all_employee_checkbox').prop('checked', false).off('click').on('click', handleSelectAllCheckboxClick)
    $('.employeeCheckbox').each(function () {
      const state = getBtnStateFromByCheckbox(this)
      if (state === 'import') {
        $(this).prop('checked', false).off('click').on('click', handleEmployeeCheckboxClick)
      } else if (state === 'deactivate') {
        $(this).prop('checked', false).off('click').attr('disabled', 'true')
      }
    })
  }

  function handleSelectAllCheckboxClick() {
    var activeCheckboxes = listCheckedCheckboxes()

    if (activeCheckboxes.length > 0) {
      $('#all_employee_checkbox').prop('checked', false)
      uncheckAllCheckboxes()
      hideImportSelectedBtn()
    } else {
      $('#all_employee_checkbox').prop('checked', true)
      activeAllCheckboxes()
      showImportSelectedBtn()
    }
  }

  function handleEmployeeCheckboxClick() {
    var not_checked = $('input.employeeCheckbox:not(:checked)');
    var checked = $('input.employeeCheckbox:checked');

    if (not_checked.length > 0) {
      $('#all_employee_checkbox').prop('checked', false)
    } else if (not_checked.length === 0) {
      $('#all_employee_checkbox').prop('checked', true)
    }

    if (checked.length === 0) {
      hideImportSelectedBtn()
    } else {
      showImportSelectedBtn()
    }
  }

  function listCheckedCheckboxes() {
    var checkedBoxes = []
    $('.employeeCheckbox').each(function(index, el) {
      if (el.checked) {
        checkedBoxes.push(el.value)
      }
    })
    return checkedBoxes
  }

  function enableCheckbox(aoid) {
    $(`#checkbox_${aoid}`).removeAttr('disabled')
  }

  function disableCheckbox(aoid) {
    document.getElementById('checkbox_' + aoid).setAttribute('disabled', 'true')
  }

  function uncheckAllCheckboxes() {
    $('input.employeeCheckbox:checked').each(function(index, el) {
      el.checked = false
    })
  }

  function activeAllCheckboxes() {
    $('input.employeeCheckbox').each(function (index, el) {
      const state = getBtnStateFromByCheckbox(el)
      if (state === 'import') {
        el.checked = true
      }
    })
  }

  function uncheckByAoid(aoid) {
    var employee = document.getElementById('checkbox_' + aoid);

    // if the employee element doesn't exist, the page may have reloaded due to an error
    if (employee) {
      employee.checked = false;
    }

    handleEmployeeCheckboxClick();
  }

  function showImportSelectedBtn() {
    $("#import-sel-emp-btn").show()
  }

  function hideImportSelectedBtn() {
    $("#import-sel-emp-btn").hide()
  }

  return {
    init: init
  }

})(jQuery, window, document);

