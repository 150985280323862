import { weeklyBudget } from 'core/weekly_budget.js';
import { departmentSchedule } from 'core/department_schedules.js';
import { clearModalAlerts } from 'core/makeshift.js';
window.shiftRequirementsImport = (function($, window, document) {

  var init = function(settings) {
    $('#import-selector').on("change", function(option){
      window.location.href = this.value
    })
    $('#import-selector').select2();

    $('[data-toggle="tooltip"]').tooltip({
      placement : 'top'
    })
  };

  // Default settings
  var config = {
    highlightColor:     '#ffcc00',
    highlightInterval:  750
  };

  // Setup Pusher Listeners
  var initPusher = function(config){
    var pusher = window.getPusherInstance(config.pusherKey);
    var pusherChannel = pusher.subscribe('shift_requirements_import');

    var importID = config.importID;
    var isProcessing = config.isProcessing;

    var updateMessage = function(){
      var $out_of_date_el = $('#shift-requirements-import-message');
      $out_of_date_el.show();
      //var $processing = $("#shift-requirements-import-processing");
      //$out_of_date_el.hide();
    }

    var updateCount = function(){
      var $count_el = $('#shift-requirements-import-count');
      var val = parseInt($count_el.text(), 10);
      var newval = val + 1;
      $count_el.text(newval);
      //var $status = $("#shift-requirements-import-details");
      //$status.show();
    }

    // Hack: We have a bug that the Pusher messages can be sent out after the controller
    // has retrieved the import but before the view renders/pusher client is bound
    // The messages then go into the ether and are never retrievable. If the
    // import is processing we'll just show a refresh message after a short time
    // to trigger the reload.
    if (isProcessing) {
      setTimeout(updateMessage, 5000);
    }

    // Pusher event triggered by successful import attempt
    pusherChannel.bind('shift_requirements_import_attempt.succeeded', function(data) {
      console.log('success')
      console.log(data)

      if(data.import_id === importID){
        updateMessage();
        updateCount();
      }
    }, this);

    // Pusher event triggered by failed import attempt
    pusherChannel.bind('shift_requirements_import_attempt.failed', function(data) {
      console.log('failure')
      console.log(data)

      if(data.import_id === importID){
        updateMessage();
        updateCount();
      }
    }, this);
  }

  /**
   * Update of ShiftRequirement attributes
   * Update tab counters, move to next tab, and delegate div element update and publish button toggle to departmentSchedule
   * @param {Object} shiftRequirement       ShiftRequirement
   */
  var updateShiftRequirement = function(shiftRequirement) {
    clearModalAlerts();
    var $requiredEmployeeCount = $('#required-employees-count');
    var previousEmployeeCount = parseInt($requiredEmployeeCount.html());

    updateCounter($requiredEmployeeCount, shiftRequirement.required_employees);
    departmentSchedule.updateShiftRequirement(shiftRequirement, false); // delegate cell update but don't close modal
    weeklyBudget.budgetEvents.trigger('weeklyBudget:calculate');
  };

  /**
   * Creation/deletion of ShiftRequirement Shifts
   * Update tab counters, and delegate div element update and publish button toggle to departmentSchedule
   * @param {Integer} id ShiftRequirement   id
   * @param {Boolean} hasUnpublishedShifts  True when assigning new Shifts
   */
  var updateShiftRequirementShifts = function(id, hasUnpublishedShifts) {
    return $.getJSON('/shift_requirements/' + id)
    .then(function(data) {
      var shiftRequirement = data.shift_requirement;
      updateCounter($('#scheduled-users-count'), shiftRequirement.shifts_count);
      updateCounter($('#unscheduled-users-count'), shiftRequirement.unscheduled_users_count);
      departmentSchedule.updateShiftRequirement(shiftRequirement, false); // delegate cell update but don't close modal
      departmentSchedule.togglePublishButton(hasUnpublishedShifts);
      return shiftRequirement;
    });
  };

  /**
   * Update and highight tab counters
   * @param {Object} $label   Span that contains the counter
   * @param {Integer} count   User count
   */
  var updateCounter = function($label, count) {
    $label.text(count);
    $label.closest('span.label').effect('highlight', {color: config.highlightColor}, config.highlightInterval);
  };

  return {
    init: init,
    initPusher: initPusher
  };

})(jQuery, window, document);
