import { analytics } from 'core/analytics/analytics.js';
import { am_pm_to_hours } from 'core/helpers/format_helpers.js';
window.analyticsTimeExceptions = (function($) {
  var config, errorDiv;
  var init = function(settings) {
    config = settings;
    $.extend(config, {
      selectors: {
        locationsDepartments: $('select#location-department-filter'),
        reportType: $('select#data-set-filter'),
        datePicker: $('#datepicker'),
        startDate: $('input#start-date'),
        endDate: $('input#end-date'),
        hourOverage: $('#hour_overage'),
        hourOverageThreshold: $('#hour_overage_threshold'),
        submitButton: $('.time-exceptions #submit-chart'),
        timeExceptions: $('#time-exceptions'),
      },
    });


    errorDiv = $('#analytics-error');

    initReportForm();
    initTimeExceptionsReport();
    analytics.subscribeToAnalyticsReportChannel(config.privatePusherChannel, 'time-exceptions', constructTimeExceptionsReport, displayPusherError);
  };

  var initReportForm = function () {
    config.selectors.datePicker.show();
    toggleSubmitButtonState();
    toggleHourOveragePresence();
  }

  var initTimeExceptionsReport = function() {
    analytics.initializePage($('.time-exceptions'), $(null));

    $('select#data-set-filter, select#location-department-filter, input#start-date, input#end-date').on('change', function() {
      toggleSubmitButtonState();
    });

    config.selectors.reportType.on('change', function() {
      toggleHourOveragePresence();
    });

    config.selectors.hourOverageThreshold.on('focusout', function() {
      var threshold = parseFloat($(this).val());
      var invalidThreshold = true;
      if (threshold && threshold > 0) {
        invalidThreshold = false;
      }

      if (invalidThreshold) {
        $(this).val('0');
      } else {
        // Limiting the value to 2 decimal places.
        $(this).val(threshold.toFixed(2));
      }
    });
  }

  var toggleSubmitButtonState = function() {
    if (config.selectors.locationsDepartments.val() && config.selectors.reportType.val() && config.selectors.startDate.val() && config.selectors.endDate.val()) {
      config.selectors.submitButton.removeClass('disabled');
      config.selectors.submitButton.unbind('click').on('click', getTimeExceptions);
    } else {
      config.selectors.submitButton.addClass('disabled');
      config.selectors.submitButton.unbind('click');
    }
  }

  var toggleHourOveragePresence = function() {
    if (_.include(config.selectors.reportType.val(), 'hour_overage')) {
      config.selectors.hourOverage.show();
    } else {
      config.selectors.hourOverage.hide();
    }
  }

  var getTimeExceptions = function() {
    var reportTypes = config.selectors.reportType.val();
    var locationDepartmentValues = config.selectors.locationsDepartments.val();
    if (!reportTypes || !locationDepartmentValues) {
      return;
    }
    hidePusherError();
    analytics.hideDateRangeError();
    var url = '/analytics/time_exceptions';
    analytics.showLoadingModal();
    var reportOptions = {
      'report_types': JSON.stringify(reportTypes),
      'hour_overage_threshold': parseFloat(config.selectors.hourOverageThreshold.val()),
    };
    analytics.selectCustomDateRange(url, ".time-exceptions");
    var analyticsPromise = analytics.makeAsyncChartDataRequest(url, reportOptions, "time-exceptions", "report-type-filter");
    analyticsPromise.fail(function (error) {
      analytics.hideLoadingModal();
      var errorMessage = error.responseJSON ? error.responseJSON.message : 'There was an error building the report';
      analytics.displayDateRangeError(errorMessage)
    });
  };

  var constructTimeExceptionsReport = function(analyticsReport) {
    var data = analyticsReport.data;
    var exportableRowCount = 0;
    errorDiv.hide();
    config.selectors.timeExceptions.html('');

    _.each(data.locations, function(location) {
      config.selectors.timeExceptions.append(
        "<hr/><h3>" + location.name + "</h3>"
      );
      _.each(location.departments, function(department) {
        var departmentTable = $("<div class='time-exceptions table-container'><table></table></div>");
        exportableRowCount += department.exceptions.length;
        config.selectors.timeExceptions.append(
          "<hr><h4>" + department.name + "</h4>"
        ).append(
          departmentTable
        ).append(
          timeExceptionsTable(departmentTable.find('table'), department)
        );
      });

      analytics.hideLoadingModal();
    });

    removeExportButton();
    if (exportableRowCount > 0) {
      addExportButton();
    }
  };

  var addExportButton = function() {
    var button = "<button class='export-btn'>" + I18n.t('buttons.export') + "</button>";
    $(".box-header").append(button);
    $('.export-btn').on('click', makeRequestForTimeExceptionsXlsx);
    return button;
  };

  var removeExportButton = function() {
    $(".box-header").find('.export-btn').remove();
  };

  var makeRequestForTimeExceptionsXlsx = function() {
    analytics.showLoadingModal();
    var exportOptions = {
      'report_types': JSON.stringify(config.selectors.reportType.val()),
      'hour_overage_threshold': parseFloat(config.selectors.hourOverageThreshold.val()),
    };
    var url = analytics.exportURL(exportOptions);
    $.ajax({
      url: encodeURI(url)
    });
  };

  var displayPusherError = function(data) {
    analytics.hideLoadingModal();
    errorDiv.show();
    errorDiv.text(data.message);
  };

  var hidePusherError = function() {
    $('#time-exceptions-error').hide();
    $('#time-exceptions-error').text('');
  };

  var timeExceptionsTable = function(table, departmentData) {
    var columns = [
      {sTitle: I18n.t('time_exceptions.js.employee_name'), mData: "employeeName"},
      {sTitle: I18n.t('time_exceptions.js.employee_id'), mData: "employeeId"},
      {sTitle: I18n.t('time_exceptions.js.date'), mData: "date"},
      {sTitle: I18n.t('time_exceptions.js.position'), mData: "position"},
      {sTitle: I18n.t('time_exceptions.js.job_site'), mData: "jobSite"},
      {sTitle: I18n.t('time_exceptions.js.clock_in_time'), mData: "clockInTime", iDataSort: 6},
      {sTitle: I18n.t('time_exceptions.js.hidden_clock_in_time'), mData: function(data) { return am_pm_to_hours(data.clockInTime); }, 'bVisible': false},
      {sTitle: I18n.t('time_exceptions.js.clock_out_time'), mData: "clockOutTime", iDataSort: 8},
      {sTitle: I18n.t('time_exceptions.js.hidden_clock_out_time'), mData: function(data) { return am_pm_to_hours(data.clockOutTime); }, 'bVisible': false},
      {sTitle: I18n.t('time_exceptions.js.shift_start_time'), mData: "shiftStartTime", iDataSort: 10},
      {sTitle: I18n.t('time_exceptions.js.hidden_clock_out_time'), mData: function(data) { return am_pm_to_hours(data.shiftStartTime); }, 'bVisible': false},
      {sTitle: I18n.t('time_exceptions.js.shift_end_time'), mData: "shiftEndTime", iDataSort: 12},
      {sTitle: I18n.t('time_exceptions.js.hidden_clock_out_time'), mData: function(data) { return am_pm_to_hours(data.shiftEndTime); }, 'bVisible': false},
      {sTitle: I18n.t('time_exceptions.js.breaks'), mData: "totalBreaks"},
      {sTitle: I18n.t('time_exceptions.js.overtime_hours'), mData: "overtimeHours"},
      {sTitle: I18n.t('time_exceptions.js.total_hours'), mData: "totalHours"},
    ];

    table.dataTable({
      bPaginate: true,
      bFilter: false,
      sPaginationType: "full_numbers",
      bLengthChange: false,
      iDisplayLength: 10,
      bInfo: false,
      bAutoWidth: false,
      aaData: departmentData.exceptions,
      aoColumns: columns,
      fnHeaderCallback: function(nHead, aData, iStart, iEnd, aiDisplay) {
        var headers = $(nHead).children('th');
        if (headers.find('i').length == 0) {
          headers.append($('<i class="fa fa-caret-down" aria-hidden="true"></i>'));
          headers.click(function(e){
            $(this).siblings().find("i").removeClass("fa-sort");
            $(this).find("i").addClass("fa-sort");
          });
        }
      },
      oLanguage: dataTables_i18n
    });

    table.append(
      "<tfoot>" +
        "<tr>" +
          "<td>" + departmentData['totalShifts'] + "</td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td></td>" +
          "<td>" + departmentData['totalHours'] + "</td>" +
        "</tr>" +
      "</tfoot>"
    );
  }

  return {
    init: init,
    constructTimeExceptionsReport: constructTimeExceptionsReport,
  }
})(jQuery);

