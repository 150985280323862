window.adminCompanies = (function($, window, document) {

  /* public */

 /*
  * Initializes all the things
  *
  * @param {Object} settings
  */
  var init = function(settings) {
    $.extend(true, config, settings, {
      selectors: {                        // Selectors available at init time
        trialEndsAt: $('#company_trial_ends_at'),
        reactivateButton: $('#reactivate_trial'),
        companyTrialGroup: $('#company_trial_ends_at_group')
      }
    });

    config.selectors.reactivateButton.on("click", function(){
      config.selectors.reactivateButton.hide();
      config.selectors.companyTrialGroup.show();
    });

    config.selectors.trialEndsAt.datepicker({
      format: 'yyyy-mm-dd',
      orientation: 'right auto',
      todayHighlight: true,
      startDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      autoclose: true,
      language: I18n.locale
    });
  };

  config = {};

  return {
    init:   init
  };

})(jQuery, window, document);
