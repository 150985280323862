import { reverse_shifts_time } from 'core/models/shift_type.js';
// tableCrud add-on for ShiftTypes
window.shiftTypeForm = (function($, window, document) {

  // public

  var init = function(settings) {
    $.extend(true, config, settings, {
      selectors: {}
    });

    initForms();
    initActions();
  };

  // private

  var config = {};

  // Handles coalescing of individual duration fields into a single breaks comma-delimited value
  var initForms = function() {
    $('body').on('submit', '.new-record-row form, .edit-record-row form', function(e) {
      var breaks = $('.shift-break-field:not(.hidden) input').map(function() { return $(this).val(); }).get();
      $('#shift_type_breaks_in_minutes').val(breaks);
    });
  };

  // Handles custom breaks field insertion/removal
  var initActions = function() {
    $('body').on('click', 'a[data-tr-action], button[data-tr-action]', function(e) {

      var $self = $(this);

      switch($self.data('tr-action')) {

      case 'save-template':
        var startTime = $("#shift_type_start_time");
        var endTime = $("#shift_type_end_time");
        reverse_shifts_time(startTime, endTime, 12);
        $(this).submit();

        if ($(this).error()) reverse_shifts_time(startTime, endTime, 24);
        break;

      case 'remove-break':
        e.preventDefault();
        $self.closest('.shift-break-field').remove();
        break;

      case 'add-break':
        e.preventDefault();
        var $template = $('.shift-break-field.hidden').clone().removeClass('hidden');
        $template.insertBefore($(this).closest('span'));
        $template.find('input').focus();
        break;
      }
    });
  };

  return {
    init: init
  };

})(jQuery, window, document);
