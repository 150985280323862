import { calendarHelper } from 'core/calendar_helper.js';
import { convert_pair } from 'core/models/shift_type.js';

export const userCalendarModals = {};

userCalendarModals.conflictsWithAnotherDepartmentDialog = function() {
  bootbox.alert(I18n.t('user_calendar_modal.js.cannot_overwrite_shifts'));
};

userCalendarModals.conflictsWithEventDialog = function() {
  bootbox.alert(I18n.t('user_calendar_modal.js.cannot_overwrite'));
};

userCalendarModals.conflictsWithUnavailableDialog = function() {
  bootbox.alert(I18n.t('user_calendar_modal.js.cannot_overwrite_time_off'));
};

userCalendarModals.conflictsWhenCreatingRotationsWarning = function() {
  bootbox.alert(I18n.t('user_calendar_modal.js.conflicting_rotations'));
};

userCalendarModals.promptForDeleteRotationDialog = function(existingRotation) {
  const dialogMessage =
    '<div><p>' +
    I18n.t('user_calendar_modal.js.overwrite_unsaved_rotation') +
    '</p></div>';

  const buttons = [];
  buttons.push({
    label: I18n.t('user_calendar_modal.js.unsaved_rotation_clear'),
    class: 'btn-red pull-left',
    callback: function() {
      userCalendar.deleteRotation(existingRotation);
    }
  });
  buttons.push({
    label: I18n.t('user_calendar_modal.js.unsaved_rotation_save'),
    class: 'btn-green',
    callback: function() {
      userCalendar.saveCalendarChanges();
    }
  });
  buttons.push({
    label: I18n.t('buttons.cancel'),
    class: 'btn-blue',
    callback: function() {}
  });

  const headerText = I18n.t('user_calendar_modal.js.unsaved_rotation');

  bootbox.dialog($(dialogMessage), buttons, { header: headerText });
};

userCalendarModals.duplicateShiftDialog = function() {
  bootbox.alert(I18n.t('user_calendar_modal.js.duplicate_shift'));
};

// User clicks on event which opens a modal with details of that event
userCalendarModals.eventClickDialog = function(event, isReadOnlySchedule = false) {
  const dialogMessage = ['<div class=\'row-fluid\'>'];
  const buttons = [];
  const localeColon = I18n.locale === 'fr' ? ' : ' : ': ';
  let headerText = `${I18n.t('xlsx.export.shift')}${localeColon}`;

  if (event.type === 'shift') {
    shiftContent(event, dialogMessage, isReadOnlySchedule);
  } else if (event.type === 'unavailable-request') {
    unavailableRequestContent(event, dialogMessage,isReadOnlySchedule);
    headerText = I18n.t('user_calendar_modal.js.time_off_request_header');
  } else if (event.type === 'unavailable' && event.unavailable_request_id) {
    unavailableRequestContent(event, dialogMessage,isReadOnlySchedule);
    headerText = I18n.t('user_calendar_modal.js.time_off_header');
  } else if (event.type === 'unavailable') {
    unavailableContent(event, dialogMessage,isReadOnlySchedule);
    headerText = I18n.t('user_calendar_modal.js.time_off_header');
  }
  dialogMessage.push('</div>');
  // End Row Fluid

  // Add buttons at bottom of modal
  addModalButtons(event, buttons,isReadOnlySchedule);

  // Modal Title with date
  headerText += getEventTime(event, true);

  // Create modal with content, buttons, and title
  bootbox.dialog($(dialogMessage.join('\n')), buttons, { header: headerText });

  const textarea = document.querySelector('#event_notes');
  if (textarea) {
    textarea.addEventListener('input', function(){
      const notesNotice = document.querySelector('#notes-length-notice');
      const currentLength = this.value.length;

      if (currentLength > 550) {
        notesNotice.style.display = 'block';
      } else {
        notesNotice.style.display = 'none';
      }
    });
  }
};

userCalendarModals.rotationClickDialog = function(addedRotation, event) {
  const dialogMessage = HandlebarsTemplates[
    'user_calendar/rotation_click_dialog'
  ]({
    department_id: addedRotation.department_id,
    calendar_user: MKS.calendar_user,
    start: $.fullCalendar.formatDate(
      new Date(addedRotation.start),
      'MMMM dd, yyyy'
    ),
    end: $.fullCalendar.formatDate(
      new Date(addedRotation.end),
      'MMMM dd, yyyy'
    ),
    rotation: I18n.t('user_calendar_modal.js.rotation'),
    employee_name: I18n.t('user_calendar_modal.js.employee_name')
  });

  const buttons = [];
  buttons.push({
    label: I18n.t('user_calendar_modal.js.delete_rotation'),
    class: 'btn-red pull-left',
    callback: function() {
      userCalendar.deleteRotation(addedRotation);
    }
  });
  buttons.push({
    label: I18n.t('buttons.cancel'),
    class: 'btn-blue',
    callback: function() {}
  });

  const headerText =
    I18n.t('user_calendar_modal.js.rotation') +
    ': ' +
    $.fullCalendar.formatDate(new Date(event.start), 'MMMM dd, yyyy');
  bootbox.dialog($(dialogMessage), buttons, { header: headerText });
};

userCalendarModals.waitMessageDialog = function() {
  const dialogMessage = ['<div>'];
  dialogMessage.push(
    '<p>' + I18n.t('user_calendar_modal.js.wait_message_dialog') + '</p>'
  );
  dialogMessage.push('</div>');

  const headerText = I18n.t('user_calendar_modal.js.please_wait');
  bootbox.dialog($(dialogMessage.join('\n')), [], { header: headerText });
  $('.bootbox')
    .find('.close')
    .remove();
};

userCalendarModals.datePicker = function(startDate, event, rotation) {
  const dialogMessage = ['<div>'];
  const rotationDays = Object.keys(rotation).length - 1;
  const nextDay = startDate
    .clone()
    .add(rotationDays)
    .day();

  dialogMessage.push(
    '<div class=\'datepicker\' id=\'dater\' data-date-format=\'yyyy-mm-dd\'>'
  );
  dialogMessage.push(
    '<input type=\'hidden\' id=\'endDate\' value=\'' +
      $.datepicker.formatDate('yy-mm-dd', nextDay) +
      '\' readonly text-align:center;\'></div>'
  );
  dialogMessage.push('</div>');

  const buttons = [];
  buttons.push({ label: I18n.t('buttons.cancel'), callback: function() {} });
  buttons.push({
    label: I18n.t('buttons.ok'),
    class: 'btn-blue',
    callback: function() {
      setupRotation(startDate, event, rotation);
    }
  });

  const headerText = I18n.t('user_calendar_modal.js.what_end_date');
  bootbox.dialog($(dialogMessage.join('\n')), buttons, { header: headerText });
  showDatePicker(startDate);
};

userCalendarModals.clearDatesDialog = function() {
  const dialogMessage = HandlebarsTemplates['user_calendar/clear_dates_dialog'](
    {
      by_performing: I18n.t('user_calendar_modal.js.by_performing'),
      existing_shifts: I18n.t(
        'user_calendar_modal.js.affected.existing_shifts'
      ),
      existing_days_off: I18n.t(
        'user_calendar_modal.js.affected.existing_days_off'
      ),
      available_shifts: I18n.t(
        'user_calendar_modal.js.affected.available_shifts'
      ),
      exchanges: I18n.t('user_calendar_modal.js.affected.exchanges'),
      greatly_alter: I18n.t('user_calendar_modal.js.greatly_alter'),
      clear_from: I18n.t('user_calendar_modal.js.clear_from'),
      to: I18n.t('user_calendar_modal.js.to')
    }
  );
  const buttons = [];
  buttons.push({
    label: I18n.t('buttons.cancel'),
    callback: function() {
      $('div#modal-alert').remove();
    }
  });
  buttons.push({
    label: I18n.t('buttons.ok'),
    class: 'btn-blue',
    callback: function() {

      const startDateStr = $('#date-range-start').val();
      const endDateStr   = $('#date-range-end').val();

      // Don't allow empty/invalid settings for date range start and end
      // Send focus back to start date and disallow closing
      if (!startDateStr) {
        $('#date-range-start')[0].focus();
        return false;
      } else if (!endDateStr) {
        $('#date-range-end')[0].focus();
        return false;
      }

      userCalendar.deleteEventsBetween(
        startDateStr,
        endDateStr,
        true, // Clearing Dates DOES clear time off and time off requests
        'clear_dates'
      );
    }
  });

  const headerText = I18n.t('user_calendar_modal.js.clear_schedule');

  bootbox.dialog(dialogMessage, buttons, { header: headerText });

  selectDateRangeToClear();
};

function getTranslatedEmployeeType() {
  // one of: "Full Time", "Part Time", "Casual"
  // if not one of those option return raw string
  const employmentType = MKS.calendar_user_employment_type;
  const translationMap = {
    'Full Time': 'employment_type.full_time',
    'Part Time': 'employment_type.part_time',
    Casual: 'employment_type.casual'
  };
  const employmentTypeTranslated = I18n.t(translationMap[employmentType]);

  if(!employmentTypeTranslated) {
    return employmentType;
  }

  return (
    (typeof employmentTypeTranslated === 'string' &&
      employmentTypeTranslated) ||
    '---'
  );
}

function selectDateRangeToClear() {
  const date = new Date();
  const now = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );

  // Allow deleting back to "yesterday" because calendar doesn't know about time zone
  // Clearing dates is based on a calendar day
  // If you are a day ahead in your system/browser time zone, you may not be able to clear
  // events that have yet to happen (but don't appear to be "today" to you)
  $('#date-range-start')
    .datepicker({
      autoclose: true,
      language: I18n.locale,
      weekStart: MKS.departmentStartOfWeek
    })
    .datepicker('setStartDate', now.addDays(-1), 'setDate', now)
    .on('changeDate', e => {
      const possibleEndDate = new Date(e.date);
      $('#date-range-end')
        .datepicker('setDate', possibleEndDate)
        .datepicker('setStartDate', possibleEndDate)
        .datepicker('update');

      $('#date-range-end')[0].focus();
    })
    .data('datepicker');

  $('#date-range-end')
    .datepicker({ autoclose: true, weekStart: MKS.departmentStartOfWeek, language: I18n.locale })
    .data('datepicker');
}

function showDatePicker(startDate) {
  $('.datepicker')
    .datepicker({ weekStart: MKS.departmentStartOfWeek, language: I18n.locale })
    .datepicker('setStartDate', startDate)
    .on('changeDate', function(e) {
      $(this)
        .parent()
        .find('input#endDate')
        .val($.datepicker.formatDate('yy-mm-dd', e.date));
    });
}

function setupRotation(startDate, event, rotation) {
  const endDateStr = $('input#endDate').val();
  $('.bootbox')
    .first()
    .modal('hide');

  userCalendarModals.waitMessageDialog();
  setTimeout(() => {
    userCalendar.createRotation(startDate, endDateStr, event, rotation);
    $('.bootbox')
      .first()
      .modal('hide');
    // userCalendarModals.conflictsWhenCreatingRotationsWarning();
  }, 1000);
}

// format_as_date is boolean which determines if it should be formatted as a date or time
function getEventTime(event, format_as_date) {
  format_as_date = format_as_date || false;
  const mStart = moment(event.start);
  const moEnd = moment(event.real_end);
  if (calendarHelper.isUnavailable(event) || format_as_date) {
    const locale = mStart.locale();
    if (locale === 'es') {
      // use a replace because moment doesn't support formatting without a trailing
      // period (on short form months)
      return mStart.format('dddd, D MMM').replace('.', '');
    }
    if (locale === 'fr') {
      return mStart.format('dddd D MMM');
    }
    return mStart.format('dddd, MMM D');
  }

  return event.hide_ends_at
    ? mStart.format('HH:mm') + ' - (' + moEnd.format('HH:mm') + ')'
    : mStart.format('HH:mm') + ' - ' + moEnd.format('HH:mm');
}

/**
 * Formats breaks based on if the event is persisted or a new event
 * @param  {fullCalendarEvent} event Current Full Calendar event
 * @return {String} Formatted label
 */
function formatBreaks(event) {
  if (event.formatted_breaks) {
    return event.formatted_breaks;
  } else if (event.breaks_in_minutes) {
    return formatUnformattedBreaks(event);
  } else {
    return '-';
  }
}

/**
 * Formats Shift Breaks the same way as the backend
 * @param  {fullCalendarEvent} event Current Full Calendar event
 * @return {String}       Formatted Label
 */
function formatUnformattedBreaks(event) {
  return _.chain(event.breaks_in_minutes.split(','))
    .reduce((hash, duration) => {
      hash[duration] = (hash[duration] || 0) + 1;
      return hash;
    }, {})
    .map((count, duration) => {
      let breakString = I18n.t('user_calendar_modal.js.num_minutes', {
        duration: duration
      });
      if (count > 1) breakString += ' (' + count + ')';
      return breakString;
    })
    .value()
    .join(', ');
}

///////// MODAL CONTENT and BUTTONS /////////

// Returns true if the event is a shift in the future
function showShiftFooter(event) {
  return (
    event.type != 'unavailable' &&
    event.type != 'unavailable-request' &&
    calendarHelper.persistedEvent(event) &&
    !calendarHelper.earlierDayThanToday(event.start)
  );
}

// Modal content for shift notes
function shiftNotes(event, isReadOnlySchedule = false) {
  var eventDescription = event.notes === null ? '' : event.notes;
  var disableNotes;
  if (
    !calendarHelper.isInCurrentDepartment(event) ||
    event.locked ||
    scheduleSyncError(event) ||
    isReadOnlySchedule
  ) {
    disableNotes = 'disabled';
  } else {
    disableNotes = '';
  }
  var shiftNotesArea =
    calendarHelper.isInCurrentDepartment(event) || eventDescription != ''
      ? '<p><strong>' +
        I18n.t('user_calendar_modal.js.notes_label') +
        '</strong></p><textarea id=\'event_notes\' type=\'text\' rows=\'5\' class=\'span12\' ' +
        disableNotes +
        '>' +
        eventDescription +
        '</textarea>' +
        '<span id=\'notes-length-notice\' class=\'text-error\' style=\'display:none\'>' +
        I18n.t('shifts.edit.notes_limit') +
        '</span>'
      : '';

  return shiftNotesArea;
}

// Modal content for shift position
function shiftPosition(event) {
  return $(HandlebarsTemplates['shared/p_strong_label_with_value']({
    label: I18n.t('user_calendar_modal.js.position'),
    value: event.position || '-'
  })).addClass('word-wrap').prop('outerHTML');
}

// Modal content for shift job site
function shiftJobSite(event) {
  return $(HandlebarsTemplates['shared/p_strong_label_with_value']({
    label: I18n.t('user_calendar_modal.js.job_site'),
    value: event.job_site || '-'
  })).addClass('word-wrap').prop('outerHTML');
}

// Modal content for shift time reporting code
function shiftTRC(trc) {
  return $(HandlebarsTemplates['shared/p_strong_label_with_value']({
    label: I18n.t('user_calendar_modal.js.time_reporting_code'),
    value: filterXSS(trc)
  })).addClass('word-wrap').prop('outerHTML');
}

// Modal content for shift pay code
function shiftPayCode(payCode) {
  return $(HandlebarsTemplates['shared/p_strong_label_with_value']({
    label: I18n.t('user_calendar_modal.js.pay_code'),
    value: filterXSS(payCode)
  })).addClass('word-wrap').prop('outerHTML');
}

function alertError(msg) {
  let list = '<div class=\'alert alert-error\'>';
  list += msg;
  list += '</div>';
  return list;
}

// Modal content for ADP Eti Error
function adpEtiError() {
  let list = '<div class=\'alert alert-error\'>';
  list += '<p>' + I18n.t('adp.shift_exporter.user_error_info_1') + '</p>';
  list += '<p>' + I18n.t('adp.shift_exporter.user_error_info_2') + '</p>';
  list += '<ul>';
  list += '<li>' + I18n.t('adp.shift_exporter.user_error_info_3') + '</li>';
  list += '<li>' + I18n.t('adp.shift_exporter.user_error_info_4') + '</li>';
  list += '<li>' + I18n.t('adp.shift_exporter.user_error_info_5') + '</li>';
  list += '<li>' + I18n.t('adp.shift_exporter.user_error_info_6') + '</li>';
  list += '</ul>';
  list += '<p>' + I18n.t('adp.shift_exporter.user_error_info_7') + '</p>';
  list += '</div>';

  return list;
}

// Modal content for a locked shift
function lockedShift() {
  let message = '<div class=\'alert alert-error\'>';
  message += I18n.t('integrations.shift_exporter.locked');
  message += '</div>';

  return message;
}

// Buttons associated for shift notes
function shiftNotesButton(event) {
  if (event.locked || scheduleSyncError(event)) {
    return null;
  } else {
    return {
      label: I18n.t('user_calendar_modal.js.save_shift_notes'),
      class: 'btn btn-green',
      callback: function() {
        userCalendar.submitNotes(
          event,
          $('#event_notes')
            .val()
            .trim()
        );
      }
    };
  }
}

// Modal content for Shift details
function shiftContent(event, dialogMessage, isReadOnlySchedule = false) {
  // Shift Details
  if (scheduleSyncError(event)) {
    if (event.schedule_integration === 'adp') {
      dialogMessage.push(adpEtiError());
    } else {
      const message = I18n.t(
        'integrations.' +
          event.schedule_integration +
          '.errors.' +
          event.shift_export_attempt.status_reason
      );
      dialogMessage.push(alertError(message));
    }
  } else if (event.locked) {
    dialogMessage.push(lockedShift());
  }

  dialogMessage.push('<div class=\'span6\'>');
  dialogMessage.push();
  if (event.type === 'shift') {
    let eventTime = getEventTime(event);

    eventTime = convert_pair(eventTime);

    dialogMessage.push(
      '<p><strong>' +
        I18n.t('location.location_label') +
        '</strong> ' +
        event.location_name +
        '</p>'
    );
    dialogMessage.push(
      '<p><strong>' +
        I18n.t('department.department_label') +
        '</strong> ' +
        event.department_name +
        '</p>'
    );
    dialogMessage.push(
      '<p><strong>' +
        I18n.t('user_calendar_modal.js.time_label') +
        '</strong> ' +
        eventTime +
        '</p>'
    );
    dialogMessage.push(
      '<p><strong>' +
        I18n.t('user_calendar_modal.js.breaks_label') +
        '</strong> ' +
        formatBreaks(event) +
        '</p>'
    );
    if (event.time_reporting_code && MKS.trc_enabled)
      dialogMessage.push(shiftTRC(event.time_reporting_code));
    if (event.pay_code && MKS.pay_code_enabled)
      dialogMessage.push(shiftPayCode(event.pay_code));
  }
  dialogMessage.push('</div>');
  // End Shift Details

  // Employee Details
  dialogMessage.push('<div class=\'span6\'>');
  dialogMessage.push(
    '<h4><strong>' +
      I18n.t('user_calendar_modal.js.employee_details') +
      '</strong></h4>'
  );
  dialogMessage.push('<p><strong>' + MKS.calendar_user + '</strong></p>');
  dialogMessage.push(
    '<p><strong>' +
      I18n.t('user_calendar_modal.js.employment_type_label') +
      '</strong> ' +
      getTranslatedEmployeeType() +
      '</p>'
  );
  dialogMessage.push('</div>');
  // End Employee Details

  // Shift Footer: hide ends at, position, and shift notes
  dialogMessage.push('<div class=\'span12\' style=\'margin-left: 0px;\'>');

  if (showShiftFooter(event)) {
    dialogMessage.push(shiftPosition(event));
    if (event.job_sites_enabled) {
      dialogMessage.push(shiftJobSite(event));
    }
    dialogMessage.push(shiftNotes(event,isReadOnlySchedule));
  }
  dialogMessage.push('</div>');
  // End Shift Footer
}

// Modal content for Unavailable (time off) details
function unavailableContent(event, dialogMessage) {
  dialogMessage.push('<p><strong>' + MKS.calendar_user + '</strong></p>');
  dialogMessage.push(
    '<p><strong>' +
      I18n.t('user_calendar_modal.js.employment_type_label') +
      '</strong> ' +
      getTranslatedEmployeeType() +
      '</p>'
  );
  dialogMessage.push(
    '<p><strong>' +
      I18n.t('user_calendar_modal.js.time_off_label') +
      '</strong> ' +
      event.duration +
      '</p>'
  );
  dialogMessage.push(
    '<p><strong>' +
      (!event.import_source || event.import_source == 'ms'
        ? I18n.t('user_calendar_modal.js.time_off_type_label')
        : I18n.t('activerecord.attributes.unavailable.unavailable_policy')
      ) +
      '</strong> ' +
      event.unavailable_type_name +
      '</p>'
  );
  if (event.admin_notes) {
    dialogMessage.push(
      '<p><strong>' +
        I18n.t('unavailable_requests.edit.admin_notes') +
        '</strong> ' +
        event.admin_notes +
        '</p>'
    );
  }
}

// Modal content for UnavailableRequest (time off request) details
function unavailableRequestContent(event, dialogMessage,isReadOnlySchedule) {
  dialogMessage.push('<p><strong>' + MKS.calendar_user + '</strong></p>');
  dialogMessage.push(
    '<p><strong>' +
      I18n.t('user_calendar_modal.js.employment_type_label') +
      '</strong> ' +
      getTranslatedEmployeeType() +
      '</p>'
  );
  dialogMessage.push(
    '<p><strong>' +
      I18n.t('user_calendar_modal.js.request_label') +
      '</strong> ' +
      event.duration +
      '</p>'
  );

  // I18n plural of "day"
  let time_off = '';
  if (event.annual_total === 1) {
    time_off = I18n.t('user_calendar_modal.js.time_off_single', {
      total: event.annual_total,
      year: event.start.getFullYear()
    });
  } else {
    time_off = I18n.t('user_calendar_modal.js.time_off_plural', {
      total: event.annual_total,
      year: event.start.getFullYear()
    });
  }

  dialogMessage.push(
    '<p><strong>' +
      ((event.import_source || event.import_source == 'ms')
        ? I18n.t('user_calendar_modal.js.time_off_type_label')
        : I18n.t('activerecord.attributes.unavailable.unavailable_policy')
      ) +
      '</strong> ' +
      event.unavailable_type_name +
      '</p>'
  );

  if (!event.import_source || event.import_source == 'ms') {
    dialogMessage.push(
      '<p><strong>' +
        I18n.t('user_calendar_modal.js.requested_label') +
        '</strong> ' +
        event.created_at +
        '</p>'
    );

    dialogMessage.push(
      '<p><strong>' +
        I18n.t('user_calendar_modal.js.annual_total_label', {
          type: event.unavailable_type_name
        }) +
        '</strong> ' +
        event.annual_total +
        '</p>'
    );
  }

  if (event.notes) dialogMessage.push('<p><strong>' + I18n.t('activerecord.attributes.unavailable_request.notes') + '</strong> ' + event.notes + '</p>');
  if (event.admin_notes) dialogMessage.push('<p><strong>' + I18n.t('unavailable_requests.edit.admin_notes') + '</strong> ' + event.admin_notes + '</p>');

  if (MKS.show_time_off && event.state === 'new' && !isReadOnlySchedule) {
    dialogMessage.push(
      '<em>' +
        I18n.t('user_calendar_modal.js.approve_request_link', {
          link:
            '<a href="' +
            window.location.origin +
            '/time_off_requests' +
            '">' +
            I18n.t('user_calendar_modal.js.time_off_requests_page') +
            '</a>'
        }) +
        '</em>'
    );
  }
}

// Modal buttons to be displayed
function addModalButtons(event, buttons,isReadOnlySchedule = false) {
  if(!isReadOnlySchedule){
    if (event.type === 'shift' && calendarHelper.isInCurrentDepartment(event)) {
      if (!shiftLocked(event)) {
        buttons.push({
          label: I18n.t('buttons.delete_subject', { subject: I18n.t('shift.shift') }),
          class: 'btn-red pull-left',
          callback: function() {
            userCalendar.deleteEvents([event]);
          }
        });
      }

      if (showShiftFooter(event) && shiftNotesButton(event)) {
        buttons.push(shiftNotesButton(event));
      }
    } else if (event.type === 'unavailable') {
      if (MKS.show_time_off && (!event.import_source || event.import_source == 'ms')) {
        buttons.push({
          label: I18n.t('user_calendar_modal.js.delete_time_off'),
          class: 'btn-red pull-left',
          callback: function() {
            userCalendar.deleteEvents([event]);
          }
        });
      }
    }
    buttons.push({
      label: I18n.t('buttons.cancel'),
      class: 'btn-blue',
      callback: function() {}
    });
  }else{
    buttons.push({
      label: I18n.t('buttons.close'),
      class: 'btn-grey',
      callback: function() {}
    });
  }
}

function shiftLocked(shift) {
  return shift.locked;
}

function scheduleSyncError(event) {
  return event.shift_export_attempt_error;
}
