<template>
  <div class="fatigue-rule inner-form" id="fatigue-rule-1">
    <div class="row-fluid">
      <span>{{I18n.t('fatigue_rule.js.an_employee')}}</span>
      <select name="fatigue_rule[rule_type]" class="fatigue-rule-type" :value="ruleType" @change="handleRuleSelection">
        <option value="should_work_no_more_than">{{I18n.t('fatigue_rule.js.not_work_more_than')}}</option>
        <option value="should_not_start_shift_within">{{I18n.t('fatigue_rule.js.not_start_shift_within')}}</option>
      </select>
      <input name="fatigue_rule[time_value]" type="number" class="fatigue-rule-time-value" v-model="timeValue" />
      <select name="fatigue_rule[time_unit]" class="fatigue-rule-time-unit" v-model="timeUnit">
        <option value="hours">{{I18n.t('fatigue_rule.js.hours')}}</option>
        <option value="days_in_a_row" :style="isVisible(ruleType, 'should_work_no_more_than')">{{I18n.t('fatigue_rule.js.days_in_a_row')}}</option>
        <option value="hours_in_a_row" :style="isVisible(ruleType, 'should_work_no_more_than')">{{I18n.t('fatigue_rule.js.hours_in_a_row')}}</option>
      </select>
      <select name="fatigue_rule[time_period]" class="fatigue-rule-time-period" v-model="timePeriod" :style="isTimePeriodHidden()">
        <option value="day">{{I18n.t('fatigue_rule.js.per_day')}}</option>
        <option value="week">{{I18n.t('fatigue_rule.js.per_week')}}</option>
        <option value="pay_period">{{I18n.t('fatigue_rule.js.per_pay_period')}}</option>
      </select>
      <span :style="isVisible(ruleType, 'should_not_start_shift_within')">&nbsp;{{I18n.t('fatigue_rule.js.after_previous')}}</span>
    </div>

    <div class="row-fluid" id="fatigue-rule-2">
      <div class="span6">
        <div class="fatigue-rules-positions">
          <label class="control-label">
            <strong>{{I18n.t('fatigue_rule.js.positions')}}</strong>
          </label>
          <select name="fatigue_rule[position_ids][]" ref="select2PositionsMultiple" class="select2-positions-multiple" multiple="multiple" autocomplete="off">
            <option v-for="position in positionList" :key="position.id" :value="position.id">
              {{position.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="span6">
        <div class="fatigue-rule-employment-types">
          <label class="control-label">
            <strong>{{I18n.t('fatigue_rule.js.employment')}}</strong>
          </label>
          <select name="fatigue_rule[employment_type_ids][]" ref="select2EmploymentTypesMultiple" class="select2-employment-types-multiple" multiple="multiple" autocomplete="off">
            <option v-for="employmentType in employmentTypeList" :key="employmentType.id" :value="employmentType.id">
              {{employmentType.name}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FatigueRule',
  props: {
    initialRuleType: {
      type: String,
      default: 'should_work_no_more_than',
    },
    initialTimeUnit: {
      type: String,
      default: 'hours',
    },
    initialTimePeriod: {
      type: String,
      default: 'day',
    },
    initialTimeValue: {
      // Can be a string or number
      type: [String, Number],
      default: 0,
    },
    selectedEmploymentTypeIds: {
      type: Array,
      default: () => [],
    },
    selectedPositionIds: {
      type: Array,
      default: () => [],
    },
    employmentTypeList: {
      type: Array,
      default: () => [],
    },
    positionList: {
      type: Array,
      default: () => [],
    },
    timeAndAttendance: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      ruleType:   this.initialRuleType,
      timeValue:  this.initialTimeValue,
      timeUnit:   this.initialTimeUnit,
      timePeriod: this.initialTimePeriod,
    }
  },
  mounted() {
    this.initSelect2(this.selectedPositionIds, 'position');
    this.initSelect2(this.selectedEmploymentTypeIds, 'employmentType');
  },
  methods: {
    initSelect2(selectedIds, type) {
      let select;
      let placeholder;
      if (type === 'position') {
        select = this.$refs.select2PositionsMultiple;
        placeholder = I18n.t('fatigue_rule.js.all_positions');
      } else if (type === 'employmentType') {
        select = this.$refs.select2EmploymentTypesMultiple;
        placeholder = I18n.t('fatigue_rule.js.all_employment');
      }
      $(select)
        .val(selectedIds)
        .select2({ placeholder, allowClear: true });
    },

    handleRuleSelection(e) {
      this.ruleType = e.target.value;
      if (['should_work_no_more_than', 'should_not_start_shift_within'].includes(e.target.value)) {
        this.timeUnit = 'hours';
      }
    },

    isVisible(state, value) {
      if (state === value) {
        return { display: 'inline-block' };
      }
      return { display: 'none' };
    },

    isTimePeriodHidden() {
      if (this.ruleType === 'should_not_start_shift_within' || this.timeUnit.indexOf('in_a_row') > -1) {
        return { display: 'none' };
      }
      return { display: 'inline-block' };
    }
  }
}
</script>
