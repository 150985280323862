<script>
  export default {
    name: 'StatutoryHolidayCalendar',
    props: {
      calendar: {
        type: Object,
        required: true
      },
      initialHolidays: {
        type: Array,
        required: true
      },
      multiplierOptions: {
        type: Array,
        required: true
      },
      holidayOptions: {
        type: Array,
        required: true
      },
      errorMessages: {
        type: Array,
        default() {
          return []
        }
      },
      startDate: {
        type: Date,
        default() {
          return moment('2023-01-01').toDate()
        }
      }
    },
    data() {
      return {
        holidays: Array.from(this.initialHolidays).map(({ date, holiday_name, multiplier, ...holiday }) => {
          return {
            ...holiday,
            holiday_name,
            multiplier,
            date,
            isEditing: false,
            isRemoved: false,
            isNew: false
          }
        })
      }
    },
    mounted() {
      this.initializeFormElements()
    },
    methods: {
      initializeFormElements() {
        for (let i = 0; i < this.holidays.length; i++) {
          const holiday = this.holidays[i]
          if (holiday.isRemoved || !holiday.isEditing) continue

          const nameSelector = this.buildInputPropertyForAttribute('id', i, 'holiday_name')
          const nameField = $(`#${nameSelector}`)
          nameField.val(holiday.holiday_name)

          nameField.on('change', function(e) {
            const dateAndName = e.val.split(':')
            let holiday_name = dateAndName.length === 2 ? dateAndName[1] : dateAndName[0]
            holiday.holiday_name = holiday_name
          })

          const dateSelector = this.buildInputPropertyForAttribute('id', i, 'date')
          const dateField = $(`#${dateSelector}`)
          dateField.val(holiday.date)

          dateField.on('change', function(e) {
            holiday.date = e.currentTarget.value
          })

          window.statHolidayForm.init({
            nameField,
            dateField,
            holidays: this.holidayOptions,
            startDate: this.startDate
          })
        }
      },
      toggleIsEditing(holiday = undefined) {
        this.holidays.forEach((_holiday) => {
          _holiday.isEditing = holiday && _holiday.id === holiday.id ? !_holiday.isEditing : false
        })
        this.$nextTick(() => this.initializeFormElements())
      },
      addHoliday() {
        const newLength = this.holidays.push({
          id: Date.now(),
          holiday_name: null,
          date: null,
          multiplier: '1.5',
          isEditing: false,
          isRemoved: false,
          isNew: true
        })
        this.$nextTick(() => this.toggleIsEditing(this.holidays[newLength - 1]))
      },
      removeHoliday(holiday) {
        this.holidays.forEach((_holiday) => {
          if (_holiday.id === holiday.id) {
            _holiday.isRemoved = true
          }
          _holiday.isEditing = false
        })
      },
      buildInputPropertyForAttribute(property, index, attributeName) {
        switch (property) {
          case 'name':
            return `statutory_holiday_calendar[statutory_holiday_days_attributes][${index}][${attributeName}]`
          case 'id':
            const holiday = this.holidays[index]
            const id = holiday?.id ?? index
            return `statutory_holiday_calendar_statutory_holiday_days_attributes_${id}_${attributeName}`
          default:
            return ''
        }
      }
    },
    computed: {
      transformedMultiplierOptions() {
        return this.multiplierOptions.map(([label, value]) => ({
          label,
          value: value.toFixed(1)
        }))
      }
    }
  }
</script>


<template>
  <div>
    <div v-if="errorMessages.length > 0" class="alert alert-error">
      <strong>Please review the problems below:</strong>
      <ul>
        <li v-for="error in errorMessages" :key="error">{{ error }}</li>
      </ul>
    </div>
    <div class="flex items-end justify-between">
      <div class="form-group">
        <label for="statutory_holiday_calendar_name">
          <abbr title="required">*</abbr> Name
        </label>
        <div class="control-group string required statutory_holiday_calendar_name">
          <div class="controls">
            <input
              type="text"
              v-model="calendar.name"
              class="string required form-control"
              name="statutory_holiday_calendar[name]"
              id="statutory_holiday_calendar_name"
              placeholder="Enter a name for the calendar"
            >
          </div>
        </div>
      </div>

      <div class="mb-4">
        <button type="button" class="btn btn-sm" @click="addHoliday">Add Statutory Holiday</button>
      </div>
    </div>

    <!-- Input fields for holidays -->
    <span v-for="(holiday, holidayIndex) in holidays" :key="holiday.id">
      <template v-if="!holiday.isNew">
        <input autocomplete="off" type="hidden" :value="holiday.id"
               :name="buildInputPropertyForAttribute('name', holidayIndex, 'id')">
        <input v-if="holiday.isRemoved" value="1" autocomplete="off" type="hidden"
               :name="buildInputPropertyForAttribute('name', holidayIndex, '_destroy')"
               :id="buildInputPropertyForAttribute('id', holidayIndex, '_destroy')">
      </template>
    </span>

    
    <!-- Holidays Table -->
    <table class="table table-condensed table-fixed holiday-table border border-gray-400">
      <thead>
      <tr>
        <th>Name</th>
        <th>Date</th>
        <th>Multiplier</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody id="holiday-days">
      <tr v-for="(holiday, holidayIndex) in holidays" :key="holiday.id">
        <template v-if="!holiday.isRemoved">
          <template v-if="holiday.isEditing">
            <td>
              <div class="control-group minimal-padding string required" style="margin-bottom: 5px;">
                <input
                  placeholder="Name"
                  class="string required holiday-input"
                  type="hidden"
                  autocomplete="off"
                  tabindex="-1"
                  :name="buildInputPropertyForAttribute('name', holidayIndex, 'holiday_name')"
                  :id="buildInputPropertyForAttribute('id', holidayIndex, 'holiday_name')"
                >
              </div>
            </td>
            <td>
              <div class="control-group string optional" style="margin-bottom: 5px;">
                <div class="controls">
                  <input
                    placeholder="Select date"
                    class="string optional datepicker holiday-input"
                    style="height: 30px"
                    readonly="readonly"
                    type="text"
                    :id="buildInputPropertyForAttribute('id', holidayIndex, 'date')"
                    :name="buildInputPropertyForAttribute('name', holidayIndex, 'date')"
                  >
                </div>
              </div>
            </td>
            <td>
              <div class="control-group select optional" style="margin-bottom: 5px;">
                <div class="controls">
                  <select
                    v-model="holiday.multiplier"
                    class="select optional holiday-input"
                    :id="buildInputPropertyForAttribute('id', holidayIndex, 'multiplier')"
                    :name="buildInputPropertyForAttribute('name', holidayIndex, 'multiplier')"
                  >
                    <option v-for="({label, value}) in transformedMultiplierOptions" :key="value" :value="value">
                      {{ label }}
                    </option>
                  </select>
                </div>
              </div>
            </td>
          </template>
          <template v-else>
            <td>
              <span>{{ holiday.holiday_name }}</span>
              <input :value="holiday.holiday_name" type="hidden"
                     :name="buildInputPropertyForAttribute('name', holidayIndex, 'holiday_name')">
            </td>
            <td>
              <span>{{ holiday.date }}</span>
              <input :value="holiday.date" type="hidden"
                     :name="buildInputPropertyForAttribute('name', holidayIndex, 'date')">
            </td>
            <td>
              <span>{{ holiday.multiplier }}</span>
              <input :value="holiday.multiplier" type="hidden"
                     :name="buildInputPropertyForAttribute('name', holidayIndex, 'multiplier')">
            </td>
          </template>
          <td>
            <div class="table-actions">
              <button :id="`toggle-editing-holiday-${holiday.id}`" type="button" class="btn btn-small"
                      @click="toggleIsEditing(holiday)">
                {{ holiday.isEditing ? 'Cancel' : 'Edit' }}
              </button>
              <button :id="`remove-holiday-${holiday.id}`" type="button" class="btn btn-small btn-red"
                      @click="removeHoliday(holiday)">Remove
              </button>
            </div>
          </td>
        </template>
      </tr>
      <tr class="empty-row">
        <td colspan="4">No holidays have been created.</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss">
.statutory_holiday_calendar_name .help-inline{
    display: flex;
  }
  .holiday-table {
    tbody tr th, tbody tr td {
      padding: 4px;
    }

    thead th, tbody td {
      &:nth-child(1) {
        width: 40%;
      }

      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3), &:nth-child(4) {
        width: 15%;
      }
    }

    .table-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 4px;
    }

    .table-actions button {
      width: 65px;
    }

    .holiday-input {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 0 !important;
      display: block;
    }
    
    .input-error {
      border-color: red;
      box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
    }

    .label-error {
      display: block;
    }
  }
  
</style>
