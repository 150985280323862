import { messaging } from 'core/messaging.js';
/*
 * Model that represents a Conversation
 *
 * @param {Object} data JSON attributes
 *
 * @return {Conversation}
 */
export var Conversation = function(data) {
  this.id         = data.id;
  this.messageIds = data.message_ids;
  this.rendered   = false;
  this.updatedAt  = data.updated_at;
  this.unread     = data.unread;
  this.userIds    = data.user_ids;
};

Conversation.prototype = {

  /*
   * Associated Messages in chronological order
   */
  messages: function() {
    return _.sortBy(messaging.store.findMessages(this.messageIds), function(message) {
      return message.createdAt;
    });
  },

  /*
   * Associated Users in alphabetical order
   */
  users: function() {
    return _.sortBy(messaging.store.findUsers(this.userIds), function(user) {
      return user.name;
    });
  },

  /*
   * Associated Users in alphabetical order, excluding current_user
   */
  recipients: function() {
    return _.filter(this.users(), function(user) {
      return user.id != messaging.store.currentUserId;
    });
  },

  /*
   * Add Message association, update timestamp, and trigger DOM update
   *
   * @param {Object} params Updated key/values
   */
  associateMessage: function(message) {
    this.messageIds.push(message.id);
    this.updatedAt = message.createdAt;
    messaging.events.trigger('conversation:updated', [this]);
  },

  /*
   * Mark Conversation as read and trigger DOM update
   */
  read: function() {
    if (!this.unread) { return; }
    this.unread = false;
    messaging.events.trigger('conversation:read', [this]);
  },

  /*
   * Update Conversation and trigger DOM update
   *
   * @param {Object} data Updated key/values
   */
  update: function(data) {
    this._setCamelizedProperties(data);
    messaging.events.trigger('conversation:updated', [this]);
  },

  /*
   * Render show/new template based on persistsnce:
   * If persisted,render show template with recipients, last message preview, and unread status
   * If new object, render "New Message" placeholder template
   */
  renderHeader: function() {
    if (this.id) {
      this.rendered = true;
      var lastMessage = _.last(this.messages());
      var alphabeticalUsers = this.recipients();
      // var lastMessageUserIndex;
      // _.each(alphabeticalUsers, function(user, index) {
      //    if (user.id == lastMessage.userId) {
      //       lastMessageUserIndex = index;
      //       return;
      //    }
      // });
      // //TODO: this only partially works. If current_user is owner of last message, list will just be alphabetical again
      // alphabeticalUsers.splice(0, 0, alphabeticalUsers.splice(lastMessageUserIndex, 1)[0]);

      return HandlebarsTemplates['conversations/show']({
        conversation: this,
        lastMessage:  lastMessage,
        recipients:   _.pluck(alphabeticalUsers, 'name').join(', ')
      });
    } else {
      return HandlebarsTemplates['conversations/new']({ new_message: I18n.t('conversations.js.new_message') });
    }
  },

  /*
   * Sets camelized properties on object from a data source using underscores
   *
   * @param {Object} data Non-camelized key/value pairs
   * @todo Move into base prototype
   */
  _setCamelizedProperties: function(data) {
    var _this = this;
    _.each(data, function(value, key) {
      _this[key.camelize()] = value;
    });
  }

};
