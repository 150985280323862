import { flashMessages } from 'core/helpers/flash_messages.js'
import { msUtil } from 'core/ms_util.js'
window.payrollItems = (function ($, window, document) {
  /*
  public
  */

  /**
   * Initializes Location PayRoll
   * @param  {Hash} settings Location specific configurations
   */
  var init = function (settings) {
    $.extend(config, {
      data: {
        currentLocationId:        settings.locationId,
        isEnterprise:             settings.isEnterprise,
      },
      paymentProviders: settings.paymentProviders,
      privatePusherChannel: settings.privatePusherChannel,
      pusherKey: settings.pusherKey,
      selectors: {
        departmentButton: $('#department-timesheet-link'),
        exportAlertsDiv: $('#export-alerts'),
        exportButton: $('#export-payroll'),
        exportContainer: $('.add-btn'),
        failureMessage: $('#payroll-processing-error'),
        locationTimesheetTable: $('#location-timesheets-box'),
        modal: $('#payroll-modal'),
        paymentProviderFilter: $('#payroll-provider-filter'),
        payPeriodFilter: $('#pay-period-filter'),
        payPeriodCloseBtn: $('#pay-period-close'),
        payrollTable: $('#location-payroll-box'),
        processingBtn: $('#processing-btn'),
        tooltips: '[data-toggle=tooltip]'
      },
      selected: {
        //store select export format  if one is specified
        provider: null
      },
      routes: {
        closePayPeriodPath: function(payPeriodId) {
          return '/pay_periods/'+ payPeriodId +'/' + (config.data.isEnterprise ? 'close_enterprise' : 'close');
        },
        departmentTimesheetPath: function (id, payPeriodId) {
          return '/departments/' + id + '/timesheets?pay_period_id=' + payPeriodId
        },
        exportPayrollPath: function (providerName, payPeriodId) {
          var path = '/locations/' + config.data.currentLocationId + '/payroll/export?pay_period_id=' + payPeriodId + '&provider=' + providerName
          return path
        },
        locationPayrollsPath: function (payPeriodId) {
          var url = '/locations/' + config.data.currentLocationId + '/payroll'
          if (payPeriodId) {
            url += '?pay_period_id=' + payPeriodId
          }
          return url
        },
        userPath: function (id) {
          return '/users/' + id
        }
      }
    })

    initPayPeriodFilter()
    initPaymentProviderFilter()
    initPopState()
    initPusher()
    initActions()

    loadPayPeriod(location.href, true)
  }

  /*
  private
  */

  /* Default settings */
  var config = {
    cssClasses: {
      closedTimesheet: 'text-success',
      openTimesheet: 'text-error'
    },

    data: {                           // Persisted data and interface state for current pay period
      currentLocationId:        null, // Sets the currently selected Location and PayPeriod
      currentPayPeriodId:       null, // Sets the current PayPeriod ID
      payPeriodData:            null, // Holds Pay Period Data for Location
      payPeriodStatus:          null, // Sets the current PayPeriod State
      locationHasExceptions:    null, // Boolean for when a Location has exceptions.  This is only triggered after a PayPeriod has been closed.
      isEnterprise:             null, // Boolean for an Enterprise Company
    },
    decimalPlaces: {
      currency: 2,
      hours: 2
    }
  }

  /**
   * Builds selection values for the Payment Provider Filter
   * @param  {Array} paymentProviders Payment Provider names
   * @return {Array}                  Array with provider names and their index as an id
   */
  var buildSelectionsForPaymentProviders = function (paymentProviders) {
    var paymentProviderList = _.map(paymentProviders, (provider) => {
      var label = I18n.t('payroll.payment_providers.' + provider) === undefined ? provider : I18n.t('payroll.payment_providers.' + provider)
      return { id: paymentProviders.indexOf(provider) + 1, text: label, name: provider }
    })
    var sortedProvidersByDisplayName = paymentProviderList.sort((a, b) => {
      const textA = a.text.toUpperCase()
      const textB = b.text.toUpperCase()
      if (textA < textB) {
        return -1
      }
      if (textA > textB) {
        return 1
      }
      return 0
    })

    return sortedProvidersByDisplayName
  }

  /**
   * Calculates Sum of PayrollItems Array based on PayrollItem#attribute
   * @param  {Array} payrollItems   PayrollItems for the PayPeriod
   * @param  {String} attributeName Attribute to pluck from PayrollItem
   * @return {Float}                Sum (.00)
   */
  var calculateSum = function (payrollItems, attributeName) {
    return _.chain(payrollItems)
      .pluck(attributeName)
      .reduce((memo, num) => {
        return memo + num
      }, 0)
      .value()
      .toFixed(config.decimalPlaces.currency)
  }

  /**
   * Calculates Sum in Dollars of PayrollItems Array based on PayrollItem#attribute
   * @param  {Array} payrollItems   PayrollItems for the PayPeriod
   * @param  {String} attributeName Attribute to pluck from PayrollItem
   * @return {String}               Sum represented as a dollar figure
   */
  var calculateSumAsCurrency = function (payrollItems, attributeName) {
    return I18n.toCurrency(calculateSum(payrollItems, attributeName))
  }

  /**
   * Checks if any Users are within the User Array, disables close button and creates a warning.
   * @param  {Array} users User Arrray
   */
  var checkFlaggedUsersAndProvideWarning = function (users) {
    var message,
      usersWithoutWages = []
    var maxDisplayed = 10

    if (users.length) {
      config.selectors.payPeriodCloseBtn.addClass('hidden')

      _.each(users, (user, index) => {
        if (index < maxDisplayed) {
          usersWithoutWages.push(userLinkBuilder(user))
        }
      })

      message = I18n.t('payroll.users_without_wages', { count: users.length })

      if (users.length > maxDisplayed) {
        usersWithoutWages.push(users.length - maxDisplayed + ' others')
      }

      message = message + ' ' + usersWithoutWages.toSentence()

      flashMessages.setFlash(message, 'danger')
    }
  }

  /**
   * Creates User Links for the PayRollItem
   * @param  {Object} user PayRollItem User
   * @return {String}      URL to User Profile
   */
  var departmentLinkBuilder = function (department) {
    return '<a href="' + config.routes.departmentTimesheetPath(department.id, config.data.currentPayPeriodId) + '">' + department.name + '</a>'
  }

  /**
   * Builds Employee ID portion of a User link.
   * @note If a user does not have an employee ID, an icon indicating this will be shown instead.
   *
   * @param  {String} employeeId User's employee_id
   * @return {String}            HTML for a User's Employee ID
   */
  var employeeIdBuilder = function (employeeId) {
    var employeeIdHtml = ''
    if (employeeId?.length) {
      employeeIdHtml += ' (' + filterXSS(employeeId) + ')'
    } else {
      config.data.locationHasExceptions = true
      employeeIdHtml +=
        ' <i class="fa fa-exclamation-triangle text-error pull-right" data-toggle="tooltip" data-container="body" data-placement="right" title="' +
        I18n.t('payroll.blank_employee_id') +
        '"></i>'
    }
    return employeeIdHtml
  }

  /**
   * Initializes action bindings
   */
  var initActions = function () {
    $('body')
      .on('click', '[data-refresh=true]', (e) => {
        e.preventDefault()
        loadPayPeriod(location.href)
        flashMessages.clear()
      })

      .on('click', '#pay-period-close', function (e) {
        e.preventDefault()
        var $this = $(this)
        bootbox.confirm(
          '<h4>' +
            I18n.t('payroll.close_pay_period.title') +
            '</h4>' +
            I18n.t('payroll.close_pay_period.confirmation', { pay_period_range: config.data.payPeriodData.pay_period.date_range }),
          I18n.t('buttons.cancel'),
          I18n.t('buttons.ok'),
          (result) => {
            if (result) {
              $.ajax({
                beforeSend: function () {
                  config.data.payPeriodStatus = 'processing'
                  setUiBasedOnPayPeriodStatus()
                },
                url: config.routes.closePayPeriodPath(config.data.currentPayPeriodId),
                data: { format: 'json' },
                dataType: 'json',
                method: 'PUT',
                cache: false
              }).then(
                (data) => {},
                (xhr, status, error) => {
                  loadPayPeriod(location.href)
                  var alertMessage = xhr.responseText.trim()
                  if (alertMessage.length > 0) {
                    flashMessages.setFlash(JSON.parse(alertMessage).error_message, 'danger')
                  }
                }
              )
            }
          }
        )
      })
  }

  /**
   * Initializes the Select2 Pay Period Filter and sets History Path
   */
  var initPayPeriodFilter = function () {
    config.selectors.payPeriodFilter.select2().on('change', (e) => {
      config.data.currentPayPeriodId = parseInt(e.val)
      History.pushState({}, 'Location Payroll', config.routes.locationPayrollsPath(config.data.currentPayPeriodId))
      config.selectors.exportButton.prop('disabled', true)
    })
  }

  /**
   * Initializes the Payment Provider Select2
   */
  var initPaymentProviderFilter = function () {
    config.selectors.exportButton.on('click', (e) => {
      var url = config.routes.exportPayrollPath(config.selected.provider, config.data.currentPayPeriodId)
      $.get({
        url: url,
        dataType: 'json'
      })
      showLoadingModal()
    })

    config.selectors.paymentProviderFilter
      .select2({
        data: buildSelectionsForPaymentProviders(config.paymentProviders),
        allowClear: true,
        placeholder: I18n.t('payroll.payment_providers.placeholder')
      })
      .on('change', (e) => {
        if (e.val.length) {
          config.selected.provider = e.added.name

          config.selectors.exportButton.prop('disabled', false)
        } else {
          config.selected.provider = null

          config.selectors.exportButton.prop('disabled', true)
        }
      })
  }

  var showLoadingModal = function () {
    var loadingModal = HandlebarsTemplates['shared/processing_modal']({
      loadingHeader: I18n.t('payroll.exports.loading_modal_header'),
      loadingMessage: I18n.t('payroll.exports.loading_modal_message'),
      loadingWarning: I18n.t('payroll.exports.loading_modal_warning'),
      loadingWarningMessage: I18n.t('payroll.exports.loading_modal_warning_message')
    })
    var loadingDiv = $('#payroll-export-loading')

    loadingDiv.html(loadingModal)
    loadingDiv.show()
  }

  var hideLoadingModal = function () {
    // Keep the modal displayed at least for one second in case the download is really fast. Prevent a 'blinking' modal.
    setTimeout(() => {
      $('#payroll-export-loading').hide()
    }, 1000)
  }

  /**
   * Returns Location PayPeriod data based on an ID, loads data and builds UI elements
   * @param  {Integer} id Department ID
   * @param  {Boolean} firstLoad
   * @return {Promise}    jQuery Promise
   */
  var loadPayPeriod = function (url, firstLoad) {
    var spinner = msUtil.spinner('location-payroll-box')

    // if this is the first (page load) we don't want to empty these messages
    if (!firstLoad) {
      flashMessages.clear()
    }

    config.selectors.locationTimesheetTable.find('tbody tr:not(.empty-row)').remove()

    return $.getJSON(url + '.json').then((response) => {
      config.data.locationHasExceptions = false
      config.selectors.payPeriodFilter.select2('val', response.pay_period.id)
      config.selectors.paymentProviderFilter.select2('val', 0)
      config.data.currentPayPeriodId = response.pay_period.id
      config.data.payPeriodStatus = response.pay_period.status
      config.data.payPeriodData = response
      setUiBasedOnPayPeriodStatus()

      checkFlaggedUsersAndProvideWarning(config.data.payPeriodData.flaggedUsers)

      // Quick (hack) fix to update the URL for the ADP Punch Export link
      // The Close Pay Period button is dynamic by virtue of the page URL changing (without a full refresh)
      var syncLink = $('#sync-link')
      if (syncLink.length) {
        var currentLink = syncLink.attr('href')
        var newLink = currentLink.replace(/pay_period_id=\d+/, 'pay_period_id=' + response.pay_period.id)
        syncLink.attr('href', newLink)
      }

      $('.spinner').fadeOut(300, () => {
        spinner.stop()
      })

      return config.data.payPeriodData
    })
  }

  /**
   * Initializes Pusher
   */
  var initPusher = function () {
    var pusher = window.getPusherInstance(config.pusherKey)

    // Subscribe to private user pusherChannel and listen for a refresh event
    var pusherChannel = pusher.subscribe('location-' + config.data.currentLocationId)

    // Pusher event triggered by successful timesheet update
    pusherChannel.bind('pay_period.processed', (data) => {
      if (config.data.currentPayPeriodId === data.pay_period_id) {
        loadPayPeriod(location.href)
        flashMessages.success(data.message)
      }
    })
    pusherChannel.bind('pay_period.error', (data) => {
      if (config.data.currentPayPeriodId === data.pay_period_id) {
        loadPayPeriod(location.href)
      }
    })

    config.privatePusherChannel.bind('payroll-export-success', (data) => {
      window.location.href = data.export_uri
      hideLoadingModal()
    })

    config.privatePusherChannel.bind('payroll-export-failure', (data) => {
      flashMessages.setFlash(data.message, 'danger')
      hideLoadingModal()
    })
  }

  /**
   * Handles ajax reloading of page contents from pay period navigation
   */
  var initPopState = function () {
    History.Adapter.bind(window, 'statechange', () => {
      var state = History.getState()
      loadPayPeriod(state.url)
    })
  }

  /**
   * Builds PayRoll Item Rows
   * @param  {Object} payrollItem PayrollItem
   * @return {String}             HTML Table Row
   */
  var payRollTableRowWriter = function (payrollItem) {
    var tableRow = '<tr>'
    tableRow += '<td>' + userLinkBuilder(payrollItem.user) + ' ' + employeeIdBuilder(payrollItem.user.employee_id) + '</td>'
    tableRow += '<td>' + I18n.toNumber(payrollItem.regular_hours.toFixed(config.decimalPlaces.hours), { precision: 2 }) + '</td>'
    tableRow += '<td>' + I18n.toNumber(payrollItem.overtime_hours.toFixed(config.decimalPlaces.hours), { precision: 2 }) + '</td>'
    tableRow += '<td>' + I18n.toNumber(payrollItem.stat_holiday_hours.toFixed(config.decimalPlaces.hours), { precision: 2 }) + '</td>'
    tableRow += '<td>' + I18n.toNumber(payrollItem.total_hours.toFixed(config.decimalPlaces.hours), { precision: 2 }) + '</td>'
    tableRow += '<td>' + I18n.toCurrency(payrollItem.labour_cost.toFixed(config.decimalPlaces.currency)) + '</td>'
    tableRow += '</tr>'
    return tableRow
  }

  /**
   * Builds PayRoll Total Row
   * @param  {Array} payrollItems PayrollItems for the PayPeriod
   * @return {String}             HTML Table Row
   */
  var payRollTableTotalRowWriter = function (payrollItems) {
    var tableRow = '<tr class="total">'
    tableRow += '<td>' + I18n.t('payroll_items.js.total') + '</td>'
    tableRow += '<td>' + I18n.toNumber(calculateSum(payrollItems, 'regular_hours'), { precision: 2 }) + '</td>'
    tableRow += '<td>' + I18n.toNumber(calculateSum(payrollItems, 'overtime_hours'), { precision: 2 }) + '</td>'
    tableRow += '<td>' + I18n.toNumber(calculateSum(payrollItems, 'stat_holiday_hours'), { precision: 2 }) + '</td>'
    tableRow += '<td>' + I18n.toNumber(calculateSum(payrollItems, 'total_hours'), { precision: 2 }) + '</td>'
    tableRow += '<td>' + calculateSumAsCurrency(payrollItems, 'labour_cost') + '</td>'
    tableRow += '</tr>'
    return tableRow
  }

  /**
   * Renders the Table Rows for a Location within a PayPeriod
   *
   * @description Renders all PayrollItems for a PayPeriod. After which if a Employee is flagged as having exceptions then a exception row is added to the top of the table.
   */
  var renderPayRollItemsForPayPeriod = function () {
    var payrollItems = config.data.payPeriodData.payroll_items
    config.selectors.payrollTable.find('tbody tr:not(.empty-row)').remove()

    _.each(payrollItems, (payrollItem) => {
      config.selectors.payrollTable.find('.empty-row').before(payRollTableRowWriter(payrollItem))
    })

    config.selectors.payrollTable.find('.empty-row').before(payRollTableTotalRowWriter(payrollItems))

    // Display warning messages that some employees are missing employee ids and it will affect the export.
    if (config.data.locationHasExceptions) {
      config.selectors.payrollTable.find('tbody').prepend(tableExceptionRowWriter())
      flashMessages.setFlash(I18n.t('payroll.blank_employee_ids'), 'warning')
    }

    $(config.selectors.tooltips).tooltip()
  }

  /**
   * Hides/Shows all visual elements based on the state of the PayPeriod
   */
  var setUiBasedOnPayPeriodStatus = function () {
    config.selectors.exportButton.addClass('hidden')
    config.selectors.paymentProviderFilter.addClass('hidden')
    config.selectors.payPeriodCloseBtn.addClass('hidden')
    config.selectors.processingBtn.addClass('hidden')
    config.selectors.payrollTable.addClass('hidden')
    config.selectors.failureMessage.addClass('hidden')

    var timesheetRows = []
    _.each(config.data.payPeriodData.departments, (department) => {
      timesheetRows.push(timesheetsTableRowWriter(department))
    })
    config.selectors.locationTimesheetTable.find('tbody tr:not(.empty-row)').remove()
    config.selectors.locationTimesheetTable.find('tbody').prepend(timesheetRows)

    switch (config.data.payPeriodStatus) {
      case 'closed':
        config.selectors.exportButton.removeClass('hidden')
        config.selectors.paymentProviderFilter.removeClass('hidden')
        config.selectors.payrollTable.removeClass('hidden')

        renderPayRollItemsForPayPeriod()
        break
      case 'processing':
        config.selectors.processingBtn.removeClass('hidden')
        config.selectors.payrollTable.find('tbody tr:not(.empty-row)').remove()
        config.selectors.payrollTable
          .find('.empty-row')
          .before(
            tableProcessingRowWriter(
              I18n.t('payroll.location.being_processed') +
                '<br>' +
                I18n.t('payroll.location.time_estimate') +
                '<br>' +
                I18n.t('payroll.location.check_back')
            )
          )
        config.selectors.payrollTable.removeClass('hidden')
        var confirmation_button = $('.modal-footer a.btn-primary')
        confirmation_button.addClass('disabled')
        confirmation_button.prop('disabled', true)
        break
      case 'failed':
        config.selectors.processingBtn.addClass('hidden')
        config.selectors.payrollTable.addClass('hidden')
        config.selectors.failureMessage.removeClass('hidden')
        config.selectors.payrollTable.find('tbody tr:not(.empty-row)').remove()
        break
      default:
        config.selectors.payPeriodCloseBtn.removeClass('hidden')
    }
  }

  /**
   * Builds Timesheet Status Indicator
   * @param  {Object} department Department Object
   * @return {String}            HTML
   */
  var timesheetsTableRowWriter = function (department) {
    var timesheet = _.findWhere(config.data.payPeriodData.timesheets, { department_id: department.id })
    timesheet = timesheet || { id: null, approver_name: '' }
    var tableRow = '<tr>'

    if (timesheet.id) {
      tableRow += '<td><i class="fa fa-check-circle-o fa-lg"></i>     ' + I18n.t('activerecord.attributes.timesheet.states.approved') + '</td>'
    } else {
      tableRow += '<td><i class="fa fa-circle-o fa-lg"></i>     ' + I18n.t('activerecord.attributes.timesheet.states.open') + '</td>'
    }

    tableRow += '<td>' + departmentLinkBuilder(department) + '</td>'
    tableRow += '<td>' + timesheet.approver_name + '</td>'

    if (timesheet.id) {
      tableRow += '<td>' + timesheet.created_at + '</td>'
    } else {
      tableRow += '<td></td>'
    }
    tableRow += '</tr>'

    return tableRow
  }

  /**
   * Creates processing table row for when a Department timesheet is being processed
   * @return {String} HTML
   */
  var tableProcessingRowWriter = function (processing_text) {
    return '<tr class="empty-state"><td colspan="7" class="text-center">' + processing_text + '</td></tr>'
  }

  /**
   * Creates Exception table row for when a User within a Location does not have an employee_id
   * @return {String} HTML
   */
  var tableExceptionRowWriter = function () {
    return (
      '<tr class="footer-legend"><td colspan="7" class="text-center"><i class="fa fa-exclamation-triangle text-error"></i> - ' +
      I18n.t('payroll.location.contains_blank_employee_ids') +
      '</td></tr>'
    )
  }

  /**
   * Creates User Links for the PayRollItem
   * @param  {Object} user PayRollItem User
   * @return {String}      URL to User Profile
   */
  var userLinkBuilder = function (user) {
    return '<a href="' + config.routes.userPath(user.id) + '">' + filterXSS(user.name) + '</a>'
  }

  return {
    init: init
  }
})(jQuery, window, document)
