<template>
  <img
    v-if="show"
    class="ui-spinner"
    v-bind:class="{ 'absolute': absolute }"
    src="~images/icons/spinner.svg" 
  />
</template>

<script>
export default {
  name: 'UiSpinner',
  props: {
    show: Boolean,
    absolute: Boolean
  },
  example: `
<template>
  <div>
    <h4 class="my-6 border-b">UiSpinner</h4>
    <div class="relative w-full h-32 border border-dashed">
      <ui-spinner show absolute></ui-spinner>
    </div>
  </div>
</template>

<script>
  export default {}
<\/script>
`
}
</script>

<style lang="scss">
  .ui-spinner {
    animation: spin 1.5s infinite linear;
  }

  .ui-spinner.absolute {
    @apply #{ absolute inset-0 m-auto };
  }
</style>
