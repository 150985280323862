import { analytics } from 'core/analytics/analytics.js';
window.analyticsShiftExchange = (function($) {

  /*
  public
  */

  var locationGroup, departmentGroup;
  // Initialize Analytics::Shift Exchange
  var init = function(settings) {
    var today = new Date();
    $.extend(config, {
      endDate: new Date(today.setFullYear(today.getFullYear(), today.getMonth(), today.getDate() + 7)),
    });
    initShiftExchange();

    locationGroup = $('.shift-exchange').find('#location-department-filter optgroup#location');
    departmentGroup = $('.shift-exchange').find('#location-department-filter optgroup#department');
  };

  /*
  private
  */

  // Default settings
  var config = {
    chartName: "shift-exchange",
    filterName: "data-set-filter"
  };

  var clearCustomDateRangeForShiftExchange = function() {
    analytics.clearCustomDateRange(".shift-exchange");
    getShiftExchange();
  };

  var getFilterDataHashForShiftExchange = function() {
    var dataFilter = $('option:selected', '#data-set-filter').val();
    var filterDataHash = { state: dataFilter }

    return filterDataHash;
  };

  var getShiftExchange = function() {
    var isInactive = $(this).hasClass('inactive');
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();
    var dateRange = analytics.filterDateRange($(this).text());
    var dataFilter = $('option:selected', '#data-set-filter').val();
    var exchangeType = analytics.filterExchangeType();
    config.endDate = analytics.filterEndDate($(this).attr('id'), config.endDate, dateRange);
    var dataHash = { locations: locationIds, departments: departmentIds, range: dateRange, state: dataFilter, endDate: $.datepicker.formatDate('yy/mm/dd', config.endDate), exchangeType: exchangeType }

    if (!isInactive) { $('#exchange-filter').hide(); }

    if (analytics.canMakeChartRequest(config.chartName, isInactive, locationIds, departmentIds)) {
      $('#exchange-filter').show();
      analytics.makeChartRequest(MKS.shift_exchange_url, dataHash);
    } else {
      return;
    }
  };

  var initShiftExchange = function() {
    analytics.initializePage($('.shift-exchange'), $('#shift-exchange-chart-box'));
    $('#exchange-filter').hide();
    $('.shift-exchange').find('#btn-group-date-range #date-range-days').removeClass('active');
    $('.shift-exchange').find('#btn-group-date-range #date-range-months').addClass('active');
    $('.shift-exchange').find('#data-set-filter').on("change", makeRequestForShiftExchange);

    $('.shift-exchange').find('#exchange_type_all').on("change", makeRequestForShiftExchange);
    $('.shift-exchange').find('#exchange_type_exchange').on("change", makeRequestForShiftExchange);
    $('.shift-exchange').find('#exchange_type_drop').on("change", makeRequestForShiftExchange);

    $('.shift-exchange').find('#location-department-filter').select2().on("change", removeShiftExchangeOptionGroup);
    $(".shift-exchange #submit-chart").on("click", makeRequestForShiftExchange);
    $(".shift-exchange #date-range-days, .shift-exchange #date-range-weeks, .shift-exchange #date-range-months, .shift-exchange #date-range-previous, .shift-exchange #date-range-next").on("click", getShiftExchange);

    $('.shift-exchange #set-custom-date-range').on("click", selectCustomDateRangeForShiftExchange);
    $('.shift-exchange #clear-custom-date-range').on("click", clearCustomDateRangeForShiftExchange);
  };

  var makeRequestForShiftExchange = function() {
    var locationIds = analytics.filterLocations();
    var departmentIds = analytics.filterDepartments();
    if (locationIds.length == 0 && departmentIds.length == 0) { analytics.clearCustomDateRange(".shift-exchange"); }
    if ($(".shift-exchange #date-range-days").hasClass('inactive')) {
      selectCustomDateRangeForShiftExchange();
    } else {
      getShiftExchange();
    }
  };

  var removeShiftExchangeOptionGroup = function() {
    analytics.removeOptionGroup($('.shift-exchange').find('#location-department-filter'), locationGroup, departmentGroup);
  };

  var selectCustomDateRangeForShiftExchange = function() {
    var url = MKS.shift_exchange_url;
    analytics.selectCustomDateRange(url, ".shift-exchange");

    // if the start & end date are already set, execute the ajax call, otherwise listen for a date change event
    if ($(".analytics #start-date").val() != "" && $(".analytics #end-date").val() != "") {
      analytics.requestCustomDateRange(url, getFilterDataHashForShiftExchange(), config.chartName, config.filterName);
    }
    else {
      $('.analytics .input-daterange').on("changeDate", function(event) {
        analytics.requestCustomDateRange(url, getFilterDataHashForShiftExchange(), config.chartName, config.filterName);
      });
    }
  };


  return {
    init: init
  };

})(jQuery);
