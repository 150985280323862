window.adminFeatures = (function($, window, document) {

  /*
  public
  */

  /**
   * Initiailizes the Admin Features Class
   * @param  {Hash} settings Additional settings to be used inside the class
]   */
  var init = function(settings) {
    $.extend(true, config, settings, {
      elements: {
        deauthorizingFeature: '.deauthorizing_feature'
      }
    });

    initToggleActions();
  };

  /**
   * Default configuration
   * @type {Object}
   */
  var config = {};

  /*
  private
  */

  /**
   * Initializes onChange events for feature toggles
   */
  var initToggleActions = function() {
    $('body').on('change', config.elements.deauthorizingFeature, function(e){
      e.preventDefault();
      var $self         = $(this),
          newValue      = $self.prop('checked'),
          originalValue = JSON.parse($self.data("original")),
          htmlWarning;

      if ((newValue === false) && (newValue !== originalValue)) {
        htmlWarning  = '<h4>' + I18n.t('features.js.warning') + '</h4>';
        htmlWarning += I18n.t('features.js.warning_detail', { name: config.companyName });
        bootbox.confirm(
          htmlWarning,
          I18n.t('buttons.cancel'),
          I18n.t('buttons.ok'),
          function(result) {
            if (!result) {
              $self.prop('checked', $self.data("original"));
            }
          }
        );
      }
    });
  };

  return {
    init: init,
  };

})(jQuery, window, document);
