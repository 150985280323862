<template>
  <div :class="{'ui-datatable-container': true, 'bootstrap': bootstrap}">
    <ui-spinner :show="!bootstrap && loading" />
    <table ref="datatable">
      <!--
        You can define custom templates to slot for certain columns.
        These templates MUST be static, meaning they cannot have functions
        attached to them.

        Example:

        <template
          slot="actions"   // Column name
          slot-scope="ctx" // Injected data object
        >
          <button class="btn btn-gray" data-action="Edit">{{ctx.data}}</button> // Will be rendered
        </template>
      -->
    </table>
  </div>
</template>

<script>
import UiSpinner from './ui_spinner';
import UiCheckbox from './ui_checkbox';

export default {
  name: 'UiDatatable',
  props: {
    /**
     * Use old school bootstrap styles
     */
    bootstrap:           Boolean,

    /**
     * Show ui-spinner
     */
    loading:             Boolean,

    /**
     * Show the search bar
     */
    includeSearch:       Boolean,

    /**
     * Show the page size dropdown
     */
    includePageSize:     Boolean,

    /**
     * Show the pagination buttons
     */
    includePagination:   Boolean,

    /**
     * Show the loading indicator
     */
    includeProcessing:   Boolean,

    /**
     * Show the information text
     */
    includeInformation:  Boolean,

    /**
     * Allow for row selection
     */
    includeRowSelection: Boolean,

    /**
     * Remove the "odd" and "even" classes used for zebra stripe styling
     */
    disableZebraStriping: Boolean,

    /**
     * Passing a string here will enable that column's data property to be the grouping source
     */
    rowGroup: String,

    /**
     * URL to retrieve json data
     */
    source:              String,

    /**
     * Pass data inline as an array
     * https://datatables.net/examples/data_sources/js_array.html
     */
    inlineData:          Array,

    /**
     * Define columns for table
     *
     * Example:
     *
     * Source returns [[123, "Bob Smith", "bob.smith@test.com"]]
     *
     * fields: {
     *   id: {          // Keys are used as column identifiers unless specified
     *     title: "ID", // Column header title
     *     data: 0,     // Index from json data
     *   },
     *   name: {
     *     title: "Name",
     *     data: 1,
     *     width: "25%",
     *   },
     *   email: {
     *     title: "Email",
     *     data: 2,
     *     width: "30%",
     *   }
     * }
     */
    fields:              Object,

    /**
     * Extra DataTable options
     * https://datatables.net/manual/options
     */
    opts:                Object,
  },
  watch: {
    inlineData: function(newVal, oldVal) {
      this.setTableData(newVal);
    }
  },
  data() {
    return {
      tableEl: null,
      table:   null,
      options: {},
    };
  },
  created() {
    // This fixes in issue where links are sorted separately
    // See https://datatables.net/forums/discussion/43509/links-and-plain-text-are-sorted-separately
    $.fn.dataTable.ext.type.order['html-pre'] = (a) => {
      return !a ? ''
                : a.replace
                  ? $.trim(a.replace(/<.*?>/g, '').replace(/[\u200b]/g, '').toLowerCase())
                  : a + '';
    };
  },
  mounted() {
    if (!this.fields) {
      alert('ERROR! Need to define fields prop in order to display data.');
      return;
    }
    this.options = this.buildDataTableOptions();
    this.tableEl = this.$refs.datatable;
    this.table   = $(this.tableEl).DataTable(this.options);
    this.initHandlers();
  },
  beforeDestroy() {
    if (this.table) {
      this.table.destroy(true);
    }
    this.table = null;
  },
  methods: {
    /**
     * Build out complete DataTable options with given props
     */
    buildDataTableOptions() {
      const options = {
        language:       window.dataTables_i18n,
        autoWidth:      false,
        processing:     true,
        pagingType:     'full_numbers',
        paging:         this.includePagination,
        fnDrawCallback: this.fnDrawCallback,
        dom:            this.buildDomString(),
        columns:        [],
        order:          [],
        rowGroup:       { dataSrc: this.rowGroup, enable: true },
        ...this.opts,
      };
      let icol     = 0;
      let startCol = 0;

      if (this.rowGroup === undefined) {
        options.rowGroup = { enable: false };
      }

      if (this.source) {
        options.ajaxSource = this.source;
        options.serverSide = true;
      }

      if (this.disableZebraStriping) {
        options.stripeClasses = [];
      }

      if (this.inlineData) {
        options.data = this.inlineData;
      }

      if (this.fields) {
        for (let k in this.fields) {
          const field = this.fields[k];
          field.name  = field.name || k;
          // disable search and sort for local field
          if (field.isLocal) {
            field.searchable = false;
            field.sortable   = false;
          }
          // generate
          const col = {
            title:     field.title || field.name,
            data:      field.data,
            width:     field.width,
            name:      field.name,
            className: field.className,
            index:     field.index || (icol + 1),
          }
          if (field.hasOwnProperty('width')) {
            col.width = field.width;
          }
          if (field.hasOwnProperty('defaultContent')) {
            col.defaultContent = field.defaultContent;
          }
          if (field.hasOwnProperty('sortable')) {
            col.sortable = field.sortable;
          }
          if (field.hasOwnProperty('visible')) {
            col.visible = field.visible;
          }
          if (field.hasOwnProperty('searchable')) {
            col.searchable = field.searchable;
          }
          if (field.template || this.$scopedSlots[field.name]) {
            field.render = this.compileTemplate(field, this.$scopedSlots[field.name]);
          }
          if (field.render) {
            if (!field.render.templated) {
              let myRender = field.render;
              field.render = () => myRender.apply(this, Array.prototype.slice.call(arguments));
            }
            col.render = field.render;
          }

          options.columns.push(col);

          if (field.defaultOrder) {
            options.order.push([icol, field.defaultOrder]);
          }

          icol++;
        }

        // sort columns
        options.columns = options.columns.sort((a, b) => a.index - b.index);
      }

      if (this.includeRowSelection) {
        // create checkbox column
        const col = {
          index:          0,
          orderable:      false,
          searchable:     false,
          sortable:       false,
          className:      null,
          data:           null,
          width:          '2.5%',
          name:           '_select_checkbox',
          defaultContent: this.bootstrap
                            ? '<input type="checkbox" class="select-checkbox" />'
                            : $(new Vue({...UiCheckbox, propsData: { icon: 'dash', small: true, inputClass: 'select-checkbox' }}).$mount().$el)[0].outerHTML,
          title:          this.bootstrap
                            ? '<input type="checkbox" class="select-all-checkbox" />'
                            : $(new Vue({...UiCheckbox, propsData: { icon: 'dash', small: true, inputClass: 'select-all-checkbox' }}).$mount().$el)[0].outerHTML,
        }
        options.columns.unshift(col);
        options.select = {
          style:    'multi',
          selector: 'td input.select-checkbox',
        };
        startCol++;
      }

      if (startCol > 0) {
        if (options.order.length > 0) {
          options.order.forEach((v) => {
            v[0] += startCol;
          });
        } else {
          options.order = [[startCol, 'asc']];
        }
      }

      return options;
    },

    /**
     * Build out DataTable DOM string when given certain props
     */
    buildDomString() {
      return `${this.buildTableHeaderString()}<"table-responsive"t>${this.buildTableFooterString()}`;
    },
    buildTableHeaderString() {
      let ext = '';
      if (this.includeSearch)     ext += 'f';
      if (this.includePageSize)   ext += 'l';
      if (this.includeProcessing) ext += 'r';
      if (ext.length > 0) return `<"table-header"${ext}>`;
                          return '';
    },
    buildTableFooterString() {
      let ext = '';
      if (this.includePagination)  ext += 'p';
      if (this.includeInformation) ext += 'i';
      if (ext.length > 0) return `<"table-footer"${ext}>`;
                          return '';
    },

    /**
     * Initialize handlers for jQuery and DataTable events to emit them as Vue events
     */
    initHandlers() {
      if (this.includeRowSelection) {
        // handle select all checkbox
        $(this.tableEl).on('click', 'th input.select-all-checkbox', (e) => {
          const $rowCheckboxes = $(this.tableEl).find('td input.select-checkbox');
          if ($(e.target).is(':checked')) {
            $rowCheckboxes.prop('checked', true);
            this.table.rows().select();
          } else {
            $rowCheckboxes.prop('checked', false);
            this.table.rows().deselect();
          }
        });
        // handle individual row select events
        this.table
          .on('select.dt deselect.dt', (e) => {
            const $headerCheckbox = $(this.tableEl).find('th input.select-all-checkbox');
            if (this.table.rows({ selected: true }).count() !== this.table.rows().count()) {
              $headerCheckbox.prop('checked', false);
            } else {
              $headerCheckbox.prop('checked', true);
            }
          })
          .on('select.dt',   (e) => { this.$emit('row-selected',   this.table.rows({ selected: true }).data().toArray()); })
          .on('deselect.dt', (e) => { this.$emit('row-deselected', this.table.rows({ selected: true }).data().toArray()); });
      }

      // wire up edit, delete, and/or action buttons
      $(this.tableEl).on('click', '[data-action]', (e) => {
        e.preventDefault();
        e.stopPropagation();
        let target = $(e.target);
        let action = target.attr('data-action');
        while (!action) {
          // don't let it propagate outside of container
          if (target.hasClass('datatable-container') || target.prop('tagName') === 'table') {
            // no action, simply exit
            return;
          }
          target = target.parent();
          action = target.attr('data-action');
        }
        // only emit if there is action
        if (action) {
          // detect if row action
          let tr = target.closest('tr');
          if (tr) {
            if (tr.attr('role') !== 'row') {
              tr = tr.prev();
            }
            const row  = this.table.row(tr);
            const data = row.data();
            this.$emit(action, data, row, tr, target);
          } else {
            // not a row click, must be other kind of action
            // such as bulk, csv, pdf, etc...
            this.$emit(action, null, null, null, target);
          }
        }
      });
    },

    /**
     * Vue.compile a template string and return the compiled function
     *
     * @param  {Object} field object with template property
     * @param  {Object} slot the slot
     * @return {Function} the compiled template function
     */
    compileTemplate(field, slot) {
      const res = Vue.compile(`<div>${field.template || ''}</div>`);
      const renderFunc = (data, type, row, meta) => {
        const dataObj = {
          data:    data,
          type:    type,
          row:     row,
          meta:    meta,
          wrapper: this,
          def:     field,
          comp:    this.$parent,
        };

        let myRender = res.render;
        if (slot) {
          myRender = (createElement) => createElement('div', [slot(dataObj)]);
        }

        const comp = new Vue({
          data:            dataObj,
          render:          myRender,
          staticRenderFns: res.staticRenderFns,
        }).$mount();

        return $(comp.$el).html();
      }

      renderFunc.templated = true;
      return renderFunc;
    },

    /**
     * Set table data array that was loaded from somewhere else
     * This method allow for local setting of data; though, it
     * is recommended to use ajax instead of this.
     *
     * @param {Array} data the array of data
     * @return {Object} the component
     */
    setTableData(data) {
      if (data.constructor === Array) {
        this.table.clear().rows.add(data);
        this.table.page(0);
        this.table.draw(false);
        this.table.columns.adjust();
      }
      return this;
    },

    /**
     * Pass through reload method
     *
     * @param {Boolean} resetPaging true to reset current page position
     * @return {Object} the component
     */
    reload(resetPaging = false) {
      this.table.ajax.reload(
        (data) => { this.$emit('reloaded', data, this); },
        resetPaging
      );
      return this;
    },

    /**
     * Pass through search method
     *
     * @param {String} value search query
     * @return {Object} the component
     */
    search(value) {
      this.table.search(value).draw();
      return this;
    },

    /**
     * Reset checkbox on draw
     */
    fnDrawCallback() {
      if (this.includeRowSelection && this.table) {
        $(this.tableEl).find('.select-all-checkbox').prop('checked', false);
        this.table.rows().deselect();
      }
    },
  },
  components: {
    UiSpinner,
    UiCheckbox,
  },
  example: `
<template>
  <div>
    <h4 class="my-6 border-b">DataTable</h4>
    <h6>New</h6>
    <ui-datatable
      ref="new_example1"
      :fields="fields"
    >
    </ui-datatable>

    <h6>Bootstrap</h6>
    <ui-datatable
      bootstrap
      ref="example1"
      :fields="fields"
    >
    </ui-datatable>

    <h4 class="my-6 border-b">Search</h4>
    <h6>New</h6>
    <ui-datatable
      ref="new_example2"
      include-search
      :fields="fields"
    >
    </ui-datatable>

    <h6>Bootstrap</h6>
    <ui-datatable
      ref="example2"
      bootstrap
      include-search
      :fields="fields"
    >
    </ui-datatable>

    <h4 class="my-6 border-b">Page Size / Pagination</h4>
    <h6>New</h6>
    <ui-datatable
      ref="new_example3"
      include-page-size
      include-pagination
      :fields="fields"
    >
    </ui-datatable>

    <h6>Bootstrap</h6>
    <ui-datatable
      ref="example3"
      bootstrap
      include-page-size
      include-pagination
      :fields="fields"
    >
    </ui-datatable>

    <h4 class="my-6 border-b">Information</h4>
    <h6>New</h6>
    <ui-datatable
      ref="new_example4"
      include-information
      :fields="fields"
    >
    </ui-datatable>

    <h6>Bootstrap</h6>
    <ui-datatable
      ref="example4"
      bootstrap
      include-information
      :fields="fields"
    >
    </ui-datatable>

    <h4 class="my-6 border-b">Row Selection</h4>
    <h6>New</h6>
    <ui-datatable
      ref="new_example5"
      include-row-selection
      :fields="fields"
    >
    </ui-datatable>

    <h6>Bootstrap</h6>
    <ui-datatable
      ref="example5"
      bootstrap
      include-row-selection
      :fields="fields"
    >
    </ui-datatable>

    <h4 class="my-6 border-b">Disable "Zebra Stripe" Odd/Even Classes</h4>
    <h6>Bootstrap only</h6>
    <ui-datatable
      ref="example6"
      bootstrap
      disable-zebra-striping
      :fields="fields"
    >
    </ui-datatable>

    <h4 class="my-6 border-b">Template Columns</h4>
    <h6>New</h6>
    <ui-datatable
      ref="new_example7"
      :fields="fields"
    >
      <template
        slot="full_name"
        slot-scope="ctx"
      >
        <b>{{ctx.data}}</b>
      </template>
    </ui-datatable>

    <h6>Bootstrap</h6>
    <ui-datatable
      ref="example7"
      bootstrap
      :fields="fields"
    >
      <template
        slot="full_name"
        slot-scope="ctx"
      >
        <b>{{ctx.data}}</b>
      </template>
    </ui-datatable>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        sampleData: [
          [1, 'Brendan Walker', 'bwalker@appcolony.ca'],
          [2, 'Ryan Bosinger', 'rbosinger@appcolony.ca'],
          [3, 'Ian Clarkson', 'iclarkson@appcolony.ca'],
        ],
        fields: {
          id: {
            title: '<div>ID</div>',
            data: 0,
            width: '10%',
          },
          full_name: {
            title: '<div>Full Name</div>',
            data: 1,
            width: '30%',
          },
          email: {
            title: '<div>Email</div>',
            data: 2,
            width: '60%',
          }
        }
      }
    },
    mounted() {
      Object.values(this.$refs).forEach((ref) => {
        ref.setTableData(this.sampleData);
      });
    },
  }
<\/script>
`
}
</script>

<style lang="scss">

  //////////////////////////
  // MakeShift 1.0 styles //
  //////////////////////////
  .ui-datatable-container.bootstrap {

    .table-header {
      border-bottom: 1px solid #eaebef;
      box-shadow: 0 1px 0 #fff inset;
      clear: both;
      padding: 0 16px;
      font-size: 11px;
      color: #666;
      background: #eaeaea;
    }

    .table-responsive {
      background: white;
    }

    .dataTables_processing {
      text-align: center;
    }

    .dataTables_length {
      label {
        display: inline-flex;
        align-items: center;
      }

      select, .selector {
        margin: 0 5px;
      }
    }

    .dataTables_info {
      clear: none;
      float: left;
      padding: 10px 0;

      .select-item {
        margin-left: 5px;
      }
    }

    .dataTables_paginate {
      float: right;
      margin-top: 10px;
    }

    .dataTable tr.selected {
      background-color: #d9edf7;
    }

    .paging_full_numbers {
      height: 22px;
      line-height: 22px;
    }

    .paginate_button {
      border-top: 1px solid #E2E2E2;
      border-left: 1px solid #C9C9C9;
      border-right: 1px solid #C9C9C9;
      border-bottom: 1px solid #AEAEAE;
      border-radius: 3px;
      padding: 4px 7px;
      margin: 0 3px;
      color: #666;
      font-weight: 600;
      text-shadow: 0 1px rgba(255, 255, 255, 0.5);
      font-size: 11px;
      cursor: pointer;
    }

    .select-checkbox,
    .select-all-checkbox {
      display: block;
      margin: auto;
      text-align: center;
    }

    .sorting > div,
    .sorting_asc > div,
    .sorting_desc > div {
      position: relative;
      height: unset;
      min-height: 20px;
      line-height: 20px;
      padding-right: 15px;
    }
  }

  //////////////////////////
  // MakeShift 2.0 styles //
  //////////////////////////
  .ui-datatable-container:not(.bootstrap) {
    @apply #{ relative shadow-md bg-white rounded-md px-6 };

    table.dataTable thead::after {
      @apply #{ absolute };
      content: '';
      height: 1px;
      left: -1.5rem;
      right: -1.5rem;
      background: #eaebef;
    }

    table.dataTable thead th {
      @apply #{ text-gray font-normal uppercase border-none text-left };
      padding: 8px 11px 7px 11px;
      font-size: 11px;
      line-height: unset;
    }

    table.dataTable thead tr {
      @apply #{ border-t-0 };
    }

    table.dataTable thead tr,
    table.dataTable tr,
    table.dataTable tr.even,
    .table-responsive {
      background: transparent;
    }

    table.dataTable tbody tr td {
      @apply #{ text-graphite border-l-0 };
      padding: 15px 11px;
      font-size: 13px;
    }

    .table-header {
      @apply #{ border-b-0 };
    }

    .table-footer {
      @apply #{ px-0 };
      border-top-color: #eaebef;
      background: transparent;
    }

    .dataTables_processing {
      @apply #{ text-center };
    }

    .dataTables_filter {
      margin-left: -5px;
    }

    .dataTables_length {
      label {
        @apply #{ inline-flex items-center };
      }

      select, .selector {
        margin: 0 5px;
      }
    }

    .dataTables_info {
      @apply #{ text-gray float-left };
      clear: none;
      padding: 10px 0;

      .select-item {
        margin-left: 5px;
      }
    }

    .dataTables_paginate {
      @apply #{ float-right };
      margin-top: 10px;
    }

    .dataTable tr.selected {
      background-color: transparent;
    }

    .paging_full_numbers {
      height: 22px;
      line-height: 22px;
    }

    .paginate_button {
      @apply #{ text-gray rounded cursor-pointer };
      background: white;
      background-image: none;
      border: 1px solid #E2E2E2;
      padding: 4px 7px;
      margin: 0 3px;
      font-size: 11px;

      &:hover,
      &:active  {
        @apply #{ shadow-none };
        background: #E2E2E2;
        border: 1px solid #E2E2E2;
      }
    }

    .sorting > div,
    .sorting_asc > div,
    .sorting_desc > div {
      @apply #{ relative };
      height: unset;
      min-height: 20px;
      line-height: 20px;

      &::after {
        @apply #{ relative };
        top: 1px;
        right: unset;
        margin-left: 3px;
      }
    }

    .sorting_asc,
    .sorting_desc {
      background: transparent !important;
    }

    .ui-checkbox-container {
      margin-bottom: 15px;
    }
  }
</style>
