<template>
  <div 
    class="modal hide fade" 
    tabindex="-1" 
    role="dialog" 
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-header">
      <button 
        type="button" 
        class="close" 
        data-dismiss="modal" 
        aria-hidden="true"
      >
        ×
      </button>
      <h3>{{title}}</h3>
    </div>
    <div class="modal-body">
      <slot name="body" />
    </div>
    <div class="modal-footer">
      <slot name="footer">
        <button 
          class="btn" 
          data-dismiss="modal" 
          aria-hidden="true"
        >
          Cancel
        </button>
        <button class="btn btn-primary">
          Confirm
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    title: String
  }
}
</script>
