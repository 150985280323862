<template>
  <users-table 
    :custom-id-column-name="I18n.t('integrations.quickbooks.attributes.payroll_id')"
    :async-import-func="importTsheetsUsers"
    :async-archive-func="archiveMakeshiftUser"
    :async-refresh-func="cacheTsheetsUsers"
    :table-data="tableData"
    show-refresh-button
  >
    <template slot="header">
      <slot name="header" />
    </template>
  </users-table>
</template>

<script>
import UsersTable from '../users_table';

export default {
  name: 'TsheetsUsersTable',
  props: {
    initialTsheetsUsers:   Array,
    initialMakeshiftUsers: Array,
    initialErrors:         Array,
    pusherKey:             String,
    integrationId:         Number
  },
  data() {
    return {
      tsheetsUsers: this.initialTsheetsUsers,
      makeshiftUsers: this.initialMakeshiftUsers,
      errors: this.initialErrors,
      tableData: [],
      pusher: null,
    }
  },
  created() {
    this.mergeUserInformation();
    this.registerListeners();
  },
  methods: {
    mergeUserInformation() {
      const mergedUsers = this.tsheetsUsers.map((tsheetsUser) => {
        const makeshiftUser  = _.findWhere(this.makeshiftUsers, { external_id: tsheetsUser.external_id }) || {};
        const error          = _.findWhere(this.errors, { status: 'failed', external_id: tsheetsUser.external_id }) || {};
        const name           = _.compact([tsheetsUser.first_name, tsheetsUser.last_name]).join(' ');
        const role           = I18n.t('roles.' + (makeshiftUser.role || 'employee'));
        const employmentType = I18n.t('employment_type.' + (makeshiftUser.employment_type_name ? makeshiftUser.employment_type_name.toLowerCase().replace(/\s/, '_') : 'full_time'));

        return [
          error.status_error || '',
          tsheetsUser.payroll_id,
          name,
          tsheetsUser.email,
          tsheetsUser.data['phone'],
          tsheetsUser.data['hire_date'],
          role,
          employmentType,
          {
            makeshift_id:   makeshiftUser.id,
            integration_id: tsheetsUser.external_id,
          },
        ];
      });

      this.tableData = mergedUsers;
    },

    registerListeners() {
      this.pusher = window.getPusherInstance(this.pusherKey);
      this.pusher.subscribe(`tsheets-user-import-${this.integrationId}`);
    },

    cacheTsheetsUsers() {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: '/integrations/tsheets/refresh_cached_users',
          method: 'POST',
          success: () => {
            // Need to bind and unbind afterward in order to keep things async
            // and clear previous bound events
            this.pusher.bind('tsheets-user-import-refresh-complete', () => {
              this.getUsers().then(resolve).catch(reject);
              this.pusher.unbind('tsheets-user-import-refresh-complete');
            });
          },
          error: (xhr) => {
            console.log(xhr);
            reject();
          }
        });
      });
    },

    importTsheetsUsers(tsheetsIds = []) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: '/integrations/tsheets/users',
          method: 'POST',
          data: {
            tsheets_external_ids: tsheetsIds.join(','),
          },
          success: (xhr) => {
            const pusherChannel = this.pusher.subscribe(`tsheets-user-import-${xhr.id}`);
            pusherChannel.bind('tsheets-user-import-complete', () => {
              this.getUsers().then(resolve).catch(reject);
            });
          },
          error: (xhr) => {
            console.log(xhr);
            reject();
          }
        });
      });
    },

    archiveMakeshiftUser(makeshiftId) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `/integrations/tsheets/users/${makeshiftId}`,
          method: 'DELETE',
          success: (xhr) => {
            this.makeshiftUsers = xhr.makeshift_users;
            this.tsheetsUsers = xhr.tsheets_users;
            this.errors = xhr.errors;
            this.mergeUserInformation();
            resolve();
          },
          error: (xhr) => {
            console.log(xhr);
            reject();
          }
        });
      });
    },

    getUsers() {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: '/integrations/tsheets/users',
          method: 'GET',
          dataType: 'json',
          success: (xhr) => {
            this.makeshiftUsers = xhr.makeshift_users;
            this.tsheetsUsers = xhr.tsheets_users;
            this.errors = xhr.errors;
            this.mergeUserInformation();
            resolve();
          },
          error: (xhr) => {
            console.log(xhr);
            reject();
          }
        });
      });
    }
  },
  components: {
    UsersTable,
  }
}
</script>
