<template>
  <div class="budget-bar-toggle span2">
    <div class="btn-group">
      <button
        id="dollars-view"
        :class="buttonClasses('dollars')"
        @click="onClick('dollars')"
      >
        {{I18n.t('budget_bar.js.dollars')}}
      </button>
      <button
        id="hours-view"
        :class="buttonClasses('hours')"
        @click="onClick('hours')"
      >
        {{I18n.t('budget_bar.js.hours')}}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode:    String,
    onClick: Function,
  },
  methods: {
    buttonClasses(type) {
      return {
        'btn btn-small': true,
        'btn-current': type === this.mode,
      };
    }
  }
}
</script>
