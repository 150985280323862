<template>
  <div class="row-fluid">
    <div class="span12">
      <div class="users box">
        
        <div class="box-header">
          <slot name="header" />
          <button
            v-if="selectedIntegrationIds.length > 0"
            @click="handleImportOrRefresh"
            data-action="import"
            class="btn btn-small btn-success"
          >
            {{I18n.t('integrations.index.import_selected')}}
          </button>
          <button
            v-if="showRefreshButton"
            @click="handleImportOrRefresh"
            data-action="refresh"
            class="btn btn-small btn-success btn-refresh"
          >
            {{I18n.t('integrations.index.refresh')}}
          </button>
        </div>

        <ui-datatable
          ref="datatable"
          bootstrap
          include-row-selection
          :fields="fields"
          @import="handleImportOrArchive"
          @archive="handleImportOrArchive"
          @row-selected="handleSelection"
          @row-deselected="handleSelection"
        >
          <template
            slot="errors"
            slot-scope="ctx"
          >
            <div v-if="ctx.data" class="text-center">
              <i
                class="fa fa-exclamation-triangle"
                data-toggle="tooltip"
                data-container="body"
                data-placement="right"
                :data-original-title="ctx.data"
                style="color: red;"
              >
              </i>
            </div>
          </template>
          <template
            slot="name"
            slot-scope="ctx"
          >
            <a
              v-if="ctx.row[8].makeshift_id"
              :href="`/users/${ctx.row[8].makeshift_id}`"
            >
              {{ctx.data}}
            </a>
            <div v-else>{{ctx.data}}</div>
          </template>
          <template
            slot="action"
            slot-scope="ctx"
          >
            <button
              v-if="!ctx.data.makeshift_id"
              data-action="import"
              class="btn btn-success"
            >
              {{I18n.t('integrations.index.import')}}
            </button>
            <button
              v-else
              data-action="archive"
              class="btn btn-danger"
            >
              {{I18n.t('integrations.index.archive')}}
            </button>
          </template>
        </ui-datatable>
      </div>
    </div>
  </div>
</template>

<script>
import UiDatatable from 'core/vue/common/ui/ui_datatable';

export default {
  name: 'UsersTable',
  props: {
    customIdColumnName: String,
    showRefreshButton:  Boolean,
    tableData:          Array,
    asyncImportFunc:    Function,
    asyncArchiveFunc:   Function,
    asyncRefreshFunc:   Function,
  },
  data() {
    return {
      selectedIntegrationIds: [],

      // DataTable
      fields: {
        errors: {
          title: '<div></div>',
          data: 0,
          width: '2.5%',
          defaultOrder: 'desc',
        },
        custom_id: {
          title: this.customIdColumnName,
          data: 1,
        },
        name: {
          title: I18n.t('activerecord.attributes.user.name'),
          data: 2,
        },
        email: {
          title: I18n.t('activerecord.attributes.user.email'),
          data: 3,
        },
        phone_number: {
          title: I18n.t('activerecord.attributes.user.phone_number'),
          data: 4,
          width: '12.5%',
        },
        hire_date: {
          title: I18n.t('activerecord.attributes.user.hire_date'),
          data: 5,
          width: '10%',
        },
        role: {
          title: I18n.t('activerecord.attributes.user.role'),
          data: 6,
        },
        employment_type: {
          title: I18n.t('activerecord.attributes.user.employment_type_id'),
          data: 7,
          width: '12.5%',
        },
        action: {
          title: I18n.t('integrations.index.action'),
          data: 8,
          width: '10%',
        }
      }
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    tableData() {
      this.init();
    }
  },
  methods: {
    init() {
      this.$refs.datatable.setTableData(this.tableData);
      $('[data-toggle=tooltip]').tooltip();
    },

    handleSelection(data) {
      this.selectedIntegrationIds = data.map((arr) => arr[8].integration_id);
    },

    async handleImportOrRefresh(event) {
      const $button     = $(event.target);
      const revertText  = $button.text();
      const isImporting = $button.data().action === 'import';

      $button.prop('disabled', true)
             .html(I18n.t('buttons.disable_with.processing'));

      try {
        if (isImporting) {
          await this.asyncImportFunc(this.selectedIntegrationIds);
        } else {
          await this.asyncRefreshFunc();
        }
        this.selectedIntegrationIds = [];
      } finally {
        $button.prop('disabled', false)
               .html(revertText);
      }
    },

    async handleImportOrArchive(data, row, tr, target) {
      const { integration_id, makeshift_id } = data[8];
      const $button     = $(target);
      const revertText  = $button.text();
      const isImporting = $button.data().action === 'import';

      $button.prop('disabled', true)
             .html(I18n.t('buttons.disable_with.processing'));

      try {
        if (isImporting) {
          await this.asyncImportFunc([integration_id]);
        } else {
          await this.asyncArchiveFunc(makeshift_id);
        }
      } catch (e) {
        $button.prop('disabled', false)
               .html(revertText);
      }
    },
  },
  components: {
    UiDatatable,
  }
}
</script>
